import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import { getEndPoint } from "../../class/api";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import $ from 'jquery'
import { ReactSession } from 'react-client-session'
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
class RecipeAdd extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);
        this.state = {
            typeCount: []
        };
        this.sonKullanmaTarihRef = React.createRef(null);
        this.cellPhoneRef = React.createRef(null);
        this.nameRef = React.createRef();
        this.statusRef = React.createRef();
        this.financeTypeRef = React.createRef();
        this.vehicleType = React.createRef();
        this.firstDateRef = React.createRef();
        this.lastDateRef = React.createRef();

    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props
        DateRangePickerHandler(this.firstDateRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            minDate: moment().startOf('day').format('DD/MM/YYYY'),
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.lastDateRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            minDate: moment().startOf('day').format('DD/MM/YYYY'),
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        var bikeTypeList = ReactSession.get('bikeTypes');
        var bikeSelect = $(this.vehicleType.current);
        $(this.vehicleType.current).empty();
        bikeTypeList.reverse().forEach((element, index) => {
            var option = new Option(
                element.Name,
                element.VehicleTypeId,
                true,
                true);
            bikeSelect.append(option).trigger('change');
        });

        this.state.typeCount.forEach((element, index) => {
            var id = element.current.attributes.id.value
            var bikeSelect = $('#VehicleTypeId' + id);
            $('#VehicleTypeId' + id).empty();
            bikeTypeList.forEach((element, index) => {
                var option = new Option(
                    element.Name,
                    element.VehicleTypeId,
                    true,
                    true);
                bikeSelect.append(option).trigger('change');
            });
        });
    }

    componentDidUpdate = () => {
        this.componentDidMount()

    };
    typeClick = () => {
        this.state.typeCount.push(React.createRef());
        this.setState({
            typeCount: this.state.typeCount
        })
    }
    parentValueChange = (valueType, type) => {
        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    saveRecipe = () => {
        const { t } = this.props
        var name = this.nameRef.current.value
        var status = this.statusRef.current.value
        var financeType = this.financeTypeRef.current.value
        var vehicleType = this.vehicleType.current.value
        var firstDate = this.firstDateRef.current.value
        var lastDatedemo = this.lastDateRef.current.value
        var detailsData = [];
        var validateStatus = 0;
        const _this = this;

        if (name == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("name") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return;
        }
        if (financeType == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("financeType") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return;
        }
        if (vehicleType == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("bikeType") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return;
        }
        this.state.typeCount.forEach((element, index) => {
            var id = element.current.attributes.id.value

            if ($('#Name' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("name") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false;
            }
            if ($('#FirstMinute' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("FirstMinute") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false;
            }
            if ($('#EndMinute' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("EndMinute") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false
            }
            if ($('#Unit' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("Unit") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false
            }
            if ($('#UnitOperator' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("UnitOperator") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false
            }
            if ($('#Fee' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("Fee") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false
            }
            if ($('#Currency' + id).val() == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("details") + ' ' + t("Currency") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                validateStatus += 1
                return false
            }

            detailsData.push({
                "VehicleTypeId": $('#VehicleTypeId' + id).val(),
                "Name": $('#Name' + id).val(),
                "FirstMinute": $('#FirstMinute' + id).val(),
                "EndMinute": $('#EndMinute' + id).val(),
                "Unit": $('#Unit' + id).val(),
                "UnitOperator": $('#UnitOperator' + id).val(),
                "Fee": $('#Fee' + id).val(),
                "Currency": $('#Currency' + id).val(),
                "Status": ($('#Status' + id).val() == "true")
            })
        });
        var data = {
            "FinanceTypeId": financeType,
            "VehicleTypeId": vehicleType,
            "Name": name,
            "StartDate": Functions.getFormatDBDateHours($(this.firstDateRef.current).val() + ' ' + firstDateHours),
            "EndDate": Functions.getFormatDBDateHours($(this.lastDateRef.current).val() + ' ' + lastDateHours),
            "Status": (status == "true"),
            "Details": detailsData
        }
        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Finance/Tariff',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };
        if (validateStatus == 0) {
            axios(config)
                .then(function (response) {
                    swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: t("swal.success.successMessage", { ns: "translations" }),
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }

    }
    onRemoveDetailsButtonClick = (e, elm, index) => {
        delete this.state.typeCount[index];
        this.setState({
            typeCount: this.state.typeCount
        })
    }
    render() {
        const { t } = this.props;

        return (
            <div className="recipe-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("recipes")}</h6>
                        <button className="btn btn-warning" onClick={this.typeClick}>{t("typeAdd")}</button>
                    </div>
                    <div className="card-body py-2">

                        <div className="form-group row">
                            <label className="col-form-label col-lg-2">{t("name")}</label>

                            <div className="col-lg-4">
                                <input ref={this.nameRef} type="text" className="form-control" />
                            </div>

                            <label className="col-form-label col-lg-2">{t("status.status")}</label>

                            <div className="col-lg-4">
                                <select ref={this.statusRef} type="text" className="form-control" >
                                    <option value={true}>{t("status.select1")}</option>
                                    <option value={false}>{t("status.select2")}</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-form-label col-lg-2">{t("financeType")}</label>

                            <div className="col-lg-4">
                                <input ref={this.financeTypeRef} type="number" className="form-control" />
                            </div>

                            <label className="col-form-label col-lg-2">{t("bikeType")}</label>

                            <div className="col-lg-4">
                                <select ref={this.vehicleType} className="form-control" />
                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-form-label col-lg-2">{t("firstDate")}</label>

                            <div className="col-lg-2">
                                <input ref={this.firstDateRef} type="text" className="form-control dateTime" />

                            </div>
                            <div className="col-lg-2">
                                <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                            </div>
                            <label className="col-form-label col-lg-2">{t("lastDate")}</label>

                            <div className="col-lg-2">
                                <input ref={this.lastDateRef} type="text" className="form-control dateTime" />

                            </div>
                            <div className="col-lg-2">
                                <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                            </div>
                        </div>
                        {this.state.typeCount.length !== 0 && <p><strong>{t("details")}</strong></p>}

                        <>
                            {this.state.typeCount.map((element, index) => {

                                return (
                                    <div key={index} id={index} ref={element} className="col-12 row">
                                        <div className="col-12">

                                            <a href="#" onClick={(e) => this.onRemoveDetailsButtonClick(e, element, index)}>
                                                <span className="mr-2 text-danger"><i className="fas fa-window-close"></i></span>
                                            </a>
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("bikeType")} </label>
                                            <select id={"VehicleTypeId" + index} className={"form-control VehicleTypeId" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("name")} </label>
                                            <input type="text" id={"Name" + index} className={"form-control Name" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("firstMin")} </label>
                                            <input type="number" id={"FirstMinute" + index} className={"form-control date FirstMinute" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("lastMin")} </label>
                                            <input type="number" id={"EndMinute" + index} className={"form-control date EndMinute" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("unit")} </label>
                                            <input type="number" id={"Unit" + index} className={"form-control Unit" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("unitOperator")} </label>
                                            <input type="number" id={"UnitOperator" + index} className={"form-control UnitOperator" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("fee")} </label>
                                            <input type="text" id={"Fee" + index} className={"form-control Fee" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("Currency")} </label>
                                            <input type="text" id={"Currency" + index} className={"form-control Currency" + index} />
                                        </div>
                                        <div className="form-group col" >
                                            <label >{t("status.status")}  </label>
                                            <select id={"Status" + index} className={"form-control Status" + index}>
                                                <option value={true}>{t("status.select1")}</option>
                                                <option value={false}>{t("status.select2")}</option>
                                            </select>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        <a className="btn col-12 btn-success" onClick={this.saveRecipe}>{t("saveChanges")}</a>
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation(['recipe', 'translations'])(RecipeAdd);
