import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import i18n from "../../i18n/i18n";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import { getEndPoint } from "../../class/api";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import jq from 'jquery'
import { ReactSession } from 'react-client-session'
import DatePicker from "../../components/elements/DatePicker";
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
class NotificationPost extends React.Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        const { t } = this.props
        const _this = this
        if (this.postTable != undefined)
            this.postTable.destroy();


        this.postTable = jq('#post-table').DataTable({
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Posts',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    ReactSession.set("postList", json.data);
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#post-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                };

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { title: t("ID"), data: "PostId" },
                {
                    title: t("name"),
                    data: "Title", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }, "orderable": true
                },
                {
                    title: "Zaman Ayar Durumu",
                    data: "Status", render: function (data, type, row) {
                        var content = data == 1 ? "Aktif" : "Pasif"
                        return content
                    }, "orderable": true
                },
                {
                    title: t("operations"),
                    width: "250px",
                    className: 'operation',
                    data: null, render: function (data, type, row) {
                        return `
                    <div class="form-group">
                            <div class="d-flex justify-content-between align-items-center">

                              ${Functions.RoleControl(38, 2) == true ? `<a href="/management/notification-post-edit/${row.PostId}" type="button" id="postEdit" class="btn btn-warning ">${t("edit")}</a> ` : ""}
                              ${Functions.RoleControl(38, 4) == true ? ` <button type="button" id="deleted" class="btn btn-danger "><i class="icon-bin"> </i>${t("remove")}</button> ` : ""}
                                
                            </div> 
                        </div>`

                    }, "orderable": false
                },

            ]

        });
        new jq.fn.dataTable.Responsive(this.postTable, { details: true, Responsive: true },);

        jq('#post-table  tbody').on('click', 'td.operation #deleted', function () {
            var tr = jq(this).closest('tr');
            var row = _this.postTable.row(tr);
            swal.fire({
                title: t("swal.info.head", { ns: "translations" }),
                text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.delete", { ns: "translations" }) }),
                showDenyButton: true,
                confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
                denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
            }).then((result) => {
                if (result.isConfirmed) {
                    var config = {
                        method: 'delete',
                        url: getEndPoint() + '/api/Admin/Posts',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({
                            "PostId": row.data().PostId
                        })
                    };

                    axios(config)

                        .then((response) => {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            _this.postTable.ajax.reload()
                        })
                        .catch(function (error) {
                            Functions.requestAxiosErrorFunction(error);
                        });
                }
            })
        });
    }

    componentDidUpdate = () => this.componentDidMount();


    render() {
        const { t } = this.props;

        return (
            <div className=" p-2">
                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">Duyurular Listesi</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(38, 3) == true ? <button onClick={() => window.location.href = "/management/notification-post-add"} className="btn btn-success" >{t("save")}</button> : ""}
                                <a onClick={() => {
                                    this.componentDidMount()
                                }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">

                                <table id="post-table" className="table-loader dataTable display  compact  w-100">


                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['notificationPost', 'translations'])(NotificationPost);
