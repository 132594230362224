import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import axios, { Axios } from "axios";
import Text from "../../components/elements/text";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import jq from 'jquery'
import AbcIcon from '@mui/icons-material/Abc';
import { getEndPoint } from "../../class/api";
import { Select } from "../../components/Select";
import { ReactSession } from "react-client-session";
const cookies = new Cookies();

var actionData = {
    MaintenanceId: 0,
    StationId: 0,
    ParkUnitId: 0,
    DeviceId: 0,

}

class Faults extends React.Component {
    constructor(props) {
        super(props);
        this.stationsRef = React.createRef();
        this.statusRef = React.createRef();
        this.pritoryRef = React.createRef();







    }
    tableInstall = () => {
        const { t } = this.props;
        let _this = this;



        if (this.faultTable != undefined) {
            jq('#faultsList').empty();
            this.faultTable.destroy();
        }

        this.faultTable = jq('#faultsList').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Maintenance',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#faultsList`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    ParentId: 0
                };
                // if ($(".userFilter").val() != "0") {
                //     aoData['Filter']["UserId"] = $(".userFilter").val()
                // }
                if ($(".stationFilter").val() != "0") {
                    aoData['Filter']["StationId"] = $(".stationFilter").val()
                }
                if ($(".faultStatues").val() != "0") {
                    aoData['Filter']["Status"] = [$(".faultStatues").val()]
                }
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            "columns": [

                {
                    title: t("ID"),
                    data: "MaintenanceId"
                },
                {
                    title: t("faultName"),

                    data: "Subject", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }, "orderable": false
                },
                {
                    title: t("faultStations"),
                    data: "station", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return (data.Name + ' ' + row.park_unit.ParkName);
                    }, "orderable": false
                },
                {
                    title: t("status"),
                    data: "status", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return (data.Name);
                    }, "orderable": false
                },
                {
                    title: t("action-list"),
                    className: '',
                    orderable: false,
                    data: "MaintenanceId", render: function (data, type, row) {
                        return `<a id="details" data-toggle="modal" data-target="#modal_table_details"  class="btn btn-info"> ${t("showingActionsList")} "</a>`
                    },
                    defaultContent: '',
                },
                {
                    title: t("operations"),
                    width: "250px",
                    data: "MaintenanceId", render: function (data, type, row) {
                        return `
                        <div class="form-group">
                                <div class="d-flex justify-content-between align-items-center">
                                ${Functions.RoleControl(28, 2) == true ? `<button type="button" data-toggle="modal" data-target="#actionAddModal" id="actionAdd" class="btn btn-warning ">${t("action-add")}</button>` : ""}
                                ${Functions.RoleControl(28, 4) == true ? `<button type="button" id="deleted"  class="btn btn-danger "><i class="icon-bin"> </i>${t("removeFault")}</button>` : ""}
                                </div> 
                            </div>`

                    }, "orderable": false
                },

            ]

        });
        new jq.fn.dataTable.Responsive(this.faultTable, { details: true, Responsive: true },);
    }
    componentDidMount() {
        const { t } = this.props;
        let _this = this;

        var stationList = ReactSession.get('stationList')
        var stationFilterSelect = $('.stationFilter')
        var stationSelect = $(this.stationsRef.current);
        $(stationFilterSelect).empty().trigger("change");

        var option = new Option(
            t("all", { ns: "translations" }),
            "0",
            true,
            true);
        stationFilterSelect.append(option).trigger('change');
        $(this.stationsRef.current).empty().trigger("change");
        stationList.forEach(elm => {
            var newOption = `<option value="${elm.StationId} "> ${elm.Name} ( ${elm.Number} )</option>`
            $(stationSelect).append(newOption).trigger('change');
            $(stationFilterSelect).append(newOption).trigger('change');

        });

        const statusList = ReactSession.get('faultStatues')
        var statusSelect = $('.statusSelect');
        var filterStatusSelect = $('.faultStatues')
        $(filterStatusSelect).empty().trigger("change");
        $(statusSelect).empty().trigger("change");
        var option = new Option(
            'Lütfen Seçim yapınız',
            "0",
            true,
            true);
        statusSelect.append(option).trigger('change');
        filterStatusSelect.append(option).trigger('change');

        statusList.forEach(data => {

            var opt = '<optgroup label="' + data.Name + '">';
            opt += '<option data-id="' + data.MaintenanceStatusId + '" value="' + data.MaintenanceStatusId + '">' + data.Name + ' </option>'

            data.child_list.forEach((elm, index) => {


                opt += '<option data-id="' + elm.MaintenanceStatusId + '" value="' + elm.MaintenanceStatusId + '">' + elm.Name + ' </option>'

            });

            opt += '</optgroup>';
            statusSelect.append(opt).trigger('change');
            filterStatusSelect.append(opt).trigger('change');

        });



        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $('.selects').select2({
            language: selectLanguage
        });
        $('.actionSelectModal').select2({
            dropdownParent: "#actionAddModal",
            language: selectLanguage
        });

        $('.modalSelect').select2({
            dropdownParent: "#faultAddModal",
            language: selectLanguage
        });
        this.tableInstall();
        function format(d) {
            // faultDetails
            if (!d.actions.length) {
                return ""
            }
            var returnListData = ``
            d.actions.forEach((element, index) => {

                returnListData +=
                    `<tr>
                <td>${element.MaintenanceActionId}</td>
                <td>${element.Action}</td>

                </tr>`

            });
            return returnListData

        }
        jq('#faultsList tbody').on('click', 'td #details', function () {
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
            var data = row.data()

            if (data == undefined) {
                row = _this.faultTable.row(this);
                data = row.data();
            }
            $("#faultDetails tbody").empty();
            $("#faultDetails tbody").append(format(row.data()));


        });
        jq('#faultsList tbody').on('click', 'td  #actionAdd', function () {//action add butonu
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.faultTable.row(this);
                data = row.data();
            }
            console.log(data)
            actionData.MaintenanceId = data.MaintenanceId
            actionData.StationId = data.StationId
            actionData.ParkUnitId = data.ParkUnitId
            actionData.DeviceId = data.DeviceId
        });
        jq('#faultsList tbody').on('click', 'td #deleted', function () {//action delete butonu
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.faultTable.row(this);
                data = row.data();
            }
            swal.fire({
                title: t("swal.info.head", { ns: "translations" }),
                text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.delete", { ns: "translations" }) }),
                showDenyButton: true,
                confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
                denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
            }).then((result) => {
                if (result.isConfirmed) {
                    var config = {
                        method: 'delete',
                        url: getEndPoint() + '/api/Admin/Maintenance',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({
                            "MaintenanceId": row.data().MaintenanceId
                        })
                    };

                    axios(config)

                        .then(function (response) {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            _this.faultTable.ajax.reload();
                        })
                        .catch(function (error) {
                            swal.fire({
                                title: t("swal.error.head", { ns: "translations" }),
                                text: t("swal.error.subject", { ns: "translations" }) + error.message,
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.error.icon", { ns: "translations" })
                            })
                        });
                }
            })
        });
    }

    componentDidUpdate = () => this.componentDidMount();
    handleFaultAddClick = () => {
        const _this = this;
        const { t } = this.props
        if (jq(this.statusRef.current).val() == "0") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: "Lütfen Bir Durum Seçiniz",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return false
        }
        var data = JSON.stringify({
            "Priority": jq('#prioritySelect').val(),
            "Subject": jq('#subject').val(),
            "StationId": jq(this.stationsRef.current).val(),
            "ParkUnitId": 0,
            "DeviceId": 0,
            "Status": jq(this.statusRef.current).val()
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Maintenance',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.faultTable.ajax.reload();
            })
            .catch(function (error) {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.subject", { ns: "translations" }) + error.message,
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
            })
    }
    handleActionClick = () => {
        const _this = this;
        const { t } = this.props
        if (jq('#Action').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: "Lütfen Aksiyon Adı Giriniz",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return false
        }
        if (jq('#statusSelect2').val() == "0") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: "Lütfen Bir Durum Seçiniz",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return false
        }

        var data = JSON.stringify({
            "MaintenanceId": actionData.MaintenanceId,
            "Action": jq('#Action').val(),
            "StationId": actionData.StationId,
            "ParkUnitId": actionData.ParkUnitId,
            "DeviceId": actionData.DeviceId,
            "Status": jq('#statusSelect2').val()
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Maintenance/Actions',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.faultTable.ajax.reload();
            })
            .catch(function (error) {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.subject", { ns: "translations" }) + error.message,
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
            })
    }
    render() {
        const { t } = this.props;

        return (
            <div className="user-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">

                                {/* 
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("userFilter")}</label>
                                    <div className="col-lg-8">

                                        <Select
                                            selectID="userFilter"
                                            customCss={"userFilter"}
                                            result={["Name", "Surname", "Phone"]}
                                            resultID={["MemberId"]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("faultStatuesFilter")}</label>
                                    <div className="col-lg-8">
                                        <select className="form-control faultStatues">
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("stationFilter")}</label>
                                    <div className="col-lg-8">
                                        <select className="form-control selects stationFilter" />
                                    </div>
                                </div>



                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => jq.fn.dataTable.tables({ api: true }).ajax.reload()} type="button" className="btn btn-primary w-100">{t("filter", { ns: "translations" })}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">

                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("fault-head")}</h6>
                        <div className=' '>

                            {Functions.RoleControl(28, 3) == true ? <button data-toggle="modal" data-target="#faultAddModal" className='btn btn-success'>{t("add-fault")}</button> : ""}
                            <a onClick={() => { this.faultTable.ajax.reload() }} className="list-icons-item" data-action="reload"></a>


                        </div>
                    </div>
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12 pt-2">
                                <table id="faultsList" className="table-loader dataTable display  compact  w-100">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modal_table_details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="card-body py-2">
                                    <div className="row">
                                        <div id="details_table_fill" className="col-lg-12 pt-2">
                                            <table id="faultDetails" className=" dataTable display  compact  w-100">
                                                <thead>
                                                    <tr>
                                                        <th>{t("ID")}:</th>
                                                        <th>{t("action_name")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="faultAddModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("add-fault")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("faultStations")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="stationSelect" ref={this.stationsRef} type="text" className="form-control modalSelect selects w-100 " />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("priority")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="prioritySelect" type="text" className="form-control modalSelect selects w-100 "  >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("faultName")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <textarea id="subject" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("status")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="statusSelect" ref={this.statusRef} type="text" className="form-control statusSelect  selects w-100 " />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                                <button type="button" onClick={this.handleFaultAddClick} data-dismiss="modal" className="btn btn-primary">Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="actionAddModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("action-add")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("status")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="statusSelect2" type="text" className="form-control statusSelect actionSelectModal selects w-100 " />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("action-name")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <textarea id="Action" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                                <button type="button" onClick={this.handleActionClick} data-dismiss="modal" className="btn btn-primary">Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['faults', 'translations'])(Faults);
