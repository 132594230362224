import React, { Component } from 'react';
import i18n from '../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import Division from '../../components/elements/content-division';
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import axios from 'axios';
import { getEndPoint } from "../../class/api";
import jq from 'jquery'
import Functions from '../../class/functions'
import swal from '../../js/plugins/notifications/sweet_alert.min';
import Cookies from 'universal-cookie';
import { ReactSession } from 'react-client-session';
import DatePicker from '../../components/elements/DatePicker';
import Text from '../../components/elements/text';
import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';
import moment from 'moment';
const cookies = new Cookies();
var _this = this;



let userList = []
let dateRangeStatus = 0;
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";

class User extends React.Component {
    constructor(props) {
        super(props);

        this.usersTableRef = React.createRef(null);

        this.filterNameRef = React.createRef(null);
        this.filterSurnameRef = React.createRef(null);
        this.filterFirstDateRef = React.createRef(null);
        this.filterLastDateRef = React.createRef(null);
        this.filterPhoneRef = React.createRef(null);
        this.filterStatusRef = React.createRef(null);
        this.balanceRef = React.createRef(null);
        this.descriptionRef = React.createRef(null);
        this.userCreditData = []

        this.handleClickAddBalance = this.handleClickAddBalance.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClickFilter = this.handleClickFilter.bind(this);
        this.state = {
            status: true
        };
    }

    parentValueChange = (valueType, type) => {
        console.log(valueType);
        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    tableInstall = () => {
        const { t } = this.props;
        const _this = this;
        var input = document.querySelector("#user-phone");
        var iti = window.intlTelInputGlobals.getInstance(input);

        if (this.usersTable != undefined) {
            this.usersTable.destroy();
            jq(`#users-table`).empty();
        }

        this.usersTable = jq('#users-table').DataTable({
            "createdRow": function (row, data, dataIndex) {
                if (data.Status == false) {
                    console.log(data.Status, 'girdis');
                    $(row).addClass('bg-custom-red');
                }

            },
            lengthMenu: [[5, 10, 20, 50, 100, 500], [5, 10, 20, 50, 100, 500]],
            "pageLength": 50,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            serverSide: true,
            order: [0, 'ASC'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Member',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    userList = json.data
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#users-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                if (ReactSession.get('userSearchData') != null) {
                    var userData = ReactSession.get('userSearchData');
                    aoData['Filter'] = {
                        q: userData.user,
                        TcNumber: userData.TcNumber,
                        PassportNumber: userData.passportNumber,
                        Phone: userData.phone,
                        id: userData.id,
                        order: "Name",
                        sort: aoData.order[0].dir,
                    };
                    ReactSession.remove('userSearchData');
                } else {
                    console.log(jq("#user_id_filter").val());
                    aoData['Filter'] = {
                        q: `${aoData.search.value}${_this.filterNameRef.current.value} ${_this.filterSurnameRef.current.value}`,
                        id: jq("#user_id_filter").val(),
                        Phone: iti.getNumber(),
                        order: aoData.columns[aoData.order[0].column].data,
                        sort: aoData.order[0].dir,
                    };
                    if (jq('#filterStatus').val() != "2") {
                        aoData['Filter']["Status"] = parseInt(jq('#filterStatus').val())
                    }
                }

                if (dateRangeStatus == 1) {
                    aoData.Filter.dateRange = [
                        Functions.getFormatDBDateHours(jq(this.filterFirstDateRef.current).val() + ' ' + firstDateHours),
                        Functions.getFormatDBDateHours(jq(this.filterLastDateRef.current).val() + ' ' + lastDateHours)
                    ];
                }
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },

            "columns": [

                {
                    title: t("table.userId"),

                    data: "id", "orderable": true
                },
                { title: t("table.userType"), data: "MemberType", "orderable": false },
                {
                    title: t("table.userIdentification"), data: "TcNumber", render: function (data, type, row) {
                        return Functions.MaskCharacter(`${data}`, '*', 4)
                    },
                    "orderable": false
                },
                {
                    title: t("table.userNameSurnameCardId"),
                    data: "Name", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        var userID = '/users/edit/' + row.id
                        var userNameSurname = Functions.MaskCharacter(`${row.Name} ${row.Surname}`, '*', 2)

                        userID = Functions.RoleControl(11, 4) == false ? '#' : userID

                        return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.id + '">' + userNameSurname + '</a>';
                    }, "orderable": true
                },
                {
                    title: t("table.userPhone"),
                    data: "Phone", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return Functions.MaskCharacter(`${data}`, '*', 4);
                    }, "orderable": false
                },
                {
                    title: t("table.userUsageTime"),
                    data: null, render: function (data, type, row) {

                        return (row.VehicleCountActive + ' / ' + row.VehicleCountMax);
                    }, "orderable": false
                },
                {
                    title: t("table.userBalance"),
                    data: "Balance", render: function (data, type, row) {
                        return (parseFloat(data).toFixed(2) + 'TL');
                    }, "orderable": false
                },
                {
                    title: t("Son Erişim Tarihi"),
                    data: "LastConnect", render: function (data) {
                        return moment(data).format('DD/MM/YYYY HH:mm');
                    }
                },
                {

                    title: t("table.userOperations"),
                    data: "id", render: function (data, type, row) {
                        var htmlContent = `<div style="display: ${Functions.RoleControl(11, 2) == false ? 'none' : ""}  class="list-icons">
                        <div class="dropdown">
                            <a href="#" class="list-icons-item" data-toggle="dropdown">
                                <i class="icon-menu9"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                ${Functions.RoleControl(1, 2) == true ?
                                `
                                <a href="/users/edit/${row.id}" class="dropdown-item">
                                    <i class="icon-pencil"></i>${t("table.userEdit")}
                                </a>`
                                : ""
                            }
                            ${Functions.RoleControl(12, 2) == true ? ` <a href="#" id="user-creditBtn" type="button"  data-id="${data}" data-target="#user-credit" data-toggle="modal" class="user-credit  dropdown-item">
                            <i class="icon-plus3"> </i>
                            ${t("table.userAddCredit")}
                        </a>`
                                : ""
                            }
                               
                            </div >
                        </div >
                    </div > `

                        return (htmlContent);
                    },
                    "orderable": false
                },
            ],
            drawCallback: function () {
                if (dateRangeStatus == 1) {
                    jq('#countsTableDiv').show()
                    processInfo(this.api().page.info());

                }
                else {
                    jq('#countsTableDiv').hide()

                }
            }
        });

        new jq.fn.dataTable.Responsive(this.usersTable, { details: true, Responsive: true },);
        function processInfo(info) {

            if (info.recordsTotal > 0) {
                if (_this.userCountTable != null)
                    _this.userCountTable.destroy();


                _this.userCountTable = jq('#countTable').DataTable({
                    dom: 'Blfrtip',
                    lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
                    "pageLength": 50,
                    buttons: [
                        'copy', 'csv', 'excel', 'pdf', 'print'
                    ],
                    language: {
                        ...(t("tableLocalization", { ns: "translations" })),
                        paginate: {
                            'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                            'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                        }
                    }
                });

                _this.userCountTable.clear().draw();
                _this.userCountTable.row.add([_this.filterFirstDateRef.current.value, _this.filterLastDateRef.current.value, info.recordsTotal]).draw(false);

            }
            else {
                jq('#countsTableDiv').hide()
            }


        }
    }
    componentDidMount() {
        const { t } = this.props;
        let _this = this;

        var phoneInputID = "#user-phone";
        var input = document.querySelector("#user-phone");
        var iti = intlTelInput(input,
            {
                autoPlaceholder: "polite",
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,
            }
        )
        $(phoneInputID).on("countrychange", function (event) {

            var selectedCountryData = iti.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");
            console.log(jq(this));
            $(this).mask(mask);
        });
        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });





        DateRangePickerHandler(this.filterFirstDateRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            startDate: "01/01/1990",
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.filterLastDateRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.tableInstall()

        jq('#users-table tbody').on('click', 'td #user-creditBtn', function () {//action delete butonu
            var tr = jq(this).closest('tr');
            var row = _this.usersTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.usersTable.row(this);
                data = row.data();
            }
            _this.userCreditData = data
            $('.userID').val(`${data.Name} ${data.Surname} `)
        });
    }




    handleClickAddBalance(e) {

        e.preventDefault();

        const _this = this;
        const { t } = this.props


        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var balance = _this.balanceRef.current.value.replace(',', '.')
                dateRangeStatus = 1;
                var data = JSON.stringify({
                    "AddBalance": parseFloat(balance).toFixed(2),
                    "Description": _this.descriptionRef.current.value,
                    "PaymentMethodId": 5,
                    "UserId": _this.userCreditData.id
                });
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member/AddBalance',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data,
                    withCredentials: true
                };


                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            _this.descriptionRef.current.value = ""
                            _this.balanceRef.current.value = ""
                            _this.usersTable.ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });


            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })


    }
    handleClickFilter = () => {
        dateRangeStatus = 1

        this.usersTable.ajax.reload()
    };
    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="user-page p-2">
                <div className="card mb-1">
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("ID")}</span>
                                    <div className="d-flex">
                                        <input id='user_id_filter'  type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("userName")}</span>
                                    <div className="d-flex">
                                        <input ref={this.filterNameRef} name="name" type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("userSurname")}</span>
                                    <div className="d-flex">
                                        <input ref={this.filterSurnameRef} name="surname" type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("firstDate")}</span>
                                    <div className="d-flex">
                                        <input ref={this.filterFirstDateRef} name="firstDate" type="text" className="form-control" />
                                        <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("lastDate")}</span>
                                    <div className="d-flex">
                                        <input ref={this.filterLastDateRef} name="lastDate" type="text" className="form-control" />
                                        <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="d-block w-100 mt-2">
                                    <span>{t("userPhone")}</span>
                                    <div className="d-flex">
                                        <input type="tel" name="Phone" ref={this.filterPhoneRef} id="user-phone" className="form-control form-control-lg" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group">
                                    <div className="d-block w-100 mt-2">
                                        <span>{t("userStatus")}</span>
                                        <div className="d-flex">
                                            <select name="Status" id="filterStatus" ref={this.filterStatusRef} defaultChecked="2" className="custom-select">
                                                <option value={"2"}>{t("all", { ns: "translations" })}</option>
                                                <option value={"1"}>{t("active", { ns: "translations" })}</option>
                                                <option value={"0"}>{t("passive", { ns: "translations" })}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Division className="col-sm-12 mt-4">
                                <button onClick={this.handleClickFilter} type="button" className="btn btn-primary w-100">{t("filter")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="card border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                    <div className="card-custom-color card-header header-elements-inline">
                        <h6 className="card-title">{t('userList')}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={() => {
                                    this.tableInstall()
                                }} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="users-table" className="dataTable display compact w-100">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card" id="countsTableDiv" >
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12 pt-2">
                                <table id="countTable" className="dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("İlk Tarih")}</th>
                                            <th>{t("Son Tarih")}</th>
                                            <th>{t("Üye Sayısı")}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>






                <div id="user-credit" ref={this.modalUserCreditsRef} className="modal fade" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={this.handleSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title">{t("modal.head")}</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    <div className="row">


                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.userID")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" className="form-control form-control-lg userID" readOnly placeholder={t("modal.userID")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                    </Division>
                                                </Division>
                                            </Division>

                                        </div>

                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.addedBalance")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" ref={this.balanceRef} className="form-control form-control-lg balanceNumber" placeholder={t("modal.addedBalance")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                    </Division>
                                                </Division>
                                            </Division>

                                        </div>

                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.description")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" ref={this.descriptionRef} className="form-control form-control-lg description" placeholder={t("modal.description")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                    </Division>
                                                </Division>
                                            </Division>

                                        </div>


                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">{t("modal.cancel")}</button>
                                    <button type="button" className="btn btn-primary" onClick={this.handleClickAddBalance} data-dismiss="modal">{t("modal.save")}</button>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['userList', 'translations'])(User);
