import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { getEndPoint } from "../../class/api";

import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Button from '../../components/elements/button';

import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';
import LoopIcon from '@mui/icons-material/Loop';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';

class GpsZoneAlarmDefinitions extends Component {
    constructor(props) {
        super(props);

        this.commandTableRef = React.createRef();
        this.dateRangeRef = React.createRef();

        this.tables = [];
    }

    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props

        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
            var $this = $(this);
            if ($this.data('bs.inputmask')) return;
            var inputMask = new InputMask($this, $this.data());
        });

        $('.select-search').select2({
            language: selectLanguage
        });

        this.processDataTable(this.commandTableRef, "this.bicycleTable");

        DateRangePickerHandler(this.dateRangeRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.gps.gpsHistory", { ns: "translations" })}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <Button className="btn btn-primary my-2" data-target="#add-zone-alarm-definitions" data-toggle="modal">{t("addNew")}</Button>
                        <table id="bicycle-gps-table" className="mt-2" ref={this.commandTableRef} style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>{t("table.ID")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.coordinates")}</th>
                                    <th>{t("table.operation")}</th>
                                    <th>{t("table.status")}</th>
                                    <th>{t("table.notificationStatus")}</th>
                                    <th>{t("table.operations")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ORMANYATEST</td>
                                    <td>40.86868, ...</td>
                                    <td>Dışına Çıkarsa Alarm Oluştur</td>
                                    <td><span className="badge badge-success">{t("active", { ns: "translations" })}</span></td>
                                    <td><span className="badge badge-success">{t("active", { ns: "translations" })}</span></td>
                                    <td className="text-center">
                                        <div className="list-icons">
                                            <div className="dropdown">
                                                <a href="#" className="list-icons-item" data-toggle="dropdown">
                                                    <i className="icon-menu9"></i>
                                                </a>

                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a href="#" className="dropdown-item"><i className="icon-pencil"></i> ...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Division>
                </Division>

                <div id="add-zone-alarm-definitions" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modal.addZoneAlarmDefinition")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Text Tag="label">{t("modal.title")}</Text>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Text Tag="label">{t("modal.operation")}</Text>
                                            <select className="select-search form-control" defaultValue={-1}>
                                                <option disabled value={-1}>{t("choose")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Text Tag="label">{t("modal.priority")}</Text>
                                            <input type="number" className="form-control" defaultValue={0} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Text Tag="label">{t("modal.status")}</Text>
                                            <select className="form-control" defaultValue={-1}>
                                                <option disabled value={-1}>{t("choose")}</option>
                                                <option value={0}>{t("active", { ns: "translations" })}</option>
                                                <option value={1}>{t("passive", { ns: "translations" })}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Text Tag="label">{t("modal.notificationStatus")}</Text>
                                            <select className="form-control" defaultValue={-1}>
                                                <option disabled value={-1}>{t("choose")}</option>
                                                <option value={0}>{t("active", { ns: "translations" })}</option>
                                                <option value={1}>{t("passive", { ns: "translations" })}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>


                                </div>
                                <Button className="btn btn-secondary my-2">{t("modal.removePolygons")}</Button>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12099.67679390418!2d29.876394687152906!3d40.69777716933339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb50576b2e8185%3A0x4039b382c6a74823!2sKocaeli%20University%20Technopark!5e0!3m2!1sen!2str!4v1642415583652!5m2!1sen!2str" width="100%" height="500" style={{ border: "none" }} allowFullScreen="" loading="lazy"></iframe>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal">{t("modal.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation(['gpsZoneAlarmDefinitions', 'translations'])(GpsZoneAlarmDefinitions);