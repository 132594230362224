import UnorderedList, { ListItem } from "./elements/unordered-list";
import Anchor from "./elements/anchor";
import Text from "./elements/text";
import enums from "../class/enum.json";
import { ReactSession } from 'react-client-session';
import Functions from "../class/functions";

export default function AccordionList(props) {
    return (
        props.abilityid !== undefined && props.abilityid !== null
            ?
            <ListItem style={{ display: Functions.RoleControl(props.abilityid) == false ? "none" : "" }} className="nav-item nav-item-submenu">
                <Anchor href="#" className="nav-link">
                    {props.Icon && props.Icon} <Text Tag="span">{props.Title}</Text>
                </Anchor>

                <UnorderedList className="nav nav-group-sub" data-submenu-title={props.Title} style={{ display: "none" }}>
                    {props.children}
                </UnorderedList>
            </ListItem>
            :
            <ListItem className="nav-item nav-item-submenu">
                <Anchor href="#" className="nav-link">
                    {props.Icon && props.Icon} <Text Tag="span">{props.Title}</Text>
                </Anchor>

                <UnorderedList className="nav nav-group-sub" data-submenu-title={props.Title} style={{ display: "none" }}>
                    {props.children}
                </UnorderedList>
            </ListItem>

    );
}