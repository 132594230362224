import React from "react";
import { withTranslation } from "react-i18next";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from '../../class/functions';
import Text from "../../components/elements/text";
import MapIcon from '@mui/icons-material/Map';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FemaleIcon from '@mui/icons-material/Female';
import PublicIcon from '@mui/icons-material/Public';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import axios from "axios";
import { getEndPoint } from "../../class/api";
import jsonCity from '../../class/il.json'
import jsonCountry from '../../class/ilce.json'
import Cookies from 'universal-cookie';
import moment from "moment";
const cookies = new Cookies();
let processStatus = "0"
class UserAdd extends React.Component {
    constructor(props) {
        super(props);


        this.BirthDate = React.createRef();
        this.registrationDate = React.createRef();
        this.birthDateRef = React.createRef();

        this.state = {
            userData: [],
            status: false,
            dataCityList: jsonCity,
            dataCountryList: '',
        };
        this.tables = [];
    }




    componentDidMount() {

        const { t } = this.props;
        const _this = this;
        var phoneInputID = "#user-phone";
        var input = document.querySelector("#user-phone");
        var iti = intlTelInput(input,
            {

                autoPlaceholder: "polite",
                geoIpLookup: (success, failure) => {
                    //success(countryCode)
                },
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,


            }
        )
        $(phoneInputID).on("countrychange", function (event) {

            var selectedCountryData = iti.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");

            $(this).mask(mask);
        });



        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });


        var configMember = {
            method: 'PUT',
            url: getEndPoint() + '/api/Admin/Member/Types',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ per_page: 1000 })
        };
        axios(configMember)
            .then(function (response) {
                var responseData = response.data.data
                $('#MemberType').empty();
                responseData.forEach(memberElm => {
                    var newOption = new Option(
                        memberElm.Name,
                        memberElm.MemberTypeId,
                        _this.state.userData.MemberType == memberElm.Name ? true : false,
                        _this.state.userData.MemberType == memberElm.Name ? true : false,
                    );
                    $('#MemberType').append(newOption).trigger('change');
                });
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
        $('#AddressHomeCityId').empty();
        jsonCity.forEach(element => {

            var optionHome = new Option(
                element.text,
                element.id,
                false,
                false
            );
            $('#AddressHomeCityId').append(optionHome).trigger('change');



        });

        $('#AddressHomeCityId').on('change', function () {
            $('#AddressHomeCountyId').empty();

            jsonCountry.filter(x => x.il_id == this.value).map(element => {

                if (this.value != 0) {

                    var newOption = new Option(
                        element.text,
                        element.id,
                        this.value == element.id ? true : false,
                        this.value == element.id ? true : false);
                    $('#AddressHomeCountyId').append(newOption);
                }

            });
        });
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $('.select-search').select2({
            language: selectLanguage
        });
        DateRangePickerHandler(this.registrationDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        DateRangePickerHandler(this.birthDateRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
            var $this = $(this);
            if ($this.data('bs.inputmask')) return;
            var inputMask = new InputMask($this, $this.data());
        });




    }

    componentDidUpdate = () => this.componentDidMount();
    handleSubmit = (e) => {
        const _this = this;
        const { t } = this.props
        e.preventDefault();
        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
        var input = document.querySelector(".phoneData");
        var iti = window.intlTelInputGlobals.getInstance(input);
        var phoneReturn = this.phoneControl()

        if (processStatus == "0") {
            phoneReturn
                .then((response) => {
                    console.log(response);

                    if (!response) {

                        swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.existsDataSubject", { ns: "translations", requiredData: t("userPhone") }),
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })
                        return

                    }
                })
        }
        var phoneNumbers = iti.getNumber();

        if ((phoneNumbers.search("90500") == -1) && (!iti.isValidNumber())) {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userPhone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }

        if (formDataEdit.Sifre == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("webAccessPassword") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.Name == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userName") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.Surname == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userSurname") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.Email == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userEmail") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if ((formDataEdit.Tourist === "0") && (formDataEdit.TcNumber.length != 11)) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if ((formDataEdit.Tourist === "1") && (formDataEdit.PassportNumber === "")) {

            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Pasaport Numarası" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Balance == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userBalance") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Phone == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userPhone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }


        if (formDataEdit.Gender == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("gender") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Contract == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userAgreementStatus") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }


        phoneReturn
            .then((response) => {
                console.log(response);

                if (!response) {

                    swal.fire({
                        title: t("swal.error.head", { ns: "translations" }),
                        text: t("swal.error.existsDataSubject", { ns: "translations", requiredData: t("userPhone") }),
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: t("swal.error.icon", { ns: "translations" })
                    })
                    return

                }
                else {
                    var data = JSON.stringify({
                        "Name": formDataEdit.Name,
                        "Surname": formDataEdit.Surname,
                        "Email": formDataEdit.Email,
                        "CountryCode": '+' + iti.selectedCountryData.dialCode,
                        "Phone": `${iti.getNumber()}`,
                        "PhoneNoCountryCode": Functions.phoneControl(iti.getNumber(), iti.selectedCountryData),
                        "Sifre": formDataEdit.Sifre,
                        "TimeZone": "UTC+3",
                        "Status": parseInt(formDataEdit.Status),
                        "Member": {
                            "Tourist": parseInt(formDataEdit.Tourist),
                            "Balance": parseFloat(formDataEdit.Balance),
                            "AddressHome": formDataEdit.AddressHome,
                            "TcNumber": formDataEdit.TcNumber,
                            "PassportNumber": formDataEdit.PassportNumber,
                            "Gender": formDataEdit.Gender,
                            "Profilepicture": 'null',
                            "VehicleCountMax": parseInt(formDataEdit.VehicleCountMax),
                            "VehicleCountActive": 0,
                            "Ccsec": 'null',
                            "Contract": parseInt(formDataEdit.Contract),
                            "RegistrationPlaceId": parseInt(formDataEdit.RegistrationPlaceId),
                            "LastConnect": moment().format('YYYY-MM-DD HH:ss'),//zorunlu alan 
                            "BirthDate": Functions.getFormatDBDate(formDataEdit.birthDate), //this.birthDateRef.current.value,
                            "AddressHomeCityId": parseInt(formDataEdit.AddressHomeCityId),
                            "AddressHomeCountyId": parseInt(formDataEdit.AddressHomeCountyId),
                        }
                    });

                    swal.fire({
                        title: t("swal.info.head", { ns: "translations" }),
                        text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
                        showDenyButton: true,
                        confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
                        denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var config = {
                                method: 'post',
                                url: getEndPoint() + '/api/Admin/Member',
                                headers: {
                                    'X-XSRF-TOKEN': 'TESTxcrf',
                                    "Accept": "application/json",
                                    'Content-Type': 'application/json'
                                },
                                data: data,
                                withCredentials: true
                            };

                            axios(config)
                                .then(function (response) {
                                    swal.fire({
                                        title: t("swal.success.head", { ns: "translations" }),
                                        text: t("swal.success.subject", { ns: "translations" }),
                                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                        icon: t("swal.success.icon", { ns: "translations" })
                                    })

                                })
                                .catch(function (error) {
                                    Functions.requestAxiosErrorFunction(error);
                                });

                        } else if (result.isDenied) {
                            swal.fire({
                                title: t("swal.cancelSwal.head", { ns: "translations" }),
                                text: t("swal.cancelSwal.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.cancelSwal.icon", { ns: "translations" })
                            })
                        }
                    })

                }
            })



    }
    phoneControl = () => {
        const _this = this;
        const { t } = this.props

        var input = document.querySelector(".phoneData");
        var iti = window.intlTelInputGlobals.getInstance(input);
        console.log(iti);
        var data = JSON.stringify({
            "Phone": `${iti.getNumber()}`
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Ask',
            headers: {
                'X-XSRF-TOKEN': 'TESTxcrf',
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: data
        };
        const axiosReturn = axios(config)
            .then(response => {
                if (response.data.data.length == 0) {
                    processStatus = true
                    return true
                }
                else if (response.data.data[0].Phone == _this.state.userData.Phone) {
                    processStatus = true
                    return true
                }
                else {
                    swal.fire({
                        title: t("swal.error.head", { ns: "translations" }),
                        text: t("swal.error.existsDataSubject", { ns: "translations" }),
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: t("swal.error.icon", { ns: "translations" })
                    })
                    return false
                }

            })
        return axiosReturn
    }
    printClick = (param) => {
        var data = "";
        var RegistrationPlace = jsonCity.filter(x => x.id == parseInt($("#RegistrationPlaceId").val()))[0]
        var input = document.querySelector(".phoneData");
        var iti = window.intlTelInputGlobals.getInstance(input);


        if (param == "tr") {
            data = `<div id="aboneform"><table align="center" border="1" cellspacing="0" width="100%">
            <tbody>
                <tr>
                    <td align="center" colspan="2"><strong>AKILLI BİSİKLET ABONE BİLGİ FORMU</strong></td>
                    <td align="center"><span style="text-align: right;">KOBIS.18</span></td>
                </tr>
                <tr>
                    <td width="33%"><strong>*Abone Kart No</strong></td>
                    <td width="49%">${$("#RFID").val()} </td>
                    <td align="center" rowspan="7" width="18%">fotoğraf</td>
                </tr>
                <tr>
                    <td><strong>*TC Kimlik No</strong></td>
                    <td>${$("#TcNumber").val()}</td>
                </tr>
                <tr>
                    <td><strong>*Adı - Soyadı</strong></td>
                    <td>${$("#Name").val()} ${$("#Surname").val()}</td>
                </tr>
                <tr>
                    <td><strong>*İkamet Adresi</strong></td>
                    <td>${$('#AddressHome').val()} </td>
                </tr>
                <tr>
                    <td><strong>Ev Telefonu&nbsp;&nbsp;&nbsp; </strong></td>
                    <td></td>
                </tr>
                <tr>
                    <td><strong>İş Telefonu</strong></td>
                    <td></td>
                </tr>
                <tr>
                    <td><strong>*GSM&nbsp; </strong></td>
                   
                    <td>${Functions.phoneControl(iti.getNumber(), iti.selectedCountryData)}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>E – Mail</strong></td>
                    <td>${$("#Email").val()}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>Doğum &nbsp;Yeri ve Tarihi</strong></td>
                    <td> ${typeof RegistrationPlace == 'undefined' ? "" : RegistrationPlace.adi} / ${this.birthDateRef.current.value}</td >
            <td>&nbsp;</td>
                </tr >
            <tr>
                <td align="right" colspan="3">
                    <table border="0" width="100%">
                        <tbody>
                            <tr>
                                <td width="63%">
                                    <p>İş bu sözleşme ABONE tarafından doldurulmuş, iki nüsha düzenlenerek ve kayıtlar gizli tutulması şartıyla kayıt altına alınmıştır. Onay kaydı verilmeyen bilgi formları kabul edilmemiştir.<br>
                                        Aşağıdaki ekler ile teslim edilmeyen başvuru formları geçersizdir.<br>
                                            1 Adet Fotoğraf ,Nüfus Cüzdanı , Ehliyet-Pasaport-Evlilik Cüzdanı Vs. herhangi birinden 1 adet fotokopi,<br>
                                                ABONE Sözleşmesi.<br>
                                                    *Doldurulması zorunludur. &nbsp;**Her sayfa paraflanacaktır.</p>
                                            </td>
                                            <td width="37%">Yukarıda vermiş olduğum tüm bilgilerin doğru olduğunu beyan ederim.<br>
                                                <br>
                                                    <strong>Tarih :</strong> ....../...../..........<br>
                                                        <br>
                                                            <strong>İmza :........................</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" height="1148">
                                                <p align="center"><strong>Bireysel&nbsp; Taşıma Hizmeti Akıllı Bisiklet Abonelik&nbsp; Genel Koşulları</strong></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 1 -SÖZLEŞMENİN TARAFLARI</strong></span></p>

                                                <p><span style="font-size: 10px;">İş bu sözleşme; <strong>Kocaeli Büyükşehir Belediyesi Ulaşım ve Trafik Yönetimi Dairesi Başkanlığı Toplu Taşıma Şube Müdürlüğü; Oramiral Salim Dervişoğlu Caddesi Kocaeli Fuarı&nbsp; İzmit / Kocaeli</strong> adresinde mukim <strong>(</strong>Bundan böyle kısaca "<strong>Kurum</strong>" olarak anılacaktır) ile &nbsp; adresinde mukim (Bundan böyle kısaca "<strong>Abone</strong>" olarak anılacaktır) arasında yapılmıştır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 2 -Amaç</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'lerin burada öngörülen koşullar altında, <strong>Kurum</strong> kentsel alanı içerisinde bisikletlerin, <strong>Abone</strong>'lerin sorumluluğu altında istasyonlardan alınıp, tekrar istasyona geri getirilmesi durumlarının açıklanmasıdır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>Madde 3- Abone Kaydı</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, &nbsp;abone merkezlerine başvurarak abone kaydı işlemlerini başlatacaktır. Abone kaydı işlemleri tamamlanınca <strong>Abone</strong>'lere bisikletleri kullanmak için Abone kartı verilecektir. &nbsp;Abone kartları kişiye özel olup bir başkasına kullandırılamaz. Abone kartının başkasına kullandırılması halinde kullandıran, meydana gelebilecek her türlü sorumluluğu üstlenmiş olur. Ayrıca Kocaeli Büyükşehir Belediyesine her hangi bir hak iddia edemez.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 4- Sözleşmenin Feshi</strong></span></p>

                                                <p><span style="font-size: 10px;">Kayıt silme işlemi; <strong>Abone</strong>'nin kendi isteği doğrultusunda ya da sözleşmeye aykırı davranış neticesinde, gerekçe göstermeden <strong>Kurum</strong> tarafından silinir ve sözleşmesi tek taraflı olarak fesih edilir. Kayıt silinmesi durumunda abonelik bedeli iade edilmeyecektir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 5- Akıllı Bisiklet Hizmet Sözleşmesi</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, kartını kullanarak <strong>Kurum</strong> ile olan sözleşme haklarına muktedir olacaktır. Her yeni bisiklet kullanımı kuralların kabul edildiği anlamına gelir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 6- Abone Kart Geçerlilik Süresi ve Vize işlemi</strong></span></p>

                                                <p><span style="font-size: 10px;">Abone kart; <strong>Kurum</strong> ile <strong>Abone</strong> arasında yapılan sözleşme tarihinden itibaren geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>Kurum</strong>'da indirimli tarife yoktur.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 7- Ücret Tarifesi ve Abonelik Bedeli</strong></span></p>

                                                <p><span style="font-size: 10px;">Ücret tarifesi ve abonelik bedeli, Kocaeli Büyükşehir Belediyesi Meclisi tarafından belirlenir ve bu tarifeye göre ücret toplama işlemi gerçekleştirilir. <strong>Abone</strong> ücret tarifesi ve abonelik bedelini ödemeyi kabul ve taahhüt eder.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 8- Akıllı Bisiklet Abone Yükümlülüğü</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'nin yükümlülüğü bisikleti sağlam olarak iade etmek ve Madde-10 hükümlerine riayet etmektir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 9- Akıllı bisiklet Abonelik Şartı</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong> yasal yaşta, trafik kurallarına aşina, ayrıca bisiklet sürme vasfına sahip olmalıdır. 18 yaş altı olanlar; kati suretle abone kartına sahip olma ve kullanma hakkına sahip değildirler.&nbsp;</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 10- Akıllı Bisiklet Abone Hakları</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Kurum</strong> ile <strong>Abone</strong> arasında yapılan sözleşme, burada belirtilen limitler dâhilinde bisiklet kullanım hakkı sağlar. Bisikletler kullanıma hazır olarak <strong>Abone</strong>'lere sunulacaktır. <strong>Abone</strong> alacağı hizmetin hükümlerini, şartlarını öğrenmek ya da çeşitli isteklerini iletebilmek için www.kobis.com.tr adresinden ve/veya ilan edilmiş telefon hatları üzerinden müşteri hizmetleri ile irtibata geçebilirler. <strong>Kurum</strong> olabilecek en kısa sürede soruları ve talepleri işleme koyacak ve cevaplayacaktır. Bu süre <strong>Abone</strong>'nin iletişime geçmesinden itibaren en fazla 15 iş günü alacaktır. Eğer <strong>Abone</strong>'nin aldığı bisiklet kullanmak için uygun durumda değilse <strong>Abone</strong> başka bir bisiklet alma hakkına sahiptir. Kullanım için uygun olmayan bisikleti <strong>Abone</strong> 5 dakika içinde iade edecek, sistem <strong>Abone</strong>'ye başka bir bisiklet verecektir.&nbsp;</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 11- Akıllı Bisiklet Abone Yükümlülükleri</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'ler Akıllı bisiklet hizmetini kullanırken;</span></p>

                                                <ol style="list-style-type: lower-alpha;">
                                                    <li><span style="font-size: 10px;">Hizmeti ve bisikletleri azami özenle kullanmak, sisteme veya bisikletlere, 3. kişilere veya 3. kişilere ait malvarlığına hasar vermemek,</span></li>
                                                    <li><span style="font-size: 10px;">Herhangi bir durumda kendisine tahsis edilen bisikleti 3. şahıslara kullandırmamak, kullandırılması durumunda abonelik iptal edilecektir. 3. Şahıslara kullandırılmasından doğan zararlar <strong>Abone</strong>'den tahsil edilecektir.</span></li>
                                                    <li><span style="font-size: 10px;">Şehir bisikletlerini yalnızca kişisel seyahat için kullanmak,</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletleri sadece, bisiklet yolu olarak belirtilen, yol çizgileri ile belirlenmiş alanlarda kullanmak. Bu alan dışından kullanımda doğabilecek olumsuzluklardan <strong>Kurum</strong> mesul tutulamaz.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletin etkilendiği herhangi bir arıza ya da kazada, yer ve durum bilgilerini gecikmeksizin <strong>Kurum</strong> Müşteri Hizmetlerine bildirmek. Eğer bisiklet arızadan dolayı istasyona dönemezse, <strong>Abone</strong>'nin arızayı rapor etmesinden sonra bisikletin 2 saat süresince güvende kalmasının sorumluluğu <strong>Abone</strong>'ye ait olacaktır. Bu süre zarfında <strong>Kurum</strong> belirtilen noktadan bisikleti teslim alacaktır.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong>, bisikletin istasyondan alıp geri bırakana kadar geçen sürede, bisikletlerin doğru şekilde korunması, bakılması ve güvende olmasından sorumludur. Kullanımdan sonra bisikletler istasyonlara geri getirilir ve kilitlendiğinden emin olarak ilgili yerlere bırakılır.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletler kullanılmak üzere alınırken, frenlerinin, oynar parçalarının kontrol edilmesi, koltuk yüksekliğinin ayarlanması ve son olarak da genel kullanıma uygunluğuna bakılması <strong>Abone</strong>'nin sorumluluğundadır.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong> bisikleti Türkiye Cumhuriyeti Kanunlarınca suç sayılabilecek şekilde ve diğer gayri kanuni işlerde ve yasak derecede alkol ve herhangi bir uyuşturucu alarak kullanamaz. Bisiklete zarar veremez. Bisiklete, bisiklet istasyonuna veya kamuya ait diğer eşyalara zarar verilmesi ve zararın tazmin edilmemesi durumunda Türkiye Cumhuriyeti Kanunlarının ilgili maddeleri uyarınca suç duyurusunda bulunulur.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletin <strong>Abone</strong>'de olduğu süre içerisinde (ileride çıkacak da olsa) gerek bisiklete, gerek <strong>Abone</strong>'ye ve gerekse 3. şahıslara verilen zararlardan dolayı tüm maddi ve manevi tazminat sorumlulukları ve cezai sorumluluklar <strong>Abone</strong>'ye aittir.&nbsp;Abone bisiklet kullanılması esnasında meydana gelebilecek zararları tazmin etmeyi kabul ve taahhüt eder. Aksi durumda Kocaeli Büyükşehir Belediyesine karşı talep edilecek tazminatları, açılacak dava veya icra takiplerinden dolayı Kocaeli Büyükşehir Belediyesinin rücu hakkı her zaman saklıdır.</span></li>
                                                    <li><span style="font-size: 10px;">Bisiklet kullanımından doğan trafik cezaları <strong>Abone</strong>'ye aittir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Kurum</strong> bisikleti tüm fonksiyonları çalışır vaziyette <strong>Abone</strong>'ye teslim etmiştir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong> kullanıma başladıktan itibaren bisikletin en iyi şekilde korunması için gereken tüm önlemleri alacak; kötü ve kurallara uygun olmayan kullanımlardan dolayı bisiklette meydana gelebilecek her türlü arıza ve onarım giderleri <strong>Abone</strong>'ye ait olacaktır.</span></li>
                                                    <li><span style="font-size:10px;"><strong>Abone</strong>, tek üyelik ile en fazla 3 (üç) bisiklet kiralayabilir. Bisikleti istasyondan alabilmesi için abone kartında en az 5 (beş) TL bakiye bulundurması gerekmektedir. Her bisiklet için 5 (beş) TL, toplamda 3 bisiklet için 15 (onbeş) TL bakiyesi bulunması gerekmektedir.</span></li>
                                                    <li><span style="font-size: 10px;">Kullanım ücretleri; <strong>Kurum</strong> tarafından belirlenmiş abone kart dolum istasyonlarından nakit olarak yapılacaktır.</span></li>
                                                    <li><span style="font-size: 10px;">Bildirilen tarihe kadar tahakkuk ettirilen cezaları ödemeyen <strong>Abone</strong>'lerin abone kartları iptal edilecek ve yasal takibat başlatılacaktır. Yasal tahsilât sürecinde tüm masraflar <strong>Abone</strong>'ye rücu ettirilecektir.</span></li>
                                                    <li><span style="font-size: 10px;">Kartı iyi kullanma ve saklama sorumluluğu <strong>Abone</strong>'lere aittir. Kartın kaybolması, kötü kullanım neticesinde zarar görmesi nedeniyle başka bir kopyasının istenmesi durumunda kartın idarece belirlenen yeni basımının masrafı 10 (On) TL &nbsp;<strong>Abone</strong>'ye ait olacaktır.</span></li>
                                                    <li><span style="font-size: 10px;">Kask ve benzeri koruma malzemeleri temini <strong>Abone</strong>'nin sorumluluğundadır.</span></li>
                                                    <li><span style="font-size: 10px;">Kiralama zamanını takip eden 24 (yirmidört) saat süre zarfında bisikletin teslimi gerçekleşmez ise sistem çalıntı durumuna düşecektir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Kurum</strong> Akıllı bisiklet Abone Yükümlülükleri şartlarında değişiklik yapma hakkını saklı tutar.</span></li>
                                                </ol>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 12- Gizlilik</strong></span></p>

                                                <p><span style="font-size: 10px;">Taraflar; sahip oldukları bilgileri (kişisel/kurumsal) gizli tutmakla sorumludur. Elde edilen bilgi ve belgeler kesinlikle 3. Şahıslarla paylaşılmayacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 13- Tebligat Adresi</strong></span></p>

                                                <p><span style="font-size: 10px;">Tarafların bu sözleşmede yazılı adreslerine yapılacak tebligatlar geçerlidir. Bu adreslerde bir değişiklik olması halinde adres değişikliği yapan taraf diğer tarafa yeni adresini yazılı olarak bildirmekle yükümlüdür. Bunun yapılmaması halinde eski adrese yapılacak tebligatlar kanunen geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 14- Mücbir Sebepler</strong></span></p>

                                                <p><span style="font-size: 10px;">Yasalarda belirtilmiş olan mücbir sebepler dâhilinde yine yasalarda belirtilmiş olan hükümler geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 15- Uygulanacak Hükümler</strong></span></p>

                                                <p><span style="font-size: 10px;">Bu sözleşme ile ilgili olarak çıkabilecek bütün ihtilaflarda öncelikle sözleşme hükümleri, bu sözleşmede bulunmayan hükümlerde ise TC kanunları uygulanacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 16- Yetkili Mahkeme ve İcra Daireleri</strong></span></p>

                                                <p><span style="font-size: 10px;">İş bu sözleşmeden dolayı doğacak bütün ihtilaflarda Kocaeli Mahkemeleri ve İcra daireleri yetkili olacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 17- Yürürlük</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, Üyelik Sözleşmesi ve Başvuru Formu' nu eksiksiz doldurup imzaladıktan sonra müracaat etmiş olur. <strong>Kurum</strong> tarafından müracaat onaylandıktan sonra yürürlüğe girer.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;İş bu sözleşme 4 sayfa ve 17 Maddeden ibaret olup, iki nüsha olarak tanzim edilmiş, ekleri ile birlikte taraflarca okunarak&nbsp; &nbsp;…&nbsp; /&nbsp; …&nbsp; / 20… &nbsp;&nbsp;tarihinde karşılıklı imza altına alınmıştır.</span></p>

                                                <p><span style="line-height: 1.6; font-size: 10px;">.</span></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>

                                    <p>&nbsp;</p>

                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" height="51" width="50%">
                                                    <p><strong>Kocaeli Büyükşehir Belediyesi</strong></p>

                                                    <p><strong>Ulaşım Dairesi Başkanlığı </strong></p>

                                                    <p><strong>Trafik Yönetimi Şube Müdürlüğü;&nbsp;</strong></p>

                                                    <p>ADSOYAD - İMZA</p>

                                                    <p>&nbsp;</p>
                                                </td>
                                                <td align="center" width="50%">
                                                    <p>${$("#Name").val()} ${$("#Surname").val()}<br>
                                                        ADSOYAD - İMZA</p>

                                                    <p>&nbsp;</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>&nbsp;</p>
                                </div>`;

        }
        else {
            data = `<div id="aboneform_en"><p>Individual Carriage Service Smart Bicycle Subscription General Conditions</p>

<p>ARTICLE 1 - THE PARTIES OF THE AGREEMENT</p>

<p>Business this contract; Kocaeli Metropolitan Municipality Department of Transportation and Traffic Management Department of Public Transportation; Admiral Salim Dervişoğlu Caddesi Kocaeli Fair was held between İzmit / Kocaeli address (hereinafter referred to as "Institution)) and  address (hereinafter referred to as Abone Subscriber)).</p>

<p>&nbsp;ARTICLE 2 - Purpose</p>

<p>It is the disclosure of cases where the subscribers are taken from the stations under the responsibility of the Subscribers and brought back to the station in the urban area of ??the Authority under the conditions stipulated herein.</p>

<p>&nbsp;Article 3- Subscriber Registration</p>

<p>The subscriber shall apply to subscriber centers and initiate subscriber registration. Subscriber card will be given to Subscribers to use the bicycles when the Subscriber registration process is completed. Subscriber cards are personal and cannot be used by anyone else. In case the subscriber card is used to another person, the user assumes all kinds of responsibilities that may occur. In addition, Kocaeli Metropolitan Municipality can not claim any rights.</p>

<p>&nbsp;ARTICLE 4- Termination of Contract</p>

<p>Registration deletion; At the request of the Subscriber, or as a result of the behavior contrary to the contract, it is deleted by the Authority without any justification and the contract is terminated unilaterally. If the registration is deleted, the subscription fee will not be refunded.</p>

<p>ARTICLE 5- Smart Bicycle Service Contract</p>

<p>Subscriber shall be able to use the card to contract rights with the Authority. The use of each new bicycle means that the rules are accepted.</p>

<p>ARTICLE 6- Subscriber Card Validity Period and Visa</p>

<p>Subscriber card; Valid from the date of the contract between the Authority and the Subscriber.</p>

<p>There is no discounted tariff at the Authority.</p>

<p>ARTICLE 7- Fee Schedule and Subscription Fee</p>

<p>The fee tariff and subscription fee are determined by Kocaeli Metropolitan Municipality Assembly and fee collection process is performed according to this tariff. The subscriber agrees and undertakes to pay the tariff and subscription fee.</p>

<p>ARTICLE 8- Smart Bicycle Subscriber Obligation</p>

<p>The Subscriber's obligation is to return the bicycle in good standing and to comply with the provisions of Article 10.</p>

<p>ARTICLE 9- Smart Bicycle Subscription Requirement</p>

<p>The subscriber must be of legal age, familiar with traffic rules, and also be qualified to ride a bicycle. Those under the age of 18; they do not have the right to own and use the subscriber card.</p>

<p>ARTICLE 10- Smart Bicycle Subscriber Rights</p>

<p>The contract between the Authority and the Subscriber provides the right to use the bicycle within the limits specified herein. Bicycles will be available to the Subscribers ready for use. The subscriber may contact the customer service at www.kobis.com.tr and / or on the announced phone lines in order to learn the terms and conditions of the service to be received or to convey various requests. The Institution will process and respond to questions and requests as soon as possible. This period shall take a maximum of 15 working days from the date the Subscriber communicates. If the Subscriber has not been eligible to use the bicycle, the Subscriber shall be entitled to purchase another bicycle. The unsuitable bike will be returned to the Subscriber within 5 minutes and the system will give the Subscriber another bike.</p>

<p>ARTICLE 11- Smart Bicycle Subscriber Obligations</p>

<p>Subscribers When using the smart bike service;</p>

<p>a.&nbsp;To use the service and bicycles with utmost care, not to damage the system or the property of bicycles, third parties or third parties,</p>

<p>b.&nbsp;In any case, it is not allowed to use the bicycle assigned to third parties and in case it is used, the subscription will be canceled. 3. The damages arising from the disbursement to the persons shall be collected from the Subscriber.</p>

<p>c.&nbsp;Use city bikes only for personal travel,</p>

<p>d.&nbsp;Use bikes only in areas designated by road lines, designated as bicycle paths. The Authority shall not be held liable for any inconveniences that may arise in use outside this area.</p>

<p>e.&nbsp;To report the location and status information to the Customer Service without any delay in case of any breakdown or accident affected by the bicycle. If the bicycle cannot return to the station due to the malfunction, the Subscriber shall be responsible for keeping the bicycle safe for 2 hours after the Subscriber reports the malfunction. During this period, the Authority will take the bicycle from the specified point.</p>

<p>f.&nbsp;The subscriber is responsible for the correct protection, care and safety of the bicycles until the time the bike is picked up and released. After use, the bikes are returned to the stations and left to the relevant places, making sure they are locked.</p>

<p>g.&nbsp;It is the responsibility of the Subscriber to check the brakes, moving parts, adjust the seat height and finally check their suitability for general use when the bicycles are taken for use.</p>

<p>h.&nbsp;The subscriber shall not use the bicycle in a manner that may be considered a crime by the laws of the Republic of Turkey and in other illegal activities and by taking alcohol and drugs to a prohibited degree. He can't hurt the bike. In case of damage to a bicycle, bicycle station or other public property and no compensation for the damage, a criminal complaint shall be filed in accordance with the relevant articles of the laws of the Republic of Turkey.</p>

<p>i.&nbsp;All material and non-pecuniary damages and penal liability for damages to the bicycle, to the Subscriber and to third parties during the time the bicycle is in the Subscriber (even in the future) shall belong to the Subscriber. The subscriber agrees and undertakes to compensate for the damages that may occur during the use of bicycles. Otherwise, the right of recourse of Kocaeli Metropolitan Municipality shall always be reserved due to the claims to be filed against the Kocaeli Metropolitan Municipality, due to lawsuits or enforcement proceedings.</p>

<p>j.&nbsp;Traffic fines arising from the use of bicycles belong to the Subscriber.</p>

<p>k.&nbsp;The organization has delivered all functions of the bicycle to the Subscriber.</p>

<p>l.&nbsp;The subscriber shall take all necessary measures for the best protection of the bicycle from the start of use; any fault and repair expenses that may occur on the bicycle due to bad and improper use shall be borne by the Subscriber.</p>

<p>m.&nbsp;The subscriber can rent up to 3 (three) bicycles with a single membership. In order to take the bicycle from the station, the subscriber card must have a balance of at least 5 (five) TL. Each bicycle must have a balance of 5 (five) TL and a total of 15 (fifteen) TL for 3 bikes.</p>

<p>n.&nbsp;Usage fees; Subscriber card filling stations determined by the Authority will be made in cash.</p>

<p>o.&nbsp;Subscriber cards of the Subscribers who have not paid the accrued fines until the notified date will be canceled and legal proceedings will be initiated. All expenses will be recourse to the Subscriber during the legal collection process.</p>

<p>p.&nbsp;Subscriber is responsible for the good use and storage of the card. If another copy is requested due to loss of the card and damage due to misuse, the cost of the new edition of the card determined by the administration shall be 10 (Ten) TL Subscriber.</p>

<p>q.&nbsp;It is the responsibility of the Subscriber to provide helmets and similar protection materials.</p>

<p>r.&nbsp;If the delivery of the bicycle does not take place within 24 (twenty-four) hours following the rental period, the system will be stolen.</p>

<p>s.&nbsp;The Authority reserves the right to change the terms of the Smart Bicycle Subscriber Liability.</p>

<p>ARTICLE 12- Confidentiality</p>

<p>Sides; is responsible for keeping the information they have (personal / corporate) confidential. The information and documents obtained will never be shared with third parties.</p>

<p>ARTICLE 13- Notification Address</p>

<p>The notices to be made to the addresses of the parties written in this agreement are valid. In case of a change in these addresses, the party making the change is obliged to inform the other party in writing of its new address. If this is not done, the notifications to the old address are valid by law.</p>

<p>ARTICLE 14- Inevitable Reasons</p>

<p>For the purposes of force majeure specified in the law, the provisions of the law shall also apply.</p>

<p>ARTICLE 15- Applicable Provisions</p>

<p>In all disputes that may arise in connection with this agreement, the provisions of the agreement shall be applied firstly and the provisions of the Republic of Turkey shall be applied in the provisions not included in this agreement.</p>

<p>ARTICLE 16- Competent Courts and Enforcement Offices</p>

<p>Kocaeli Courts and Enforcement Offices shall be authorized in all disputes arising out of this agreement.</p>

<p>ARTICLE 17- Effectiveness</p>

<p>The subscriber has applied after completing and signing the Membership Agreement and Application Form. It comes into force after the application is approved by the institution.</p>

<p>This agreement consists of 4 pages and 17 articles and it has been prepared in two copies and read by the parties together with the annexes and has been mutually signed on… /… / 20….</p>
</div>`;
        }
        var printWin = window.open("", "", "menubar=0,location=0,height=700,width=850")
        printWin.document.open()
        printWin.document.write(data)
        printWin.print()
        // printWin.addEventListener(
        //     'load',
        //     function () {
        //         printWin.print()
        //     },
        //     true
        // )
    }
    render() {
        const { t } = this.props;
        const dataCity = this.state.dataCityList;
        return (
            <Division className="user-page p-2">



                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.user.newUser", { ns: "translations" })}</h6>
                    </Division>
                    <form onSubmit={this.handleSubmit}>
                        <Division className="card-body py-2">
                            <Division className="d-lg-flex">
                                <ul className="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
                                    <li className="nav-item"><a href="#uye-bilgileri" className="nav-link active" data-toggle="tab">{t("userInformation")}</a></li>
                                    <li className="nav-item"><a href="#kimlik" className="nav-link" data-toggle="tab">{t("identification")}</a></li>
                                    <li className="nav-item"><a href="#iletisim" className="nav-link" data-toggle="tab">{t("contactInformation")}</a></li>
                                </ul>
                                <Division className="tab-content flex-lg-fill p-2">
                                    <Division className="tab-pane fade show active" id="uye-bilgileri">

                                        <Division className="form-group row">
                                            <Text Tag="label" className="col-form-label col-lg-2">{t("userName")}</Text>
                                            <Division className="col-lg-4">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input id="Name" name="Name" type="text" className="form-control form-control-lg" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <i className="icon-user"></i>
                                                    </Division>
                                                </Division>
                                            </Division>
                                            <Text Tag="label" className="col-form-label col-lg-2">{t("userSurname")}</Text>
                                            <Division className="col-lg-4">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" id="Surname" name="Surname" className="form-control form-control-lg" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <i className="icon-user"></i>
                                                    </Division>
                                                </Division>
                                            </Division>
                                        </Division>

                                        <Division className="form-group row">
                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userEmail")}</Text>
                                            <Division className="col-lg-4 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input id="Email" name="Email" type="text" className="form-control form-control-lg" placeholder="example@example.com" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <i className="icon-envelope"></i>
                                                    </Division>
                                                </Division>
                                            </Division>

                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("webAccessPassword")}</Text>
                                            <Division className="col-lg-4 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input name="Sifre" type="password" className="form-control form-control-lg" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <i className="icon-eye"></i>
                                                    </Division>
                                                </Division>
                                            </Division>
                                        </Division>




                                        <Division className="form-group row">
                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("accountStatus")}</Text>

                                            <Division className="col-lg-4">
                                                <select name="Status" id="Status" className=" form-control w-100" >
                                                    <option value="-1" disabled>{t("accountStatus")}</option>
                                                    <option value="1">{t("accountStatusActive")}</option>
                                                    <option value="0">{t("accountStatusDeactive")}</option>
                                                </select>
                                            </Division>
                                            <Text Tag="label" className="col-form-label col-lg-2">{t("userBalance")}</Text>
                                            <Division className="col-lg-4">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input name="Balance" defaultValue="0" type="text" className="form-control form-control-lg" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <i className="icon-credit-card"></i>
                                                    </Division>
                                                </Division>
                                            </Division>
                                        </Division>


                                        <Division className="form-group row">
                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("bicycleMaxCount")}</Text>
                                            <Division className="col-lg-4 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input id="VehicleCountMax" defaultValue="3" name="VehicleCountMax" type="text" className="form-control form-control-lg" />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <PedalBikeIcon />
                                                    </Division>
                                                </Division>
                                            </Division>
                                        </Division>



                                        <Division className="form-group row">
                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userPhone")}</Text>
                                            <Division className="col-lg-7">
                                                <Division className="form-group form-group-feedback ">
                                                    <input name="Phone" id="user-phone" type="tel" className="form-control phoneData form-control-lg"
                                                    />
                                                </Division>
                                            </Division>
                                            <Division className="col-lg-3">
                                                <a type="button" onClick={this.phoneControl} className="btn btn-primary w-100 h-100">{t("Telefon Kontrol")}</a>
                                            </Division>
                                        </Division>


                                        <Division className="form-group row">

                                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userAgreementStatus")}</Text>

                                            <Division className="col-lg-4">

                                                <select name="Contract" id="Contract" className=" form-control w-100" >
                                                    <option value="-1" disabled>{t("userAgreementStatus")}</option>
                                                    <option value={1}>{t("userAgreementStatusActive")}</option>
                                                    <option value={0}>{t("userAgreementStatusDeactive")}</option>
                                                </select>
                                            </Division>

                                            <Text Tag="label" className="col-form-label col-lg-2">{t("userAccountType")}</Text>

                                            <Division className="col-lg-4">

                                                <select name="MemberType" id="MemberType" className=" form-control w-100" >
                                                    <option value="-1" disabled>{t("userAccountType")}</option>

                                                </select>
                                            </Division>

                                        </Division>

                                        <Division className="form-group row">
                                            <Division className="col-md-2 mt-4">
                                            </Division>

                                            <Division className="col-md-4 mt-4">
                                                <button type="button" onClick={() => this.printClick("tr")} className="btn btn-primary">{t("printSubscriptionForm")}</button>
                                            </Division>
                                            <Division className="col-md-2 mt-4">
                                            </Division>
                                            <Division className="col-md-4 mt-4">
                                                <button type="button" onClick={() => this.printClick("en")} className="btn btn-primary">{t("printSubscriptionFormInEnglish")}</button>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Division className="tab-pane fade" id="kimlik">

                                        <Division className="row">
                                            <Division className="form-group row">
                                                <Text Tag="h4" className="mb-2">{t("identification")}</Text>

                                                <Division className="col-md-6 mt-2">
                                                    <Division className="row">
                                                        <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                            <Text Tag="h6" className="m-0">{t("gender")}</Text>
                                                        </Division>
                                                        <Division className="col-md-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">

                                                                <select name="Gender" id="Gender" className=" form-control material-icons"  >
                                                                    <option value="">{t("selectGender.selectGender")}</option>
                                                                    <option value="E">{t("selectGender.Select1")}</option>
                                                                    <option value="B">{t("selectGender.Select2")}</option>
                                                                </select>
                                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                                    <FemaleIcon />
                                                                </Division>
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                    <Division className="row mt-2">
                                                        <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                            <Text Tag="h6" className="m-0">{t("selectTourist.label")}</Text>
                                                        </Division>
                                                        <Division className="col-md-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                                <select name="Tourist" id="Tourist" className=" form-control material-icons"   >
                                                                    <option value="0">{t("selectTourist.Select2")}</option>
                                                                    <option value="1">{t("selectTourist.Select1")}</option>
                                                                </select>
                                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                                    <WorkOutlineIcon />
                                                                </Division>
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                    <Division className="row mt-2">
                                                        <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                            <Text Tag="h6" className="m-0">Kayıt Yeri</Text>
                                                        </Division>
                                                        <Division className="col-md-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                                <select name="RegistrationPlaceId" id="RegistrationPlaceId" className="infoSelect select-search form-control material-icons"  >
                                                                    <option value={"4"}>Admin Panel</option>
                                                                    <option value={"1"}>İstasyon</option>
                                                                    <option value={"2"}>Abone Noktası</option>
                                                                    <option value={"3"}>Web</option>
                                                                    <option value={"5"}>Mobil</option>
                                                                    <option value={"0"}>Diğer</option>

                                                                </select>

                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>
                                                <Division className="col-md-6 mt-2">
                                                    <Division className="row">
                                                        <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                            <Text Tag="h6" className="m-0">{t("birthDate")}</Text>
                                                        </Division>
                                                        <Division className="col-md-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                                <input ref={this.birthDateRef} name="birthDate" className="form-control material-icons" type="text" />
                                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                                    <CalendarTodayIcon />
                                                                </Division>
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                    <Division className=" row mt-2">

                                                        <Text Tag="label" className="col-form-label col-lg-4">{t("userId")}</Text>
                                                        <Division className="col-lg-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                                <input type="number" id="TcNumber" name="TcNumber" className="form-control form-control-lg" />
                                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                                    <i className="icon-user"></i>
                                                                </Division>
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                    <Division className="mt-2 row">
                                                        <Text Tag="label" className="col-form-label col-lg-4">{t("passportNumber")}</Text>
                                                        <Division className="col-lg-8">
                                                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                                <input name="PassportNumber" type="text" className="form-control material-icons" placeholder={t("passportNumber")} />
                                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                                    <PublicIcon />
                                                                </Division>
                                                            </Division>
                                                        </Division>
                                                    </Division>

                                                </Division>





                                            </Division>
                                        </Division>
                                    </Division>

                                    <Division className="tab-pane fade" id="iletisim">

                                        <Division className="row">

                                            <Division className="col-md-12">
                                                <Text Tag="h4" className="mt-4 mb-2">{t("homeAddress")}</Text>
                                                <Division className="row">
                                                    <Division className="col-md-2 d-flex justify-content-start align-items-start align-content-start">
                                                        <Text Tag="h6" className="m-0">{t("address")}</Text>
                                                    </Division>
                                                    <Division className="col-md-10">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <textarea name="AddressHome" id="AddressHome" className="form-control material-icons" rows="5"></textarea>
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <MapIcon />
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>
                                                <Division className="row mt-2">
                                                    <Division className="col-md-2 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">{t("province")}</Text>
                                                    </Division>
                                                    <Division className="col-md-10">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <select name="AddressHomeCityId" id="AddressHomeCityId" className="infoSelect select-search form-control material-icons" >
                                                                <option value="0">{t("selectProvince")}</option>

                                                            </select>

                                                        </Division>
                                                    </Division>
                                                </Division>
                                                <Division className="row mt-2">
                                                    <Division className="col-md-2 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">{t("district")}</Text>
                                                    </Division>
                                                    <Division className="col-md-10">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <select name="AddressHomeCountyId" id="AddressHomeCountyId" className="infoSelect select-search form-control material-icons" >
                                                                <option value="0">{t("selectDistrict")}</option>

                                                            </select>

                                                        </Division>
                                                    </Division>
                                                </Division>

                                            </Division>
                                        </Division>
                                    </Division>

                                </Division>
                            </Division>
                            <Division className="form-group row mt-2 d-flex justify-content-center">
                                <Division className="col-lg-3">
                                    <button type="submit" className="btn btn-secondary w-100 h-100">{t("save")}</button>
                                </Division>
                                <Division className="col-lg-3">
                                    <button type="button" onClick={this.backList} className="btn btn-danger w-100 h-100">{t("cancel")}</button>
                                </Division>
                            </Division>
                        </Division>
                    </form>

                </Division>
            </Division>
        )
    }
}

export default withTranslation(['userEdit', 'translations'])(UserAdd);

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}