import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import TableData from "../components/table.js";
import TableDataV2 from "../components/tableV2.js";
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");
import Anchor from "../components/elements/anchor.js";
import Division from "../components/elements/content-division";
import Functions from "../class/functions";
import swal, { Swal } from "../js/plugins/notifications/sweet_alert.min.js";
import axios from "axios";
import Cookies from 'universal-cookie';
import jq from 'jquery';
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net-buttons/js/buttons.print';
import 'bootstrap/dist/js/bootstrap';
import jsZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
window.JSZip = jsZip;
import { getEndPoint } from "../class/api";
let stationList = [];
const cookies = new Cookies();

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tables: []
        }
        const { t } = this.props

        this.parkingoccupancy2Column = [
            {
                title: t("table.parkingOccupancyRate.stations"),
                data: "Name", render: function (data, type, row) {
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${data}</a> `;
                }, orderable: false
            },
            {
                title: t("Boş Park / Dolu Park"),
                data: "ParkFull", render: function (data, type, row) {
                    var result = parseInt(row.ParkEmpty) * 100 / parseInt(row.ParkFull + row.ParkEmpty);
                    if (result <= 25) {
                        return `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                    else if (result >= 75) {
                        return `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                    else {
                        return `<div style="text-align: center;">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                }, orderable: false

            },

        ]
        this.parkingoccupancy2ColumnV2 = [
            {
                title: t("table.parkingOccupancyRate.stations"),
                data: "Name", render: function (data, type, row) {
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${data}</a> `;
                }, orderable: false
            }, 
            {
                title: t("Boş Park / Dolu Park"),
                data: "ParkFull", render: function (data, type, row) {
                    var result = parseInt(row.ParkEmpty) * 100 / parseInt(row.ParkFull + row.ParkEmpty);
                    if (result <= 25) {
                        return `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                    else if (result >= 75) {
                        return `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                    else {
                        return `<div style="text-align: center;">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                    }
                }, orderable: true

            },

        ]
        this.mostRentedBicyclesColumn = [
            {

                title: t("table.mostRentedBicycles.bicyclesNo"),
                data: "VehicleNo", render: function (data, type, row) {
                    return `<a id="most_vehicle_no"  href="#">${data}</a>`
                }, orderable: false
            },
            {
                title: t("Toplam Kullanılma Zamanı"),
                data: "TotalDuration"
            },
            {
                title: t("İstasyon"),
                data: "StationId",
                render: function (data, type, row) {
                    var stationList = ReactSession.get("stationList");
                    if (data == null) {
                        return "Üye Üzerinde Kullanımda"
                    }

                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${data}` : `#`} ">${stationList.find(x => x.StationId == data).Name}</a> `;
                }
            },

        ]
        this.passiveStationColumn = [
            {
                title: t("İstasyon Numarası"),
                data: "Number"
            },
            {

                title: t("table.passive.stations"),
                data: "Order", render: function (data, type, row) {
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${row.Name} </a>`
                }

            },
            {

                title: t("table.passive.lastConnect"),
                data: "LastConnect",
                render: function (data) {
                    return moment(data).format('YYYY-MM-DD HH:mm:ss');
                }, orderable: false

            },

        ]
        this.passiveStationFilter = [
            {
                Status: false,
                NotStationId: [287, 45 ,39,40, 341],
            }
        ]
        const moment = require('moment');

        const myDate = moment.now();
        const newDate = moment(myDate).add(-6, 'hours').format('YYYY-MM-DD HH:mm:ss');

        this.filterMostRentedFilter = [
            {
                "EntryDate": moment().startOf('month').format('YYYY-MM-DD HH:MM'),
                "ExitDate": moment().endOf('month').format('YYYY-MM-DD HH:MM'),
            }
        ]
        this.notDeliveredColumn = [
            {

                data: "VehicleNo", render: function (data, type, row) {
                    return `<a id="not_delivered_vehicle_no"  href="#">${data}</a>`
                },
                title: t("table.bicyclesNotDelivered.bicyclesNo"),
            },
            {
                title: t("table.bicyclesNotDelivered.userName"),
                data: null, render: function (data, type, row) {

                    return `<a href="/users/edit/${row.User.id} ">  ${row?.User?.Name} ${row?.User?.Surname} </a>`
                }
            },
            {
                title: t("Çıkış İstasyonu"),
                data: "LastProcessingTrip", render: function (data, type, row) {
                    return `<p> ${data.StationParkUnitEntry?.StationName === null ? "İstasyon Bulunamadı" : data.StationParkUnitEntry?.StationName}</p> `
                }
            },
            {
                title: t("table.bicyclesNotDelivered.dateTime"),
                data: "LastConnect"
            },
        ]
        this.notDeliveredFilter = [
            {
                takeOffDate: newDate,
                StatusId: [10],
            }
        ]
        this.forceFullyColumn = [
            {

                title: t("table.forcefullyTakenBicycles.bicyclesNo"),
                data: "VehicleNo", render: function (data, type, row) {
                    return `<a id="forcefully_vehicle_no"  href="#">${data}</a>`
                }
            },

            {
                title: t("table.forcefullyTakenBicycles.time"),
                data: 'ProcessDateDiff'
            },
            {
                title: t("table.faultparks.dateTime"),
                data: "ProcessDate", render: function (data) {
                    return moment(data).format('DD-MM-YYYY HH:mm:ss');
                }
            },
        ]
        this.forceFullyFilter = [
            {
                StatusId: [5],
            }
        ]
        this.faultParksColumn = [
            {
                title: t("table.faultparks.parkNo")
                , data: 'ParkId'
            },

            {
                title: t("table.faultparks.stationName")
                , data: "StationId", render: function (data) {
                    var stationList = ReactSession.get('stationList');
                    var stationData = stationList.find(x => x.StationId == data)
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${data}` : `#`}">${stationData.Name} </a>`
                }
            },
            {
                title: t("table.faultparks.parkOrder")
                , data: "ParkOrder"
            },
            {
                title: t("table.faultparks.faultType")
                , data: "StatusTxt"
            },
            {
                title: t("table.faultparks.dateTime")
                , data: "LastConnect"
            },
        ]
        this.faultParksFilter = [{ Status: [1, 4, 5, 6] }]
        this.parkingoccupancyColumn = [
            {
                title: t("table.parkingOccupancyRate.stations"),
                data: "Name", render: function (data, type, row) {
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${data} </a>`
                }, orderable: false
            },
            {
                title: t("table.parkingOccupancyRate.emptyParkSlot"),
                data: 'ParkEmpty',
                render: function (data, type, row) {

                    return data
                },
                orderable: false
            },
            {
                title: t("table.parkingOccupancyRate.fullParkSlot"),
                data: "ParkFull", render: function (data, type, row) {
                    var result = parseInt(row.ParkEmpty) * 100 / parseInt(data + row.ParkEmpty);
                    if (result <= 25) {
                        return `<div class="bg-custom-red text-center">${data}</div>`;
                    }
                    else if (result >= 75) {
                        return `<div class="bg-custom-red text-center">${data}</div>`;
                    }
                    else {
                        return `<div style="text-align: center;">${data}</div>`;
                    }
                },
                orderable: false
            },
        ]
        this.usersTableFilter = [
            {
                takeOffDate: "2000-04-06 10:00:00",
                StatusId: [9, 10]
            }
        ]
        this.usersTableColumn = [
            { title: t("table.user.userId"), data: "id", "orderable": true },
            {
                title: t("table.user.userIdentification"), data: "TcNumber",
                render: function (data, type, row) {
                    return Functions.MaskCharacter(`${data}`, '*', 4)
                },
                "orderable": false
            },
            {
                title: t("table.user.userNameSurnameCardId"),
                data: "Name", render: function (data, type, row) {

                    if (data == null) {
                        return ' ';
                    }
                    var userID = '/users/edit/' + row.id

                    var userNameSurname = Functions.MaskCharacter(`${row.Name} ${row.Surname}`, '*', 2)
                    userID = Functions.RoleControl(11, 4) == false ? '#' : userID
                    return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.id + '">' + userNameSurname + '</a>';
                }, "orderable": true
            },
            {
                title: t("table.user.userPhone"),
                data: "Phone", render: function (data, type, row) {
                    if (data == null) {
                        return ' ';
                    }
                    return Functions.MaskCharacter(`${data}`, '*', 4);
                }, "orderable": true
            },
            {
                title: t("table.user.userUsageTime"),
                data: null, render: function (data, type, row) {

                    return (row.VehicleCountActive + ' / ' + row.VehicleCountMax);
                }, "orderable": true
            },
            {
                title: t("table.user.userBalance"),
                data: "Balance", render: function (data, type, row) {
                    return (parseFloat(data).toFixed(2) + 'TL');
                }, "orderable": true
            },
        ]
        this.usersTableFilter = [
            {
                takeOffDate: "2000-04-06 10:00:00",
                StatusId: [9, 10]
            }
        ]
        this.userNotUsageVehicleColumn = [
            {
                title: t("Üye"), data: "member.user.Name", render: function (data, type, row) {
                    return `<a href="/users/edit/${row.member?.UserId}"> ${row.member?.user?.Name} ${row.member?.user?.Surname} </a>`
                }, "orderable": false
            },
            {
                title: t("Bisiklet No"),
                data: "VehicleId",
                render: function (data, type, row) {
                    return `<a id="not_usage_vehicle_no" href="#"> ${row.vehicle?.VehicleNo}</a>`
                },
                "orderable": false
            },
            {
                title: t("İstasyon > Park"),
                data: "StationParkUnitEntryId",
                render: function (data, type, row) {
                    return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.station_park_unit_entry.StationId}` : `#`} "> ${row.station_park_unit_entry?.station?.Name} >  ${row.station_park_unit_entry?.ParkOrder} </a>`
                },
                "orderable": false
            },
            {
                title: t("İşlem Tarihi"), data: "EntryDate", render: function (data, type, row) {
                    return moment(data).format('DD-MM-YYYY HH:MM')
                }, "orderable": true
            }
        ]
        this.userNotUsageVehicleFilter = [{

            "ProcessStatusId": [2, 3],

            "dateRange": [moment().startOf('year').format('YYYY:MM:DD HH:mm:ss'), moment().add(-10, 'minutes').format('YYYY:MM:DD HH:mm:ss')],
        }]
        this.bikeListColumn = [
            {
                title: t("table.VehicleNo", { ns: 'systemBicycles' }),
                data: "VehicleNo", render: function (data) {
                    return (`<a id="list_vehicle_no"  href="#" >${data}</a>`);
                }
            },

            {
                title: t("table.BicycleType", { ns: 'systemBicycles' }), data: "VehicleTypeId", render: function (data, type, row) {
                    return row.VehicleType
                }
            },
            {
                title: t("table.userID", { ns: 'systemBicycles' }),

                data: "User", render: function (data, type, row) {

                    var user;
                    var userID;
                    if (row.User != null) {
                        user = row.User.Name + ' ' + row.User.Surname
                        userID = '/users/edit/' + row.UserId
                        userID = Functions.RoleControl(11, 4) == false ? '#' : userID
                        var split = user.split()
                        return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.UserId + '">' + user + '</a>';
                    }
                    else {
                        var stationName = row.Station
                        if (row.Station == null) {
                            return "İstasyon Bulunamadı"
                        }
                        var stationList = ReactSession.get('stationList');
                        return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${stationList.find(x => x.Name == stationName).StationId}` : `#`} ">${stationName} İstasyonu</a> `;
                    }

                }, orderable: false
            },
            { title: t("table.RFID", { ns: 'systemBicycles' }), data: "RFID" },
            {
                title: t("table.UsedMinute", { ns: 'systemBicycles' }),
                data: "ProcessDate"
                , render: function (data, type, row) {
                    if (row.User != null) {

                        return '<p>' + row.ProcessDateDiff + '</p>'
                    }
                    else {

                        return "00:00:00 00:00"
                    }
                }
            },
            {
                title: t("table.VehicleTypeId", { ns: 'systemBicycles' }), data: "StatusId", render: function (data, type, row) {
                    return row.StatusIdTxt
                }
            }

        ]

    }


    componentDidMount() {
        const { t } = this.props;
        var _this = this;
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $('.selectStation').select2({
            language: selectLanguage
        });
        stationList = ReactSession.get('stationList')


        var stationSelect = $('#detailsStationSelect');

        $('#detailsStationSelect').empty().trigger("change");
        var option = new Option(t("all", { ns: "translations" }), "0", true, true);
        stationSelect.append(option).trigger('change');
        stationList.forEach(elm => {
            var newOption = new Option(
                `${elm.Name} ( ${elm.Number} )`,
                elm.StationId,
                false, false);
            $(stationSelect).append(newOption).trigger('change');
        });

        $('#resetStation').empty().trigger("change");

        var stationSelect = $('#resetStation');
        if (Functions.RoleControl(8, 1)) {
            var option = new Option(
                t("all", { ns: "translations" }),
                "ALL-RESET",
                true,
                true);
            stationSelect.append(option).trigger('change');
        }


        stationList.forEach(elm => {
            var newOption = new Option(
                elm.Name + ' (' + elm.Number + ') ',
                elm.Number,
                false, false);
            $('#resetStation').append(newOption).trigger('change');
        });
        $('#detailsStationSelect').on('select2:select', function (e) {
            var stationData = $('#detailsStationSelect').val()
            if (stationData == "0") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("station") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                return
            }
            if (stationData == null) {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("station") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                return
            }
            window.location.href = '/stations/list/' + stationData
        });

        // mostRentedBicycles-table  forceFully-table   

        $('#notDelivered-table tbody').on('click', 'td #not_delivered_vehicle_no', function () {
            var tr = $(this).closest('tr');
            var row = _this.state.tables[0].row(tr);
            var data = row.data()
            ReactSession.set('bikeSearchData', data.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });
        $('#mostRentedBicycles-table tbody').on('click', 'td #most_vehicle_no', function () {
            var tr = $(this).closest('tr');
            var row = _this.state.tables[1].row(tr);
            var data = row.data()
            ReactSession.set('bikeSearchData', data.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });
        $('#vehicle-table tbody').on('click', 'td #list_vehicle_no', function () {
            var tr = $(this).closest('tr');
            console.log(_this.state.tables);
            var row = _this.state.tables[2].row(tr);
            var data = row.data()
            console.log(data);
            ReactSession.set('bikeSearchData', data.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });
        $('#forceFully-table tbody').on('click', 'td  #forcefully_vehicle_no', function () {
            var tr = $(this).closest('tr');
            var row = _this.state.tables[3].row(tr);
            var data = row.data()
            ReactSession.set('bikeSearchData', data.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });


        $('#userNotUsageVehicle tbody').on('click', 'td #not_usage_vehicle_no', function () {
            var tr = $(this).closest('tr');
            console.log(tr);
            var row = _this.state.tables[4].row(tr);
            var data = row.data()
            ReactSession.set('bikeSearchData', data.vehicle.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });


    }

    componentDidUpdate = () => this.componentDidMount();
    resetSubmitHandler = (e) => {
        e.preventDefault();
        const _this = this;
        const { t } = this.props
        if ($('#resetStation').val() != "ALL-RESET") {
            var data = stationList.find(x => x.Number == $('#resetStation').val())
            if (data.Status == false) {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: "Bu İstasyona Reset İşlemi Gerçekleştirilemez",
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                return

            }
        }

        // { ns: "translations" }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: "Resetlemek" }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                // var parksNo = document.getElementById('parkNo').value;
                var stationData = $('#resetStation').val();
                var data = JSON.stringify({
                    "StationNumber": stationData == "ALL-RESET" ? stationData : parseInt(stationData),
                    "ProccessType": 3
                });
                var config = {
                    method: 'POST',
                    url: getEndPoint() + '/api/Admin/Station/Control',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (stationData == "ALL-RESET") {
                            var data = JSON.stringify({
                                "Filter": {
                                    "Status": false
                                }
                            });

                            var config = {
                                method: 'put',
                                url: getEndPoint() + '/api/Admin/Station',
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    var passiveStations = `<br/> </strong> `;
                                    response.data.data.forEach(element => {
                                        passiveStations += `<p>  ${element.Name} -</p>`
                                    })
                                    passiveStations += 'İstasyonlarına Reset İşlemi Yapılamadı'
                                    swal.fire({
                                        title: "Başarılı Ancak Resetlenemeyen İstasyonlar Mevcut",
                                        html: passiveStations,
                                        icon: "error"
                                    })
                                    return
                                })
                        } else {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            return
                        }

                    })
                    .catch(function (error) {
                        swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.subject", { ns: "translations" }) + error.message,
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })
                    });
            }
        })
    }

    render() {
        const { t } = this.props;


        return (

            <div className="retail-page p-2" >
                <ul className="nav nav-tabs nav-tabs-highlight">
                    <li className="nav-item">
                        <a href="#tab-1" className="nav-link active" data-toggle="tab">{t("tabs.tab1")}</a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-2" className="nav-link" data-toggle="tab">{t("tabs.tab2")}</a>
                    </li>

                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab-1">
                        <div className="row">
                            <div style={{ display: Functions.RoleControl(15, 0) == false ? "none" : "" }} className="col-sm-12 col-md-6 col-lg-6">
                                <Division className="card ">
                                    <Division className="card-body">
                                        <Division className="row">


                                            <Division className="col-md-12 col-sm-12 d-flex  justify-content-center align-items-center mt-1">
                                                <div className="input-group">
                                                    <select id="detailsStationSelect" defaultValue="0" className="form-control selectStation "  >
                                                        <option value="0">  {t("loading", { ns: "translations" })} </option>
                                                    </select>
                                                </div>

                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>

                            </div>
                            <div style={{ display: Functions.RoleControl(8, 0) == false ? "none" : "" }} className="col-sm-12 col-md-6 col-lg-6">
                                <Division className="card ">
                                    <Division className="card-body">
                                        <Division className="row">


                                            <Division className="col-md-9 col-sm-12 d-flex justify-content-center align-items-center mt-1">
                                                <div className="input-group">
                                                    <select id="resetStation" defaultValue="0" className="form-control selectStation "  >
                                                        <option value="0">  {t("loading", { ns: "translations" })} </option>
                                                    </select>
                                                </div>

                                            </Division>

                                            <Division className="col-md-3 mt-2">
                                                <Anchor type="submit" onClick={this.resetSubmitHandler} className="navbar-nav-link btn btn-primary">
                                                    {t("resetStations")}
                                                    <i className="icon-reset ml-2"></i>
                                                </Anchor>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                            </div>
                        </div>
                        <div className="row">
                            <TableData
                                cardTitle={t("table.passive.head")}
                                abilityid={5}
                                auth={0}
                                length="10"
                                ajaxUrl="/api/Admin/Station"
                                tableName="passiveStation"
                                filter={this.passiveStationFilter}
                                columns={this.passiveStationColumn} />
                            <TableData cardTitle={t("table.faultparks.head")}
                                abilityid={2}
                                auth={0}
                                searching={false}
                                customOrder={[4, "desc"]}
                                length="5"
                                ajaxUrl="/api/Admin/Station/ParkUnit"
                                tableName="faults-table"
                                filter={this.faultParksFilter}
                                columns={this.faultParksColumn}
                            />





                            <TableData
                                setTable={(e) => {
                                    var table = this.state.tables
                                    table.push(e);
                                    this.setState({ tables: table })

                                }}
                                cardTitle={t("table.bicyclesNotDelivered.head")}
                                className="col-lg-12"
                                abilityid={3}
                                searching={false}
                                auth={0}
                                length="5"
                                ajaxUrl="/api/Admin/Station/Vehicle"
                                tableName="notDelivered-table"
                                filter={this.notDeliveredFilter}
                                columns={this.notDeliveredColumn} />
                            <TableData
                                setTable={(e) => {
                                    var table = this.state.tables
                                    table.push(e);
                                    this.setState({ tables: table })
                                }}
                                customTotal="true"
                                cardTitle={t("table.mostRentedBicycles.head")}
                                abilityid={4}
                                auth={0}
                                searching={false}
                                length="5"

                                customOrder={[1, "desc"]}
                                ajaxUrl="/api/Admin/Reports/VehiclesUsedDurationOnTrips"
                                tableName="mostRentedBicycles-table"
                                filter={this.filterMostRentedFilter}
                                columns={this.mostRentedBicyclesColumn}
                            />


                            <TableData cardTitle={t("VehicleList", { ns: 'systemBicycles' })}
                                setTable={(e) => {
                                    var table = this.state.tables
                                    table.push(e);
                                    this.setState({ tables: table })
                                }}
                                abilityid={18}
                                auth={0}
                                customOrder={[5, "desc"]}
                                length="5"
                                ajaxUrl="/api/Admin/Station/Vehicle"
                                tableName="vehicle-table"
                                columns={this.bikeListColumn}
                            />

                            <TableData
                                cardTitle={t("table.forcefullyTakenBicycles.head")}
                                setTable={(e) => {
                                    var table = this.state.tables
                                    table.push(e);
                                    this.setState({ tables: table })
                                }}
                                abilityid={6}
                                auth={0}
                                length="5"
                                customOrder={[2, "desc"]}
                                ajaxUrl="/api/Admin/Station/Vehicle"
                                tableName="forceFully-table"
                                filter={this.forceFullyFilter}
                                columns={this.forceFullyColumn} />




                            <TableData
                                cardTitle={t("table.parkingOccupancyRate.head")}
                                abilityid={7}
                                auth={0}
                                length="5"
                                ordering={false}
                                ajaxUrl="/api/Admin/Station/v2"
                                tableName="parkingoccupancy-table"
                                columns={this.parkingoccupancyColumn} />

                            {/* <TableData
                                setTable={(e) => {
                                    var table = this.state.tables
                                    table.push(e);
                                    this.setState({ tables: table })
                                }}
                                searching={false}
                                cardTitle={t("Alınma talebi gelip alınamamış Bisiklet listesi")}
                                abilityid={10}
                                auth={0}
                                customOrder={[3, "desc"]}
                                length="5"
                                ajaxUrl="/api/Admin/Member/Trips"
                                tableName="userNotUsageVehicle"
                                filter={this.userNotUsageVehicleFilter}
                                columns={this.userNotUsageVehicleColumn} /> */}

                            {/* <TableData
                                cardTitle={t("table.user.head")}
                                abilityid={9}
                                length="5"
                                auth={0}
                                ajaxUrl="/api/Admin/Member"
                                tableName="user-table"
                                filter={this.usersTableFilter}
                                columns={this.usersTableColumn} /> */}
                        </div>


                    </div>
                    <div className="tab-pane fade" id="tab-2">

                        <TableDataV2
                            cardTitle={t("table.stationBasedParkStatus.title")}
                            abilityid={7}
                            auth={0}
                            length="1"
                            ordering={false}
                            customClass="col-6"
                            ajaxUrl="/api/Admin/Station/v2"
                            tableName="parkingoccupancy-table2"
                            columns={this.parkingoccupancy2ColumnV2} />
                    </div>

                </div>
            </div>
        )
    }
}

export default withTranslation(['Homepage', 'translations', 'systemBicycles'])(Main);