import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { getEndPoint, getUploadsImgUri } from '../../class/api';
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import axios from 'axios';
import formData from 'form-data'
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import SelectCordinate from '../../components/region-map';
import Functions from '../../class/functions';
import DatePicker from '../../components/elements/DatePicker';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
import { ReactSession } from 'react-client-session';
import i18n from '../../i18n/i18n';
import moment from 'moment';

class NotificationPostEdit extends Component {

    constructor(props) {
        super(props);

        this.parksTableRef = React.createRef();
        this.parkFaultsHistoryTableRef = React.createRef();
        this.parkFaultsTableRef = React.createRef();
        this.firstDate = React.createRef(null);
        this.lastDate = React.createRef(null);

        // this.LastConnectRef = React.createRef();
        // this.LastSuccessfulBootRef = React.createRef();
        this.state = {
            fileState: [],
            postData: ""
        };
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1);

        var data = JSON.stringify({
            "Filter": {
                "PostId": id,
            }
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Posts',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    postData: response.data.data[0]

                })
                $('#Title').val(this.state.postData.Title)
                $("#Description").summernote("code", this.state.postData.Description);
                $('.statusSelect').val(String(this.state.postData.Status)).trigger('change');
                if ($('.statusSelect').val() != 0) {
                    $('.dates').show()
                    $(this.firstDate.current).val(moment(this.state.postData.StartDate, 'YYY-MM-DD').format("DD/MM/YYYY"))
                    $(this.lastDate.current).val(moment(this.state.postData.EndDate, 'YYY-MM-DD').format("DD/MM/YYYY"))
                }
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });


    }


    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        //console.log('didmount', this.state.postData);
        if (this.state.postData != undefined) {

        }

        $('.summernote-height').summernote({
            height: 200,
            lang: i18n.language == "en" ? "en-US" : "customLang"
        });
        DateRangePickerHandler(this.firstDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.lastDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



    }
    componentDidUpdate = () => this.componentDidMount();

    filesUpload = (e) => {
        //console.log(e.target.files);
        this.setState({
            fileState: e.target.files
        })

        // var PostMediaType = String(this.state.fileState[0]).split("/")[0]
        // console.log(e.target.files[0].type.split("/")[0]);
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const { t } = this.props;
        // const formData = new FormData(e.target);
        var data = new formData();
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1);
        if ($('#Title').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Duyuru Adı" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        data.append('PostId', id);
        data.append('PostTypeId', ' 2');
        data.append('Title', $('#Title').val());
        data.append('Description', $("#Description").summernote("code"));
        data.append('Status', $('.statusSelect').val());
        if ($('.statusSelect').val() != 0) {

            data.append('StartDate', Functions.getFormatDBDateHours($(this.firstDate.current).val() + ' ' + firstDateHours));
            data.append('EndDate', Functions.getFormatDBDateHours($(this.lastDate.current).val() + ' ' + lastDateHours));
        }

        // const formDataEdit = stringifyFormData(formData);
        for (let i = 0; i < this.state.fileState.length; i++) {

            var PostMediaType = ""
            if (String(this.state.fileState[i].type).split("/")[0] == "image") {
                PostMediaType = "1"
            }
            if (String(this.state.fileState[i].type).split("/")[0] == "video") {
                PostMediaType = "2"

            }
            if (PostMediaType == "") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("IMAGE") }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                return
            }
            data.append(`Medias[${i}][Media]`, this.state.fileState[i]);
            data.append(`Medias[${i}][Title]`, 'Test Zorunlu Değil 2');
            data.append(`Medias[${i}][Order]`, '1');
            data.append(`Medias[${i}][PostMediaType]`, PostMediaType);
        }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {

            if (result.isConfirmed) {


                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Posts',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',

                    },

                    data: data
                };
                axios(config)
                    .then(function (response) {
                        swal.fire({
                            title: t("swal.success.head", { ns: "translations" }),
                            text: t("swal.success.subject", { ns: "translations" }),
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.success.icon", { ns: "translations" })
                        })

                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });


            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }

        })
    }
    imageRemoveClick = (ImageID) => {
        const { t } = this.props
        var data = JSON.stringify({
            "PostMediaId": ImageID
        });

        var config = {
            method: 'delete',
            url: getEndPoint() + '/api/Admin/Posts/Media',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                document.getElementById(`imageRemove${ImageID}`).style.display = "none"
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props;
        return (
            <div className="p-2 ">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <form onSubmit={(e) => this.handleSubmit(e)}>

                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("name")}</Text>
                                    <Division className="col-lg-12">
                                        <Division className="form-group form-group-feedback ">
                                            <input type="text" name="Title" id='Title' className="form-control form-control-lg" />

                                        </Division>
                                    </Division>
                                </Division>


                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("timeSettingStatus")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback ">
                                            <select name="Status" type="text"
                                                onChange={(e) => e.target.value == 1 ? $('.dates').show() : $('.dates').hide()}
                                                className="form-control statusSelect form-control-lg" >
                                                <option value={"0"}>{t("passive", { ns: "translations" })}</option>
                                                <option value={"1"}>{t("active", { ns: "translations" })}</option>
                                            </select>

                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("file")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback ">
                                            <input type="file" onChange={(e) => this.filesUpload(e)} name="file" multiple className="form-control form-control-lg" />
                                        </Division>
                                    </Division>
                                </Division>

                                <Division className="form-group row dates" style={{ display: "none" }}>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("firstDate")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback ">
                                            <input ref={this.firstDate} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />


                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("lastDate")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback ">
                                            <input ref={this.lastDate} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />
                                        </Division>
                                    </Division>
                                </Division>
                                <Text Tag="label" className="col-form-label col-lg-2">{t("description")}</Text>
                                <Division className="col-lg-12">
                                    <Division className="form-group form-group-feedback ">
                                        <textarea name='Description' id="Description" className="form-control form-control-lg summernote-height" />

                                    </Division>
                                </Division>
                                <Division className="form-group row">
                                    <Division className="col-lg-12">
                                        <button onClick={this.handleClick} type="submit" className="btn btn-secondary w-100 h-100">{t("Save", { ns: "translations" })}</button>
                                    </Division>
                                </Division>

                            </form>

                            <div className='row'>

                                {
                                    this.state.postData && this.state.postData.medias.map((elm, index) => {
                                        return (
                                            <div key={index} id={`imageRemove${elm.PostMediaId}`} className="card col-sm-2 col-lg-6 col-md-6 col">
                                                <div className="card-header bg-transparent header-elements-inline">
                                                    <h6 className="card-title"></h6>
                                                    <div className="header-elements">
                                                        <div className="list-icons">
                                                            <a onClick={(e) => this.imageRemoveClick(elm.PostMediaId)} data-action="remove"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body ">
                                                    <img className="card-img-top  "
                                                        data-src="holder.js/100px180/?text=Image cap"
                                                        alt="Image cap [100%x180]"
                                                        src={`${getUploadsImgUri()}${elm.Media}`}
                                                        data-holder-rendered="true" style={{ display: "block", }} />
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default withTranslation(['notificationPost', 'translations'])(NotificationPostEdit);

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}