import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import jq from "jquery"
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
class SupportList extends React.Component {

    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.firstAndLastTimeRef = React.createRef(null);
        this.firstDate = React.createRef(null);
        this.lastDate = React.createRef(null);
        this.sonKullanmaTarih3Ref = React.createRef(null);
        this.sonKullanmaTarih4Ref = React.createRef(null);
    }


    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        var phoneInputID = "#user-phone";
        var input = document.querySelector("#user-phone");
        var iti = intlTelInput(input,
            {

                autoPlaceholder: "polite",
                geoIpLookup: (success, failure) => {
                    //success(countryCode)
                },
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,


            }
        )
        $(phoneInputID).on("countrychange", function (event) {

            var selectedCountryData = iti.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");

            $(this).mask(mask);
        });



        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });
        DateRangePickerHandler(this.firstDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.lastDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        if (this.listTable != undefined)
            this.listTable.destroy();

        this.listTable = jq('#list-table').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            ordering: false,
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Support',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#list-table`).removeClass('table-loader').show()
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    dateRange: [
                        Functions.getFormatDBDateHours(jq(this.firstDate.current).val() + ' ' + firstDateHours),
                        Functions.getFormatDBDateHours(jq(this.lastDate.current).val() + ' ' + lastDateHours)
                    ],
                    Status: parseInt(jq(".filterStatus").val())

                };
                if (jq("#user-phone").val() != "") {
                    aoData["Filter"]["Phone"] = iti.getNumber()
                }
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "SupportId", "orderable": true },
                { data: "created_at", "orderable": true },
                { data: "Subject", "orderable": true },
                {
                    data: "Status", render: function (data) {
                        return data == true ? t("completed", { ns: "translations" }) : t("continues", { ns: "translations" })
                    }, "orderable": true
                },
                {
                    data: "user", "orderable": false, render: function (data, type, row) {
                        return `<a href="${Functions.RoleControl(11, 4) == true ? `/users/edit/${row.user.id}` : "#"}" >  ${Functions.MaskCharacter(`${row.user.Name} ${row.user.Surname}`, '*', 2)}  </a>`
                    }
                },
                Functions.RoleControl(40, 2) == true ?
                    {
                        className: "operation",
                        data: "SupportId", "orderable": false, render: function (data, type, row) {
                            return `<a class="btn btn-warning" id="operationBTN" data-toggle="modal" data-target="#actionAddModal">İşlem Ekle</a>`
                        }
                    } : ""

            ],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
        });
        new jq.fn.dataTable.Responsive(this.listTable, { details: true, Responsive: true },);
        function format(d) {

            var returnListData =
                `<table class="table" id="actionTable">
                            <thead>
                                <tr>
                                    <th>${t("ID")}:</th>
                                    <th>${t("actionEmployee")}:</th>
                                    <th>${t("action-name")}</th>
                                </tr>
                            </thead>
                        <tbody>`
            d.actions.forEach((element, index) => {

                returnListData +=
                    `<tr>
                <td>${element.SupportActionId}</td>
                <td>${d.user_id_last_action.Name} ${d.user_id_last_action.Surname}</td>
                <td>${element.Action}</td>

                </tr>`

            });

            returnListData +=
                `</tbody>
        </table>`
            return returnListData

        }

        jq('#list-table tbody').on('click', 'td.operation #operationBTN', function () {
            var tr = jq(this).closest('tr');
            var row = _this.listTable.row(tr);
            jq('#actionTable').remove();
            jq('#actionTableArea').append(format(row.data()));
            jq('#ActionID').val(row.data().SupportId);

        });
    }

    componentDidUpdate = () => this.componentDidMount();

    handleClickSaveAction = () => {
        const _this = this;
        const { t } = this.props
        if (jq('#Action').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("action-name") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })

            return;
        }
        var data = JSON.stringify({
            "SupportId": jq('#ActionID').val(),
            "Action": jq('#Action').val(),
            "Status": jq('#Status').val(),
            "Priority": parseInt(jq('#prioritySelect').val()),
            "Notification": parseInt(jq('#Notification').val())
        });
        console.log(data);
        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Support/AddAction',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.listTable.ajax.reload()
                jq('#Action').val(" ")
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    render() {
        const { t } = this.props;

        return (
            <div className="repair-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("startingDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.firstDate} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("status")}</label>
                                    <div className="col-lg-8">
                                        <select className="form-control filterStatus">
                                            <option value="0">{t("continues", { ns: "translations" })}</option>
                                            <option value="1">{t("completed", { ns: "translations" })}</option>


                                        </select>
                                    </div>
                                </div>




                            </div>
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("endDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.lastDate} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("phoneNumber")}</label>
                                    <div className="col-lg-8">
                                        <input type="text" id="user-phone" placeholder={t("phoneNumber")} className="form-control" />
                                    </div>
                                </div>



                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => jq.fn.dataTable.tables({ api: true }).ajax.reload()} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="list-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.date")}</th>
                                            <th>{t("table.subject")}</th>
                                            <th>{t("table.Status")}</th>
                                            <th>{t("table.customer")}</th>
                                            <th>{t("table.operation")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="modal fade" id="actionAddModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("add-fault")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("action-name")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input disabled readOnly id="ActionID" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("action-name")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <textarea id="Action" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("Status")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="Status" type="text" className="form-control  w-100 ">
                                                <option value="1">{t("completed", { ns: "translations" })}</option>
                                                <option value="0">{t("continues", { ns: "translations" })}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("prioritySelect")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="prioritySelect" type="text" className="form-control selects w-100 "  >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("NotificationSend")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="Notification" type="text" className="form-control  w-100 ">
                                                <option value="1">Mail Gönder</option>
                                                <option value="0">Mail Gönderme</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div id="actionTableArea">

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                                <button type="button" onClick={this.handleClickSaveAction} data-dismiss="modal" className="btn btn-primary">Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>

                <script src="js/plugins/ui/moment/moment.min.js"></script>
                <script src="js/plugins/pickers/daterangepicker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.date.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.time.js"></script>
                <script src="js/plugins/pickers/pickadate/legacy.js"></script>
                <script src="js/plugins/notifications/jgrowl.min.js"></script>
            </div >
        )
    }
}

export default withTranslation(['supportList', 'translations'])(SupportList);
