import React, { Component } from 'react';

import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Button from '../../components/elements/button';
import { withTranslation } from 'react-i18next';
import Anchor from '../../components/elements/anchor';
import swalInit from '../../js/plugins/notifications/sweet_alert.min.js'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';
import LoopIcon from '@mui/icons-material/Loop';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import axios from 'axios';
import { Select } from '../../components/Select';
import moment from 'moment';

class GpsPair extends Component {
    constructor(props) {
        super(props);

        this.bicycleTableRef = React.createRef();
        this.dateRangeRef = React.createRef();
        this.bikeGpsSelect = React.createRef();
        this.gpsHandleClick = this.gpsHandleClick.bind(this);
        this.gpsHandleClickBike = this.gpsHandleClickBike.bind(this);

        
        this.gpsRef = React.createRef();
        this.bikeRef = React.createRef(); 

        this.tables = [];
    }

    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }
    gpsHandleClick() {
        const _this = this;
        const { t } = this.props
        if (this.gpsRef.current.value == '') {
            swalInit.fire('HATA!', 'Lütfen Bir GPS Seçiniz.', 'error');

        }
        else {
            swalInit.fire('HATA!', 'Donanımınız bu özelliği desteklemiyor.', 'error');
        }
    }
    gpsHandleClickBike() {
        const _this = this;
        const { t } = this.props
        if (this.bikeRef.current.value == '') {
            swalInit.fire('HATA!', 'Lütfen Bisiklet Numarası Yazın', 'error');

        }
        else {
            


        var data = JSON.stringify({
            "VehicleGPSId": parseInt($('#gpsSelect').val()),
            "VehicleNo": this.bikeRef.current.value
        });
        // console.log ( data ); 
        // swalInit.fire('HATA!', 'Donanımınız bu özelliği desteklemiyor.', 'error');
        // return false ; 
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/OtherModules/GPS/Local',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
               // _this.parkList.ajax.reload()

            })
            
        }
    }


    
    componentDidMount() {
        const { t } = this.props;

        $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
            var $this = $(this);
            if ($this.data('bs.inputmask')) return;
            var inputMask = new InputMask($this, $this.data());
        });


        this.processDataTable(this.bicycleTableRef, "this.bicycleTable");

        DateRangePickerHandler(this.dateRangeRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });


    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
            <Division className="card">
                <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                    <h6 className="card-title">{t("IMEI ile GPS eşleştirme")}</h6>
                    <Division className="header-elements"></Division>
                </Division>
                <Division className="card-body py-2">
                    <Division className="row">
                        <Division className="col-md-7 mt-2">
                            <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                <Select
                                    defaultValues="1"
                                    selectID="gpsSelect"
                                    customCss={"gpsSelect"}
                                    resultID={["VehicleGPSId"]}
                                    result={["VehicleGPSId", "VehicleNo", "IMEI" ]}
                                    ajaxURL="/api/Admin/OtherModules/GPS/Local" 
                                />
                            </Division>
                        </Division>

                        <Division className="col-md-5 mt-2">
                            <input ref={this.bikeRef} placeholder='Bisiklet No Giriniz' name="bisikletNo" type="text" className="form-control" />
                        </Division>
                        <Division className="col-md-12 text-right pt-4">
                            <button onClick={this.gpsHandleClickBike} className="btn btn-primary col-12">
                                <LoopIcon className="mr-2" />
                                {t("Değiştir")}
                            </button>
                            {/* <Anchor href="/gps/list">
                                <Button className="btn btn-secondary ml-2">
                                    <AdUnitsIcon className="mr-2" />
                                    {t("fetchAll")}
                                </Button>
                            </Anchor> */}
                        </Division>
                    </Division>

                </Division>
            </Division>


                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("head")}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-md-7 mt-2">
                                <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                    <Select
                                        defaultValues="1"
                                        selectID="bikeSelect"
                                        customCss={"bikeSelect"}
                                        resultID={["VehicleId"]}
                                        result={["VehicleId", "VehicleNo", "Name", "RFID", "VehicleType", "StatusIdTxt"]}
                                        ajaxURL="/api/Admin/Station/Vehicle"
                                    />
                                </Division>
                            </Division>

                            <Division className="col-md-5 mt-2">
                                <input ref={this.gpsRef} placeholder='GPS Giriniz' name="tagName" type="text" className="form-control" />
                            </Division>
                            <Division className="col-md-12 text-right pt-4">
                                <button onClick={this.gpsHandleClick} className="btn btn-primary col-12" disabled="disabled">
                                    <LoopIcon className="mr-2" />
                                    {t("Değiştir")}
                                </button>
                                {/* <Anchor href="/gps/list">
                                    <Button className="btn btn-secondary ml-2">
                                        <AdUnitsIcon className="mr-2" />
                                        {t("fetchAll")}
                                    </Button>
                                </Anchor> */}
                            </Division>
                        </Division>

                    </Division>
                </Division>


            </div>
        );
    }
}

export default withTranslation(['gpsHistory', 'translations'])(GpsPair);