import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import { ReactSession } from 'react-client-session';
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from "universal-cookie";
const cookies = new Cookies();
import jq from 'jquery'
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
var arr = [];

let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";

export const data = [
    [
        {
            type: "date",
            id: "Date",
        },
        {
            type: "number",
            id: "Won/Loss",
        },
    ],

];
class ExpeditionsBetweenStations extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
        this.entryStation = React.createRef(null);
        this.exitStation = React.createRef(null);
        this.state = {
            chartData: ""
        }
    }
    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    componentDidMount() {
        const { t } = this.props;

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        if (this.stationsTable != null) {
            jq('#expeditions-table').empty();
            this.stationsTable.destroy();

        }

        this.stationsTable = jq('#expeditions-table').DataTable({
            "lengthChange": false,
            dom: 'Blfrtip',
            paging: false,
            "searching": false,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            order: [0, 'desc'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Reports/ExpeditionsBetweenStations',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },

                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.data.length
                    json.recordsTotal = json.data.length
                    $(`#expeditions-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    EntryDate: moment($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
                    ExitDate: moment($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
                    // this.sonKullanmaTarih2Ref.current
                };
                jq(this.entryStation.current).val() != "0" ? aoData['Filter']["stationentryId"] = jq(this.entryStation.current).val() : aoData['Filter']["_stationentryId"] = jq(this.entryStation.current).val()
                jq(this.exitStation.current).val() != "0" ? aoData['Filter']["stationexitId"] = jq(this.exitStation.current).val() : aoData['Filter']["_stationexitId"] = jq(this.exitStation.current).val()

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },


            "columns": [
                { data: "stationexit" },
                { data: "stationentry" },
                { data: "seferSAyisi" },
            ],
        });
        new jq.fn.dataTable.Responsive(this.stationsTable, { details: true, Responsive: true },);
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $('.selectStation').select2({
            language: selectLanguage
        });
        $('.selectStation').empty().trigger("change");

        var stationSelect = $('.selectStation');
        var option = new Option(
            t("all", { ns: "translations" }),
            "0",
            true,
            true);
        stationSelect.append(option).trigger('change');

        var stationList = ReactSession.get('stationList')
        stationList.forEach(elm => {
            var newOption = new Option(
                elm.Name + ' (' + elm.Number + ') ',
                elm.StationId,
                false, false);
            $(stationSelect).append(newOption).trigger('change');
        });
    }

    componentDidUpdate = () => this.componentDidMount();

    handleClickFilter = () => {
        const _this = this;
        const { t } = this.props;

        var data = {

            "Filter": {
                "EntryDate": Functions.getFormatDBDateHours($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours),
                "ExitDate": Functions.getFormatDBDateHours($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours),

            }
        };
        jq(this.entryStation.current).val() != "0" ? data.Filter.stationentryId = jq(this.entryStation.current).val() : data.Filter._stationentryId = jq(this.entryStation.current).val()
        jq(this.exitStation.current).val() != "0" ? data.Filter.stationexitId = jq(this.exitStation.current).val() : data.Filter._stationexitId = jq(this.exitStation.current).val()
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Reports/ExpeditionsBetweenStations',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                var resData = response.data.data
                if (!resData.length) {
                    swal.fire({
                        title: t("swal.error.head", { ns: "translations" }),
                        text: t("swal.error.filterSubject", { ns: "translations" }),
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: t("swal.error.icon", { ns: "translations" })
                    })
                    return
                }
                let drawLineChart = () => {
                    let line_chart_element = document.getElementById('google-line');

                    var datas = [];
                    var Header = [t("chart.chartData"), t("chart.chartContent")];
                    datas.push(Header);
                    resData.forEach(element => {
                        var temp = [];
                        temp.push(element.stationentry + element.stationexit);
                        temp.push(element.seferSAyisi);

                        datas.push(temp);
                    });
                    // for (var i = 0; i < resData.length; i++) {
                    //     var temp = [];
                    //     temp.push(resData[i].stationentry + resData[i].stationexit);
                    //     temp.push(resData[i].seferSAyisi);

                    //     datas.push(temp);
                    // }
                    var data = new google.visualization.arrayToDataTable(datas);
                    let options = {
                        fontName: 'Roboto',
                        height: 400,
                        fontSize: 15,
                        chartArea: {
                            left: '5%',
                            width: '94%',
                            height: 350
                        },
                        pointSize: 7,
                        curveType: 'function',
                        backgroundColor: 'transparent',
                        tooltip: {
                            textStyle: {
                                fontName: 'Roboto',
                                fontSize: 13
                            }
                        },
                        vAxis: {
                            titleTextStyle: {
                                fontSize: 13,
                                italic: false,
                                color: '#333'
                            },
                            textStyle: {
                                color: '#333'
                            },
                            baselineColor: '#ccc',
                            gridlines: {
                                color: '#eee',
                                count: 10
                            },
                            minValue: 0
                        },
                        hAxis: {
                            textStyle: {
                                color: '#333'
                            }
                        },
                        legend: {
                            position: 'top',
                            alignment: 'center',
                            textStyle: {
                                color: '#333'
                            }
                        },
                        series: {
                            0: { color: '#EF5350' },
                            1: { color: '#66BB6A' }
                        }
                    };

                    const themeType = ReactSession.get("themeType");
                    if (themeType == "dark-mode") {
                        options = {
                            fontName: 'Roboto',
                            height: 650,
                            fontSize: 12,
                            chartArea: {
                                left: '5%',
                                width: '94%',
                                height: 350
                            },
                            pointSize: 7,
                            curveType: 'function',
                            backgroundColor: 'transparent',
                            tooltip: {
                                textStyle: {
                                    fontName: 'Roboto',
                                    fontSize: 13
                                }
                            },
                            vAxis: {
                                titleTextStyle: {
                                    fontSize: 13,
                                    italic: false,
                                    color: '#fff'
                                },
                                textStyle: {
                                    color: '#fff'
                                },
                                baselineColor: '#565b63',
                                gridlines: {
                                    color: '#474b50',
                                    count: 10
                                },
                                minorGridlines: {
                                    color: '#3b3f44'
                                },
                                minValue: 0
                            },
                            hAxis: {
                                textStyle: {
                                    color: '#fff'
                                }
                            },
                            legend: {
                                position: 'top',
                                alignment: 'center',
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                }
                            },
                            series: {
                                0: { color: '#EF5350' },
                                1: { color: '#66BB6A' }
                            }
                        };
                    }

                    //options.vAxis.title = "blablaba";

                    // Draw chart
                    let line_chart = new google.visualization.ColumnChart(line_chart_element);
                    line_chart.draw(data, options);
                    this.stationsTable.ajax.reload()

                }

                google.charts.load('current', {
                    callback: function () {
                        drawLineChart();

                        var sidebarToggle = document.querySelectorAll('.sidebar-control');
                        if (sidebarToggle) {
                            sidebarToggle.forEach(function (togglers) {
                                togglers.addEventListener('click', drawLineChart);
                            });
                        }

                        var resizeLineBasic;
                        window.addEventListener('resize', function () {
                            clearTimeout(resizeLineBasic);
                            resizeLineBasic = setTimeout(function () {
                                drawLineChart();
                            }, 200);
                        });
                    },
                    packages: ['corechart']
                });

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });




    }
    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("exitStation")}</label>
                                    <div className="col-lg-8">

                                        <select ref={this.exitStation} className="form-control selectStation">
                                            <option value="0">  {t("loading", { ns: "translations" })} </option>
                                        </select>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("entranceStation")} </label>
                                    <div className="col-lg-8">
                                        <select ref={this.entryStation} className="form-control selectStation">
                                            <option value="0">  {t("loading", { ns: "translations" })} </option>

                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={this.handleClickFilter} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("tableHead")}</h6>
                        <a onClick={() => {
                            this.componentDidMount()
                        }} className="list-icons-item" data-action="reload"></a>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="expeditions-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th><h5>{t("exitStation")}</h5></th>
                                            <th><h5>{t("entranceStation")}</h5></th>
                                            <th><h5>{t("totalTrip")}</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody className="font-weight-bold">
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header">
                        <h5 className="card-title">{t("chart.chartHead")}</h5>
                    </div>

                    <div className="card-body">
                        <div className="chart-container">
                            <div className="chart" id="google-line"></div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['expeditionsBetweenStations', 'translations'])(ExpeditionsBetweenStations);
