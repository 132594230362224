import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import { ReactSession } from 'react-client-session';
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import $ from "jquery"
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
import 'datatables.net-datetime'
const cookies = new Cookies();

let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
let statusLoadPage = false;
class MonthlyGiro extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);
        this.state = {
            tableDataSet: []
        }
        this.ilkTarih = React.createRef(null);
        this.sonTarih = React.createRef(null);
    }
    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    componentDidMount() {
        const { t } = this.props;
        $('.chartDivision').hide();

        DateRangePickerHandler(this.ilkTarih.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonTarih.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        if (this.monthly != undefined) {
            $('#monthly-table').empty();
            this.monthly.destroy();

        }

        this.monthly = $('#monthly-table').DataTable({
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            data: this.state.tableDataSet,
            order: [0, 'asc'],
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            dom: 'Blfrtip',
            "columns": [
                {
                    data: "null", render: function (data, type, row) {
                        var dateGroup = $('#acktarihgruplama').val()
                        if (dateGroup == "D") {
                            var date = moment(`${row.Day}/${row.Month}/${row.Year}`, "D/M/YYYY").toDate()
                            return moment(date).format("YYYY/MM/DD")
                        }
                        if (dateGroup == "H") {
                            return `${row.Hour}`
                        }
                        if (dateGroup == "W") {
                            return `${row.Week}`
                        }
                        if (dateGroup == "M") {
                            var date = moment(`${row.Month}/${row.Year}`, "M/YYYY").toDate()
                            return moment(date).format("YYYY/MM")
                        }
                        if (dateGroup == "Y") {
                            return `${row.Year}`
                        }
                    }
                },
                {
                    data: "Total"
                },
                {
                    data: "TotalDuration", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }
                },
                {
                    data: "Fee", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }

                        return parseFloat(data).toFixed(2)

                    }
                },
            ],

        });
        new $.fn.dataTable.Responsive(this.monthly, { details: true, Responsive: true },);
        if (statusLoadPage == false) {
            this.handleClickFilter();
            statusLoadPage = true;
        }
    }

    componentDidUpdate = () => this.componentDidMount();
    handleClickFilter = () => {
        function orderName() {

            var dataName = "";
            var dateGroup = $('#acktarihgruplama').val()
            if (dateGroup == "D") {
                dataName = `Dayasdasd`
            }
            if (dateGroup == "H") {
                dataName = "Hour"
            }
            if (dateGroup == "W") {
                dataName = "Week"
            }
            if (dateGroup == "M") {
                dataName = "Month"
            }
            if (dateGroup == "Y") {
                dataName = "Year"
            }
            return dataName
        }
        const { t } = this.props

        var data = {
            "Filter": {
                "Type": $('#acktarihgruplama').val(),
                "EntryDate": moment($(this.ilkTarih.current).val() + ' ' + firstDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
                "ExitDate": moment($(this.sonTarih.current).val() + ' ' + lastDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
                "order": orderName(),
                "sort": "ASC",
            }
        };

        var config = {
            url: getEndPoint() + '/api/Admin/Reports/TurnoverBetween',
            method: 'PUT',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data,
            withCredentials: true
        };

        axios(config)
            .then((response) => {
                $(`#monthly-table`).removeClass('table-loader').show()
                this.setState({
                    tableDataSet: response.data.data

                })
                if (response.data.data.length == 0) {
                    $('.chartDivision').hide();
                }
                else {
                    $('.chartDivision').show();
                }
                let drawLineChart = () => {
                    let line_chart_element = document.getElementById('google-line');

                    var datas = [];
                    var Header = [t("chart.chartData"), t("table.totalAmount")];
                    datas.push(Header);
                    this.state.tableDataSet.forEach(element => {
                        var temp = [];
                        var dateGroup = $('#acktarihgruplama').val()
                        if (dateGroup == "D") {
                            temp.push(`${element.Year}/${element.Month}/${element.Day} `);
                        }
                        if (dateGroup == "H") {
                            temp.push(element.Hour)
                        }
                        if (dateGroup == "W") {
                            temp.push(element.Week)
                        }
                        if (dateGroup == "M") {

                            temp.push(`${element.Year}/${element.Month}`)
                        }
                        if (dateGroup == "Y") {
                            temp.push(element.Year)
                        }
                        temp.push(parseFloat(element.Fee));

                        datas.push(temp);
                    });
                    var data = new google.visualization.arrayToDataTable(datas);
                    let options = {
                        fontName: 'Roboto',
                        height: 400,
                        fontSize: 15,
                        chartArea: {
                            left: '5%',
                            width: '94%',
                            height: 350
                        },
                        pointSize: 7,
                        curveType: 'function',
                        backgroundColor: 'transparent',
                        tooltip: {
                            textStyle: {
                                fontName: 'Roboto',
                                fontSize: 13
                            }
                        },
                        vAxis: {
                            titleTextStyle: {
                                fontSize: 13,
                                italic: false,
                                color: '#333'
                            },
                            textStyle: {
                                color: '#333'
                            },
                            baselineColor: '#ccc',
                            gridlines: {
                                color: '#eee',
                                count: 10
                            },
                            minValue: 0
                        },
                        hAxis: {
                            textStyle: {
                                color: '#333'
                            }
                        },
                        legend: {
                            position: 'top',
                            alignment: 'center',
                            textStyle: {
                                color: '#333'
                            }
                        },
                        series: {
                            0: { color: '#EF5350' },
                            1: { color: '#66BB6A' }
                        }
                    };

                    const themeType = ReactSession.get("themeType");
                    if (themeType == "dark-mode") {
                        options = {
                            fontName: 'Roboto',
                            height: 650,
                            fontSize: 12,
                            chartArea: {
                                left: '5%',
                                width: '94%',
                                height: 350
                            },
                            pointSize: 7,
                            curveType: 'function',
                            backgroundColor: 'transparent',
                            tooltip: {
                                textStyle: {
                                    fontName: 'Roboto',
                                    fontSize: 13
                                }
                            },
                            vAxis: {
                                titleTextStyle: {
                                    fontSize: 13,
                                    italic: false,
                                    color: '#fff'
                                },
                                textStyle: {
                                    color: '#fff'
                                },
                                baselineColor: '#565b63',
                                gridlines: {
                                    color: '#474b50',
                                    count: 10
                                },
                                minorGridlines: {
                                    color: '#3b3f44'
                                },
                                minValue: 0
                            },
                            hAxis: {
                                textStyle: {
                                    color: '#fff'
                                }
                            },
                            legend: {
                                position: 'top',
                                alignment: 'center',
                                textStyle: {
                                    fontSize: 12,
                                    color: '#fff'
                                }
                            },
                            series: {
                                0: { color: '#EF5350' },
                                1: { color: '#66BB6A' }
                            }
                        };
                    }
                    let line_chart = new google.visualization.LineChart(line_chart_element);
                    line_chart.draw(data, options);
                }

                google.charts.load('current', {
                    callback: function () {
                        drawLineChart();

                        var sidebarToggle = document.querySelectorAll('.sidebar-control');
                        if (sidebarToggle) {
                            sidebarToggle.forEach(function (togglers) {
                                togglers.addEventListener('click', drawLineChart);
                            });
                        }

                        var resizeLineBasic;
                        window.addEventListener('resize', function () {
                            clearTimeout(resizeLineBasic);
                            resizeLineBasic = setTimeout(function () {
                                drawLineChart();
                            }, 200);
                        });
                    },
                    packages: ['corechart']
                });

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    render() {
        const { t } = this.props;

        return (
            <div className="monthly-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>
                    </div>
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("dateGrouping")}</label>
                                    <div className="col-lg-8">
                                        <select id="acktarihgruplama" className="form-control">
                                            <option value="H">Saatlik</option>
                                            <option value="D">Günlük</option>
                                            <option value="W">Haftalık</option>
                                            <option value="M">Aylık</option>
                                            <option value="Y">Yıllık</option>
                                            {/* "D:Günlük,H:Saatlik,W:haftalık,M:ay */}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.ilkTarih} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonTarih} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Division className="col-lg-12 mt-2">
                                <button onClick={this.handleClickFilter} id="filterClickButton" type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")}</h6>
                        <a onClick={() => {
                            this.componentDidMount()
                        }} className="list-icons-item" data-action="reload"></a>


                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="monthly-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.historyPeriod")}</th>
                                            <th>{t("table.numberOfRentals")}</th>
                                            <th>{t("table.totalTime")}</th>
                                            <th>{t("table.totalAmount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card chartDivision">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header">
                        <h5 className="card-title">{t("chart.chartHead")}</h5>
                    </div>

                    <div className="card-body">
                        <div className="chart-container">
                            <div className="chart" id="google-line"></div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['monthlyGiro', 'translations'])(MonthlyGiro);