import React, { Component } from 'react'

class ComingSoon extends Component {
  render() {
    return (
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <div className="flex-fill">
                <div className="text-center mb-4">
                  <img src="../../../../global_assets/images/error_bg.svg" className="img-fluid mb-3" height="230" alt="" />
                  <h1 className="display-2 font-weight-semibold line-height-1 mb-2">Hazırlanıyor...</h1>
                  <h6 className="w-md-25 mx-md-auto">Bu Sayfa Hazırlanma Aşamasında</h6>
                </div>
                <div className="text-center">
                  <a href="/" className="btn btn-primary"><i className="icon-home4 mr-2"></i> Ana Sayfaya Dön</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ComingSoon;