import React from "react";
import CustomElement from "./custom-element";

//<button></button>
export default function Button(props) {
    return (
        <CustomElement { ...{Tag:"button", ...props} }>
            {props.children}
        </CustomElement>
    );
}