import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import axios, { Axios } from "axios";
import Text from "../../components/elements/text";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import jq from 'jquery'
import AbcIcon from '@mui/icons-material/Abc';
import { getEndPoint } from "../../class/api";
import { ReactSession } from "react-client-session";
const cookies = new Cookies();
let pageCount = 0;
let userList = []
let userData = []
let dateRangeStatus = 0;
var statuesData = {
    MaintenanceStatusId: 0,
    ParentId: 0,
}
class FaultDefinitions extends React.Component {
    constructor(props) {
        super(props);
    }
    tableInstall = () => {
        const { t } = this.props;
        let _this = this;
        var collapsedGroups = {};

        var groupColumn = 2;
        if (this.faultTable != undefined) {
            this.faultTable.destroy();
        }


        this.faultTable = $('#faults').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Maintenance/Statuses',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;

                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#faults`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    ParentId: 0
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [

                { data: "MaintenanceStatusId" },
                {
                    data: "Name", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }, "orderable": false
                },
                {
                    data: "Description", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return (data);
                    }, "orderable": false
                },
                {

                    orderable: false,
                    data: "ParentId", render: function () {
                        return `<a id="dt-control" class="btn btn-info">${t('subStatusList')} </a>`
                    },
                    defaultContent: '',
                },
                {

                    data: "MaintenanceStatusId", render: function (data, type, row) {
                        return `<div class="form-group" >
                        <div class="d-flex justify-content-between align-items-center">
                            ${Functions.RoleControl(29, 2) == true ? ` <a type="button" data-toggle="modal" data-target="#StatusAddModal" id="statusAdd" class="btn btn-success">
                            <i class="fas fa-plus-circle"></i> ${t("addChildStatues")} </a>` : ""}
                            ${Functions.RoleControl(29, 4) == true ? `  <a href="#" id="deleted" class="btn btn-danger "><i class="icon-bin"> </i>${t("removeStatues")}  </a>` : ""}
                        </div>
                    </div >`

                    }, "orderable": false
                },


            ],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
        });
        new jq.fn.dataTable.Responsive(this.faultTable, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;
        let _this = this;


        this.tableInstall()
        function format(d) {
            if (!d.child_list.length) {
                return ""
            }
            var returnListData = `    <table class="table">
            <thead>
                <tr>
                    <th>${t("ID")}:</th>
                    <th>  ${t("statusName")}</th>
                </tr>
            </thead>
            <tbody>`
            d.child_list.forEach((element, index) => {

                returnListData += `
                    <tr>
                        <td>${element.MaintenanceStatusId}</td>
                        <td>${element.Name}</td>
                    </tr>
                `

            });

            returnListData += `
            </tbody>
        </table>
`
            return returnListData

        }
        jq('#faults tbody').on('click', 'td #dt-control', function () {
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
           
            if (row.child.isShown()) {
                row.child.hide();
                tr.removeClass('shown');
            } else {

                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });
        jq('#faults tbody').on('click', 'td #statusAdd', function () {//statues child add butonu
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
            var data = row.data()
            statuesData.ParentId = data.MaintenanceStatusId
            statuesData.MaintenanceStatusId = 0

        });
        jq('#faults tbody').on('click', 'td #deleted', function () {//status delete butonu
            var tr = jq(this).closest('tr');
            var row = _this.faultTable.row(tr);
            swal.fire({
                title: t("swal.info.head", { ns: "translations" }),
                text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.delete", { ns: "translations" }) }),
                showDenyButton: true,
                confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
                denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
            }).then((result) => {
                if (result.isConfirmed) {
                    var config = {
                        method: 'delete',
                        url: getEndPoint() + '/api/Admin/Maintenance/Statuses',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({
                            "MaintenanceStatusId": row.data().MaintenanceStatusId
                        })
                    };

                    axios(config)

                        .then(function (response) {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            _this.faultTable.ajax.reload()

                        })
                        .catch(function (error) {
                            swal.fire({
                                title: t("swal.error.head", { ns: "translations" }),
                                text: t("swal.error.subject", { ns: "translations" }) + error.message,
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.error.icon", { ns: "translations" })
                            })

                        });
                }
            })



        });


    }






    componentDidUpdate = () => this.componentDidMount();
    handleActionClick = () => {
        const _this = this;
        const { t } = this.props
        if (jq('#Order').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("order") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (jq('#Name').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("Ad") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (jq('#Description').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("Açıklama") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        var data = JSON.stringify({
            "Name": jq('#Name').val(),
            "ParentId": statuesData.ParentId,
            "Description": jq('#Description').val(),
            "Order": jq('#Order').val(),
            "Status": (jq('#Status').val() == "true")
        });
        if (statuesData.MaintenanceStatusId != 0) {

            var data = JSON.stringify({
                "MaintenanceStatusId": statuesData.MaintenanceStatusId,
                "Name": jq('#Name').val(),
                "ParentId": statuesData.ParentId,
                "Description": jq('#Description').val(),
                "Order": jq('#Order').val(),
                "Status": (jq('#Status').val() == "true")
            });
        }

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Maintenance/Statuses',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.faultTable.ajax.reload()
            })
            .catch(function (error) {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: t("swal.error.subject", { ns: "translations" }) + error.message,
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
            })
    }
    render() {
        const { t } = this.props;

        return (
            <div className="user-page p-2">
                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">

                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("status-head")}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(29, 3) == true ? <button onClick={() => { statuesData.ParentId = 0 }} data-toggle="modal" data-target="#StatusAddModal" className='btn btn-success'>{t("addStatus")}</button> : ""}
                                <a onClick={() => { this.faultTable.ajax.reload() }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12 pt-2">
                                <table id="faults" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("ID")}</th>
                                            <th>{t("statusName")}</th>
                                            <th>{t("statusDescription")}</th>
                                            <th>{t("childStatusList")}</th>
                                            <th>{t("operations")}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="StatusAddModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("addStatus")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("statusName")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input id="Name" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("statusDescription")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <textarea id="Description" type="text" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("order")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input defaultValue={1} id="Order" type="number" className="form-control  w-100 " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("status")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="Status" className="form-control  w-100 " >
                                                <option value="true">{t("active", { ns: "translations" })}</option>
                                                <option value="false">{t("passive", { ns: "translations" })}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{t("modal.close", { ns: "translations" })}</button>
                                <button type="button" onClick={this.handleActionClick} data-dismiss="modal" className="btn btn-primary">{t("modal.save", { ns: "translations" })}</button>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        )
    }
}

export default withTranslation(['faultsStatus', 'translations'])(FaultDefinitions);
function getFormatDBDate(date) {
    if (date == "") {
        return ""
    }
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}