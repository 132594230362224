import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Functions from '../../functions';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios'
import { getEndPoint } from "../../class/api";
import jq from 'jquery';

import { ReactSession } from 'react-client-session';
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import Cookies from 'universal-cookie';
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
const cookies = new Cookies();

class ParkAdd extends Component {
    constructor(props) {
        super(props);
        this.parksTableRef = React.createRef();
        this.parkFaultsHistoryTableRef = React.createRef();
        this.parkFaultsTableRef = React.createRef();
        // this.LastConnectRef = React.createRef();
        // this.LastSuccessfulBootRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            parkData: [],
            status: true,
            stationId: 0,
        };
    }



    componentDidMount() {
        const { t } = this.props;
        const _this = this;

        if (this.state.status != false) {
            const selectLanguage = {
                noResults: () => t("select2Localization.noResults", { ns: "translations" }),
                searching: () => t("select2Localization.searching", { ns: "translations" }),
                maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
                loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
                inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
                inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
                errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
            };

            $('.select-search').select2({
                language: selectLanguage
            });


            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Station',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                },
                data: JSON.stringify({
                    "per_page": -1, "order": "Order",
                })
            };
            axios(config)
                .then(function (response) {

                    $('#StationSelect').empty().trigger("change");
                    var stationSelect = $('#StationSelect');

                    var option = new Option(
                        t("selectDefaultValue", { ns: "translations" }),
                        "0",
                        (true),
                        (true)
                    );
                    stationSelect.append(option).trigger('change');

                    response.data.data.forEach((elm) => {
                        var option = new Option(
                            elm.Name,
                            elm.StationId,
                            (false),
                            (false)
                        );
                        stationSelect.append(option).trigger('change');
                    });
                })
                .catch(function (error) {
                    Functions.requestAxiosErrorFunction(error);
                });

        }
    }
    componentDidUpdate = () => this.componentDidMount();
    handleSubmit(e) {
        e.preventDefault();
        const _this = this;
        const {t} = this.props;
        
        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
        if (formDataEdit.StationId == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("StationId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.ActiveBikeRfId == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("ActiveBikeRfId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.ParkIpAddress == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("ParkIpAddress") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.ParkId == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("ParkId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.ParkName == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("ParkName") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        swal.fire({
            title: t("swal.info.subjectNoData", { ns: "translations" }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {

            if (result.isConfirmed) {
                var data = JSON.stringify(
                    {
                        "StationId": parseInt($('#StationSelect').val()),
                        "StationParkUnitTypeId": parseInt(formDataEdit.StationParkUnitTypeId),
                        "ActiveBikeRfId": formDataEdit.ActiveBikeRfId,
                        "ParkIpAddress": formDataEdit.ParkIpAddress,
                        "ParkNewIpAddress": formDataEdit.ParkNewIpAddress,
                        "ParkId": formDataEdit.ParkId,
                        "ParkNewId": formDataEdit.ParkNewId,
                        "ParkOrder": formDataEdit.ParkOrder,
                        "ParkName": formDataEdit.ParkName,
                        "ParkStatusEventCode": "PD_1",
                        "ParkFaultEmergencyId": "0",
                        "Chargeable": (formDataEdit.Chargeable),
                        "Charge": (formDataEdit.Charge),
                        "HavingKeypad": (formDataEdit.HavingKeypad),
                        "Status": parseInt(formDataEdit.Status),
                        "ProgramVersion": formDataEdit.ProgramVersion,
                        "ResetCount": 0,
                    }
                );
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Station/ParkUnit',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            _this.componentDidMount()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }

        })
    }
    render() {
        const { t } = this.props;
        return (
            <div className="p-2 ">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <form onSubmit={this.handleSubmit}>

                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("parkName")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" required name="ParkName" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-profile"></i>
                                            </Division>
                                        </Division>
                                    </Division>

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ProgramVersion")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="ProgramVersion" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-seven-segment-9"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>


                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("StationParkUnitTypeId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="StationParkUnitTypeId" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-more"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkOrder")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="number" required name="ParkOrder" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>





                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="ParkId" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="fa-solid fa-square-parking"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkNewId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" name="ParkNewId" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-new"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>




                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("StationId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select id="StationSelect" name="StationId" type="text" className="select-search form-control form-control-lg">
                                                <option value="0"> {t("loading", { ns: "translations" })}</option>
                                            </select>
                                            {/* <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-station"></i>
                                            </Division> */}
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ActiveBikeRfId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" required name="ActiveBikeRfId" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-bike"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>


                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkIpAddress")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="ParkIpAddress" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-seven-segment-9"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkNewIpAddress")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" name="ParkNewIpAddress" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-new"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>





                                <Division className="form-group row">

                                </Division>


                                {/* 
                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkStatusEventCode")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="ParkStatusEventCode" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-circle-code"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ParkFaultEmergencyId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="number" name="ParkFaultEmergencyId" required className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="fa-solid fa-exclamation"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division> */}





                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("HavingKeypad")}</Text>
                                    <Division className="col-lg-4">
                                        <select defaultValue={true} name="HavingKeypad" className=" form-control w-100" >
                                            <option value="-1" disabled>{t("selectKeypad")}</option>
                                            <option value="true">{t("active")}</option>
                                            <option value="false">{t("passive")}</option>
                                        </select>
                                    </Division>


                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Charge")}</Text>
                                    <Division className="col-lg-4">
                                        <select defaultValue={true} name="Charge" className=" form-control w-100" >
                                            <option value="-1" disabled>{t("selectCharge")}</option>
                                            <option value="true">{t("active")}</option>
                                            <option value="false">{t("passive")}</option>
                                        </select>
                                    </Division>
                                </Division>


                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Status")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select defaultValue={true} name="Status" className=" form-control w-100" >
                                                <option value="-1" disabled>{t("selectChargeable")}</option>
                                                <option value="1">{t("active")}</option>
                                                <option value="0">{t("passive")}</option>
                                            </select>
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-question6"></i>
                                            </Division>
                                        </Division>
                                    </Division>


                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Chargeable")}</Text>
                                    <Division className="col-lg-4">
                                        <select defaultValue={true} name="Chargeable" className=" form-control w-100" >
                                            <option value="-1" disabled>{t("selectChargeable")}</option>
                                            <option value="true">{t("active")}</option>
                                            <option value="false">{t("passive")}</option>
                                        </select>
                                    </Division>


                                </Division>


                                <Division className="form-group row">
                                    <Division className="col-lg-3" />
                                    <Division className="col-lg-3">
                                        <button onClick={this.handleClick} type="submit" className="btn btn-secondary w-100 h-100">{t("Save", { ns: "translations" })}</button>
                                    </Division>

                                </Division>


                            </form>

                        </div>
                    </div>
                </div>




            </div>
        )
    }
}

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withTranslation(['stationParkEdit', 'translations'])(withRouter(ParkAdd));
function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}
function getFormattedDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [month, day, year].join('-');
}
function getFormatDBDate(date) {
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}