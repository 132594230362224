import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import TableData from "../../components/table.js";
import TableDataV2 from "../../components/tableV2.js";
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");
import Anchor from "../../components/elements/anchor.js";
import Division from "../../components/elements/content-division";
import Text from "../../components/elements/text";
import Button from "../../components/elements/button";
import Functions from "../../class/functions";
import swal, { Swal } from "../../js/plugins/notifications/sweet_alert.min.js";
import axios from "axios";
import Cookies from 'universal-cookie';
import jq from 'jquery';
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net-buttons/js/buttons.print';
import 'bootstrap/dist/js/bootstrap';
import jsZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
window.JSZip = jsZip;
import { getEndPoint } from "../../class/api";
let stationList = [];
const cookies = new Cookies();

class EnergyTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tables: []
        }
        const { t } = this.props
 
      const onItemClick = value => {
        alert( "onItemClick") ; 
      }
 
        this.energyTrackingColumn = [   
            // {
            //     title: t("İstasyon Numarası"),
            //     data: "Number"
            // },
            // {

            //     title: t("table.passive.stations"),
            //     data: "Order", render: function (data, type, row) {
            //         return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${row.Name} </a>`
            //     }

            // },
            
            { title: t("Module Id"), data: "module_id" },
            { title: t("Module Vers."), data: "model_ver" },
            { title: t("Lokasyon"), data: "location" }, 
            {

                title: t("table.passive.lastConnect"),
                data: "last_change_time",
                render: function (data) {
                    return data;
                    return moment(data).format('YYYY-MM-DD HH:mm:ss');
                }, orderable: false

            },

            {title: t("Giriş 1 "), 
                data: "input_status", render: function (data, type, row) {
                    return `<span id="dt-controlis1" class="btn " style="background:${data[0] ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${data[0] ? row.status_1_name[0] : row.status_0_name[0]} </span>` 
                }, "orderable": false
            }, 



            // {title: t("Çıkış 1 "), 
            //     data: "output_status", render: function (data, type, row) {
            //         return `<a id="dt-control" class="btn " style="background:${data[0] ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${row.output_name[0] } </a>` 
            //     }, "orderable": false
            // }, 
 
            // {title: t("Çıkış 1 "), 
            //     data: "output_status", render: function (data, type, row) {
            //         return `<a id="dt-control" class="btn " style="background:${data[0] ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${data[0] ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${data[0] != row.output_desired_status[0] ? "<br> **işlemde** " : "" }</a>` 
            //     }, "orderable": false
            // }, 

            {title: t("Çıkış 1 "), 
                data: "output_status", render: function (data, type, row) {

                    var moduleKey = 0 ;
                    var modul = data[ moduleKey ] ;

                    var uyariMesaji = row.location + ', Çıkış 1 - ' + (modul ? "Kapatılsın mı ? " : "Açılsın mı ? ");
 
                    var jssend = ` `;
 
                    var test1 = `` 
                     test1 = `<a id="dt-control" class="btn cikisstatus"  data-channel="${moduleKey+1}" data-module-id="${row.module_id}" data-module-new-status="${modul ? 0 : 1}" data-uyari-mesaji="${uyariMesaji}" style="background:${modul ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${modul ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${modul != row.output_desired_status[ moduleKey ] ? "<br> **işlemde** " : "" }</a>` 
                    return test1

                    return `<a id="dt-control" class="btn " style="background:${data[1] ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${data[1] ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${data[1] != row.output_desired_status[1] ? "<br> **işlemde** " : "" }</a>` 
                }, "orderable": false
            }, 
 
            {title: t("Giriş 2 "), 
                data: "input_status", render: function (data, type, row) {
                    return `<span id="dt-controlis2" class="btn " style="background:${data[1] ? row.status_1_color[0] : row.status_0_color[0]} ; color :#fff">${data[1] ? row.status_1_name[0] : row.status_0_name[0]} </span>` 
                }, "orderable": false
            }, 

            // {title: t("Çıkış 2 "), 
            //     data: "output_status", render: function (data, type, row) {
            //         return `<a id="dt-control" class="btn " style="background:${data[1] ? row.status_1_color[0] : row.status_0_color[0]} ; color :#fff">${row.output_name[1] }</a>` 
            //     }, "orderable": false
            // }, 
 
            {title: t("Çıkış 2 "), 
                data: "output_status", render: function (data, type, row) {

                    var moduleKey = 1 ;
                    var modul = data[ moduleKey ] ;

                    var uyariMesaji = row.location + ', Çıkış 2 - ' + (modul ? "Kapatılsın mı ? " : "Açılsın mı ? ");

                    var jssend = `alert(${modul});   
                    var data = {
                        'module_id': '${row.module_id}',
                        'channel': 1,
                        'desired_status': ${(modul ? 0 : 1)}
                    } ;
                    var config = {
                        method: 'POST',
                        url: '${getEndPoint()}/api/Admin/OtherModules/EnergyTracking',
                        headers: {
                            'X-XSRF-TOKEN': ${cookies.get('XSRF-TOKEN')},
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        data: data
                    }; 
                    axios(config) ; ` ;
                    var jssend2 = ` `;

 

                    var test1 = `<a href="#" id="bike-editBTN" type="button"  data-id="' + data + '" data-target="#bicycle-edit" data-toggle="modal" class="bike-edit bikeEditClasses dropdown-item"><i class="icon-pencil"> </i></a>` 
                    test1 = `<a id="dt-control" class="btn cikis2status" onclickEx=" let text; if (confirm('${uyariMesaji}') == true) { ${jssend} } ; "  style="background:${modul ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${modul ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${modul != row.output_desired_status[ moduleKey ] ? "<br> **işlemde** " : "" }</a>` 
                    test1 = `<a id="dt-control" class="btn cikisstatus"  data-channel="${moduleKey+1}" data-module-id="${row.module_id}" data-module-new-status="${modul ? 0 : 1}" data-uyari-mesaji="${uyariMesaji}" style="background:${modul ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${modul ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${modul != row.output_desired_status[ moduleKey ] ? "<br> **işlemde** " : "" }</a>` 
                    return test1

                    return `<a id="dt-control" class="btn " style="background:${data[1] ? row.status_1_color[0] : row.status_0_color[0]}; color :#fff">${data[1] ? "Açık, <b>Kapat</b>" : "Kapalı, <b>Aç</b>"} ${data[1] != row.output_desired_status[1] ? "<br> **işlemde** " : "" }</a>` 
                }, "orderable": false
            }, 

            

            {title: t("conn_status "), 
                data: "conn_status", render: function (data, type, row) {
                    return `<span class="btn " style="background:${data ? row.status_1_color[0] : row.status_0_color[0]} ; color :#fff">${data ? "Aktif" : "Pasif"} </span>` 
                }, "orderable": false
            }, 
            {title: t("io_enabled "), 
                data: "io_enabled", render: function (data, type, row) {
                    return `<span class="btn " style="background:${data ? row.status_1_color[0] : row.status_0_color[0]} ; color :#fff">${data ? "Aktif" : "Pasif"} </span>` 
                }, "orderable": false
            }, 
  

        ]
        this.energyTrackingFilter = [
            {
                Status: false,
            }
        ]
        const moment = require('moment');

        const myDate = moment.now();
        const newDate = moment(myDate).add(-6, 'hours').format('YYYY-MM-DD HH:mm:ss');

        this.filterMostRentedFilter = [
            {
                "EntryDate": moment().startOf('month').format('YYYY-MM-DD HH:MM'),
                "ExitDate": moment().endOf('month').format('YYYY-MM-DD HH:MM'),
            }
        ] 


        this.modalBicycleRef = React.createRef();
        this.modalBicycleHandleSubmit = this.modalBicycleHandleSubmit.bind(this);

    }


    componentDidMount() {
        const { t } = this.props;
        var _this = this;
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $('.selectStation').select2({
            language: selectLanguage
        });
        stationList = ReactSession.get('stationList')


        jq('#parkingoccupancy-table2 tbody').on('click', '.cikisstatus', function () {//action delete butonu 
 
            var data = {
                "module_id": ""+jq(this).attr("data-module-id")+"",
                "channelEx": 1,
                "channel": Number.parseInt(jq(this).attr("data-channel")), 
                "desired_status": Number.parseInt(jq(this).attr("data-module-new-status")),
            } 
            var config = {
                method: 'POST',
                url: getEndPoint() + '/api/Admin/OtherModules/EnergyTracking',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                data: data
            };

            if (confirm( jq(this).attr("data-uyari-mesaji") ) == true) {

                axios(config)
                    .then(function (response) {  
                        if ( response.data.result.status == "OK" ) {
                            alert("işlem başarılı ") ; 
                            jq("#tab-1 > div > div.col-12 > div > div.card-custom-color.card-header.header-elements-inline > div > div > a").click();
                            _this.bicycleTable.ajax.reload()

                        } else { 
                            alert("Hata oluştu lütfen terkar deneyin") ; 
                        }

                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            // $("#bicycle-edit .close").trigger("click");
                            // _this.bicycleTable.ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        console.log( "_________________" ) 
                        console.log( error ) 
                        // Functions.requestAxiosErrorFunction(error);
                    });

            }


            
        });

    }

    componentDidUpdate = () => this.componentDidMount();
    resetSubmitHandler = (e) => {
        e.preventDefault();
        const _this = this;
        const { t } = this.props 

        // { ns: "translations" }
        
    }

 


    modalBicycleHandleSubmit(e) {
        e.preventDefault();

        const _this = this;
        const { t } = this.props

        Swal.fire({
            title: t("swal.info.subjectNoData", { ns: "translations" }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {

                var userEditData = userData;
                userEditData.VehicleNo = $('.newBikeNumber').val() == "" ? userEditData.VehicleNo : $('.newBikeNumber').val() 

                var data = {
                    "module_id": userEditData.VehicleId,
                    "channel": 1,
                    "desired_status": userEditData.StationParkUnitId,
                } 
                var config = {
                    method: 'POST',
                    url: getEndPoint() + '/api/Admin/OtherModules/EnergyTracking',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            $("#bicycle-edit .close").trigger("click");
                            _this.bicycleTable.ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });


            } else if (result.isDenied) {
                Swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }


    render() {
        const { t } = this.props;


        return (

            <div className="retail-page p-2" > 
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab-1">
                        
                        <div className="row"> 

<TableData
    cardTitle={t("İstasyon Enerji Yönetimi")}
    abilityid={7}
    auth={0}
    length="100"
    ordering={false}
    searching ={false} 
    customClass="col-12"
    ajaxUrl="/api/Admin/OtherModules/EnergyTracking"
    tableName="parkingoccupancy-table2"
    columns={this.energyTrackingColumn} />
 

  


 <div id="bicycle-edit" ref={this.modalBicycleRef} className="modal fade" >
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <form onSubmit={this.modalBicycleHandleSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Uyarı</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    <div className="row"> 
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.bicycleRFID")}</Text>

                                        

                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" id="deneme" data-dismiss="modal">{t("İptal")}</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                        </div>


                    </div>
                    <div className="tab-pane fade" id="tab-2">

                    </div>

                </div>
            </div>
            
        )
    }
}

export default withTranslation(['Homepage', 'translations', 'systemBicycles'])(EnergyTracking);