import React from "react";

export default function CustomElement(props) {
    const TagObject = `${props.Tag}`;
    // const AlteredProps = {Tag, ...props};
    const { Tag, ...AlteredProps } = props;
    return (
        <TagObject {...AlteredProps}>
            {props.children}
        </TagObject>
    );
}