import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";
import { ReactSession } from 'react-client-session';
import Cookies from "universal-cookie";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import $ from 'jquery'
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
const cookies = new Cookies();

let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
class StationIntensity extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
    }



    componentDidMount() {
        const { t } = this.props;

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.handleLoadTable()
    }

    componentDidUpdate = () => this.componentDidMount();
    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    handleLoadTable = () => {

        $(`#station-table tbody`).hide()
        $(`#station-table`).addClass('table-loader') 
        $("button").attr("disabled","disabled")
        var data = JSON.stringify({
            Filter: {
                EntryDate: Functions.getFormatDBDateHours($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours),
                ExitDate: Functions.getFormatDBDateHours($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours)

            }
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Reports/IntensityStations',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                const { t } = this.props;
                if (this.stationsTable != null) {
                    $('#station-table').empty();
                    this.stationsTable.destroy();

                }


                this.stationsTable = $('#station-table').DataTable({
                    dom: 'Blfrtip',
                    lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
                    "pageLength": 50,
                    data: response.data.data,
                    buttons: [
                        'Kopyala', 'csv', 'excel', 'pdf', 'Yazdır'
                    ],
                    pagingType: 'simple_numbers',
                    language: {
                        ...(t("tableLocalization", { ns: "translations" })),
                        paginate: {
                            'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                            'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                        }
                    },
                    "columns": [
                        // { data: "StationId" },
                        { data: "Number" },
                        { data: "Name" },
                        { data: "CountEntry" },
                        { data: "CountExit" },

                    ],
                });
                $(`#station-table`).removeClass('table-loader').show()
                $("button").removeAttr( "disabled")
                new $.fn.dataTable.Responsive(this.stationsTable, { details: true, Responsive: true },);
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });


    }

    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters", { ns: "translations" })}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">

                            <div className="col-sm-6">

                                <div className="form-group ">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="col-sm-6">


                                <div className="form-group ">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={this.handleLoadTable} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.tableHead")}</h6>
                        <a onClick={() => {
                            this.componentDidMount()
                        }} className="list-icons-item" data-action="reload"></a>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="station-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            {/* <th>{t("table.StationId")}</th> */}
                                            <th>{t("table.stationNo")}</th>
                                            <th>{t("table.stationName")}</th>
                                            <th>{t("table.exitsNumber")}</th>
                                            <th>{t("table.entriesNumber")}</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation(['stationIntensity', 'translations'])(StationIntensity);
