import React, { useCallback, useState, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Navigation from "./components/navigation";
import Anchor from "./components/elements/anchor.js";
import Image from "./components/elements/image.js";
import Text from "./components/elements/text.js";
import Button from "./components/elements/button.js";
import Division from "./components/elements/content-division.js";
import UnorderedList, { ListItem } from "./components/elements/unordered-list";
import Dropdown from "./components/dropdown.js";
import AccordionList from './components/accordion-list';
import Sidebar from "./components/sidebar";
import i18n from "./i18n/i18n";
import { useTranslation } from "react-i18next";
import { ReactSession } from "react-client-session";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Functions from "./class/functions";
import * as axios from 'axios';
import Cookies from 'universal-cookie';
import { getEndPoint } from "./class/api";
import swal from 'sweetalert2'
import jq from "jquery";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import "./css/daterangepicker.css";
const cookies = new Cookies();

export function NavigationBar(props) {
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState("tr");
    const languageSelected = useCallback((lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        ReactSession.set("selectedLanguage", lang);
        window.location.reload()
    }, [selectedLanguage]);

    const themeChanged = useCallback(() => {
        if (ReactSession.get("themeType") == "dark-mode")
            ReactSession.set("themeType", "light-mode");
        else
            ReactSession.set("themeType", "dark-mode");

        window.location.reload();
    });

    const placeholderImage = require("./img/placeholders/placeholder.jpg");
    const trTRLocale = require("./img/locales/tr-TR.png");
    const enUSLocale = require("./img/locales/en-US.png");
    const userSearch = useCallback(() => {
        if (Functions.RoleControl(11, 0) == false)
            return
        var input = document.querySelector("#phoneNumber");
        var iti = window.intlTelInputGlobals.getInstance(input);

        var searchData = {
            id: $('#user_id').val(),
            user: `${jq('#name').val()} ${jq('#surname').val()}`,
            phone: iti.getNumber(),
            TcNumber: jq('#Tc').val(),
            passportNumber: jq('#PassportNumber').val()
        }
        ReactSession.set('userSearchData', searchData);

        window.location.href = "/users/list";

    });
    const bikeSearch = useCallback(() => {
        ReactSession.set('bikeSearchData', jq('#bikeNumber').val());
        window.location.href = "/bicycles/bicycle-list";

    });
    useEffect(() => {
        var phoneInputID = "#phoneNumber";
        var input = document.querySelector("#phoneNumber");
        var iti = intlTelInput(input,
            {
                autoPlaceholder: "polite",
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,
            }
        )
        $(phoneInputID).on("countrychange", function (event) {

            var selectedCountryData = iti.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");
            $(this).mask(mask);
        });
        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });



    }, []);
    return (
        <Navigation AdditionalChildren={<UserSection />}>
            <UnorderedList className="navbar-nav">
                <ListItem className="nav-item dropdown">
                    <Division className="dropdown-menu dropdown-content wmin-lg-350">
                        <Division className="dropdown-content-header">
                            <Text Tag="span" className="font-weight-semibold">{t("systemUpdates")}</Text>
                            <Anchor href="#" className="text-body"><i className="icon-sync"></i></Anchor>
                        </Division>
                        <Division className="dropdown-content-body dropdown-scrollable">
                            <UnorderedList className="media-list">
                                <ListItem className="media">
                                    <Division className="media-body">
                                        {t("errorAtStation", { stationNumber: 81842853 })}
                                        <Division className="text-muted font-size-sm">
                                            4 dakika önce
                                        </Division>
                                    </Division>
                                </ListItem>
                            </UnorderedList>
                        </Division>
                        <Division className="dropdown-content-footer bg-light">
                            <Anchor href="#" className="text-body mr-auto">{t("allUpdates")}</Anchor>
                            <Division>
                                <Anchor href="#" className="text-body" data-popup="tooltip" title="" data-original-title="Mark all as read"><i className="icon-radio-unchecked"></i></Anchor>
                                <Anchor href="#" className="text-body ml-2" data-popup="tooltip" title="" data-original-title="Bug tracker"><i className="icon-bug2"></i></Anchor>
                            </Division>
                        </Division>
                    </Division>
                </ListItem>
            </UnorderedList>
            <UnorderedList className="navbar-nav ml-lg-auto">

                {/* <ListItem className="nav-item">
                        <Anchor onClick={() => themeChanged()} href="" className="navbar-nav-link">
                            <i className="icon-color-sampler"></i>
                        </Anchor>
                    </ListItem> */}
            </UnorderedList>
            <UnorderedList className="navbar-nav">
                <ListItem className="nav-item dropdown">
                    <Anchor href="#" className="navbar-nav-link" data-toggle="dropdown">
                        <i className="icon-earth"></i>
                        <Text Tag="span" className="d-lg-none ml-3">{t("language")}</Text>
                    </Anchor>

                    <Division className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-300">
                        <Division className="dropdown-content-header">
                            <Text Tag="span" className="font-weight-semibold">{t("language")}</Text>
                        </Division>

                        <Division className="dropdown-content-body dropdown-scrollable">
                            <UnorderedList className="media-list">
                                <ListItem className={`media cursor-pointer ${selectedLanguage == "en" ? "active" : ""}`} onClick={() => languageSelected("en")}>
                                    <Division className="mr-3">
                                        <Image src={enUSLocale} width="36" height="36" className="rounded-circle" alt="" />
                                    </Division>
                                    <Division className="media-body align-self-center">
                                        <Anchor href="#" className="media-title font-weight-semibold">English (United States)</Anchor>
                                    </Division>
                                </ListItem>
                                <ListItem className={`media cursor-pointer ${selectedLanguage == "tr" ? "active" : ""}`} onClick={() => languageSelected("tr")}>
                                    <Division className="mr-3">
                                        <Image src={trTRLocale} width="36" height="36" className="rounded-circle" alt="" />
                                    </Division>
                                    <Division className="media-body align-self-center">
                                        <Anchor href="#" className="media-title font-weight-semibold">Türkçe (Türkiye)</Anchor>
                                    </Division>
                                </ListItem>
                            </UnorderedList>
                        </Division>

                    </Division>

                    <Division className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-300">


                        <Division className="dropdown-content-header">
                            <Text Tag="span" className="font-weight-semibold">Aktif Operatörler</Text>
                            <Anchor href="#" className="text-body"><i className="icon-search4 font-size-base"></i></Anchor>
                        </Division>

                        <Division className="dropdown-content-footer bg-light">
                            <Anchor href="#" className="text-body mr-auto">Tüm Kullanıcılar</Anchor>
                            <Anchor href="#" className="text-body"><i className="icon-gear"></i></Anchor>
                        </Division>
                    </Division>
                </ListItem>
            </UnorderedList>


            <UnorderedList className="navbar-nav">
                <ListItem className="nav-item dropdown">
                    <Anchor href="#" className="navbar-nav-link" data-toggle="dropdown">
                        <i className="icon-search4"></i>
                        <Text Tag="span" className="d-lg-none ml-3">{t("search.search")}</Text>
                    </Anchor>

                    <Division className="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-500">
                        <Division className="dropdown-content-header">
                            <Text Tag="span" className="font-weight-semibold">{t("search.search")}</Text>
                            <Anchor href="#" className="text-body"><i className="icon-search4 font-size-base"></i></Anchor>
                        </Division>
                        <Division className="dropdown-content-header">
                            <UnorderedList className="nav nav-tabs nav-tabs-highlight m-0">
                                <ListItem className="nav-item"><a href="#search-user" className="nav-link active" data-toggle="tab">{t("search.user")}</a></ListItem>
                                <ListItem className="nav-item"><a href="#search-bicycle" className="nav-link" data-toggle="tab">{t("search.bicycle")}</a></ListItem>
                            </UnorderedList>
                        </Division>

                        <Division className="dropdown-content-body dropdown-scrollable">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="search-user">
                                    <UnorderedList className="media-list">

                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("ID")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input onKeyPress={(e) => e.key === 'Enter' && userSearch()} id="user_id" type="number" className="form-control"  />
                                                </Division>
                                            </Division>
                                        </ListItem>
                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.name")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input onKeyPress={(e) => e.key === 'Enter' && userSearch()} id="name" type="text" className="form-control" autoComplete="nope"  />
                                                </Division>
                                            </Division>
                                        </ListItem>
                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.surname")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input onKeyPress={(e) => e.key === 'Enter' && userSearch()} id="surname" type="text" className="form-control" autoComplete="off"  />
                                                </Division>
                                            </Division>
                                        </ListItem>
                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.phone")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input onKeyPress={(e) => e.key === 'Enter' && userSearch()} id="phoneNumber" type="tel" className="form-control" autoComplete="off" />
                                                </Division>
                                            </Division>



                                        </ListItem>
                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.identificationNumber")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input id="Tc" onKeyPress={(e) => e.key === 'Enter' && userSearch()} type="number" className="form-control" autoComplete="off"  />
                                                </Division>
                                            </Division>
                                        </ListItem>
                                        <ListItem className="media">

                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.passportNo")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input onKeyPress={(e) => e.key === 'Enter' && userSearch()} id="PassportNumber" type="number" className="form-control" autoComplete="off"  />
                                                </Division>
                                            </Division>
                                        </ListItem>
                                        <Division className="mt-2 dropdown-content-footer bg-light d-flex justify-content-center">
                                            <Button onClick={userSearch} className="btn btn-primary"><i className="icon-search4 mr-1"></i> {t("search.fetch")}</Button>
                                        </Division>
                                    </UnorderedList>

                                </div>

                                <div className="tab-pane fade" id="search-bicycle">
                                    <UnorderedList className="media-list">
                                        <ListItem className="media">
                                            <Division className="row media-body">
                                                <Division className="col-4 d-flex justify-content-start align-items-center">
                                                    <Division className="mr-3">
                                                        <Text Tag="span">{t("search.bicycleID")}</Text>
                                                    </Division>
                                                </Division>
                                                <Division className="col-8">
                                                    <input id="bikeNumber" onKeyPress={(e) => e.key === 'Enter' && bikeSearch()} type="number" className="form-control" autoComplete="off"/>
                                                </Division>
                                            </Division>
                                        </ListItem>
                                    </UnorderedList>
                                    <Division className="mt-2 dropdown-content-footer bg-light d-flex justify-content-center">
                                        <Button onClick={bikeSearch} className="btn btn-primary"><i className="icon-search4 mr-1"></i> {t("search.fetch")}</Button>
                                    </Division>
                                </div>

                            </div>

                        </Division>


                    </Division>
                </ListItem>
            </UnorderedList>
        </Navigation>
    );
};

export function UserSection(props) {
    const { t } = useTranslation();

    const placeholderImage = require("./img/placeholders/placeholder.jpg");

    const logOut = useCallback(() => {

        const cookies = new Cookies();

        cookies.remove('auth', { path: '/' })
        ReactSession.remove('auth');
        window.location.href = '/login';
    });
    const [sessionUserData, setSessionUserData] = useState([]);
    useEffect(() => {
        // swal.mixin({
        //     toast: true,
        //     icon: 'error',
        //     title: "81842853 numaralı istasyonda arıza!",
        //     position: 'bottom-end',
        //     showConfirmButton: false,
        //     timer: 3000,
        //     timerProgressBar: true,
        //     didOpen: toast => {
        //         toast.addEventListener('mouseenter', swal.stopTimer)
        //         toast.addEventListener('mouseleave', swal.resumeTimer)
        //     },
        //     willClose: () => {
        //         swal.mixin({
        //             toast: true,
        //             icon: 'error',
        //             title: "1220 numaralı Bisiklet Zorla Alınan Kullanıcılara Eklendi!",
        //             position: 'bottom-end',
        //             showConfirmButton: false,
        //             timer: 3000,
        //             timerProgressBar: true,
        //             didOpen: toast => {
        //                 toast.addEventListener('mouseenter', swal.stopTimer)
        //                 toast.addEventListener('mouseleave', swal.resumeTimer)
        //             }

        //         }).fire()
        //     }

        // }).fire()

        setSessionUserData(ReactSession.get('userData'));
    }, []);

    return (
        <UnorderedList className="navbar-nav flex-row order-1 order-lg-2 flex-1 flex-lg-0 justify-content-end align-items-center">
            <ListItem className="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100">
                <Dropdown
                    ClassName="d-inline-flex align-items-center h-100"
                    ButtonclassName="d-inline-flex align-items-center h-100"
                    ContentclassName=""
                    Button={
                        (
                            <>
                                <Image src={placeholderImage} className="rounded-pill mr-lg-2" height="34" alt="" />
                                <Text Tag="span" className="d-none d-lg-inline-block">{sessionUserData}</Text>
                            </>
                        )
                    }
                    Content={
                        (
                            <>
                                <Anchor type="button" onClick={logOut} className="dropdown-item"><i className="icon-switch2"></i> {t("menu.logout.logout")}</Anchor>
                            </>
                        )
                    }
                />
            </ListItem>
        </UnorderedList>
    )
}

export function SideSection(props) {
    const { t } = useTranslation();
    const url = '';
    const placeholderImage = require("./img/placeholders/placeholder.jpg");

    const logOut = useCallback(() => {
        const cookies = new Cookies();

        cookies.remove('auth', { path: '/' })
        ReactSession.remove('auth');
        window.location.href = '/login';
    });
    const [data, setData] = useState([]);
    const [members, setMembers] = useState([]);

    const [sessionUserData, setSessionUserData] = useState([]);

    const station = useCallback(async () => {

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            withCredentials: true,
            data: JSON.stringify({
                "per_page": -1,
                "Filter": {
                    "order": "Order",
                    "sort": "asc",
                }
            })

        };
        await axios(config)
            .then(function (response) {
                $('#loadingStations').hide();
                ReactSession.set('stationList', response.data.data)
                setData(response.data.data)
            })
            .catch(function (error) {
                Functions.requestErrorFunction(error.toJSON(), error.toJSON().status, error.message)
            });
    })

    const member = useCallback(() => {
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: JSON.stringify({
                "per_page": 1,
            }),
            withCredentials: true,
        };

        axios(config)
            .then(function (response) {
                setMembers(response.data.pagingData.totalCount)
            })
    })




    useEffect(() => {
        setSessionUserData(ReactSession.get('userData'));
        member();
        station();
    }, []);

    return (
        <Sidebar>
            <Division className="sidebar-section sidebar-user my-1">
                <Division className="sidebar-section-body">
                    <Division className="media">
                        <Anchor href="#" className="mr-3">
                            <Image src={placeholderImage} className="rounded-circle" alt="" />
                        </Anchor>

                        <Division className="media-body">
                            <Division className="font-weight-semibold">{sessionUserData}</Division>

                        </Division>


                        <Division className="align-self-center">
                            <Button type="Button" className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-control sidebar-main-resize d-none d-lg-inline-flex">
                                <i className="icon-transmission"></i>
                            </Button>

                            <Button type="Button" className="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none">
                                <i className="icon-cross2"></i>
                            </Button>
                        </Division>
                    </Division>
                </Division>
            </Division>

            <UnorderedList className="nav nav-sidebar" data-nav-type="accordion">
                <ListItem style={{ display: Functions.RoleControl(1) == false ? "none" : "" }} className="nav-item-header">
                    <Division className="text-uppercase font-size-xs line-height-xs">{t("menu.main")}</Division>
                    <i className="icon-menu" title="Main"></i>
                </ListItem>

                <ListItem className="nav-item">
                    <Anchor href="/" className="nav-link">
                        <i className="icon-home2"></i>
                        <Text Tag="span">{t("menu.homepage")}</Text>
                    </Anchor>
                </ListItem>

                <AccordionList abilityid={11} Icon={(<i className="icon-user"></i>)} Title={t("menu.user.user")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={11} auth={1} href="/users/add" className="nav-link">{t("menu.user.newUser")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={11} auth={0} href="/users/list" className="nav-link">{t("menu.user.userList")}</Anchor>
                    </ListItem>

                    <ListItem className="nav-item">
                        <Anchor abilityid={12} auth={0} href="/users/add-credit" className="nav-link">{t("menu.user.addUserCredits")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={12} auth={0} href="/users/merge" className="nav-link">Üye Birleştirme</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={13} auth={0} href="/users/bicycle-usage-time-price-edit" className="nav-link">{t("menu.finance.bicycleUsageTimePriceEdit")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={14} auth={0} href="/users/top-cyclists" className="nav-link">{t("menu.reports.topCyclists")}</Anchor>
                    </ListItem>
                </AccordionList>

                <AccordionList abilityid={15} Icon={(<i className="icon-station"></i>)} Title={t("menu.system.stations")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={15} auth={3} href="/stations/station-add" className="nav-link">{t("menu.stations.add")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={16} auth={0} href="/stations/station-zone-definitions" className="nav-link">{t("menu.system.stationZoneDefinitions")}</Anchor>
                    </ListItem>
                    <AccordionList Title={t("menu.system.stations")}>
                        <ListItem className="nav-item">
                            <Anchor abilityid={15} auth={0} href={"/stations"} className="nav-link">{t('menu.stations.allStation')}</Anchor>
                        </ListItem>
                        <ListItem id="loadingStations" className="nav-item">
                            <Anchor href={"#"} className="nav-link">{t('loading')}</Anchor>
                        </ListItem>
                        {data.map((station, index) =>

                            <ListItem key={station.StationId} className="nav-item">
                                <Anchor abilityid={17} auth={0} href={"/stations/list/" + station.StationId} className="nav-link">{station.Name}</Anchor>
                            </ListItem>
                        )}

                    </AccordionList>

                    <ListItem className="nav-item">
                        <Anchor abilityid={16} auth={0} href="/stations/energy-tracking" className="nav-link">{t("İstasyon Enerji Yönetimi")}</Anchor>
                    </ListItem>
                </AccordionList>

                <AccordionList abilityid={18} Icon={(<i className="icon-bike"></i>)} Title={t("menu.system.bicycles")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={18} auth={0} href="/bicycles/bicycle-list" className="nav-link">{t("menu.system.bicyclesList")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={19} auth={0} href="/bicycles/bicycle-movements" className="nav-link">{t("menu.reports.bicycleMovements")}</Anchor>
                    </ListItem>

                    <ListItem className="nav-item">
                        <Anchor abilityid={20} auth={0} href="/bicycles/retrieve-back-user-defined-bicycle" className="nav-link">{t("menu.bicycles.retrieveBackUserDefinedBicycle")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={21} auth={0} href="/bicycles/tag-change" className="nav-link">{t("menu.bicycles.tagChange")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={22} auth={0} href="/bicycles/gps-pair" className="nav-link">{t("menu.bicycles.gpsPair")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={23} auth={0} href="/bicycles/gps" className="nav-link">{t("menu.bicycles.gps")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={23} auth={0} href="/bicycles/gpsHistory" className="nav-link">{t("menu.bicycles.gps" ) + " Geçmiş İzleme" }</Anchor>
                    </ListItem> 
                </AccordionList>



                <AccordionList abilityid={24} Icon={(<i className="icon-credit-card"></i>)} Title={t("menu.finance.finance")}>

                    <ListItem className="nav-item">
                        <Anchor abilityid={24} auth={0} href="/reports/card-credit-sales-report" className="nav-link">{t("menu.reports.cardCreditSalesReport")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={24} auth={0} href="/reports/bank-consensus" className="nav-link">{t("menu.reports.bankConsensus")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={25} auth={0} href="/finance/recipe-definitions" className="nav-link">{t("menu.finance.recipeDefinitions")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={26} auth={0} href="/finance/payment-types" className="nav-link">{t("menu.finance.paymentTypes")}</Anchor>
                    </ListItem>
                    {/* <ListItem className="nav-item">
                        <Anchor href="/finance/process-types" className="nav-link">{t("menu.finance.processTypes")}</Anchor>
                    </ListItem> */}
                    <ListItem className="nav-item">
                        <Anchor abilityid={27} auth={0} href="/finance/z-reports" className="nav-link">{t("menu.finance.zReport")}</Anchor>
                    </ListItem>

                </AccordionList>


                <AccordionList abilityid={28} Icon={(<i className="icon-hammer"></i>)} Title={t("menu.faultTrackink.faultTrackink")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={29} auth={0} href="/fault/definitions" className="nav-link">{t("menu.faultTrackink.faultDefinitions")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={28} auth={0} href="/fault/faults" className="nav-link">{t("menu.faultTrackink.faultList")}</Anchor>
                    </ListItem>

                </AccordionList>

                <AccordionList abilityid={30} Icon={(<i className="icon-file-text"></i>)} Title={t("menu.reports.reports")}>

                    <ListItem className="nav-item">
                        <Anchor abilityid={31} auth={0} href="/reports/monthly-giro" className="nav-link">{t("menu.reports.monthlyGiro")}</Anchor>
                    </ListItem>
                    {/* <ListItem className="nav-item">
                        <Anchor abilityid={23} auth={0} href="/reports/giro-usage-summary-report" className="nav-link">{t("menu.reports.giroUsageSummaryReport")}</Anchor>
                    </ListItem> */}

                    <ListItem className="nav-item">
                        <Anchor abilityid={32} auth={0} href="/reports/expeditions-between-stations" className="nav-link">{t("menu.reports.expeditionsBetweenStations")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={33} auth={0} href="/reports/station-intensity" className="nav-link">{t("menu.reports.stationIntensity")}</Anchor>
                    </ListItem>

                </AccordionList>

                <AccordionList abilityid={34} Icon={(<i className="icon-users"></i>)} Title={t("menu.management.management")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={34} auth={0} href="/management/managers" className="nav-link">{t("menu.management.managers")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={35} auth={0} href="/management/manager-duties" className="nav-link">{t("menu.management.managerDuties")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={36} auth={0} href="/management/event-notification-definitions-list" className="nav-link">{t("menu.management.eventNotificationDefinitions")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={37} auth={0} href="/management/system-logs" className="nav-link">{t("menu.management.systemLogs")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={38} auth={0} href="/management/notification-post" className="nav-link">{t("menu.management.notificationPost")}</Anchor>
                    </ListItem>
                    <ListItem className="nav-item">
                        <Anchor abilityid={39} auth={0} href="/management/system-settings" className="nav-link">{t("menu.management.systemSettings")}</Anchor>
                    </ListItem>


                </AccordionList>


                {/* <AccordionList abilityid={40} Icon={(<i className="icon-lifebuoy"></i>)} Title={t("menu.support.support")}>
                    <ListItem className="nav-item">
                        <Anchor abilityid={40} auth={0} href="/support/list" className="nav-link">{t("menu.support.supportList")}</Anchor>
                    </ListItem>
                </AccordionList> */}

                <ListItem className="nav-item">
                    <Anchor type="button" onClick={logOut} className="nav-link">
                        <i className="icon-exit"></i>
                        <Text Tag="span">
                            {t("menu.logout.logout")}
                        </Text>
                    </Anchor>
                </ListItem>


                <ListItem className="nav-item nav-item">
                    <Anchor href="#" className="nav-link text-white">
                        <i className="icon-user"></i>
                        <Text Tag="span">
                            {t("menu.totalUsers")}
                        </Text>
                        <Text Tag="span" className="badge badge-warning badge-pill ml-auto">{members}</Text>
                    </Anchor>
                </ListItem>

            </UnorderedList>
        </Sidebar>
    );
}

export function FooterSection(props) {
    const [selectedLanguage, setSelectedLanguage] = useState(new Date().getHours() + ":" + new Date().getMinutes());
    useEffect(() => {
        jq.fn.dataTable.ext.errMode = 'none';
        setInterval(() => {
            setSelectedLanguage(new Date().getHours() + ":" + new Date().getMinutes())
            if (ReactSession.get('live') == true) {
                jq.fn.dataTable.tables({ api: true }).ajax.reload();

            }
        }, 900000);
    });

    const { t } = useTranslation();
    return (  
        (
            <>
                <span className="navbar-text">
                    <Text Tag="span" >
                         © {new Date().getFullYear()} Ferofen Bike V1.10.24, Tüm hakları saklıdır.
                    </Text>
                </span>
                <ul className="navbar-nav ml-lg-auto">
                    <li className="nav-item"><a href="/headCheck" className="navbar-text me-5"> Head Check</a></li>
                    <li className="nav-item">
                        <p className="navbar-text ">Son Güncelleme Zamanı {selectedLanguage}  </p>
                    </li>
                    <li className="nav-item">
                        <div className="navbar-nav-link custom-control custom-switch">

                            {/* <i className="mr-3 fas fa-sun"></i> */}
                            <input type="checkbox" className="custom-control-input"
                                onChange={() => ReactSession.set('live', (ReactSession.get('live') == false))} id="themeToggler"
                                defaultChecked={ReactSession.get("live") === null ? false : ReactSession.get("live") == true ? true : false} />
                            <label className="custom-control-label pl-0" htmlFor="themeToggler"></label>

                        </div>
                        {/* <a type="checkbox" href="#"className="navbar-nav-link custom-control-input" ><i className="icon-home2 mr-2"></i> Live </a> */}
                    </li>
                    <li className="nav-item">
                        <p className="navbar-text">Live</p>
                    </li>
                </ul>

            </>
        )
    );
}

export function HeaderSection(props) {
    const { t } = useTranslation();

    return (
        <>
            {/* <Division className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <Division className="d-flex">
                    <Division className="breadcrumb">
                        <Router >
                            <Routes>

                                <Route path="/users/list" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.user.user")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.user.userList")}</Text>
                                    </>
                                }></Route>
                                <Route path="/bicycles/gps" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.bicycles")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.GpsList")}</Text>
                                    </>
                                }></Route>

                                <Route path="/users/add-credit" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.user.user")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.user.addUserCredits")}</Text>
                                    </>
                                }></Route>
                                <Route path="/users/edit/user-:userId" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Anchor href="/users/list" className="breadcrumb-item"><Text Tag="span" className="breadcrumb-item">{t("menu.user.user")}</Text></Anchor>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.user.userEdit")}</Text>
                                    </>
                                } />
                                <Route path="/system/stations" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.stations")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/parks" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.parks")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/bicycles" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.bicycles")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/maintenance-control" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.systemMaintenanceControl")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/bicycle-status-definitions" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.bicycleStatusDefinitions")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/kiosk-control-logs" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.kioskControlLogs")}</Text>
                                    </>
                                }></Route>
                                <Route path="/stations/station-zone-definitions" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.stationZoneDefinitions")}</Text>
                                    </>
                                }></Route>
                                <Route path="/system/controls" element={
                                    <> 
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.system")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.system.systemControl")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/active-users" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.activeUsers")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/system-logs" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.systemLogs")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/system-settings" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.systemSettings")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/event-notification-definitions" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.eventNotificationDefinitions")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/manager-duties" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.managerDuties")}</Text>
                                    </>
                                }></Route>
                                <Route path="/management/managers" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.management.management")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.management.managers")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/list" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsList")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/list" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsHistory")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/history" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsHistory")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/commands" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsCommands")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/zone-alarm-definitions" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsZoneAlarmDefinitions")}</Text>
                                    </>
                                }></Route>
                                <Route path="/gps/thermal-map" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i></Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.gps.gps")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.gps.gpsThermalMap")}</Text>
                                    </>
                                }></Route>
                                <Route path="/finance/recipe-definitions" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.recipeDefinitions")}</Text>
                                    </>
                                } />
                                <Route path="/finance/payment-types" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.paymentTypes")}</Text>
                                    </>
                                } />
                                <Route path="/finance/process-types" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.processTypes")}</Text>
                                    </>
                                } />
                                <Route path="/finance/z-reports" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.zReport")}</Text>
                                    </>
                                } />
                                <Route path="/finance/retail-sale-receipts" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.retailSaleReceipts")}</Text>
                                    </>
                                } />
                                <Route path="/users/bicycle-usage-time-price-edit" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.bicycleUsageTimePriceEdit")}</Text>
                                    </>
                                } />
                                <Route path="/finance/virtual-pos-manual-payment-entry" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.finance.finance")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.finance.virtualPosManualPaymentEntry")}</Text>
                                    </>
                                } />
                                <Route path="/reports/credit-card-provision-report" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.creditCardProvisionReport")}</Text>
                                    </>
                                } />
                                <Route path="/reports/bank-consensus" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.bankConsensus")}</Text>
                                    </>
                                } />
                                <Route path="/reports/card-credit-sales-report" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.cardCreditSalesReport")}</Text>
                                    </>
                                } />
                                <Route path="/reports/monthly-giro" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.monthlyGiro")}</Text>
                                    </>
                                } />
                                <Route path="/reports/giro-usage-summary-report" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.giroUsageSummaryReport")}</Text>
                                    </>
                                } />
                                <Route path="/reports/bicycle-movements" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.bicycleMovements")}</Text>
                                    </>
                                } />
                                <Route path="/reports/expeditions-between-stations" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.expeditionsBetweenStations")}</Text>
                                    </>
                                } />
                                <Route path="/reports/station-intensity" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.stationIntensity")}</Text>
                                    </>
                                } />
                                <Route path="/reports/top-cyclists" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.topCyclists")}</Text>
                                    </>
                                } />
                                <Route path="/bicycles/fault-tracking" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.system.bicycles")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.faultTracking")}</Text>
                                    </>
                                }></Route>
                                <Route path="/reports/repair-and-maintenance-history" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.reports.reports")}</Text>

                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.reports.repairAndMaintenanceHistory")}</Text>
                                    </>
                                }></Route>
                                <Route path="/support/list" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.support.support")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.support.supportList")}</Text>
                                    </>
                                } />
                                <Route path="/support/statistics" element={
                                    <>
                                        <Anchor href="/" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> </Anchor>
                                        <Text Tag="span" className="breadcrumb-item">{t("menu.support.support")}</Text>
                                        <Text Tag="span" className="breadcrumb-item active">{t("menu.support.supportStatistics")}</Text>
                                    </>
                                } />
                            </Routes>
                        </Router>
                    </Division>
                    <Anchor href="#" className="header-elements-toggle text-body d-lg-none"><i className="icon-more"></i></Anchor>
                </Division>
                <Division className="header-elements d-none">
                    <Division className="breadcrumb justify-content-center">

                    </Division>
                </Division>
            </Division> */}
        </>
    );
}

export default null;