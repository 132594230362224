import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { ReactSession } from 'react-client-session';
import { getEndPoint } from "../../class/api";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import $ from "jquery";
import DatePicker from '../../components/elements/DatePicker';
import Cookies from 'universal-cookie';
import axios from "axios";
import moment from 'moment';

const cookies = new Cookies();
let firstDateHours = "00:00:00";

class BicyclePriceEdit extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.overchargeTableRef = React.createRef(null);
        this.undeliveredTableRef = React.createRef(null);
        this.newEntryDate = React.createRef();
    }

    parentValueChange = (valueType, type) => {
        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        // $('#priceEdit').modal('show');

        DateRangePickerHandler(this.newEntryDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            maxDate: moment().format('DD/MM/YYYY'),
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });


        if (this.overchargeTable != null)
            this.overchargeTable.destroy();
        if (Functions.RoleControl(13, 0)) {
            this.overchargeTable = $('#overcharge-table').DataTable({
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'pdf', 'print'
                ],
                language: {
                    ...(t("tableLocalization", { ns: "translations" })),
                    paginate: {
                        'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                        'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                    }
                },
                searching: false,
                serverSide: true,
                processing: true,
                ajax: {
                    url: getEndPoint() + '/api/Admin/Member/Trips',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    error: function (xhr, error, thrown) {
                        Functions.requestAjaxErrorFunction(xhr, error, thrown);
                    },
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,

                    dataFilter: function (data) {
                        var json = jQuery.parseJSON(data);
                        json.data = json.data;
                        if (json.data.length) {

                            json.recordsFiltered = json.pagingData.totalCount
                            json.recordsTotal = json.pagingData.totalCount

                        }
                        else {
                            json.recordsFiltered = 0
                            json.recordsTotal = 0
                        }
                        $(`#overcharge-table`).removeClass('table-loader').show()
                        return JSON.stringify(json);
                    },
                },
                fnServerParams: (aoData) => {
                    aoData['Filter'] = {
                        q: aoData.search.value,
                        sort: aoData.order[0].dir,
                        order: aoData.columns[aoData.order[0].column].data,
                        TotalDuration: parseInt($('#minuteFilter').val()),
                        Fee: $('#feeFilter').val(),
                        ProcessStatusId: [1, 7],
                        EntryDate: moment().add(-parseInt($('#dayFilter').val()), 'days').format('YYYY-MM-DD HH:ss')
                    };
                    aoData["page"] = aoData.start / aoData.length + 1;
                    aoData["per_page"] = aoData.length;
                },
                "columns": [
                    { data: "MemberTripId" },
                    {
                        data: 'member',
                        render: function (data, type, row) {
                            return `<a href="${Functions.RoleControl(11, 4) == true ? `/users/edit/${data.user.id}` : "#"}" > ${data.user.Name} ${data.user.Surname} </a>`
                        }
                    },

                    {
                        data: 'TotalDuration',
                        render: function (data, type, row) {

                            return data
                        }
                    },
                    // StationParkUnitEntryId
                    {
                        data: "StationParkUnitEntryId", render: function (data, type, row) {
                            if (data == null) {
                                return " "
                            }
                            if (row.station_park_unit_entry == null) {
                                return " "
                            }
                            return ` <a href="/stations/list/${row.station_park_unit_entry.StationId}">${row.station_park_unit_entry.station.Name}</a>`
                        }
                    },
                    {
                        data: "StationParkUnitEntryId", render: function (data, type, row) {
                            if (data == null) {
                                return " "
                            }
                            return Functions.getFormatLocaleDateHours(row.EntryDate)
                        }
                    },
                    {
                        data: "StationParkUnitExitId", render: function (data, type, row) {
                            if (data == null) {
                                return " "
                            }
                            if (row.station_park_unit_exit == null) {
                                return " "
                            }
                            return ` <a href="/stations/list/${row.station_park_unit_exit.StationId}">${row.station_park_unit_exit.station.Name}</a>`
                        }
                    },
                    {
                        data: "ExitDate", render: function (data) {
                            return Functions.getFormatLocaleDateHours(data)
                        }
                    },
                    {

                        data: "Fee", render: function (data, type, row) {
                            var price = parseFloat(row.Fee).toFixed(2)
                            return `<a type="button" data-toggle="modal" data-target="#priceEdit" class="" id="operationBTN" >${price}  ${row.Currency} </a>`
                        }
                    },

                ],
                lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
                "pageLength": 50,
            });

            new $.fn.dataTable.Responsive(this.overchargeTable, { details: true, Responsive: true },);
        }
        $('#overcharge-table tbody').on('click', 'td #operationBTN', function () {
            var tr = $(this).closest('tr');
            var row = _this.overchargeTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.overchargeTable.row(this);
                data = row.data();
            }
            $('#usageNo').val(data.MemberTripId)
            $('#userNameSurname').val(data.member.user.Name + ' ' + data.member.user.Surname)
            $('#entryDate').val(Functions.getFormatLocaleDateHours(data.ExitDate))
            $('#exitDate').val(Functions.getFormatLocaleDateHours(data.EntryDate))
            $('#oldTime').val(data.TotalDuration)
            $('#oldPrice').val(parseFloat(data.Fee).toFixed(2))
            $('#oldBalance').val(parseFloat(data.member.Balance).toFixed(2))
        });

        if (this.notDeliveredTable != null)
            this.notDeliveredTable.destroy();
        if (Functions.RoleControl(13, 0)) {


            this.notDeliveredTable = $('#undelivered-table').DataTable({
                lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
                "pageLength": 50,
                language: {
                    ...(t("tableLocalization", { ns: "translations" })),
                    paginate: {
                        'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                        'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                    }
                },
                serverSide: true,
                processing: true,
                "bLengthChange": false,
                searching: false,
                ajax: {
                    url: getEndPoint() + '/api/Admin/Station/Vehicle',
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    error: function (xhr, error, thrown) {
                        Functions.requestAjaxErrorFunction(xhr, error, thrown);
                    },
                    crossDomain: true,
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    dataFilter: function (data) {
                        var json = jQuery.parseJSON(data);
                        json.data = json.data;
                        json.recordsFiltered = json.pagingData.totalCount
                        json.recordsTotal = json.pagingData.totalCount
                        $(`#undelivered-table`).removeClass('table-loader').show()

                        return JSON.stringify(json);
                    },
                },
                fnServerParams: (aoData) => {
                    aoData['Filter'] = {
                        q: aoData.search.value,
                        order: aoData.columns[aoData.order[0].column].data,
                        sort: aoData.order[0].dir,
                        takeOffDate: moment().add(-parseInt($('#dayFilter').val()), 'days').format('YYYY-MM-DD HH:ss')
                    };
                    aoData["page"] = aoData.start / aoData.length + 1;
                    aoData["per_page"] = aoData.length;
                },
                "columns": [
                    { data: "VehicleId" },
                    {
                        data: 'UserId', render: function (data, type, row) {
                            console.log(row);
                            return `<a href="${Functions.RoleControl(11, 4) == true ? `/users/edit/${row.User.id}` : "#"}" > ${row.User.Name} ${row.User.Surname} </a>`
                        }, orderable: false
                    },
                    { data: "VehicleNo" },
                    { data: "LastProcessingTrip.StationParkUnitEntry.StationName" },
                    {
                        data: "LastProcessingTrip.EntryDate", render: function (data) {
                            return moment(data).format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                ],

            });
            new $.fn.dataTable.Responsive(this.notDeliveredTable, { details: true, Responsive: true },);
        }
        if (ReactSession.get('usagePriceEdit')) {


            var data = JSON.stringify({
                "Filter": {
                    "MemberTripId": ReactSession.get('usagePriceEdit')
                }
            });

            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Member/Trips',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    var data = response.data.data[0]
                    $('#usageNo').val(data.MemberTripId)
                    $('#userNameSurname').val(data.member.user.Name + ' ' + data.member.user.Surname)
                    $('#entryDate').val(data.ExitDate)
                    $('#exitDate').val(data.EntryDate)
                    $('#oldTime').val(data.TotalDuration)
                    $('#oldPrice').val(parseFloat(data.Fee).toFixed(2))
                    $('#oldBalance').val(parseFloat(data.member.Balance).toFixed(2))
                    let myModal = new bootstrap.Modal(document.getElementById('priceEdit'), {});
                    myModal.show();
                    ReactSession.remove('usagePriceEdit')
                })
                .catch(function (error) {
                    Functions.requestAxiosErrorFunction(error);
                });
        }
    }
    componentDidUpdate = () => this.componentDidMount();
    modalFillDataFunc = () => {
        const _this = this;
        const { t } = this.props

        var data = JSON.stringify({
            "Filter": {
                "MemberTripId": parseInt($('#usageNoFilter').val()),
            }
        });
        var modal = new bootstrap.Modal(document.getElementById('priceEdit'), {});
        console.log(modal);
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Trips',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.data.length) {
                    var data = response.data.data[0]

                    $('#usageNo').val(data.MemberTripId)
                    $('#userNameSurname').val(data.member.user.Name + ' ' + data.member.user.Surname)
                    $('#exitDate').val(Functions.getFormatLocaleDateHours(data.EntryDate))
                    $('#entryDate').val(Functions.getFormatLocaleDateHours(data.ExitDate))
                    $('#oldTime').val(data.TotalDuration)
                    $('#oldPrice').val(parseFloat(data.Fee).toFixed(2))
                    $('#oldBalance').val(parseFloat(data.member.Balance).toFixed(2))
                    modal.show();
                }
                else {
                    swal.fire({
                        title: t("swal.error.head", { ns: "translations" }),
                        text: "Böyle Bir Kayıt Bulunamadı!",
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: t("swal.error.icon", { ns: "translations" })
                    })
                }
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });


    }
    handleClickFilter = () => {

        this.overchargeTable.ajax.reload()
    }
    clickCalculation = () => {
        const _this = this;
        const { t } = this.props

        var data = JSON.stringify({
            "MemberTripId": parseInt($('#usageNo').val()),
            "EntryDate": Functions.getFormatDBDateHours($('#newEntryDate').val() + ' ' + firstDateHours),
            "ExitDate": $('#exitDate').val()
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Trips/CalculationTrip',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // var newBalance = ""
                // if (parseFloat($('oldBalance').val() < parseFloat(response.data.data.Fee))) {
                //     newBalance = parseFloat($('#oldBalance').val()) - parseFloat(response.data.data.Fee)
                // }
                // else {
                //     newBalance = parseFloat($('#oldBalance').val()) + parseFloat(response.data.data.Fee)
                // }
                let balanceDifference = parseFloat($('#oldBalance').val()) + (parseFloat($('#oldPrice').val()) - response.data.data.Fee);

                $('#newTime').val(response.data.data.Minutes)
                $('#newPrice').val(parseFloat(response.data.data.Fee).toFixed(2))
                $('#newBalance').val(balanceDifference)

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    handleClickUpdatePrice = () => {
        const _this = this;
        const { t } = this.props
        if ($('#newTime').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: "Lütfen İlk Önce Hesaplama İşlemi Gerçekleştiriniz",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        var data = JSON.stringify({
            "MemberTripId": parseInt($('#usageNo').val()),
            "ExitDate": Functions.getFormatDBDateHours($('#newEntryDate').val() + ' ' + firstDateHours)
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Member/Trips/EntryDateUpdate',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                $('#newTime').val("")
                $('#newPrice').val("")
                $('#newBalance').val("")
                _this.overchargeTable.ajax.reload();
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props;

        return (
            <div className="bicycle-page p-2">
                {Functions.RoleControl(13, 2) != false ?
                    <div className="card">
                        <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                            <h6 className="card-title">{t("filter1.head")}</h6>

                        </div>
                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-sm-12">

                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-4">{t("filter1.label")}</label>
                                        <div className="col-lg-8">
                                            <input id="usageNoFilter" type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>


                                <Division className="col-sm-12 mt-2">
                                    <button type="button" onClick={this.modalFillDataFunc} className="btn btn-primary w-100">{t("filter1.buttonValue")}</button>
                                </Division>
                            </div>
                        </div>
                    </div> : null}
                {Functions.RoleControl(13, 0) != false ?
                    <div className="card ">
                        <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                            <h6 className="card-title">{t("filter2.head")}</h6>
                        </div>
                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-sm-12">

                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-4">{t("filter2.filter1")}</label>
                                        <div className="col-lg-8">
                                            <input type="number" id="dayFilter" defaultValue="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-4">{t("filter2.filter2")}</label>
                                        <div className="col-lg-8">
                                            <input type="number" id="feeFilter" defaultValue="0" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-4">{t("filter2.filter3")}</label>
                                        <div className="col-lg-8">
                                            <input type="number" id="minuteFilter" defaultValue="0" className="form-control" />
                                        </div>
                                    </div>
                                </div>


                                <Division className="col-sm-12 mt-2">
                                    <button onClick={this.handleClickFilter} type="button" className="btn btn-primary w-100">{t("filter2.buttonValue")}</button>
                                </Division>
                            </div>
                        </div>
                    </div>
                    : null
                }

                {Functions.RoleControl(13, 0) != false ?
                    <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                        <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                            <h6 className="card-title">{t("table1.tableHead")}</h6>
                        </div>
                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table id="overcharge-table" className="table-loader dataTable display  compact  w-100">
                                        <thead>
                                            <tr>
                                                <th>{t("table1.usageNo")}</th>
                                                <th>{t("table1.memberNameSurname")}</th>
                                                <th>{t("table1.time")}</th>
                                                <th>{t("table1.exitStation")}</th>
                                                <th>{t("table1.exitDate")}</th>
                                                <th>{t("table1.entryStation")}</th>
                                                <th>{t("table1.entryDate")}</th>
                                                <th>{t("table1.price")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                {Functions.RoleControl(13, 0) != false ?
                    <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                        <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                            <h6 className="card-title">{t("table2.tableHead")}</h6>
                        </div>
                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table id="undelivered-table" className="table-loader dataTable display  compact  w-100">
                                        <thead>
                                            <tr>
                                                <th>{t("table2.usageNo")}</th>
                                                <th>{t("table2.memberNameSurname")}</th>
                                                <th>{t("table2.bicycleNo")}</th>
                                                <th>{t("table2.exitParking")}</th>
                                                <th>{t("table2.releaseDate")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                <div style={{ display: Functions.RoleControl(13, 2) == false ? "none" : "" }} className="modal fade" id="priceEdit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("minuteEdit")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("table1.usageNo")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input readOnly id="usageNo" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("userNameSurname")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input readOnly id="userNameSurname" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("exitDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input disabled readOnly id="exitDate" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("entryDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input disabled readOnly id="entryDate" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("newEntryDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <input id="newEntryDate" ref={this.newEntryDate} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />


                                            {/* <input id="newEntryDate" ref={this.newEntryDate} type="text" className="form-control " /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("oldTime")}</label>
                                        <div className="d-flex">
                                            <input id="oldTime" disabled readOnly type="text" className="form-control " />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("newTime")}</label>
                                        <div className="d-flex">
                                            <input readOnly disabled id="newTime" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("oldPrice")}</label>
                                        <div className="d-flex">
                                            <input id="oldPrice" disabled readOnly type="text" className="form-control " />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("newPrice")}</label>
                                        <div className="d-flex">
                                            <input readOnly disabled id="newPrice" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("oldBalance")}</label>
                                        <div className="d-flex">
                                            <input id="oldBalance" disabled readOnly type="text" className="form-control " />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="col-form-label col-lg-2">{t("newBalance")}</label>
                                        <div className="d-flex">
                                            <input readOnly disabled id="newBalance" type="text" className="form-control " />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">{t("close")}</button>
                                <button type="button" className="btn btn-warning" onClick={this.clickCalculation}>{t("calculate")}</button>
                                <button type="button" onClick={this.handleClickUpdatePrice} data-dismiss="modal" className="btn btn-primary">{t("save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['bicycleUsageTimePriceEdit', 'translations'])(BicyclePriceEdit);
