import React from "react";
import { withTranslation } from "react-i18next";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Text from "../../components/elements/text";
import axios from "axios";
import jq from 'jquery';
import { getEndPoint } from "../../class/api";
import { ReactSession } from 'react-client-session';
import { Select } from "../../components/Select";
import Cookies from 'universal-cookie';
import TableData from "../../components/table.js";

const cookies = new Cookies();

class UserAddCredit extends React.Component {
    constructor(props) {
        super(props);

        this.creditsAddedTodayTableRef = React.createRef(null);
        this.balanceRef = React.createRef(null);
        this.descriptionRef = React.createRef(null);
        this.creditClick = this.creditClick.bind(this);
        const { t } = this.props
        this.state = {
            tables: []
        }
        this.creditListColumn = [
            { title: t("table.id"), data: "MemberId" },
            {
                title: t("table.userNameSurname"),
                data: 'member',
                render: function (data, type, row) {

                    return `<a href="/users/edit/${data.user.id}"> ${data.user.Name}  ${data.user.Surname}</a>`
                }
            },
            {
                title: t("table.TransactingUser"),
                data: 'TransactingUserId', render: function (data, type, row) {
                    return row.transacting_user.Name + ' ' + row.transacting_user.Surname
                }
            },
            {
                title: t("table.BalanceAdd"),
                data: "Fee", render: function (data) {
                    return parseFloat(data).toFixed(2)
                }
            },
            {
                title: t("table.price"),
                data: "Balance", render: function (data) {
                    return parseFloat(data).toFixed(2)
                }
            },
            { title: t("table.Description"), data: "Description" },
            {
                title: t("table.date"),
                data: "created_at", render: function (data) {
                    return Functions.getFormatLocaleDateHours(data)
                }, "orderable": false
            },


        ]
        var firstDate = moment().startOf('day').format("YYYY-MM-DD HH:mm");
        var lastDate = moment().endOf("day").format("YYYY-MM-DD HH:mm");

        this.creditListFilter = [{
            dateRange: [firstDate, lastDate],
            ProvisionTypeId: "3",
        }]
    }
    creditClick() {
        const _this = this
        const { t } = this.props


        var balance = _this.balanceRef.current.value.replace(',', '.')
        if (parseFloat(balance).toFixed(2) < 0) {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.paramerterErrorSubject", { ns: "translations" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (balance == '') {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.paramerterErrorSubject", { ns: "translations" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (parseInt($('#user-list').select2('data')[0].id) == 0) {
            // userCardId
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userCardId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }

        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {


                var data = JSON.stringify({
                    "AddBalance": parseFloat(balance).toFixed(2),
                    "Description": _this.descriptionRef.current.value,
                    "UserId": parseInt($('#user-list').select2('data')[0].id),
                    "PaymentMethodId": 5
                });
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member/AddBalance',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data,
                    withCredentials: true
                };
                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            _this.descriptionRef.current.value = "";
                            _this.balanceRef.current.value = "";
                            _this.state.tables[0].ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Division className="user-page p-2">
                <Division style={{ display: Functions.RoleControl(12, 0) == false ? "none" : "" }} className="card" >
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("userAddCredit")}</Text>
                    </Division>

                    <Division className="card-body py-2">
                        <Division className="row">

                            <Division className="col-md-6">
                                <Division className="col-md-12  d-flex">
                                    <div className="col-lg-2">
                                        <Text Tag="label" className="col-form-label">{t("userCardId")}</Text>

                                    </div>
                                    <div className="col-lg-10">
                                        <Select
                                            selectID="user-list"
                                            customCss={"user-list"}
                                            defaultValues="1"
                                            resultID={["id"]}
                                            result={["TcNumber", "Name", "Surname", "Phone"]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>

                                </Division>

                                <Division className="col-md-12 d-flex">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("operationType")}</Text>
                                    <Division className="col-lg-10">
                                        <Division className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-start align-items-center mb-2">
                                            <select className="select-search form-control w-100" defaultValue="3">
                                                <option disabled value="3">Manuel Kredi Ekleme </option>
                                                {/* translate */}
                                            </select>
                                        </Division>
                                    </Division>
                                </Division>


                                <Division className="col-md-12 d-flex">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("paymentType")}</Text>
                                    <Division className="col-lg-10">
                                        <Division className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-center align-items-center mb-2">
                                            <select defaultValue="4" className="select-search form-control w-100" placeholder="Seçiniz...">
                                                <option disabled value="4">Manuel Üye Hesabı Güncellendi</option>
                                            </select>

                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="col-md-12 d-flex">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("balance")}</Text>
                                    <Division className="col-lg-10">
                                        <Division className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-center align-items-center mb-2">
                                            <input ref={this.balanceRef} required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-credit-card"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="col-md-12 d-flex">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("description")}</Text>
                                    <Division className="col-lg-10">
                                        <Division className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-center align-items-center mb-2">
                                            <input ref={this.descriptionRef} required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-credit-card"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="col-lg-4">
                                    <button onClick={this.creditClick} className="btn btn-primary w-100">{t("addCredits")}</button>
                                </Division>
                            </Division>
                            <Division className="col-md-6">

                            </Division>
                        </Division>
                    </Division>
                </Division>



                <TableData
                    setTable={(e) => {
                        var table = this.state.tables
                        table.push(e);
                        this.setState({ tables: table })
                    }}
                    cardTitle={t("creditsAddedToday")}
                    className="col-lg-12"
                    abilityid={11}
                    auth={0}
                    searching={false}
                    customClass=""
                    ajaxUrl='/api/Admin/Member/PaymentHistory'
                    tableName="credits-added-today"
                    filter={this.creditListFilter}
                    columns={this.creditListColumn} />
            </Division>
        )
    }
}

export default withTranslation(['userAddCredit', 'translations'])(UserAddCredit);
