import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import i18n from "../../i18n/i18n";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import { getEndPoint } from "../../class/api";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import $ from 'jquery'
import moment from "moment";
const cookies = new Cookies();
class RecipeDefinitions extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.sonKullanmaTarihRef = React.createRef(null);
        this.cellPhoneRef = React.createRef(null);
    }
    tableInstall = () => {
        const { t } = this.props
        var _this = this;




        if (this.recipeTable != undefined)
            this.recipeTable.destroy();

        this.recipeTable = $('#recipeTable ').DataTable({
            dom: 'Blfrtip',
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Finance/Tariff',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#recipeTable`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            order: [1, "desc"],
            "searching": false,
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { title: t("table.recipeId"), data: "FinanceTariffId", orderable: false },
                {
                    title: t("table.recipeStatus"),

                    data: 'Status', render: function (data, type, row) {
                        if (data) {
                            return "<i class=\"icon-check\"></i>"
                        }
                        else {
                            return "<i class=\"icon-cross\"></i>"

                        }
                    }
                },
                {
                    title: t("table.recipeStartingDate"),

                    data: "StartDate", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data);
                    }
                },
                {
                    title: t("table.recipeType"),

                    data: "Name"
                },
                {
                    title: t("financeType"),

                    data: "finance_type", render: function (data, type, row) {
                        if (data == null) {
                            return "Finans Tipi Bulunamadı"
                        }
                        return data.Name
                    }, orderable: false
                },
                {
                    title: t("table.recipeBicycleType"),
                    data: "vehicle_type", render: function (data, type, row) {
                        if (data == null) {
                            return "Bisiklet Tipi Bulunamadı"
                        }
                        return data.Name
                    }, orderable: false
                },
                Functions.RoleControl(25, 2) != false ?
                    {
                        title: t("table.recipeEdit"),

                        data: "FinanceTariffId", render: function (data, type, row) {
                            return '<a href="/finance/recipe-edit/' + data + '" data-id="' + data + '" class="text-center editBTN dropdown-item"><i class="icon-pencil"></i> ' + t('recipeEdit.recipeEdit') + ' </a>'
                        }
                    }
                    : null

            ],
        });
        new $.fn.dataTable.Responsive(this.recipeTable, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;


        DateRangePickerHandler(this.sonKullanmaTarihRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.tableInstall()
        $('#recipeTable tbody').on('click', ".editBTN", function () {
            var row = $(this)
            var data = row[0].attributes[1].value
        });

    }

    componentDidUpdate = () => this.componentDidMount();


    render() {
        const { t } = this.props;

        return (
            <div className="recipe-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("recipes")}</h6>

                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(25, 4) == true ? <a href="/finance/recipe-add" className="btn btn-success">{t('typeAdd')}</a> : ""}
                                <a onClick={this.tableInstall} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="recipeTable" className="table-loader dataTable display  compact  w-100">

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="recipeModal" className="modal fade" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">
                                    {t("recipeEdit.recipeEdit")}
                                </h6>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("table.recipeStartingDate")}</label>
                                    <div className="col-lg-10">
                                        <input ref={this.sonKullanmaTarihRef} type="text" className="form-control w-100 daterange-single" defaultValue="" />

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("table.recipeFirstMinute")}</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="number" name="number" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("table.recipeLastMinute")}</label>
                                    <div className="col-lg-10">
                                        <input className="form-control" type="number" name="number" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("recipeEdit.active.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("recipeEdit.active.select1")}</option>
                                            <option value="opt2">{t("recipeEdit.active.select2")}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("recipeEdit.recipeType.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("recipeEdit.recipeType.select1")}</option>
                                            <option value="opt2">{t("recipeEdit.recipeType.select2")}</option>
                                            <option value="opt2">{t("recipeEdit.recipeType.select3")}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("recipeEdit.bicycleType.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("recipeEdit.bicycleType.select1")}</option>
                                            <option value="opt2">{t("recipeEdit.bicycleType.select2")}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("close")}</button>
                                <button type="button" className="btn btn-primary">{t("saveChanges")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['recipe', 'translations'])(RecipeDefinitions);
