import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getEndPoint } from "../../class/api";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import Division from "../../components/elements/content-division";
import $ from "jquery";
import Cookies from "universal-cookie";
import moment from "moment";
import axios from "axios";
import Functions from '../../class/functions';
const cookies = new Cookies();

const BankConsensus = () => {
    const [paymentList, setPaymentList] = useState([])
    let paymentLists;
    const sonKullanmaTarih1Ref = useRef(null)
    const sonKullanmaTarih2Ref = useRef(null)
    const sonKullanmaTarih3Ref = useRef(null)
    const sonKullanmaTarih4Ref = useRef(null)
    let detailsTable;
    let bankTable;
    const { t } = useTranslation(['bankConsensus', 'translations']);

    const getPaymentData = (data) => {
        var configData = JSON.stringify({
            "per_page": 7,
            "page": 1,
            "Filter": {
                "OrderId": data.TransactionId
            }
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Provision',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: configData
        };

        axios(config)
            .then(function (response) {
                if (!response.data.data.length) {
                    swal.fire({
                        title: "Hata",
                        text: "Ödeme Alındı, Bakiye Aktarılamadı",
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: "Kapat"
                    })
                    return
                }
                else {

                    swal.fire({
                        title: "Kontrol Edildi",
                        html: `İşlem Durumu: ${response.data.data[0].status.Name} <br/> <br/> <br/>  Üye Bilgisi: <br/> ID- ${response.data.data[0].user.id} <br/> Ad Soyad- <a href="/users/edit/${response.data.data[0].user.id}"> ${response.data.data[0].user.Name} ${response.data.data[0].user.Surname}</a>`,
                        showCancelButton: true,
                        confirmButtonText: 'Aktar',
                        showConfirmButton: response.data.data[0].ProvisionStatusId != 12 ? true : false,
                        cancelButtonText: "Kapat",
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                // var data = JSON.stringify({
                                //     "AddBalance": JSON.parse(response.data.data[0].UserAgent).PurchaseAmount,
                                //     "Description": "Manuel Bakiye Aktarımı",
                                //     // "UserId": 229433,
                                //     "UserId": response.data.data[0].user.id,
                                //     "PaymentMethodId": 5
                                // });
                                var data = JSON.stringify({     
                                    "TransactionId": response.data.data[0].OrderId, 
                                });
                                console.log(JSON.parse(data));
                                var config = {
                                    method: 'post',
                                    // url: getEndPoint() + '/api/Admin/Member/AddBalance',
                                    url: getEndPoint() + '/api//Admin/Reports/BankAgreementDetailOrderIdControl',
                                    headers: {
                                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                        "Accept": "application/json",
                                        'Content-Type': 'application/json',
                                    },
                                    data: data,
                                    withCredentials: true
                                };
                                axios(config)
                                    .then(function (response) {
                                        Swal.fire({
                                            position: 'top-end',
                                            icon: 'success',
                                            title: t("swal.success.successMessage", { ns: "translations" }),
                                            showConfirmButton: false,
                                            timer: 1500
                                        }).then(function () {
                                            setTimeout(() => {
                                                detailsTable.ajax.reload();
                                            }, 1500);

                                        })
                                    })
                                    .catch(function (error) {
                                        Functions.requestAxiosErrorFunction(error);
                                    });
                            }
                        })
                }
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        DateRangePickerHandler(sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            startDate: moment().add(-1, 'days').format('DD/MM/YYYY'),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });


        DateRangePickerHandler(sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(sonKullanmaTarih3Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            startDate: moment().add(-1, 'days').format('DD/MM/YYYY'),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },

        });



        DateRangePickerHandler(sonKullanmaTarih4Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });


        var data = JSON.stringify({
            "per_page": -1,
            "page": 1,
            "Filter": {
                dateRange: [
                    moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')]
            }
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Provision',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                paymentLists = response.data.data
                setTimeout(() => {
                    loadDetailTableProccess();

                }, 1500);
            })

        if (bankTable != undefined)
            bankTable.destroy();

        bankTable = $('#bank-table').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            searching: false,
            ordering: false,
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Reports/BankAgreement',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    if (json.data.TotalItem != null) {
                        json.data = Array(json.data);
                        json.recordsFiltered = json.data.length
                        json.recordsTotal = json.data.length
                    }
                    else {
                        json.recordsFiltered = 0
                        json.recordsTotal = 0
                    }
                    $(`#bank-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    "StartDate": moment($('#firstDate').val(), 'DD/MM/YYYY').startOf("day").format('YYYY-MM-DD'),
                    "EndDate": moment($('#lastDate').val(), 'DD/MM/YYYY').endOf("day").format('YYYY-MM-DD'),
                };

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                {
                    title: "Toplam Kayıt",
                    data: "TotalCount",
                },
                {
                    title: "Toplam Tutar",
                    data: "TotalItem.TotalAmount",
                }
            ],

            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 10,
        });
        new $.fn.dataTable.Responsive(bankTable, { details: true, Responsive: true },);








    }, []);
    const loadDetailTableProccess = () => {

        if (detailsTable != undefined)
            detailsTable.destroy();

        detailsTable = $('#bank_Detail_Table').DataTable({
            "createdRow": function (row, data, dataIndex) {
                var paymentData = paymentLists.find(x => x.OrderId == data.OrderId);
                if (paymentData === undefined) {
                    $(row).addClass('bg-custom-red');
                    console.log(row, data, dataIndex);
                }
                else if (paymentData.ProvisionStatusId != 12) {
                    $(row).addClass('bg-custom-red');
                }
            },

            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            searching: false,
            ordering: false,
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Reports/BankAgreementDetail',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    if (json.data.PagedResponseInfo.TotalItemCount !== "0") {
                        json.recordsFiltered = json.data.PagedResponseInfo.TotalItemCount
                        json.recordsTotal = json.data.PagedResponseInfo.TotalItemCount

                    }
                    else {
                        json.recordsFiltered = 0
                        json.recordsTotal = 0

                    }
                    json.data = json.data.TransactionSearchResultInfo.PaymentTransactionInfo

                    // json.recordsFiltered = json.pagingData.totalCount
                    // json.recordsTotal = json.pagingData.totalCount
                    $(`#bank_Detail_Table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    "StartDate": moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').startOf("day").format('YYYY-MM-DD'),
                    "EndDate": moment($('#detailsLastDate').val(), 'DD/MM/YYYY').endOf("day").format('YYYY-MM-DD')
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                {
                    title: "ID",
                    data: "PaymentTransactionId",
                },
                {
                    title: "Kart No",
                    data: "PanMasked",
                },
                {
                    title: "İşlem Kimliği",
                    data: "TransactionId",
                },
                {
                    title: "Tutar",
                    data: "Amount",
                },
                {
                    title: "İstek Giriş Tarihi",
                    data: "RequestInsertTime",
                    render: function (data, type, row) {
                        return moment(data).format('DD/MM/YYYY HH:mm:ss');
                    },
                },
                {
                    className: "details-control",
                    title: "Ödeme Kontrol",
                    data: "PaymentTransactionId",
                    render: function (data, type, row) {
                        return `<a class="payment_control btn btn-primary btn-sm">Kontrol Et </a>`
                    }

                }


            ],
            lengthMenu: [[5, 10, 20, 50], [5, 10, 20, 50]],
            "pageLength": 10,
        });
        new $.fn.dataTable.Responsive(detailsTable, { details: true, Responsive: true },);
        $('#bank_Detail_Table tbody').on('click', 'td .payment_control', function () {
            var tr = $(this).closest('tr');
            var row = detailsTable.row(tr);
            var data = row.data();
            console.log(data);
            if (data === undefined) {

                row = detailsTable.row(this);
                data = row.data();
            }
            getPaymentData(row.data());
        });

    }

    const filterClick = () => {
        var data = JSON.stringify({
            "per_page": -1,
            "page": 1,
            "Filter": {
                dateRange: [
                    moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
                ]
            }
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Provision',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                paymentLists = response.data.data
                console.log(detailsTable);
                detailsTable.ajax.reload();
            })
    }

    return (
        <>
            <div className="bank-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">

                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="firstDate" ref={sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="lastDate" ref={sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => { bankTable.ajax.reload() }} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")}</h6>
                    </div>



                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="bank-table" className="table-loader dataTable display  compact  w-100">
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">

                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="detailsFirstDate" ref={sonKullanmaTarih3Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="detailsLastDate" ref={sonKullanmaTarih4Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => { filterClick() }} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")} Detay</h6>
                    </div>



                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="bank_Detail_Table" className="table-loader dataTable display  compact  w-100">
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default BankConsensus;

