import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import Cookies from 'universal-cookie';
import { getEndPoint } from "../../class/api";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import $ from 'jquery'
import moment from "moment";
import axios from "axios";
const cookies = new Cookies();

class ZReports extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);
        this.printData = "";
        this.sonKullanmaTarihRef = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
    }
    tableInstall = () => {
        const { t } = this.props
        var _this = this;

        if (this.report != undefined)
            this.report.destroy();

        this.report = $('#zReportTable').DataTable({
            dom: 'Blfrtip',
            order:["1","desc"],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Finance/ReportZ',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#zReportTable`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            "searching": false,
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    dateRange: {
                        "Date": [
                            moment($('#firstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                            moment($('#lastDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
                        ]
                    },
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                {
                    title: t("table.zReportId"),

                    data: "FinanceReportZId", orderable: false
                },
                {
                    title: t("table.zReportDate"),

                    data: 'Date', render: function (data, type, row) {
                        return moment(data).format('DD-MM-YYYY')
                    }
                },
                {
                    title: t("table.zReporMemberCard"),

                    data: "TotalUsedInvoiceNum_SubsCard", render: function (data, type, row) {
                        function currencyFormat(num) {
                            return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        }
                        return currencyFormat(parseFloat(data))
                    },
                },
                {
                    title: t("table.zReportTotalCollection"),

                    data: "TotalMoneyCollected", render: function (data, type, row) {
                        function currencyFormat(num) {
                            return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        }

                        return currencyFormat(parseFloat(data))

                    },
                },
                {
                    title: t("table.zReportCumulativeTotal"),

                    data: "CumulativeSum", render: function (data, type, row) {
                        function currencyFormat(num) {
                            return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        }

                        return currencyFormat(parseFloat(data))

                    }, orderable: false
                },
                {
                    title: t("table.zReportProcess.label"),
                    data: "FinanceReportZId", render: function (data, type, row) {
                        return `<div class="list-icons">
                                    <a id="print" class="btn btn-light" data-toggle="modal" data-target="#zReportModal">
                                        <i class="fa-solid fa-print"></i> ${t("saveChanges")}
                                    </a>
                            </div>`
                    }, orderable: false
                },



            ],
        });
        new $.fn.dataTable.Responsive(this.report, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;
        var _this = this


        DateRangePickerHandler(this.sonKullanmaTarihRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.tableInstall()
        $('#zReportTable tbody').on('click', 'td #print', function () {
            var data = $('#zReportTable').DataTable().row($(this).parents('tr')).data();


            var tr = $(this).closest('tr');
            var row = $('#zReportTable').DataTable().row(tr);
            var data = row.data();
            if (data == undefined) {

                row = $('#zReportTable').DataTable().row(this);
                data = row.data();
            }

            _this.printData = data;
            $('#zReportNumber').text(data.FinanceReportZId)
            $('#reportDate').text(`${data.Date}`)
            $('#shiftStartDate').text(`${data.Date} 00:00:00`)
            $('#shiftFinishDate').text(`${data.Date} 23:59:59`)
        });
    }

    componentDidUpdate = () => this.componentDidMount();
    printClick = () => {
        const { t } = this.props
        var data = JSON.stringify({
            "FinanceReportZId": this.printData.FinanceReportZId
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Finance/ReportZ/Print',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                var data = response.data.data[0]
                var windowContent = `
                <html dir="ltr" lang="tr" class="focus-outline-visible">
                    <head>
                        <meta charset="utf-8">
                            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    
                            </head>
    
                            <body>
                                <strong>
                                    KOCAELİ BÜYÜKŞEHİR BELEDİYESİ
    
                                </strong>
    
                                <hr />
                                <h1 class="display-3 text-center">- Z RAPORU -</h1>
                                <hr />
    
                                <div class="container-fuild">
                                    <div>
                                        <span>
                                            <strong>
                                                İşlem Tarihi :
                                            </strong>
                                           ${moment().format('DD-MM-YYYY HH:MM')}
    
                                        </span>
    
                                    </div>
    
    
    
                                    <div>
                                        <span>
                                            <strong>
                                                Rapor No :
                                            </strong>
                                            ${data.FinanceReportZId}
                                        </span>
                                    </div>
    
    
    
                                    <div>
                                        <span>
                                            <strong>
                                                Vardiya Başlangıç :
                                            </strong>
                                            ${moment(data.Date).format('DD-MM-YYYY')} 00:00:00
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                            <strong>
                                                Vardiya Bitiş :
                                            </strong>
                                            ${moment(data.Date).format('DD-MM-YYYY')} 23:59:59
                                        </span>
                                    </div>
                                    <hr />
    
                                    <div>
                                        <span>
                                            <strong>
                                                Toplam Tahsilat:
                                            </strong>
                                            ${parseFloat(data.TotalMoneyCollected).toFixed(2)}
                                        </span>
                                    </div>
    
                                    <div>
                                        <span>
                                            <strong>
                                                -Toplam KDV(%18):
                                            </strong>
                                            ${parseFloat(data.TotalMoneyCollected_Kdv).toFixed(2)}
                                        </span>
                                    </div>
    
                                    <div>
                                        <span>
                                        Toplanan Toplam Bisiklet Tahsilat :
                                            ${parseFloat(data.TotalBikeMoneyCollected).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplanan Toplam Bisiklet Yedek Tahsilat :
                                        ${parseFloat(data.TotalBikeSubsMoneyCollected).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplanan Toplam Abonelik Kartı Tahsilatı:
                                        ${parseFloat(data.TotalSubsCardMoneyCollected).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplam Talep Edilen Fatura Numarası:
                                        ${parseFloat(data.TotalRequestedInvoiceNum).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplam Kullanılan Fatura Sayısı :
                                        ${parseFloat(data.TotalUsedInvoiceNum).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplam Kullanılan Bisiklet Fatura Sayısı :
                                        ${parseFloat(data.TotalUsedInvoiceNum_Bike).toFixed(2)}
                                        </span>
                                    </div>
    
                                    <div>
                                        <span>
                                        Toplam Kullanılan Yedek Bisiklet Fatura Sayısı:
                                        ${parseFloat(data.TotalUsedInvoiceNum_BikeSubs).toFixed(2)}
                                        </span>
                                    </div>
    
    
                                    <div>
                                        <span>
                                        Toplam Kullanılan Abone Kart Fatura Numarası :
                                        ${parseFloat(data.TotalUsedInvoiceNum_SubsCard).toFixed(2)}
    
                                        </span>
                                    </div>
                                </div>
                            </body>
                        </html>`
                var printWin = window.open('', '', 'width=755,height=1096')
                printWin.document.open()
                printWin.document.write(windowContent)
                printWin.print()
                printWin.addEventListener(
                    'load',
                    function () {
                        printWin.print()
                    },
                    true
                )
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });

    }
    render() {
        const { t } = this.props;

        return (
            <div className="zReport-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("firstDate")} </span>
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="firstDate" ref={this.sonKullanmaTarihRef} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="col-sm-6">
                                <div className="input-group">
                                    <div className="d-block w-100 ">
                                        <span>{t("lastDate")} </span>
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="lastDate" ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => { this.report.ajax.reload() }} type="button" className="btn btn-primary w-100">{t("filter")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="recipe-page p-2">
                    <div className="card">
                        <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                            <h6 className="card-title">{t("zReports")}</h6>

                            <div className="header-elements">
                                <div className="list-icons">
                                    <a onClick={this.tableInstall} className="list-icons-item" data-action="reload"></a>
                                </div>
                            </div>
                        </div>

                        <div className="card-body py-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <table id="zReportTable" className="table-loader dataTable display  compact  w-100">

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="zReportModal" className="modal fade" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title">
                                    {t("modal.modalHead")}
                                </h1>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <h5>{t("modal.modalWarning.warningHead")} </h5>
                                <ul className=" border-x-0 rounded-0">
                                    <li className="list-group-item">
                                        <i className="icon-arrow-right5 "></i>
                                        {t("modal.modalWarning.warningSub1")}
                                    </li>
                                    <li className="list-group-item">
                                        <i className="icon-arrow-right5 "></i>
                                        {t("modal.modalWarning.warningSub2")}
                                    </li>
                                </ul>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2"><strong>{t("modal.zReportNumber")}:</strong></label>
                                    <div className="col-form-label col-lg-10">
                                        <p readOnly disabled id="zReportNumber"> </p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2"><strong>{t("modal.zReportDate")}:</strong></label>
                                    <div className="col-form-label col-lg-10">
                                        <p id="reportDate"></p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2"><strong> {t("modal.zReportStartShiftDate")}:</strong></label>
                                    <div className="col-form-label col-lg-10">
                                        <p id="shiftStartDate">   </p>

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2"><strong>{t("modal.zReportFinishShiftDate")}:</strong></label>
                                    <div className="col-form-label col-lg-10 ">
                                        <p id="shiftFinishDate">    </p>

                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("close")}</button>
                                <button type="button" onClick={this.printClick} className="btn btn-primary">{t("saveChanges")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['zReports', 'translations'])(ZReports);
