import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Division from '../../components/elements/content-division';
import Functions from '../../functions';
import Text from '../../components/elements/text';
import CalendarIcon from "@mui/icons-material/CalendarToday";
import { getEndPoint } from "../../class/api";
import { ReportType } from "../../class/enums";
import axios from 'axios';
import $ from 'jquery'
import Cookies from 'universal-cookie';
import SelectCordinate from '../../components/select-cordinate';
import { ReactSession } from 'react-client-session'

const cookies = new Cookies();
let cordinate;
let zoneList;
class StationZoneDefinitions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            zoneType: ""

        };
        this.maintenanceControlTableRef = React.createRef();
        this.filterDateRange = React.createRef();
        this.regionName = React.createRef();
        this.handleClickRegionAdd = this.handleClickRegionAdd.bind(this);
    }
    cordinateDataFunc(data) {
        cordinate = data;
    }
    componentDidMount() {
        const { t } = this.props;
        let dirAttr = 'ltr';
        const _this = this
        if ($('html') !== undefined)
            dirAttr = $('html').attr('dir');

        if (this.maintenanceTable != null)
            this.maintenanceTable.destroy();

        this.maintenanceTable = $('#regionTable').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            "ordering": false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            searching: false,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Station/Region',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    ReactSession.set('zoneEditData', json.data);
                    $(`#regionTable`).removeClass('table-loader').show()
                    json.recordsFiltered = json.data.length
                    json.recordsTotal = json.data.length
                    zoneList = json.data;
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },

            "columns": [

                { data: "StationRegionId" },
                { data: "Name" },
                {

                    data: "StationRegionId", render: function (data, type, row) {
                        return `<a href="/stations/station-zone-definitions/${row.StationRegionId}" id="editRegion"  class="dropdown-item"><i class="icon-pencil"></i> ${t("table.operations.edit")}</a>`
                    }
                },

            ],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
        });
        new $.fn.dataTable.Responsive(this.maintenanceTable, { details: true, Responsive: true },);

    }
    handleClickRegionAdd(e) {
        const _this = this;
        const { t } = this.props;
        var data = JSON.stringify({
            "Name": this.regionName.current.value,
            "Coordinate": String(cordinate[0].lat + ' , ' + cordinate[0].lng)
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Station/Region',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <Division className="system-maintenance-page p-2">
                <Division className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("menu.system.bicycleStatusDefinitions", { ns: "translations" })}</Text>
                    </Division>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <button type="button" className="btn btn-primary mb-2" data-target="#station-add-zone" data-toggle="modal">{t("addZone")}</button>
                                <table id="regionTable" ref={this.maintenanceControlTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th className="w-25">{t("table.id")}</th>
                                            <th className="w-25">{t("table.name")}</th>
                                            <th className="w-50">{t("table.operations.operations")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </Division>
                        </Division>
                    </Division>
                </Division>

                <div id="station-add-zone" className="modal fade" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modal.addStationZone")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <input type="text" className='form-control' ref={this.regionName} placeholder={t("modal.zoneName")} />
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        <SelectCordinate cordinate={this.cordinateDataFunc} />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button type="button" onClick={this.handleClickRegionAdd} className="btn btn-primary" data-dismiss="modal">{t("modal.add")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Division>
        );
    }
}

export default withTranslation(["systemStationZoneDefinitions", "translations"])(StationZoneDefinitions);