import i18n from './i18n/i18n';
import { ReactSession } from 'react-client-session';
// import Functions from "./class/functions";
// import {createProxyMiddleware} from "http-proxy-middleware";
// import {express} from "express";
ReactSession.setStoreType("localStorage");
import axios, { Axios } from "axios";
ReactSession.set("csrf-token", null);
require("./css/all.min.css");

i18n.changeLanguage(ReactSession.get("selectedLanguage"));
require("./css/index.css");

export const config = {
    imgUri: 'https://new.cloudfront.net', //Product
    testStatus: false,
    testUserName:"Test@ferofen.com",
    testPassword:"123456",
    endPointTest:
    {
        // url: 'http://apiv9x.kobis.com.tr',
        url: 'https://apiv9x.kobis.com.tr',
        reCaptcha: "6Lc_",
        uploadsImgUri: 'http://localhost:1881/uploads/',
    },
    endPointProduction: {
        url: 'https://test.com',
        reCaptcha: "6Lc_",
        uploadsImgUri: 'http://localhost:1881/uploads/',
    },
    endPointStage: {
        // url: 'http://apiv9x.kobis.com.tr',
        url: 'https://apiv9x.kobis.com.tr',
        reCaptcha: '6LfPHSw',
        // uploadsImgUri: 'http://apiv9x.kobis.com.tr/',
        uploadsImgUri: 'https://apiv9x.kobis.com.tr/',
    },
    explorerEndPointTx: 'https://xxx/tx/',
    explorerEndPointAsset: 'https://xxx/asset/',
}