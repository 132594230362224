import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { withTranslation } from "react-i18next";
import { ReactSession } from 'react-client-session';
import swalInit from 'sweetalert2/dist/sweetalert2.all';
import Functions from "../class/functions";
import '../css/app.scss'
import { getEndPoint, getTestStatus, getTestUser, getTestUserPassword } from "../class/api";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();

        this.onSignInClicked = this.onSignInClicked.bind(this);
        this.testLogin = this.testLogin.bind(this);
    }

    state = {
        //url : "http://ferofenbike.vogu.com.tr" ,
        url: "http://bikev9x.ferofen.com",
        //url : "http://localhost:8084" ,
        xcsrf: "TESTxcrf"
    };

    componentDidMount() {
        document.getElementById("page-header").remove();
        document.getElementById("navigation").remove();
        document.getElementById("sidebar").parentElement.remove();
    }

    testLogin(e) {
        e.preventDefault();
        document.getElementById('username').value = getTestUser()
        document.getElementById('password').value = getTestUserPassword()
    }
    onSignInClicked = (e) => {
        e.preventDefault();
        const { t } = this.props
        if (this.usernameRef == null || this.passwordRef == null || this.usernameRef.current == null || this.passwordRef.current == null)
            return;


        axios.get(getEndPoint() + "/spa/csrf-cookie", { withCredentials: true })
            .then(
                function (response, cookies) {
                    return response.config.headers['X-XSRF-TOKEN']

                }).then((resXcsrf) => {



                    var data = JSON.stringify({
                        "Email": this.usernameRef.current.value,
                        "password": this.passwordRef.current.value,
                        "Remember": true
                    });

                    var config = {
                        method: 'post',
                        url: getEndPoint() + "/login",
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        data: data,
                        withCredentials: true
                    };

                    axios(config)
                        .then(function (response) {

                            cookies.set('auth', 'true', { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 2) });
                            ReactSession.set('auth', 'true')
                            ReactSession.set('user', response.data)
                            ReactSession.set('userData', response.data.Name + ' ' + response.data.Surname)
                            var configVehicleTypes = {
                                method: 'put',
                                url: getEndPoint() + "/api/Admin/Station/Vehicle/Types",
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            };

                            var configStation = {
                                method: 'put',
                                url: getEndPoint() + '/api/Admin/Station',
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    "Accept": "application/json",
                                    "Content-Type": "application/json"
                                },
                                withCredentials: true,
                                data: JSON.stringify({
                                    "per_page": -1,
                                    "Filter": {
                                        "order": "Order"
                                    }
                                })

                            };
                            var configMemberTypes = {
                                method: 'put',
                                url: getEndPoint() + "/api/Admin/Member/Types",
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            };
                            var faultStatues = {
                                method: 'put',
                                url: getEndPoint() + "/api/Admin/Maintenance/Statuses",
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                data: JSON.stringify({
                                    "Filter": {

                                        "ParentId": 0
                                    }
                                })
                            };
                            swalInit.fire({
                                toast: true,
                                position: 'top-right',
                                title: t("swal.success.successMessage", { ns: "translations" }),
                                text: t("swal.success.loginMessage", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" }),
                                showConfirmButton: false,
                                timer: 9000,
                                didOpen: (() => {
                                    axios.all(
                                        [
                                            axios(configVehicleTypes),
                                            axios(configStation),
                                            axios(configMemberTypes),
                                            axios(faultStatues)
                                        ])
                                        .then(axios.spread((bikeTypes, stationList, memberTypes, faultStatues) => {
                                            new Promise(function (resolve, reject) {

                                                ReactSession.set('stationList', stationList.data.data)
                                                ReactSession.set('bikeTypes', bikeTypes.data.data)
                                                ReactSession.set('memberTypes', memberTypes.data.data)
                                                ReactSession.set('faultStatues', faultStatues.data.data)
                                                resolve(true)
                                            }).then((result) => { // (**)
                                                if (result) {
                                                    window.location.href = "/";

                                                }
                                            })

                                        }));
                                })

                            })

                        })
                        .catch((error) => {
                            cookies.set('auth', 'null', { path: '/' });

                            swalInit.fire({
                                title: t("swal.error.head", { ns: "translations" }),
                                text: t("swal.error.subject", { ns: "translations" }) + error.message,
                                icon: t("swal.error.icon", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                padding: 40
                            });

                        });
                    // .catch (function (error) {
                    // cookies.set('auth', 'null', { path: '/' });
                    // swalInit.fire({
                    //     title: 'Giriş Yapılamadı',
                    //     text: 'Bilgilerin Doğru Olduğundan Eminseniz Lütfen Sistem Yöneticisi İle Görüşün.',
                    //     icon: 'error',
                    //     padding: 40
                    // });
                });


    }
    render() {
        const { t } = this.props;

        const bikeImage = require("../img/bike.png");
        const kobisLogoImage = require("../img/kobis_logo.png");
        return (

            <>
                <div className="page-content" >

                    <div className="content-wrapper">

                        <div className="content-inner">

                            <div className="content d-flex justify-content-center align-items-center">

                                <form className="login-form">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            <div className="text-center mb-3">
                                                <img width={"100%"} src={kobisLogoImage} />
                                            </div>

                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                <input ref={this.usernameRef} type="text" id="username" className="form-control" placeholder={t("userName")} />
                                                <div className="form-control-feedback">
                                                    <i className="icon-user text-muted"></i>
                                                </div>
                                            </div>

                                            <div className="form-group form-group-feedback form-group-feedback-left">
                                                <input ref={this.passwordRef} type="password" id="password" className="form-control" placeholder={t("password")} />
                                                <div className="form-control-feedback">
                                                    <i className="icon-lock2 text-muted"></i>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <button onClick={(e) => this.onSignInClicked(e)} type="submit" id="logInBTN" className="btn btn-primary btn-block">{t("signIn")}</button>
                                            </div>
                                            {/* <div className="text-center">
                                                <a href="login_password_recover.html">{t("forgotPassword")}</a>
                                            </div> */}
                                            {getTestStatus() == true ? < div className="text-center mt-2">

                                                <a type="button" className="btn  btn-success" onClick={this.testLogin}>
                                                    <p>
                                                        Test kullanıcısı getir
                                                    </p>
                                                </a>
                                            </div> : ""
                                            }

                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                </div>
            </>

        )
    }
}
export default withTranslation(['Login', 'translations'])(Login);