import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { getEndPoint } from "../../class/api";
import $ from 'jquery'
import Text from '../../components/elements/text';
import Division from '../../components/elements/content-division';
import UnorderedList, { ListItem } from '../../components/elements/unordered-list';
import Button from '../../components/elements/button';
import Anchor from '../../components/elements/anchor';
import { ReactSession } from "react-client-session";
import Cookies from 'universal-cookie';
import Functions from '../../class/functions';
const cookies = new Cookies();

class EventNotificationDefinitionsList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { t } = this.props;
        const selectLanguage = {
            noResUnorderedListts: () => t("select2Localization.noResUnorderedListts", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        if (this.eventTable != undefined)
            this.eventTable.destroy();
        this.eventTable = $('#event-table').DataTable({
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            serverSide: true,
            order: [0, 'ASC'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/NotificationEventDefinition',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#event-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                };

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "NotificationEventDefinitionId", "orderable": true },
                { data: "ModuleName", "orderable": true },
                { data: "ModuleCode", "orderable": true },
                { data: "Description", "orderable": false },
                {
                    className: "operation",
                    data: "NotificationEventDefinitionId", render: function (data, type, row) {
                        var test1 = `${Functions.RoleControl(36, 2) ?
                            ` <div class="list-icons">
                                <a href="/management/event-notification-definitions/${data}" type="button" class=" dropdown-item">
                                    <i class="icon-pencil"> </i>${t('edit')}
                                </a>
                            </div>`
                            : ""} `
                        return test1
                    }, "orderable": false
                },
            ],
        });
        new $.fn.dataTable.Responsive(this.eventTable, { details: true, Responsive: true },);

    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="user-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.management.eventNotificationDefinitions", { ns: "translations" })}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={() => {
                                    this.componentDidMount()
                                }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12 pt-2">
                                <table id="event-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.moduleName")}</th>
                                            <th>{t("table.moduleCode")}</th>
                                            <th>{t("table.moduleDesc")}</th>
                                            <th>{t("table.operation")}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation(["managementEventNotificationDefinitions", "translations"])(EventNotificationDefinitionsList);