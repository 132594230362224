import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import $ from 'jquery'
import DatePicker from "../../components/elements/DatePicker";
import moment from "moment";
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";

class TopCyclists extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
        this.filterClick = this.filterClick.bind(this);
    }
    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    componentDidMount() {
        const { t } = this.props;
        const comp = this

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            format: 'yyyy-mm-dd',
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.tableInstall()

    }
    tableInstall = () => {
        const { t } = this.props
        const _this = this;
        if (this.topBikes != undefined) {
            this.topBikes.destroy();
            $('#cyclists-table').empty();
        }
        this.topBikes = $('#cyclists-table').DataTable({
            dom: 'Blfrtip',
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            order: [2, 'desc'],
            searching: false,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Member/MostUse',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    $(`#cyclists-table`).removeClass('table-loader').show()
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    sort: aoData.order[0].dir,
                    order: aoData.columns[aoData.order[0].column].data,
                    dateRange: [
                        Functions.getFormatDBDateHours($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours),
                        Functions.getFormatDBDateHours($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours)
                    ]

                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                {
                    title: t("table.memberID"),
                    data: "MemberId", orderable: false
                },
                {
                    title: t("table.nameSurname"),

                    data: 'member',
                    render: function (data, type, row) {
                        return `<a href="${Functions.RoleControl(11,4)==true?`/users/edit/${data.user.id}`:"#"}" > ${data.user.Name} ${data.user.Surname} </a>`
                    }, orderable: false
                },
                { title: t("table.numberOfRentals"), data: "RentCount" },
                { title: t("table.totalTime"), data: "TotalDuration" },
                {
                    title: t("table.totalPrice"),
                    data: "Fee", render: function (data, type, row) {
                        var price = parseFloat(row.Fee).toFixed(2)
                        return price
                    }
                },

            ],
        });
        new $.fn.dataTable.Responsive(this.topBikes, { details: true, Responsive: true },);
    }
    componentDidUpdate = () => this.componentDidMount();
    filterClick() {
        this.componentDidMount();
    }
    render() {
        const { t } = this.props;

        return (
            <div className="top-page p-2">
                <div className="card mb-1">
                    <div className="card-body py-2">
                        <div className="row">

                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} data-date-format="dd/mm/yy" type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={this.filterClick} type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.tableHead")}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={this.tableInstall} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="cyclists-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['TopCyclists', 'translations'])(TopCyclists);

function getFormatDBDate(date) {
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}