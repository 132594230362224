import React, { Component } from 'react';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
class DatePicker extends Component {
    constructor(props) {
        super(props);
    }
    handleValueChange = value => {
        this.setState({ value });
        this.props.parentValueChange(value && value.format('HH:mm:ss'), this.props.type)
    };

    clear = () => {
        this.setState({
            value: undefined,
        });
    };

    render() {
      
        return (
            <>
                <TimePicker  defaultValue={this.props.defaultValue} onChange={this.handleValueChange} />
            </>
        );
    }
}

export default DatePicker