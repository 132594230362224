import * as $ from "jquery";
import axios, { Axios } from "axios";
import { ReactSession } from 'react-client-session';
import { useParams, useLocation, useNavigate } from "react-router";
import { Redirect } from 'react-router-dom'
import { getEndPoint } from './api'
import enums from "../class/enum.json";
import Cookies from 'universal-cookie'
import { useTranslation, withTranslation } from "react-i18next";
const cookies = new Cookies();

axios.defaults.withCredentials = true;
var url = "http://bikev9x.ferofen.com";

var xcsrf = "";
let translate;
export default class Functions {
    static getFormatDBDateHours(data) {

        if (data == "") {
            return ""
        }

        if (data == undefined) {
            return ""
        }
        const [dateComponents, timeComponents] = data.split(' ');

        const [day, month, year] = dateComponents.split('/');
        const [hours, minutes, seconds] = timeComponents.split(':');
        var newDate = `${year}-${month}-${day} ${hours}:${minutes} `
        return `${newDate}`
    }
    static getFormatDBDate(data) {
        if (data == "") {
            return ""
        }
        if (data == undefined) {
            return ""
        }
        var newDate = data.toISOString
        var newDate = data.toString().split('/')
        var d = new Date(`${newDate[1]}/${newDate[0]}/${newDate[2]}`),
            year = d.getFullYear(),

            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    static MaskCharacter(str, mask, n = 1, s = 0) {
        if (ReactSession.get('user').id != 159) {
            return str
        }
        var masked = str.substring(0, 3).replace(/./g, "*") +
            str.substring(3, str.length - 3) +
            str.substring(0, 3).replace(/./g, "*")
        return masked;

    }
    
    static requestAxiosErrorFunction(error) {
        try {
            error = error.toJSON()
          }
          catch(err) {
            error.status = 500 ; 
          }

        
        if (error.status == 401) {
            cookies.remove('auth');
            window.location.href = "/"
        }
        else if (error.status == 419) {
            cookies.remove('auth');
            window.location.href = "/"
        }
        else if (error.status == 412) {
            swal.fire({
                title: 'HATA!',
                text: "Eksik bilgi girdiniz",
                confirmButtonText: 'Onayla',
                icon: 'error'
            })
        }
        else {
            swal.fire({
                title: 'HATA!',
                text: `İşlem Sırasında Bir Hata Oluştu. ${error?.message}`,
                confirmButtonText: 'Onayla',
                icon: 'error'
            })
        }
    }

    static requestAjaxErrorFunction(xhr, error, code) {
        if (xhr.status == 401) {
            cookies.remove('auth');
            window.location.href = "/"
        }
        else if (xhr.status == 419) {
            cookies.remove('auth');
            window.location.href = "/"
        }
        else if (error.status == 412) {
            swal.fire({
                title: 'HATA!',
                text: "Eksik bilgi girdiniz",
                confirmButtonText: 'Onayla',
                icon: 'error'
            })
        }
        else {
            swal.fire({
                title: 'HATA!',
                text: `İşlem Sırasında Bir Hata Oluştu. ${xhr?.responseJSON?.message}`,
                confirmButtonText: 'Onayla',
                icon: 'error'
            })
        }

    }
    static getFormatLocaleDate(data) {

        if (data == "") {
            return ""
        }

        if (data == undefined) {
            return ""
        }
        const [dateComponents, timeComponents] = data.split(' ');

        const [day, month, year] = dateComponents.split('-');
        var newDate = `${year}/${parseInt(month)}/${day}`
        return `${newDate}`
    }
    static getFormatLocaleDateHours(data) {

        if (data == "") {
            return ""
        }

        if (data == undefined) {
            return ""
        }
        const [dateComponents, timeComponents] = data.split(' ');


        const [day, month, year] = dateComponents.split('-');
        const [hours, minutes, seconds] = timeComponents.split(':');

        const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
        var newDate = `${year}-${month}-${day} ${hours}:${minutes} `
        return `${newDate}`
    }
    static phoneControl(phoneNumber, country) {
        let data = String(phoneNumber);
        let replaceData = data.replace('+' + country.dialCode, '')
        replaceData = replaceData.replace(/\s+/g, '');
        return String(replaceData);
    }
    static stationData() {
        var configStation = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            withCredentials: true,
            data: JSON.stringify({
                "per_page": -1,
                "Filter": {
                    "order": "Order",
                    "sort": "asc"
                }
            })

        };

        var promise = new Promise(function (resolve, reject) {
            var data = "";
            axios(configStation)
                .then(function (response) {
                    data = response.data.data;
                    ReactSession.set('stationList', response.data.data)
                    resolve(response.data.data)
                })
        })
        return promise
    }
    static profileCheck() {

        var config = {
            method: 'get',
            url: getEndPoint() + "/api/Admin/profile",
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then(function (response) {
                ReactSession.set('user', response.data.data[0])

            })
    }
    static memberTypes() {

        var configMemberTypes = {
            method: 'put',
            url: getEndPoint() + "/api/Admin/Member/Types",
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        axios(configMemberTypes).then(function (response) {
            ReactSession.set('memberTypes', response.data.data)

        })

    }
    static faultStatues() {

        var faultStatues = {
            method: 'put',
            url: getEndPoint() + "/api/Admin/Maintenance/Statuses",
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        axios(faultStatues).then(function (response) {
            ReactSession.set('faultStatues', faultStatues.data.data)

        })

    }
    static bikeTypes() {

        var configVehicleTypes = {
            method: 'put',
            url: getEndPoint() + "/api/Admin/Station/Vehicle/Types",
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
        axios(faultStatues).then(function (response) {
            ReactSession.set('faultStatues', faultStatues.data.data)

        })

    }

    static apiControl() {
        return axios.create({
            baseURL: getEndPoint() + '',
            headers: {
                'X-XSRF-TOKEN': 'TESTxcrf',
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            withCredentials: true

        });
    }
    static utcConvert(data) {
        var date = new Date(data);
        var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString();
        return utc;
    }

    static RoleControl(controlAbility, abilityProccess) {
        var userRole = ReactSession.get('user').user_auths.find(x => x.AbilitiyId == controlAbility);

        if (userRole === undefined) {
            return false
        }
        if (abilityProccess === undefined)
            return true

        var process = String(userRole.Auth).split("")
        if (process[Number(abilityProccess)] == "1") {
            return true
        }
        else {
            false
        }
        return false
    }

    static authControl() {

        axios.get("http://bikev9x.ferofen.com/api/profile", {
            headers: {
                'X-XSRF-TOKEN': 'TESTxcrf',
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            withCredentials: true
        })
            .then(function (response) {
                return '200';
            })
            .catch(function (error) {
                return '401';
            });

    }
}
