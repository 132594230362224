import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getEndPoint } from "../class/api";
import Cookies from 'universal-cookie';
import Functions from '../class/functions';
const cookies = new Cookies();


export const Select = (props) => {

    const { t, i18n } = useTranslation(["translations"]);


    useEffect(() => {

        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $(`#${props.selectID}`).select2({
            language: selectLanguage,
            minimumInputLength: props.ajaxSearch == undefined ? false : 1,
            minimumResultsForSearch: props.ajaxSearch == undefined ? false : 1,
            ajax: {
                method: 'GET',
                delay: 1500,//YAZMA İŞLEMİNİ BİTİRMESİNİ BEKLEMEK İÇİN GEREKEN SÜRE (MILISECOND)
                url: `${getEndPoint()}${props.ajaxURL}`,
                query: function (query) {
                    self = this;
                    var key = query.term;
                    var cachedData = self.cacheDataSource[key];
                    if (cachedData) {
                        query.callback({ results: cachedData.result });
                        return;
                    }
                },
                data: function (params) {
                    var query = {
                        Filter: {
                            q: params.term,
                        },
                        page: params.page,
                        per_page: 15,
                    }
                    return query;
                },
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                dataType: 'json',

                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {

                        results: $.map(data.data, function (item) {
                            var textData = "";
                            var arr = new Array(item);
                            for (Object.key in props.result) {
                                textData += `${arr[0][props.result[Object.key]]} `
                            }
                            for (Object.key in props.resultV2) {
                                textData += `${props.resultV2[Object.key].tl}${arr[0][props.resultV2[Object.key].key]}${props.resultV2[Object.key].tr} `
                            }
                            return {
                                text: textData,
                                id: arr[0][Object.values(Object.assign({}, props.resultID))]
                            }
                        }),
                        pagination: {
                            more: (params.page * 1) < data.pagingData.pageSize
                        }
                    };
                },
            },

        });


    });
    const onRemoveClick = (e) => {
        $(`#${props.selectID}`).val(null).trigger('change');
        $(`#${props.selectID}`).val(props.defaultValues != undefined ? "-1" : "0").trigger('change');

    }
    return (
        <>
            <div className='d-flex'>

                <div className='mx-auto'>
                    <a href="#" onClick={(e) => onRemoveClick()}>
                        <span className="mr-2 text-danger">
                            <i className="fas fa-window-close"></i>
                        </span>
                    </a>
                </div>

                <select id={`${props.selectID}`} defaultValue={props.defaultValues != undefined ? "-1" : "0"} className={`form-control ${props.customCss}`}  >
                    <option value="0">  {t("searchDefaultText", { ns: "translations" })} </option>
                    <option value="-1">  {t("selectDefaultValue", { ns: "translations" })} </option>
                </select>
            </div>

        </>
    )
}
