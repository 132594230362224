import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import {getEndPoint} from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import { ReactSession } from 'react-client-session';
import Functions from "../../functions";
import moment from "moment";

class GiroUsageSummaryReport extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.subIncomeTableRef = React.createRef(null);
        this.subSumTableRef = React.createRef(null);
        this.rentalTableRef = React.createRef(null);
        this.bicycleTableRef = React.createRef(null);
        this.poolTableRef = React.createRef(null);
        this.subTableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
    }
    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }

    componentDidMount() {
        const { t } = this.props;

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });


        this.processDataTable(this.subIncomeTableRef, "#sub-income-table");
        this.processDataTable(this.subSumTableRef, "#sub-sum-table");
        this.processDataTable(this.rentalTableRef, "#rental-table");
        this.processDataTable(this.bicycleTableRef, "#bicycle-table");
        this.processDataTable(this.poolTableRef, "#pool-table");
        this.processDataTable(this.subTableRef, "#sub-table");
    }

    componentDidUpdate = () => this.componentDidMount();


    render() {
        const { t } = this.props;

        return (
            <div className="subs-page p-2">
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("endDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button type="button" className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table1.head1")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="sub-income-table" ref={this.subIncomeTableRef} className="dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("operationType")}</th>
                                            <th>{t("number")}</th>
                                            <th>{t("amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table1.head2")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="sub-sum-table" ref={this.subSumTableRef} className="dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("operationType")}</th>
                                            <th>{t("number")}</th>
                                            <th>{t("amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table2.head")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="rental-income-table" ref={this.rentalTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("operationType")}</th>
                                            <th>{t("number")}</th>
                                            <th>{t("table2.provisioningExpense")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table3.head")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="bicycle-table" ref={this.bicycleTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table3.name")}</th>
                                            <th>{t("number")}</th>
                                            <th>{t("table3.totalTime")}</th>
                                            <th>{t("table3.price")}</th>
                                            <th>{t("table3.freeTime")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table4.head")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="pool-table" ref={this.poolTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table4.date")}</th>
                                            <th>{t("table4.poolNow")}</th>
                                            <th>{t("table4.uploaded")}</th>
                                            <th>{t("table4.usage")}</th>
                                            <th>{t("table4.head")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table5.head")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="sub-table" ref={this.subTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table5.previousStandard")}</th>
                                            <th>{t("table5.previousYear")}</th>
                                            <th>{t("table5.passiveSubscribers")}</th>
                                            <th>{t("table5.activeSubscriberStandard")}</th>
                                            <th>{t("table5.activeSubscriptionPerYear")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

                <script src="js/plugins/ui/moment/moment.min.js"></script>
                <script src="js/plugins/pickers/daterangepicker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.date.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.time.js"></script>
                <script src="js/plugins/pickers/pickadate/legacy.js"></script>
                <script src="js/plugins/notifications/jgrowl.min.js"></script>
            </div>
        )
    }
}

export default withTranslation(['GiroUsageSummaryReport', 'translations'])(GiroUsageSummaryReport);
