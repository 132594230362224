import React, { useState } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import TableData from "../../components/table.js";
import TableDataV2 from "../../components/tableV2.js";
import { ReactSession } from "react-client-session";
ReactSession.setStoreType("localStorage");
import Anchor from "../../components/elements/anchor.js";
import Division from "../../components/elements/content-division";
// import Map from '../../components/map.js';
import MapLine from "../../components/map-line";
import Text from "../../components/elements/text";
import Button from "../../components/elements/button";
import Functions from "../../class/functions";
import swal, { Swal } from "../../js/plugins/notifications/sweet_alert.min.js";
import axios from "axios";
import Cookies from "universal-cookie";
import jq from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons/js/buttons.print";
import "bootstrap/dist/js/bootstrap";
import jsZip from "jszip";
import "datatables.net-buttons/js/buttons.colVis.min";
import "datatables.net-buttons/js/dataTables.buttons.min";
import "datatables.net-buttons/js/buttons.flash.min";
import "datatables.net-buttons/js/buttons.html5.min";
import {
  DeviceChargeType,
  DeviceConnectionType,
  DeviceQueryType,
  DeviceStateType,
} from "../../class/enums";
import swalInit from "../../js/plugins/notifications/sweet_alert.min.js";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import DatePicker from "../../components/elements/DatePicker";
import { Select } from "../../components/Select";
import moment from "moment";
window.JSZip = jsZip;
import { getEndPoint } from "../../class/api";
let stationList = [];
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";

class GpsHistoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
      gpsListFilter: [
        {
          order: "_id",
          sort: "DESC",
          imei: "0101010101",
        },
      ],
    };
    const { t } = this.props;

    // const [gpsListFilterv1, setGpsListFilterv1] = useState(  {}  );

    this.bicycleTable = null;

    this.bikeGpsSelect = React.createRef();
    this.gpsHandleClickBike = this.gpsHandleClickBike.bind(this);

    const onItemClick = (value) => {
      alert("onItemClick");
    };

    this.sarjtip = [
      "Şarj olmuyor",
      "Dinamodan şarj ediliyor",
      "Parktan şarj ediliyor",
    ];
    this.statetip = ["Duruyor", "Kullanımda", "Taşınıyor", "Uyudu", "Uyandı"];
    this.gpsListColumn = [
      // {
      //     title: t("İstasyon Numarası"),
      //     data: "Number"
      // },
      // {

      //     title: t("table.passive.stations"),
      //     data: "Order", render: function (data, type, row) {
      //         return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${row.Name} </a>`
      //     }

      // },

      { title: t("IMEI"), data: "imei" },

      {
        title: t("Cihaz Tarihi"),
        data: "device_date",
        render: function (data) {
          return moment(data).format("YYYY-MM-DD HH:mm:ss");
        },
        orderable: false,
      },
      {
        title: t("Sistem Tarihi"),
        data: "sys_date",
        render: function (data) {
          return moment(data).format("YYYY-MM-DD HH:mm:ss");
        },
        orderable: false,
      },

      // { title: t("Cihaz Tarihi"), data: "device_date" },
      // { title: t("Sistem Tarihi"), data: "sys_date" },
      { title: t("Lat"), data: "lat" },
      { title: t("Lon"), data: "lon" },
      { title: t("Hız"), data: "hiz" },
      { title: t("Yön"), data: "yon" },
      { title: t("Uydu Sayısı"), data: "uydusayisi" },
      { title: t("Gsm Sinyal"), data: "gsmsinyal" },

      {
        title: t("pilyuzde "),
        data: "pilyuzde",
        render: function (data, type, row) {
          var pilyuzde = "";
          if (row.fark < 15) {
            pilyuzde =
              '<span style="background:url(/img/battery' +
              (row.sarjtip > 0 ? "1" : "0") +
              ".gif)\" class='battery'>%" +
              data +
              "</span>";
          } else {
            pilyuzde = "%" + data;
          }

          return pilyuzde;
        },
        orderable: false,
      },
      /*
 
            {title: t("Durum"),  data: "statetip", render: function (data, type, row)   {
                    var statetip = "";
                    switch (data) {
                        case 0:
                            statetip = "Duruyor"; 
                            break;
                        case 1:
                            statetip = "Kullanımda"; 
                            break;
                        case 2:
                            statetip = "Taşınıyor"; 
                            break;
                        case 3:
                            statetip = "Uyudu"; 
                            break;
                        case 4:
                            statetip = "Uyandı"; 
                            break;
                    
                        default:
                            statetip = "-"; 
                            break;  
                    }  
                    return statetip
                }, "orderable": false
            }, 
            */
    ];

    // this.gpsListFilter["Filter"]["sarjtip"] = "-1"
    // this.gpsListFilter["Filter"]["statetip"] = "-1"
    // this.gpsListFilter["Filter"]["bagli"] = "-1"
    // this.gpsListFilter["Filter"]["bolge"] = ""
    // this.gpsListFilter["Filter"]["sorgu"] = ""

    // this.gpsListFilter = [
    //     {
    //         "order": "_id",
    //         "sort": "DESC"
    //     }
    // ]

    const moment = require("moment");

    const myDate = moment.now();
    const newDate = moment(myDate)
      .add(-6, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

    // this.filterMostRentedFilter = [
    //     {
    //         "EntryDate": moment().startOf('month').format('YYYY-MM-DD HH:MM'),
    //         "ExitDate": moment().endOf('month').format('YYYY-MM-DD HH:MM'),
    //     }
    // ]

    this.modalBicycleRef = React.createRef();
    this.modalBicycleHandleSubmit = this.modalBicycleHandleSubmit.bind(this);

    this.sonKullanmaTarih1Ref = React.createRef(null);
    this.sonKullanmaTarih2Ref = React.createRef(null);
  }
  gpsHandleClickBike() {
    const { t } = this.props;
    const newFilter = [
      {
        order: "_id",
        sort: "DESC",
        imei: jq("#vehicleSelect").val(),
        sys_date: [
          moment(
            $(this.sonKullanmaTarih1Ref.current).val() + " " + firstDateHours,
            "DD/MM/YYYY HH:mm"
          ).format("YYYY/MM/DD HH:mm:ss"),
          moment(
            $(this.sonKullanmaTarih2Ref.current).val() + " " + lastDateHours,
            "DD/MM/YYYY HH:mm"
          ).format("YYYY/MM/DD HH:mm:ss"),
        ],
      },
    ];

    this.setState(
      {
        gpsListFilter: newFilter,
        gpsListFilterKey: new Date().getTime(),
      }
    );
  }

  // gpsHandleClickBike() {
  //     const _this = this;
  //     const { t } = this.props

  //     this.setState({
  //         gpsListFilter : [
  //             {
  //                 "order": "_id",
  //                 "sort": "DESC",
  //                 "imei" : jq('#vehicleSelect').val() ,
  //                 "sys_date": [
  //                     moment($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
  //                     moment($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss')
  //                 ],
  //             }
  //         ]
  //     })
  //     // setGpsListFilterv1 (
  //     //     [
  //     //         {
  //     //             "order": "_id",
  //     //             "sort": "DESC",
  //     //             "imei" : jq('#vehicleSelect').val() ,
  //     //             "sys_date": [
  //     //                 moment($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
  //     //                 moment($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss')
  //     //             ],
  //     //         }
  //     //     ]
  //     // )
  //     this.reRender() ;
  //     // swalInit.fire('HATA!', 'Tabloda veri görünyüleyemezseniz Lütfen tablo yenileme butonuna basın ve harita üzerine 1 defa tıklayın. ', 'error');
  // }
  componentDidMount() {
    const { t } = this.props;
    //  swalInit.fire('hata', 'GPS verisi veri sağlayıcısından getirilemedi', 'warning')
    var _this = this;
    const selectLanguage = {
      noResults: () =>
        t("select2Localization.noResults", { ns: "translations" }),
      searching: () =>
        t("select2Localization.searching", { ns: "translations" }),
      maximumSelected: () =>
        t("select2Localization.maximumSelected", { ns: "translations" }),
      loadingMore: () =>
        t("select2Localization.loadingMore", { ns: "translations" }),
      inputTooShort: () =>
        t("select2Localization.inputTooShort", { ns: "translations" }),
      inputTooLong: () =>
        t("select2Localization.inputTooLong", { ns: "translations" }),
      errorLoading: () =>
        t("select2Localization.errorLoading", { ns: "translations" }),
    };
    $(".selectStation").select2({
      language: selectLanguage,
    });
    stationList = ReactSession.get("stationList");

    DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,

      locale: t("dateRangePickerLocalization", { ns: "translations" }),
      autoApply: true,
      // startDate: moment('2023/01/01','YYYY/MM/DD').format('DD/MM/YYYY'),
      ranges: {
        Bugün: [moment(), moment()],
      },
    });

    DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
      parentEl: ".content-inner",
      showDropdowns: true,
      singleDatePicker: true,
      locale: t("dateRangePickerLocalization", { ns: "translations" }),
      autoApply: true,
      ranges: {
        Bugün: [moment(), moment()],
      },
    });

    jq("#parkingoccupancy-table2 tbody").on(
      "click",
      ".cikisstatus",
      function () {
        //action delete butonu

        var data = {
          module_id: "" + jq(this).attr("data-module-id") + "",
          channelEx: 1,
          channel: Number.parseInt(jq(this).attr("data-channel")),
          desired_status: Number.parseInt(
            jq(this).attr("data-module-new-status")
          ),
        };
        var config = {
          method: "POST",
          url: getEndPoint() + "/api/Admin/OtherModules/EnergyTracking",
          headers: {
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };

        if (confirm(jq(this).attr("data-uyari-mesaji")) == true) {
          axios(config)
            .then(function (response) {
              if (response.data.result.status == "OK") {
                alert("işlem başarılı ");
                jq(
                  "#tab-1 > div > div.col-12 > div > div.card-custom-color.card-header.header-elements-inline > div > div > a"
                ).click();
                _this.bicycleTable.ajax.reload();
              } else {
                alert("Hata oluştu lütfen terkar deneyin");
              }

              Swal.fire({
                position: "top-end",
                icon: "success",
                title: t("swal.success.successMessage", { ns: "translations" }),
                showConfirmButton: false,
                timer: 1500,
              }).then(function () {
                // $("#bicycle-edit .close").trigger("click");
                // _this.bicycleTable.ajax.reload()
              });
            })
            .catch(function (error) {
              console.log("_________________");
              console.log(error);
              // Functions.requestAxiosErrorFunction(error);
            });
        }
      }
    );

    $("#bicycle-gps-table tbody").on("click", "td .vehicleNo", function () {
      var tr = $(this).closest("tr");
      // var row = _this.bicycleTable.row(tr);
      // var data = row.data();
      // if (data == undefined) {

      //     row = _this.bicycleTable.row(this);
      //     data = row.data();
      // }
      // ReactSession.set('bikeDetails', data);
      window.location.href = "/bicycles/bicycle-movements";
    });
  }

  componentDidUpdate = () => {
    this.componentDidMount();
  };
  resetSubmitHandler = (e) => {
    e.preventDefault();
    const _this = this;
    const { t } = this.props;

    // { ns: "translations" }
  };

  modalBicycleHandleSubmit(e) {
    e.preventDefault();

    const _this = this;
    const { t } = this.props;

    Swal.fire({
      title: t("swal.info.subjectNoData", { ns: "translations" }),
      showDenyButton: true,
      confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
      denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
    }).then((result) => {
      if (result.isConfirmed) {
        var userEditData = userData;
        userEditData.VehicleNo =
          $(".newBikeNumber").val() == ""
            ? userEditData.VehicleNo
            : $(".newBikeNumber").val();

        var data = {
          module_id: userEditData.VehicleId,
          channel: 1,
          desired_status: userEditData.StationParkUnitId,
        };
        var config = {
          method: "POST",
          url: getEndPoint() + "/api/Admin/OtherModules/EnergyTracking",
          headers: {
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: t("swal.success.successMessage", { ns: "translations" }),
              showConfirmButton: false,
              timer: 1500,
            }).then(function () {
              $("#bicycle-edit .close").trigger("click");
              _this.bicycleTable.ajax.reload();
            });
          })
          .catch(function (error) {
            Functions.requestAxiosErrorFunction(error);
          });
      } else if (result.isDenied) {
        Swal.fire({
          title: t("swal.cancelSwal.head", { ns: "translations" }),
          text: t("swal.cancelSwal.subject", { ns: "translations" }),
          confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
          icon: t("swal.cancelSwal.icon", { ns: "translations" }),
        });
      }
    });
  }

  reRender = () => {
    // calling the forceUpdate() method
    this.forceUpdate();
  };

  parentValueChange = (valueType, type) => {
    if (type == "first") {
      firstDateHours = valueType;
    } else {
      lastDateHours = valueType;
    }
  };

  render() {
    const { t } = this.props;
    console.log("Component is re-rendered");

    return (
      <div className="retail-page p-2">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="tab-1">
            <div className="row">
              <div className="card">
                <div className="card-body py-2">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group row">
                        <label className="col-form-label col-lg-4">
                          {t("Cihaz")}
                        </label>
                        <div className="col-lg-8">
                          <Select
                            defaultValues="1"
                            selectID="vehicleSelect"
                            customCss={"gpsSelect"}
                            resultID={["IMEI"]}
                            result={["VehicleGPSId", "VehicleNo", "IMEI"]}
                            ajaxURL="/api/Admin/OtherModules/GPS/Local"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-2">
                      <a href="/bicycles/gps" className="btn btn-dark w-100">
                        {t("Tüm Cihazlar")}
                      </a>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group row">
                        <label className="col-form-label col-lg-4">
                          {t("firstDate")}
                        </label>
                        <div className="col-lg-8">
                          <div className="d-flex">
                            <span className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-calendar22"></i>
                              </span>
                            </span>
                            <input
                              ref={this.sonKullanmaTarih1Ref}
                              type="text"
                              className="form-control w-100 daterange-single"
                              defaultValue=""
                            />
                            <DatePicker
                              type="first"
                              parentValueChange={this.parentValueChange}
                              defaultValue={moment().startOf("day")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group row">
                        <label className="col-form-label col-lg-4">
                          {t("lastDate")}
                        </label>
                        <div className="col-lg-8">
                          <div className="d-flex">
                            <span className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon-calendar22"></i>
                              </span>
                            </span>
                            <input
                              ref={this.sonKullanmaTarih2Ref}
                              type="text"
                              className="form-control w-100 daterange-single"
                              defaultValue=""
                            />
                            <DatePicker
                              parentValueChange={this.parentValueChange}
                              defaultValue={moment().endOf("day")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Division className="col-2">
                      <button
                        type="button"
                        onClick={this.gpsHandleClickBike}
                        className="btn btn-primary w-100"
                      >
                        {t("Getir")}
                      </button>
                      {/* <button type="button" onClick={this.gpsHandleClickBike} onClickOld={() => this.bicycleTable.ajax.reload()} className="btn btn-primary w-100">{t("Getir")}</button> */}
                    </Division>
                  </div>
                </div>
              </div>

              <Division className="card mb-1">
                <MapLine
                key={this.state.gpsListFilterKey}
                ajaxUrl="/api/Admin/OtherModules/GPS/Local/History?MapLine=2"
                  filter={this.state.gpsListFilter}
                  // filter={gpsListFilterv1}

                  initialCenter={[40.7032331, 29.8843142]}
                ></MapLine>
              </Division>
              <TableData
                key={this.state.gpsListFilterKey}
                cardTitle={t("Gps Geçmiş İzleme ")}
                abilityid={7}
                auth={0}
                length="10"
                ordering={false}
                customOrder={[1, "DESC"]}
                searching={false}
                customClass="col-12"
                ajaxUrl="/api/Admin/OtherModules/GPS/Local/History"
                tableName="bicycle-gps-table"
                filter={this.state.gpsListFilter}
                columns={this.gpsListColumn}
              />

              <div
                id="bicycle-edit"
                ref={this.modalBicycleRef}
                className="modal fade"
              >
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <form onSubmit={this.modalBicycleHandleSubmit}>
                      <div className="modal-header">
                        <h5 className="modal-title">Uyarı</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="row">
                          <div className="col-lg-12">
                            <Text
                              Tag="label"
                              className="col-form-label col-lg-12 align-self-center"
                            >
                              {t("modal.bicycleRFID")}
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          id="deneme"
                          data-dismiss="modal"
                        >
                          {t("İptal")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab-2"></div>
        </div>
      </div>
    );
  }
}

export default withTranslation([
  "bicycleMovements",
  "Homepage",
  "translations",
  "systemBicycles",
])(GpsHistoryList);
