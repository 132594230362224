import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import {getEndPoint} from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../functions";
import moment from "moment";

class CreditCardProvisionReport extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
    }
    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }
    componentDidMount() {
        const { t } = this.props;

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
                "autoApply": true,
                ranges: {
                    'Bugün': [moment(), moment()],
                },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        this.processDataTable(this.tableRef, "#provision-table");

    }
    componentDidUpdate = () => this.componentDidMount();


    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters.filterHead")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.provisionStatus")} </label>
                                    <div className="col-lg-8">
                                        <select id="provdurumu" className="form-control">
                                            <option value="">{t("all",{ns:"translations"})}</option>

                                            <option value="0">AÇIK</option>

                                            <option value="10">Üye Bisikleti Teslim Etmemiş.Prov.Kapama İptal Edildi</option>

                                            <option value="20">Maximum Provizyon Süresi Geçmiş Cezalı İşlem</option>

                                            <option value="100">Üye Hesabı Güncellendi</option>

                                            <option value="110">Provizyonu Kapanacak Kayıtlar Hazırlandı</option>

                                            <option value="120">Provizyon Dosyası Hazırlandı</option>

                                            <option value="130">Provizyon Dosyası Bankaya Gönderildi</option>

                                            <option value="140">Bankadan Gönderim Onayı Alındı</option>

                                            <option value="145">Bankadan Provizyon KAPATILAMADI.</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.memberBusiness")}</label>
                                    <div className="col-lg-8">

                                        <select className="form-control">
                                            <option value="">{t("all",{ns:"translations"})}</option>

                                            <option value="1">1101-1.Yahya Kaptan Bisiklet Egitim-</option>

                                            <option value="2">1102-2. Yahya Kaptan D-100-</option>

                                            <option value="3">1103-3. Kinali Asker Parki-</option>

                                            <option value="4">1104-4. Demokrasi Kavsagi-</option>

                                            <option value="5">1105-5. Dogu kisla parki-</option>

                                            <option value="6">1106-6. Anit Park-</option>

                                            <option value="7">1107-7. Izmit Belediyesı-</option>

                                            <option value="8">1108-8. Kocaeli Fuari-</option>

                                            <option value="9">1109-9. Mimar Sinan Köprüsü-</option>

                                            <option value="10">1110-10. Marina -</option>

                                            <option value="11">1111-11. Tren Gari-</option>

                                            <option value="12">1112-9029 eski palmiye -</option>

                                            <option value="13">1113-13. Seka Park Uçurtma Tepesi -</option>

                                            <option value="14">1114-14. Seka Park 2. Etap-</option>

                                            <option value="15">1115-15. Plaj Yolu-</option>

                                            <option value="200">1116-16. Yildiz Konutlari-</option>

                                            <option value="201">1117-17. Alikahya Merkez-</option>

                                            <option value="207">1123-23. Kongre Sarayi Parki Gölcük-</option>

                                            <option value="208">1126-26. Bekirdere Üçyol Zincirliku-</option>

                                            <option value="209">1127-27. Muhsin Yazicioglu Parki-</option>

                                            <option value="210">1132-32. Tramvay Terminal-</option>

                                            <option value="211">1133-33. Köseköy MYO.-</option>

                                            <option value="212">1134-Kartepe Belediyesi-</option>

                                            <option value="213">1130-30. 60 Evler Sahil-</option>

                                            <option value="214">1131-31. Tütünçiftlik Sahil-</option>

                                            <option value="215">1128-28. Eskihisar Sahil-</option>

                                            <option value="216">1129-29. Darica Sahil-</option>

                                            <option value="217">1119-19. Naila 1-</option>

                                            <option value="218">1120-20. Naila 2 -</option>

                                            <option value="219">1122-Mehmed Ali Pasa-</option>

                                            <option value="220">1124-24. Degirmendere Sahil-</option>

                                            <option value="221">1125-25. Karamürsel Sahil-</option>

                                            <option value="236">1135-Cumhuriyet Parki-</option>

                                            <option value="239">1121-21.Outlet Center-</option>

                                            <option value="250">1136-KOÜ A Kapi-</option>

                                            <option value="251">1137-.-</option>

                                            <option value="252">1138-KOÜ Sosyal Tesis-</option>

                                            <option value="253">1139-Seka Park Balikçilar-</option>

                                            <option value="254">1140-Seka Park Otel-</option>

                                            <option value="255">1118-KOBIS OFIS ODASI-</option>

                                            <option value="256">1141-Sekapark Kongre Merkezi-</option>

                                            <option value="257">1142-Gençlik Merkezi-</option>

                                            <option value="258">1143-Dr. Sadik Ahmet -</option>

                                            <option value="259">1144-Gölkay Park-</option>

                                            <option value="260">1145-Karsiyaka Sahil-</option>

                                            <option value="261">1146-No Name -</option>

                                            <option value="262">1147-Gölcük Denizevler Sahil-</option>

                                            <option value="263">1148-Gölcük Kent Meydani-</option>

                                            <option value="264">1149-Eregli Sahil-</option>

                                            <option value="265">1150-Altinkemer Sahil-</option>

                                            <option value="266">1151-Dereköy Sahil-</option>

                                            <option value="267">1152-Kartepe  Kent Meydani-</option>

                                            <option value="268">9000-Kartepe Batti-Çikti Degil-</option>

                                            <option value="269">1154-Kandira Otogar-</option>

                                            <option value="270">1155-Kandira Kent Meydani-</option>

                                            <option value="271">1156-Çayirova Çagdaskent -</option>

                                            <option value="272">1157-Tavsancil Sahil-</option>

                                            <option value="273">1158-Körfez Belediyesi-</option>

                                            <option value="274">1159-Körfez Yarimca Sahil-</option>

                                            <option value="275">1160-Tütünçiftlik Merkez-</option>

                                            <option value="276">1161-Hereke Sahil-</option>

                                            <option value="277">1162-KOÜ B Kapi-</option>

                                            <option value="278">1163-Cebeci Sahil-</option>

                                            <option value="279">1164-Fatma Hanim Parki-</option>

                                            <option value="280">1165-Tatlikuyu Vadisi-</option>

                                            <option value="281">1166-Osman Hamdi Bey Kültür Merkezi-</option>

                                            <option value="282">1167-Tübitak Otopark-</option>

                                            <option value="283">1168-Tübitak Sosyal Tes.-</option>

                                            <option value="284">1169-Gebze Kent Meydani-</option>

                                            <option value="285">1170-Derince Kent Meydani-</option>

                                            <option value="287">1000-Ormanya-</option>

                                            <option value="299">1173-Köseköy Ertugrul Gazi Mey-</option>

                                            <option value="309">1174-Basiskele Sahil-</option>

                                            <option value="323">1153-Kartepe Batti-Çikti-</option>

                                            <option value="324">1185-KOBIS ATÖLYE-</option>

                                            <option value="325">1186-Çayirova Mesire Alani-</option>

                                            <option value="326">1187-1187-</option>

                                            <option value="327">1188-1188-</option>

                                            <option value="341">1190-KOBIS ATÖLYE-</option>

                                            <option value="342">9002-9002-</option>

                                            <option value="343">9003-9003-</option>

                                            <option value="344">9004-9004-</option>

                                            <option value="345">9005-9005-</option>

                                            <option value="346">9006-9006-</option>

                                            <option value="347">9007-9007-</option>

                                            <option value="348">9008-9008-</option>

                                            <option value="349">9009-9009-</option>

                                            <option value="350">9010-9010-</option>

                                            <option value="351">9011-9011-</option>

                                            <option value="352">9012-9012-</option>

                                            <option value="353">9013-9013-</option>

                                            <option value="354">9014-9014-</option>

                                            <option value="355">9015-9015-</option>

                                            <option value="356">9016-9016-</option>

                                            <option value="357">9017-9017-</option>

                                            <option value="358">9018-9018-</option>

                                            <option value="359">9019-9019-</option>

                                            <option value="360">9020-9020-</option>

                                            <option value="361">9021-SekaPark Palmiye-</option>

                                            <option value="362">9022-9022-</option>

                                            <option value="363">9023-9023-</option>

                                            <option value="364">9024-9024-</option>

                                            <option value="365">9025-9025-</option>

                                            <option value="366">9026-9026-</option>

                                            <option value="367">9027-9027-</option>

                                            <option value="368">9028-Halidere Sahil-</option>

                                            <option value="369">9029-Ulasli Sahil-</option>

                                            <option value="370">9030-9030-</option>

                                            <option value="371">9031-9031-</option>

                                            <option value="372">9031-9031-</option>

                                            <option value="373">9032-9032-</option>

                                            <option value="374">9033-9033-</option>

                                            <option value="375">9034-9034-</option>

                                            <option value="376">9035-Sirintepe -</option>

                                            <option value="377">9036-9036-</option>

                                            <option value="378">9037-9037-</option>

                                            <option value="379">9038-9038-</option>

                                            <option value="380">9039-9039-</option>

                                            <option value="381">9040-9040-</option>

                                            <option value="382">9041-9041-</option>

                                            <option value="383">9041-9041-</option>

                                            <option value="384">9042-9042-</option>

                                            <option value="385">9043-9043-</option>

                                            <option value="386">1171-Kerpe Sahil-</option>

                                            <option value="387">0-9044-</option>

                                            <option value="388">0-9044-</option>

                                            <option value="389">0-9044-</option>

                                            <option value="390">0-9045-</option>

                                            <option value="391">0-9044-</option>

                                            <option value="392">0-9044-</option>

                                            <option value="393">0-9045-</option>

                                            <option value="394">0-9046-</option>

                                            <option value="395">0-9044-</option>

                                            <option value="396">0-9044-</option>

                                            <option value="397">0-9044-</option>

                                            <option value="398">0-9045-</option>

                                            <option value="399">0-9046-</option>

                                            <option value="400">0-9044-</option>

                                            <option value="401">0-9045-</option>

                                            <option value="402">0-9046-</option>

                                            <option value="403">0-9047-</option>

                                            <option value="404">0-9048-</option>

                                            <option value="405">0-9049-</option>

                                            <option value="406">0-9045-</option>

                                            <option value="407">0-9044-</option>

                                            <option value="408">0-9044-</option>

                                            <option value="409">0-9044-</option>

                                            <option value="410">0-9045-</option>

                                            <option value="411">0-9044-</option>

                                            <option value="412">0-9045-</option>

                                            <option value="413">0-9046-</option>

                                            <option value="414">0-9047-</option>

                                            <option value="415">0-9044-</option>

                                            <option value="416">0-9045-</option>

                                            <option value="417">0-9044-</option>

                                            <option value="418">0-9044-</option>

                                            <option value="419">0-9044-</option>

                                            <option value="420">0-9044-</option>

                                            <option value="421">0-9044-</option>

                                            <option value="422">0-9044-</option>

                                            <option value="423">0-9045-</option>

                                            <option value="424">0-9045-</option>

                                            <option value="425">0-9045-</option>

                                            <option value="426">0-9046-</option>

                                            <option value="427">0-9044-</option>

                                            <option value="428">0-9044-</option>

                                            <option value="429">0-9044-</option>

                                            <option value="430">0-PC Test -</option>

                                            <option value="431">0-9044-</option>

                                            <option value="432">0-9044-</option>

                                            <option value="433">0-Teknik Ofis 2-</option>

                                            <option value="434">9044-Kobis Atölyee-</option>

                                            <option value="435">0-9045-</option>

                                        </select>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.firstProvisionDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.lastProvisionDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.memberID")}</label>
                                    <div className="col-lg-8">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button type="button" className="btn btn-primary w-100">{t("filters.btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card bg-success text-center">
                        <div className="card-body">
                            <h1 className="mt-2 text-white">Toplam Gelir:  <strong> 150.000 TL</strong></h1>

                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="provision-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.bankProvisionNumber")}</th>
                                            <th>{t("table.status")}</th>
                                            <th>{t("table.dates")}</th>
                                            <th>{t("table.member")}</th>
                                            <th>{t("table.bicycleNumber")}</th>
                                            <th>{t("table.provisionAmount")}</th>
                                            <th>{t("table.spendingAmount")}</th>
                                            <th>{t("table.station")}</th>
                                            <th>{t("table.process")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>00256</td>
                                            <td>Bankadan Gönderim Onaylı Alındı</td>
                                            <td>13.01.2021 17:17:05 <br />
                                                13.01.2021 17:17:05 <br />
                                                13.01.2021 17:17:05</td>
                                            <td>Murat Toker</td>
                                            <td>
                                                2
                                            </td>
                                            <td>20  </td>
                                            <td>0</td>
                                            <td>Cebeci Sahil</td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>


                <script src="js/plugins/ui/moment/moment.min.js"></script>
                <script src="js/plugins/pickers/daterangepicker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.date.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.time.js"></script>
                <script src="js/plugins/pickers/pickadate/legacy.js"></script>
                <script src="js/plugins/notifications/jgrowl.min.js"></script>
            </div>
        )
    }
}

export default withTranslation(['CreditCardProvisionReport', 'translations'])(CreditCardProvisionReport);
