import React, { Component } from 'react'
import SelectCordinate from '../../components/select-cordinate';
import { ReactSession } from 'react-client-session'
import { useTranslation, withTranslation } from 'react-i18next';
import { getEndPoint } from "../../class/api";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import axios from 'axios';
import { useParams } from 'react-router';
import Functions from '../../class/functions';

class StationZoneDefinitionsEdit extends Component {
    constructor(props) {
        super(props)
        this.cordinate = ""
        const url = window.location.pathname
        const strs = url.split('/');
        const id = strs.at(-1)
        var data = ReactSession.get('zoneEditData')

        this.ZoneData = data.find(x => x.StationRegionId == id)
    }
    cordinateDataFunc = (data) => {
        this.ZoneData.Coordinate = data
    }
    handleClickRegionEdit = (e) => {
        const _this = this;
        const { t } = this.props;
        var ZoneData = this.ZoneData
        ZoneData.Coordinate = `${ZoneData.Coordinate[0].lat},${ZoneData.Coordinate[0].lng}`
        ZoneData.Name = $('#zoneName').val()
        if (this.ZoneData.Coordinate == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Kordinat" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        var data = JSON.stringify(this.ZoneData);

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Station/Region',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then((response) => {
                ReactSession.set('zoneEditData', this.ZoneData)

                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                setTimeout(() => {
                    window.location.href = "/stations/station-zone-definitions"
                }, 1500);
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props
        return (
            <div className="card">
                <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                    <h6 className="card-title">{t("Bölge Düzenle")}</h6>
                </div>
                <div className="card-body py-2">
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <input type="text" className='form-control' id='zoneID' placeholder="ID" defaultValue={this.ZoneData.StationRegionId} readOnly disabled />
                        </div>
                        <div className="col-lg-12 form-group">
                            <input id='zoneName' type="text" className='form-control' defaultValue={this.ZoneData.Name} placeholder={t("modal.zoneName")} />
                        </div>
                        <div className="col-lg-12 mt-2">
                            <SelectCordinate defaultCordinate={this.ZoneData.Coordinate} cordinate={this.cordinateDataFunc} />
                        </div>
                        <div className="col-lg-12 mt-2">
                            <button onClick={this.handleClickRegionEdit} className='btn btn-success btn-lg btn-block'>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation(["systemStationZoneDefinitions", "translations"])(StationZoneDefinitionsEdit);