import React from "react";

import Anchor from "./elements/anchor.js";
import Image from "./elements/image.js";
import Button from "./elements/button.js";
import Division from "./elements/content-division.js";
export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const kobisLogoImage = require("../img/kobis_logo.png");
        return (
            <Division className="navbar navbar-expand-lg navbar-dark navbar-static">
                <Division className="d-flex flex-1 d-lg-none">
                    <Button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
                        <i className="icon-paragraph-justify3"></i>
                    </Button>
                    <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
                        <i className="icon-cog2"></i>
                    </Button>
                </Division>

                <Division className="navbar-brand text-center text-lg-center">
                    <Anchor href="/" className="d-inline-block">
                        <Image src={kobisLogoImage} className="d-none d-sm-block" alt="" style={{ height: "4.5rem", marginTop: "-15px" }} />

                        <Image src={kobisLogoImage} className="d-sm-none" alt="" style={{ height: "5rem" }} />

                    </Anchor>

                </Division>

                <Division className="collapse navbar-collapse order-2 order-lg-1" id="navbar-mobile">

                    {this.props.children}
                </Division>

                {this.props.AdditionalChildren && this.props.AdditionalChildren}
            </Division>
        );
    }
}
