import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MarkerYes from "../img/marker_yes.png";
import MarkerNo from "../img/marker_no.png";
class SelectCordinate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [
            ]
        }
        if (this.props.defaultCordinate != undefined) {
            this.setState({
                markers: this.props.defaultCordinate
            })
        }

        this.mapClicked = this.mapClicked.bind(this);
    }
    mapClicked(mapProps, map, clickEvent) {
        this.setState({
            markers: [{ lat: clickEvent.latLng.lat(), lng: clickEvent.latLng.lng(), status: true }]
        });
        this.props.cordinate(this.state.markers)
    }
    render() {
        const containerStyle = {
            position: 'relative',
            width: '100%',
            height: '450px'
        } 


        return (
            <div>
                <Map
                    onClick={this.mapClicked}
                    initialCenter={{
                        lat: 40.7032331, lng: 29.8843142
                    }}
                    google={this.props.google}

                    zoom={12}
                    containerStyle={containerStyle}>
                    {this.state.markers.map((elm, index) => {
                        return <Marker key={index}
                            position={{ lat: elm.lat, lng: elm.lng }} />

                    })
                    }
                </Map>
            </div>
        )
    }
}
export default GoogleApiWrapper({ apiKey: ("AIzaSyD13goluYdOUCH2z3zVYpSFZZa3RxgxKsY") })(SelectCordinate)