import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import MarkerYes from "../img/marker_yes.png";
import MarkerNo from "../img/marker_no.png";


import { getEndPoint } from "../class/api"; 
import Cookies from 'universal-cookie';
import axios from "axios";
const cookies = new Cookies();
class Maps extends Component {
  constructor(props) {
    super(props); 
    var self = this;
    this.state = {
      labelSize : { width: 220} , 
      labelPadding : 8 , 
      places: [], 
      markers: [], 
      markersTest: [
        { lat: 40.7042346, lng: 29.8843142, status: true },
        { lat: 40.7012323, lng: 29.8143142, status: true },
        { lat: 40.7082337, lng: 29.8643142, status: false },
        { lat: 40.7102350, lng: 29.8843142, status: true },
        { lat: 40.7502310, lng: 29.8343142, status: false }
      ]
    }
    console.log(this.state.markers);

    var config = {
      method: 'put',
      url: `${getEndPoint()}${this.props.ajaxUrl}`,
      headers: {
          'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }; 

    axios(config)
    .then(function (response) { 
      self.setState({ 
        markers: [   ] 
      }) 
        response.data.data.forEach((element, index) => {  
          const newPlace3 = {
            //id: self.state.markers.length, 
            id: element.VehicleGPSId,  
            title: element.VehicleNo + " - IMEI : " + element.IMEI, 
            label : element.VehicleNo,
            lat: element.Lat, 
            lng: element.Long,  
            status: true 
          };
          self.setState({ 
            markers: [  ...self.state.markers,newPlace3 ] 
          }) 

           
        }); 
    }); 

    }



    addMarkerLoad( e ) { 
      console.log(e); 
    this.setState({
      //markers: [...this.state.markers, newPlace2], 
      markers: [ ...this.state.markers ], 
      //markers: [ newPlace2 ]
    });
    console.log ( this.state.markers );
  }

 




 
  render() {
    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '450px'
    }


    return (
      <div>
        <Map
          //onClick={this.mapClicked}
          // onClick={this.addMarkerLoad.bind(this)}
          initialCenter={{
            lat: this.props.initialCenter[0] ?? 40.7032331, lng: this.props.initialCenter[1] ?? 29.8843142
          }}
          containerStyle={containerStyle}
          google={this.props.google} zoom={14}>
          {this.state.markers.map((elm, index) => {
            return <Marker key={index} icon={{
              url: elm.status == true ? MarkerYes : MarkerNo,
            }}
              position={{ lat: elm.lat, lng: elm.lng }} 
              title= { elm.title }
              label= { elm.label }
              
              >   
              </Marker>

          })
          }

        </Map>
      </div>
    )
  }
}
export default GoogleApiWrapper({ apiKey: ("AIzaSyD13goluYdOUCH2z3zVYpSFZZa3RxgxKsY") })(Maps)