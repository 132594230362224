import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import { getEndPoint } from "../../class/api";
import jq from 'jquery';
import { ReactSession } from 'react-client-session';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import axios from 'axios';
import Functions from '../../class/functions';
import DatePicker from '../../components/elements/DatePicker';
import { Select } from '../../components/Select';
const cookies = new Cookies();
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";

class SystemLogs extends Component {
    constructor(props) {
        super(props);
        this.firstDate = React.createRef(null);
        this.lastDate = React.createRef(null);
    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props

        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Vehicle',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                "per_page": -1
            })
        };

        axios(config)
            .then(function (response) {
                const responseData = response.data.data;
                $('.bikeSelect').empty().trigger("change");

                var bikeSelect = $('.bikeSelect');
                var option = new Option(
                    t("all", { ns: "translations" }),
                    "0",
                    true,
                    true);
                bikeSelect.append(option).trigger('change');
                responseData.forEach(data => {
                    var option = new Option(
                        data.VehicleNo,
                        data.VehicleId,
                        false,
                        false);
                    bikeSelect.append(option).trigger('change');

                });


            });

        var configEvent = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/NotificationEventDefinition',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({

                "per_page": -1,
                "Filter": {
                    "order": "NotificationEventDefinitionId",
                    "sort": "ASC"
                }
            })
        };

        axios(configEvent)
            .then(function (response) {
                // reportSelect
                const responseData = response.data.data;
                $('.reportSelect').empty().trigger("change");

                var reportSelect = $('.reportSelect');
                var option = new Option(
                    t("all", { ns: "translations" }),
                    "0",
                    true,
                    true);
                reportSelect.append(option).trigger('change');
                responseData.forEach(data => {
                    var option = new Option(
                        data.Description,
                        data.ModuleCode,
                        false,
                        false);
                    reportSelect.append(option).trigger('change');

                });
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });

        var translate = {
            ...(t("tableLocalization", { ns: "translations" })),
            paginate: {
                'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
            }
        };

        DateRangePickerHandler(this.firstDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        DateRangePickerHandler(this.lastDate.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        $(".select-search").select2({
            language: selectLanguage
        }); 


        $('.stationSelect').on('select2:select', function (e) {
            var data = e.params.data;
            console.log(data);  
            var parknumber = $('.stationSelect option:selected').attr('data-parknumber')
            $("#ParkId").val( parknumber + "01" ) 

            // $("#ParkId").val( data.data("parknumber") ) 
        });

        

        var stationList = ReactSession.get('stationList')
        var stationSelect = $('.stationSelect');
        $(stationSelect).empty().trigger("change");
        var option = new Option(
            t("all", { ns: "translations" }),
            "0",
            true,
            true);
        stationSelect.append(option).trigger('change');
        stationList.forEach(elm => {
            var newOption = new Option(
                elm.Name  ,
                elm.StationId,
                false, false); 
                newOption.setAttribute("data-parknumber" , elm.Number )

            $(stationSelect).append(newOption).trigger('change');
        });








        if (this.systemLogsTable != null)
            this.systemLogsTable.destroy();

        this.systemLogsTable = jq('#system-logs-table').DataTable({
            dom: 'Blfrtip',
            "searching": false,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            order: [2, 'asc'],
            processing: true,
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            ajax: {
                url: getEndPoint() + '/api/Admin/Reports/SystemLogs',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#system-logs-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                if (ReactSession.get('systemlogs') != undefined) {
                    $(this.firstDate.current).val("20/06/2000")
                    $('#ParkId').val(ReactSession.get('systemlogs'))
                    ReactSession.remove('systemlogs')
                }
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    dateRange: [
                        moment($(this.firstDate.current).val() + ' ' + firstDateHours,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
                        moment($(this.lastDate.current).val() + ' ' + lastDateHours,'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
                    ],
                };


                if ($('.stationSelect').val() != "0") {
                    aoData["Filter"]["StationId"] = $('.stationSelect').val();
                }
                if ($('.bikeSelect').val() != "0") {
                    aoData["Filter"]["VehicleId"] = $('.bikeSelect').val();
                }
                if ($('#ParkId').val() != "") {
                    aoData["Filter"]["ParkUnitId"] = $('#ParkId').val();
                }
                if ($('#usedID').val() != "") {
                    aoData["Filter"]["ModuleId"] = $('#usedID').val();
                }
                if ($('#userID').val() != "0") {
                    aoData["Filter"]["UserId"] = $('#userID').val();
                }
                if ($('.reportSelect').val() != "0") {
                    aoData["Filter"]["ModuleCode"] = $('.reportSelect').val();
                }
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },


            "columns": [
                { data: "LogSystemId" },
                {
                    data: "DeviceDate", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data)
                    }, orderable: false
                },
                {
                    data: "created_at", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data)
                    }
                },
                { data: "Description" },
                {
                    data: "StationId"
                    , render: function (data, type, row) {
                        if (data == null) {
                            return ""
                        }
                        else if (data == 0) {
                            return " "
                        }
                        else if (data == undefined) {
                            return " "
                        }
                        return `<a href='/stations/list/${row.station.StationId}'>${row.station.Name} > ${row?.park_unit?.ParkOrder} </a>`

                    }, "orderable": false
                },
                {
                    data: "park_unit"
                    , render: function (data, type, row) {
                        if (data == null) {
                            return " "
                        } else if (data == 0) {
                            return " "
                        }
                        else if (data == undefined) {
                            return " "
                        }
                        return `${data.StationParkUnitId} -- ${data.ParkIpAddress}`
                    }, "orderable": false
                },
                {

                    data: "VehicleId", render: function (data, type, row) {
                        if (data == null) {
                            return " "
                        } else if (data == 0) {
                            return " "
                        }
                        else if (data == undefined) {
                            return " "
                        }
                        //console.log(data);
                        return `<a id="vehicle_no" href="#"> Bisiklet ID  ${row.vehicle.VehicleId} || RFID ${row.vehicle.RFID} || Bisiklet No${row.vehicle.VehicleNo}</a> `
                    }, "orderable": false
                },
                {
                    data: "UserId", render: function (data, type, row) {
                        if (data == null) {
                            return " "
                        } else if (data == 0) {
                            return " "
                        }
                        else if (data == undefined) {
                            return " "
                        }
                        return `<a href="/users/edit/${row.user.id}"> ${row.user.Name} ${row.user.Surname} </a>`
                    }
                },
                { data: "ModuleId" },
            ],
        });
        new jq.fn.dataTable.Responsive(this.systemLogsTable, { details: true, Responsive: true },);
        $('#system-logs-table tbody').on('click', 'td #vehicle_no', function () {
            var tr = jq(this).closest('tr');
            var row = _this.systemLogsTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.systemLogsTable.row(this);
                data = row.data();
            }
            ReactSession.set('bikeSearchData', data.vehicle.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";


        });
    }

    componentDidUpdate = () => this.componentDidMount();
    filterHandleClick = () => [
        this.systemLogsTable.ajax.reload()
    ]
    parentValueChange = (valueType, type) => {

        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("filters", { ns: "translations" })}</Text>
                    </Division>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-3">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.firstDate")}</span>
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="form-control"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.firstDate} type="text" className="form-control daterange w-100 " />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.lastDate")}</span>
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="form-control"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.lastDate} type="text" className="form-control daterange w-100 " />

                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />
                                        </div>
                                    </div>
                                </div>
                            </Division>


                            <Division className="col-lg-3">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.station")}</span>
                                        <div className="d-flex">
                                            <select className="form-control stationSelect select-search" >

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Division>
                            <Division className="col-lg-3">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.park")}</span>
                                        <div className="d-flex">
                                            <input id='ParkId' className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3 mt-2">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.bicycle")}</span>

                                        <Select
                                            selectID="bikeSelect"
                                            customCss={"bikeSelect"}
                                            resultID={["VehicleId"]}
                                            result={["VehicleId", "VehicleNo", "Name", "RFID", "VehicleType", "StatusIdTxt"]}
                                            ajaxURL="/api/Admin/Station/Vehicle"
                                        />
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3 mt-2">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.reportType")}</span>
                                        <div className="d-flex">
                                            <select className="form-control reportSelect select-search">
                                                <option value="0">  {t("loading", { ns: "translations" })} </option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3 mt-2">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.userId")}</span>
                                        <Select
                                            selectID="userID"
                                            customCss={"userID"}
                                            resultID={["id"]}
                                            result={["Name", "Surname"]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3 mt-2">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>{t("filters.usageNumber")}</span>
                                        <div className="d-flex">
                                            <input id='usedID' type="number" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </Division>

                            <Division className="col-lg-3 mt-4">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <button onClick={this.filterHandleClick} className='btn btn-primary'>{t("filter", { ns: "translations" })}</button>
                                    </div>
                                </div>
                            </Division>
                        </Division>
                    </Division>
                </Division>

                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("menu.management.systemLogs", { ns: "translations" })}</Text>
                    </Division>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table id="system-logs-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.dateOfDevice")}</th>
                                            <th>{t("table.dateOfSystem")}</th>
                                            <th>{t("table.description")}</th>
                                            <th>{t("table.station")}</th>
                                            <th>{t("table.park")}</th>
                                            <th>{t("table.bicycle")}</th>
                                            <th>{t("table.user")}</th>
                                            <th>{t("table.usageID")}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </Division>
                        </Division>
                    </Division>
                </Division>
            </div>
        );
    }
}

export default withTranslation(["managementSystemLogs", "translations"])(SystemLogs);