import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import {getEndPoint} from "../../class/api";
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';

class ActiveUsers extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { t } = this.props;
 
        if (this.activeUsersTable != null)
            this.activeUsersTable.destroy();

        this.activeUsersTable = $('#active-users-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", {ns:"translations"}) + '&larr;' : t("tableLocalization.paginate.next", {ns:"translations"}) + ' &rarr;', 
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", {ns:"translations"}) : '&larr; ' + t("tableLocalization.paginate.previous", {ns:"translations"}) 
                }   
            }
        });

    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <Division className="card">
                    <Division style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("menu.management.activeUsers", {ns:"translations"})}</Text>
                    </Division>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table id="active-users-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.userType")}</th>
                                            <th>{t("table.name")}</th>
                                            <th>{t("table.task")}</th>
                                            <th>{t("table.phone")}</th>
                                            <th>{t("table.email")}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </Division>
                        </Division>
                    </Division>        
                </Division>
            </div>
        );
    }
}

export default withTranslation(["managementActiveUsers", "translations"])(ActiveUsers);