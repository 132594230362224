import React from "react";

export default function Text(props) {
    const ElementTag = `${props.Tag}`;
    const { Tag, ...AlteredProps } = props;
    return (
        <ElementTag {...AlteredProps}>
            {props.children}
        </ElementTag>
    );
}