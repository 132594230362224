import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Division from '../../components/elements/content-division';
import i18n from '../../i18n/i18n';
import { getEndPoint } from "../../class/api";
import { ReactSession } from "react-client-session";
import Cookies from 'universal-cookie';
import jq from 'jquery';
import axios from 'axios';
import Functions from '../../class/functions';
const cookies = new Cookies();

class ManagerDuties extends Component {
    constructor(props) {
        super(props);

        this.table = [];


    }


    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $.extend($.summernote.lang, {
            "customLang": { ...(t("summerNoteEditorLocalization", { ns: "translations" })) }
        });

        $('.summernote-height').summernote({
            height: 200,
            lang: i18n.language == "en" ? "en-US" : "customLang"
        });

        $('.multiple-select').select2({
            tags: true,
            language: selectLanguage
        });
        if (this.roleTable != undefined)
            this.roleTable.destroy();


        this.roleTable = $('#manager-duties-table').DataTable({
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Manager/Roles',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#manager-duties-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    ParentId: 0
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "ordering": false,
            "searching": false,
            "columns": [

                { data: "UserRoleId", orderable: false },
                {
                    data: "Name", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }, "orderable": false
                },
                {
                    data: "Description", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return (data);
                    }, "orderable": false
                },
                {
                    className: 'dt-control',
                    orderable: false,
                    data: "ParentId", render: function () {
                        return "<a class=\"btn btn-info\"> Alt Rolleri Göster</a>"
                    },
                    defaultContent: '',
                },
                {
                    className: 'operation',
                    data: "UserRoleId", render: function (data, type, row) {
                        return `
                            ${Functions.RoleControl(35, 3) == true ? `<a href="#" data-toggle="modal" data-target="#duty-edit" id="duty-edits" class="dropdown-item"><i class="icon-pencil"></i> ${t("table.edit")}</a>` : ""} 
                        `

                    }, "orderable": false
                },


            ],

        });
        new jq.fn.dataTable.Responsive(this.roleTable, { details: true, Responsive: true },);
        function format(d) {

            var returnListData = `    <table class="table">
            <thead>
                <tr>
                    <th>${t("ID")}:</th>
                    <th>  ${t("table.name")}</th>
                </tr>
            </thead>
            <tbody>`
            if (!d.child.length) {
                returnListData += `
                
                <tr>
                    <td rowspan="2">${t("tableLocalization.emptyTable", { ns: "translations" })}</td>
                
                </tr>`
            }
            else {
                d.child.forEach((element, index) => {

                    returnListData += `
                
                                        <tr>
                                            <td>${element.UserRoleId}</td>
                                            <td>${element.Name}</td>
                                        
                                        </tr>
                                           
                    `

                });
            }


            returnListData += `
            </tbody>
        </table>
`
            return returnListData

        }
        jq('#manager-duties-table tbody').on('click', 'td.dt-control', function () {
            var tr = jq(this).closest('tr');
            var row = _this.roleTable.row(tr);
            if (row.child.isShown()) {
                row.child.hide();
                tr.removeClass('shown');
            } else {

                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });
        jq('#manager-duties-table tbody').on('click', 'td.operation #duty-edits', function () {
            var tr = jq(this).closest('tr');
            var row = _this.roleTable.row(tr);
            $('#editDutyId').val(row.data().UserRoleId);
            $('#editDutyName').val(row.data().Name);
            $('#editDutyDescription').val(row.data().Description);
            $('#editDutyOrder').val(row.data().Order);
            $('#editDutyStatus').val(row.data().Order == true ? "true" : "false");


        });

    }

    componentDidUpdate = () => this.componentDidMount();

    handleEditClick = () => {
        const _this = this;
        const { t } = this.props

        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "Id": $('#editDutyId').val(),
                    "Name": $('#editDutyName').val(),
                    "RoleType": 0,
                    "Description": $('#editDutyDescription').val(),
                    "Order": $('#editDutyOrder').val(),
                    "Status": $('#editDutyStatus').val()
                });

                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Manager/Roles',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        swal.fire({
                            title: t("swal.success.head", { ns: "translations" }),
                            text: t("swal.success.subject", { ns: "translations" }),
                            icon: t("swal.success.icon", { ns: "translations" })
                        })
                    })
                    .catch(function (error) {
                        swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.subject", { ns: "translations" }) + error.message,
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })
                    });
            }
        })



    }
    handleAddClick = () => {
        const _this = this;
        const { t } = this.props
        if ($('#DutyName').val()) {
            swal.fire({
                title: "Hata",
                text: "Lütfen Görev İsmi Giriniz",
                icon: t("swal.success.icon", { ns: "translations" })
            })
            return
        }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "Id": $('#DutyId').val(),
                    "Name": $('#DutyName').val(),
                    "RoleType": 0,
                    "Description": $('#DutyDescription').val(),
                    "Order": $('#DutyOrder').val(),
                    "Status": $('#DutyStatus').val()
                });

                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Manager/Roles',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then((response) => {
                        swal.fire({
                            title: t("swal.success.head", { ns: "translations" }),
                            text: t("swal.success.subject", { ns: "translations" }),
                            icon: t("swal.success.icon", { ns: "translations" })
                        })
                        this.roleTable.ajax.reload()

                    })
                    .catch(function (error) {
                        swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.subject", { ns: "translations" }) + error.message,
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })
                    });
            }
        })



    }
    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.management.managerDuties", { ns: "translations" })}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(35, 3) == true ? <a href="#" data-toggle="modal" data-target="#duty-add"><button className="btn btn-primary">{t("addDuty")}</button></a> : ""}


                                <a onClick={() => {
                                    this.roleTable.ajax.reload()
                                }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table id="manager-duties-table" className="table-loader dataTable display  compact  w-100 mt-2">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.name")}</th>
                                            <th>{t("table.description")}</th>
                                            <th>{t("table.detail")}</th>
                                            <th>{t("table.operations")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </Division>
                        </Division>
                    </div>
                </div>

                <div id="duty-edit" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modal.editDuty")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <Division className="form-group">
                                    <label>{t("modal.ıdDuty")}</label>
                                    <input id='editDutyId' disabled readOnly className="form-control" type="text" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.dutyName")}</label>
                                    <input id='editDutyName' className="form-control" type="text" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.descriptionDuty")}</label>
                                    <input id='editDutyDescription' className="form-control" type="text" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.orderDuty")}</label>
                                    <input id='editDutyOrder' className="form-control" type="number" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.statusDuty")}</label>
                                    <select id='editDutyStatus' className="form-control">
                                        <option value="true">{t("active", { ns: "translations" })}</option>
                                        <option value="false">{t("passive", { ns: "translations" })}</option>
                                    </select>
                                </Division>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleEditClick} data-dismiss="modal">{t("modal.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="duty-add" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modal.addDuty")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">

                                <Division className="form-group">
                                    <label>{t("modal.dutyName")}</label>
                                    <input id='DutyName' className="form-control" type="text" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.descriptionDuty")}</label>
                                    <input id='DutyDescription' className="form-control" type="text" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.orderDuty")}</label>
                                    <input id='DutyOrder' className="form-control" type="number" />
                                </Division>
                                <Division className="form-group">
                                    <label>{t("modal.statusDuty")}</label>
                                    <select id='DutyStatus' className="form-control">
                                        <option value="true">{t("active", { ns: "translations" })}</option>
                                        <option value="false">{t("passive", { ns: "translations" })}</option>
                                    </select>
                                </Division>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleAddClick} data-dismiss="modal">{t("modal.add")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation(["managementManagerDuties", "translations"])(ManagerDuties);