import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'tr',
  lng: 'tr',
  returnObjects: true,
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
      userList: require('./locales/en/user/list.json'),
      userEdit: require('./locales/en/user/edit.json'),
      userAdd: require('./locales/en/user/add.json'),
      userAddCredit: require('./locales/en/user/add-credit.json'),
      systemStations: require('./locales/en/stations/stations.json'),
      systemParks: require('./locales/en/system/parks.json'),
      systemBicycles: require('./locales/en/system/bicycles.json'),
      systemMaintenanceControl: require('./locales/en/system/maintenance-control.json'),
      systemBicycleStatusDefinitions: require('./locales/en/system/bicycle-status-definitions.json'),
      systemStationZoneDefinitions: require('./locales/en/system/station-zone-definitions.json'),
      systemKioskControlLogs: require('./locales/en/system/kiosk-control-logs.json'),
      systemControls: require('./locales/en/system/controls.json'),

      recipe: require('./locales/en/finance/recipe.json'),
      paymentsType:  require('./locales/en/finance/payment-types.json'),
      processsType:  require('./locales/en/finance/processs-types.json'),
      zReports:  require('./locales/en/finance/z-reports.json'),
      retailSalereceipts:  require('./locales/en/finance/retail-sale-receipts.json'),
      VirtualPosManualPaymentEntry:  require('./locales/en/finance/virtual-pos-manual-payment-entry.json'),
      bicycleUsageTimePriceEdit:  require('./locales/en/finance/bicycle-usage-time-price-edit.json'),
      CreditCardProvisionReport:  require('./locales/en/report/credit-card-provision-report.json'),
      CardCreditSalesReport:  require('./locales/en/report/card-credit-sales-report.json'),
      bankConsensus:  require('./locales/en/report/bank-consensus.json'),
      monthlyGiro:  require('./locales/en/report/monthly-giro.json'),
      bicycleMovements:  require('./locales/en/report/bicycle-movements.json'),
      expeditionsBetweenStations:  require('./locales/en/report/expeditions-between-stations.json'),
      stationIntensity:  require('./locales/en/report/station-intensity.json'),
      TopCyclists:  require('./locales/en/report/top-cyclists.json'),
      RepairAndMaintenanceHistory:  require('./locales/en/report/repair-and-maintenance-history.json'),
      supportList:  require('./locales/en/support/list.json'),
      supportStatistics:  require('./locales/en/support/statistics.json'),
      GiroUsageSummaryReport:  require('./locales/en/report/giro-usage-summary-report.json'),
      Login:  require('./locales/en/login.json'),

      managementActiveUsers:  require('./locales/en/management/active-users.json'),
      managementSystemLogs: require('./locales/en/management/system-logs.json'),
      managementSystemSettings: require('./locales/en/management/system-settings.json'),
      managementEventNotificationDefinitions: require('./locales/en/management/event-notification-definitions.json'),
      managementManagerDuties: require('./locales/en/management/manager-duties.json'),
      managementManagers: require('./locales/en/management/managers.json'),
      gpsList: require('./locales/en/gps/list.json'),
      gpsHistory: require('./locales/en/gps/history.json'),
      gpsCommands: require('./locales/en/gps/commands.json'),
      gpsZoneAlarmDefinitions: require('./locales/en/gps/zone-alarm-definitions.json'),
      gpsThermalMap: require('./locales/en/gps/thermal-map.json'),
      Login: require('./locales/en/login.json'),
      Homepage:  require('./locales/en/homepage.json'),
      stationList:  require('./locales/en/stations/list.json'),
      stationParkEdit:  require('./locales/en/stations/parkEdit.json'),
      stationEdit:  require('./locales/en/stations/edit.json'),
      stationsAdd: require('./locales/en/stations/stationAdd.json'),
      faults:  require('./locales/en/fault/faults.json'),
      faultsStatus:  require('./locales/en/fault/fault-status.json'),
      notificationPost: require('./locales/en/management/notification-post.json'),

    },
    tr: {
      translations: require('./locales/tr/translations.json'),
      userList: require('./locales/tr/user/list.json'),
      userEdit: require('./locales/tr/user/edit.json'),
      userAdd: require('./locales/tr/user/add.json'),
      userAddCredit: require('./locales/tr/user/add-credit.json'),
      systemStations: require('./locales/tr/stations/stations.json'),
      stationsAdd: require('./locales/tr/stations/stationAdd.json'),
      systemParks: require('./locales/tr/system/parks.json'),
      systemBicycles: require('./locales/tr/system/bicycles.json'),
      systemMaintenanceControl: require('./locales/tr/system/maintenance-control.json'),      
      systemBicycleStatusDefinitions: require('./locales/tr/system/bicycle-status-definitions.json'),
      systemStationZoneDefinitions: require('./locales/tr/system/station-zone-definitions.json'),
      systemKioskControlLogs: require('./locales/tr/system/kiosk-control-logs.json'),
      systemControls: require('./locales/tr/system/controls.json'),
      managementActiveUsers: require('./locales/tr/management/active-users.json'),
      managementSystemLogs: require('./locales/tr/management/system-logs.json'),
      managementSystemSettings: require('./locales/tr/management/system-settings.json'),
      managementEventNotificationDefinitions: require('./locales/tr/management/event-notification-definitions.json'),
      notificationPost: require('./locales/tr/management/notification-post.json'),
      managementManagerDuties: require('./locales/tr/management/manager-duties.json'),
      managementManagers: require('./locales/tr/management/managers.json'),
      gpsList: require('./locales/tr/gps/list.json'),
      gpsHistory: require('./locales/tr/gps/history.json'),
      gpsCommands: require('./locales/tr/gps/commands.json'),      
      gpsZoneAlarmDefinitions: require('./locales/tr/gps/zone-alarm-definitions.json'),
      gpsThermalMap: require('./locales/tr/gps/thermal-map.json'),
      recipe:  require('./locales/tr/finance/recipe.json'),
      paymentsType:  require('./locales/tr/finance/payment-types.json'),
      processsType:  require('./locales/tr/finance/processs-types.json'),
      zReports:  require('./locales/tr/finance/z-reports.json'),
      retailSalereceipts:  require('./locales/tr/finance/retail-sale-receipts.json'),
      VirtualPosManualPaymentEntry:  require('./locales/tr/finance/virtual-pos-manual-payment-entry.json'),
      bicycleUsageTimePriceEdit:  require('./locales/tr/finance/bicycle-usage-time-price-edit.json'),
      CreditCardProvisionReport:  require('./locales/tr/report/credit-card-provision-report.json'),
      CardCreditSalesReport:  require('./locales/tr/report/card-credit-sales-report.json'),
      bankConsensus:  require('./locales/tr/report/bank-consensus.json'),
      monthlyGiro:  require('./locales/tr/report/monthly-giro.json'),
      bicycleMovements:  require('./locales/tr/report/bicycle-movements.json'),
      expeditionsBetweenStations:  require('./locales/tr/report/expeditions-between-stations.json'),
      stationIntensity:  require('./locales/tr/report/station-intensity.json'),
      TopCyclists:  require('./locales/tr/report/top-cyclists.json'),
      RepairAndMaintenanceHistory:  require('./locales/tr/report/repair-and-maintenance-history.json'),
      GiroUsageSummaryReport:  require('./locales/tr/report/giro-usage-summary-report.json'),
      supportList:  require('./locales/tr/support/list.json'),
      supportStatistics:  require('./locales/tr/support/statistics.json'),
      Login:  require('./locales/tr/login.json'),
      Homepage:  require('./locales/tr/homepage.json'),
      stationList:  require('./locales/tr/stations/list.json'),
      stationParkEdit:  require('./locales/tr/stations/parkEdit.json'),
      stationEdit:  require('./locales/tr/stations/edit.json'),
      faults:  require('./locales/tr/fault/faults.json'),
      faultsStatus:  require('./locales/tr/fault/fault-status.json'),

    }
  },
  ns: [
      'translations',"faults", "faultsStatus",'userList', 'userEdit', 'userAddCredit', 'userAdd', 
      "notificationPost",
      "systemStations", "systemParks", "systemBicycles", "systemMaintenanceControl", 
      "systemBicycleStatusDefinitions", "systemStationZoneDefinitions", "systemKioskControlLogs", "systemControls",  
       "managementActiveUsers", "managementSystemLogs", "managementSystemSettings", "managementEventNotificationDefinitions", 
       "managementManagerDuties", "managementManagers", "gpsList", "gpsHistory", "gpsCommands", "gpsZoneAlarmDefinitions", "gpsThermalMap",
       
       'supportStatistics','supportList', "stationsAdd",'Login','GiroUsageSummaryReport','RepairAndMaintenanceHistory','TopCyclists','stationIntensity',
       'expeditionsBetweenStations', 'bicycleMovements','monthlyGiro','bankConsensus','CardCreditSalesReport','CreditCardProvisionReport', 
       'bicycleUsageTimePriceEdit','VirtualPosManualPaymentEntry','retailSalereceipts','zReports','processsType','paymentsType','recipe',
       "gpsList", "gpsHistory", "gpsCommands", "gpsZoneAlarmDefinitions", "gpsThermalMap",
       "homepage", 'stationList','stationParkEdit',"stationEdit"
      ],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'tr'];

export default i18n;

// https://summernote.org/getting-started/#i18n-support
// Localization of DataTables component can be found in: https://datatables.net/plug-ins/i18n/
