import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { getEndPoint } from "../../class/api";
import axios from "axios";
import Text from '../../components/elements/text';
import Cookies from 'universal-cookie';
import Functions from '../../class/functions';
const cookies = new Cookies();

class SystemSettings extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props

        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $.extend($.summernote.lang, {
            "customLang": { ...(t("summerNoteEditorLocalization", { ns: "translations" })) }
        });
 
        $('.summernote-height').summernote({
            height: 200,
            lang: i18n.language == "en" ? "en-US" : "customLang"
        });

        $('.multiple-select').select2({
            tags: true,
            language: selectLanguage
        });


        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Setting',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then(function (response) {
                var resData = response.data.data[0]
                var data = JSON.parse(response.data.data[0].Configration)
                $("#webSubscriptionContract").summernote("code", data.webSubscriptionContract);
                $("#billFooterSettings").summernote("code", data.billFooterSettings);
                $("#billHeaderSettings").summernote("code", data.billHeaderSettings);
                $("#zReportHeaderSettings").summernote("code", data.zReportHeaderSettings);
                $("#kioskMainMenuInformationScreenTurkish").summernote("code", data.kioskMainMenuInformationScreenTurkish);
                $("#KioskMainMenuInformationScreenEnglish").summernote("code", data.KioskMainMenuInformationScreenEnglish);
                $("#KioskInformationScreenTurkish").summernote("code", data.KioskInformationScreenTurkish);
                $("#KioskInformationScreenEnglish").summernote("code", data.KioskInformationScreenEnglish);
                $("#KioskBicycleRentScreenTurkish").summernote("code", data.KioskBicycleRentScreenTurkish);
                $("#KioskBicycleRentScreenEnglish").summernote("code", data.KioskBicycleRentScreenEnglish);
                $("#webSubscriptionContractEnglish").summernote("code", data.webSubscriptionContractEnglish);
                $("#WebUserLoginFooter").summernote("code", data.WebUserLoginFooter);
                $("#WebUserLoginHeader").summernote("code", data.WebUserLoginHeader);
                $("#WebInformation").summernote("code", data.WebInformation);
                $("#MemberCardSubscriptionPrintSettings").summernote("code", data.MemberCardSubscriptionPrintSettings);
                $("#MemberCardSubscriptionPrintSettingsEnglish").summernote("code", data.MemberCardSubscriptionPrintSettingsEnglish);
                $("#projectName").val(data.projectName);
                $("#centreAddress").val(data.centreAddress);
                $("#centrePhone").val(data.centrePhone);
                $("#centreMail").val(data.centreMail);
                $("#bankProvisionReceivers").val(data.bankProvisionReceivers);
                $("#accountantReceivers").val(data.accountantReceivers);
                $("#notificationControlIntervalDescription").val(data.notificationControlIntervalDescription);
                $("#connectionTimeoutIntervalStation").val(data.connectionTimeoutIntervalStation);
                $("#connectionTimeoutIntervalPark").val(data.connectionTimeoutIntervalPark);
                $("#bicycleCountControlInterval").val(data.bicycleCountControlInterval);
                $("#extraOrLessBicycleCount").val(data.extraOrLessBicycleCount);
                $("#calorieSettings").val(data.calorieSettings);
                $("#googleApiKey").val(data.googleApiKey);
                $("#googleMapCenter").val(data.googleMapCenter);
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });



    }

    componentDidUpdate = () => this.componentDidMount();
    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const { t } = this.props

        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
        var jsonData = JSON.stringify(formDataEdit)
        var data = JSON.stringify({
            "SettingId": 1,
            "Title": "Kobis",
            "Logo": "logo.png",
            "Url": "www.kobis.com.tr",
            "Description": "<!-- Site Açıklama Metni: -->",
            "Configration": jsonData,
            "Status": true
        });

        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Setting',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.componentDidMount()
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header">
                        <h6 className="card-title">{t("menu.management.systemSettings", { ns: "translations" })}</h6>
                    </div>
                    <form onSubmit={this.handleSubmit}>

                        <div className="card-body">
                            <ul className="nav nav-tabs nav-tabs-highlight">
                                <li className="nav-item"><a href="#company-information" className="nav-link active" data-toggle="tab">{t("tabs.companyInformation")}</a></li>
                                <li className="nav-item"><a href="#web" className="nav-link" data-toggle="tab">{t("tabs.web")}</a></li>
                                <li className="nav-item"><a href="#user" className="nav-link" data-toggle="tab">{t("tabs.user")}</a></li>
                                <li className="nav-item"><a href="#kiosk" className="nav-link" data-toggle="tab">{t("tabs.kiosk")}</a></li>
                                <li className="nav-item"><a href="#notification-settings" className="nav-link" data-toggle="tab">{t("tabs.notificationSettings")}</a></li>
                                <li className="nav-item"><a href="#parameters" className="nav-link" data-toggle="tab">{t("tabs.parameters")}</a></li>
                                <li className="nav-item"><a href="#z-report-and-bill" className="nav-link" data-toggle="tab">{t("tabs.zReportAndBill")}</a></li>
                            </ul>

                            <div className="tab-content">

                                <div className="tab-pane fade show active" id="company-information">
                                    <div className="input-group">
                                        <div className="d-block w-100">
                                            <span>{t("projectName")}</span>
                                            <div className="d-flex">
                                                <span className="input-group-prepend">
                                                    <span className="input-group-text"><i className="icon-bubble6"></i></span>
                                                </span>
                                                <input name='projectName' id='projectName' type="text" className="form-control w-100" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-group mt-2">
                                        <div className="d-block w-100">
                                            <span>{t("centreAddress")}</span>
                                            <div className="d-flex">
                                                <span className="input-group-prepend">
                                                    <span className="input-group-text"><i className="icon-notebook"></i></span>
                                                </span>
                                                <input type="text" name='centreAddress' id='centreAddress' className="form-control w-100" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-group mt-2">
                                        <div className="d-block w-100">
                                            <span>{t("centrePhone")}</span>
                                            <div className="d-flex">
                                                <span className="input-group-prepend">
                                                    <span className="input-group-text"><i className="icon-phone"></i></span>
                                                </span>
                                                <input type="tel" name='centrePhone' id='centrePhone' className="form-control w-100" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-group mt-2">
                                        <div className="d-block w-100">
                                            <span>{t("centreMail")}</span>
                                            <div className="d-flex">
                                                <span className="input-group-prepend">
                                                    <span className="input-group-text"><i className="icon-envelope"></i></span>
                                                </span>
                                                <input type="email" name='centreMail' id='centreMail' className="form-control w-100" />
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="tab-pane fade" id="web">
                                    <h4>{t("webSubscriptionContract")}</h4>
                                    <textarea name='webSubscriptionContract' id="webSubscriptionContract" className="summernote-height" />
                                    <h4>{t("webSubscriptionContractEnglish")}</h4>
                                    <textarea name='webSubscriptionContractEnglish' id="webSubscriptionContractEnglish" className="summernote-height" />
                                    <h4>{t("webUserLoginFooter")}</h4>
                                    <textarea name='WebUserLoginFooter' id="WebUserLoginFooter" className="summernote-height" />
                                    <h4>{t("webUserLoginHeader")}</h4>
                                    <textarea name='WebUserLoginHeader' id="WebUserLoginHeader" className="summernote-height" />
                                    <h4>{t("webInformation")}</h4>
                                    <textarea id="WebInformation" name="WebInformation" className="summernote-height" />
                                </div>

                                <div className="tab-pane fade" id="user">
                                    <h4>{t("memberCardSubscriptionPrintSettings")}</h4>
                                    <textarea name='MemberCardSubscriptionPrintSettings' id="MemberCardSubscriptionPrintSettings" className="summernote-height" />
                                    <h4>{t("memberCardSubscriptionPrintSettingsEnglish")}</h4>
                                    <textarea name='MemberCardSubscriptionPrintSettingsEnglish' id="MemberCardSubscriptionPrintSettingsEnglish" className="summernote-height" />
                                </div>

                                <div className="tab-pane fade" id="kiosk">
                                    <div className="d-lg-flex">
                                        <ul className="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
                                            <li className="nav-item"><a href="#main-menu" className="nav-link active" data-toggle="tab">{t("tabs.mainMenu")}</a></li>
                                            <li className="nav-item"><a href="#information" className="nav-link" data-toggle="tab">{t("tabs.information")}</a></li>
                                            <li className="nav-item"><a href="#rent-contract" className="nav-link" data-toggle="tab">{t("tabs.rentContract")}</a></li>
                                        </ul>
                                        <div className="tab-content flex-lg-fill p-2">
                                            <div className="alert alert-success m-0 p-2">
                                                <ul className="media-list">
                                                    {t("kioskInformation").map((element, index) => {
                                                        return (<li className="media" key={`kiosk-info-${index}`}><Text Tag="span">{element}</Text></li>);
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="tab-pane fade show active" id="main-menu">
                                                <h4>{t("kioskMainMenuInformationScreenTurkish")}</h4>
                                                <textarea name='kioskMainMenuInformationScreenTurkish' id="kioskMainMenuInformationScreenTurkish" className="summernote-height" />

                                                <h4>{t("kioskMainMenuInformationScreenEnglish")}</h4>
                                                <textarea name='KioskMainMenuInformationScreenEnglish' id="KioskMainMenuInformationScreenEnglish" className="summernote-height" />
                                            </div>
                                            <div className="tab-pane fade" id="information">
                                                <h4>{t("kioskInformationScreenTurkish")}</h4>
                                                <textarea name='KioskInformationScreenTurkish' id="KioskInformationScreenTurkish" className="summernote-height" />

                                                <h4>{t("kioskInformationScreenEnglish")}</h4>
                                                <textarea name='KioskInformationScreenEnglish' id="KioskInformationScreenEnglish" className="summernote-height" />
                                            </div>
                                            <div className="tab-pane fade" id="rent-contract">
                                                <h4>{t("kioskBicycleRentContractTurkish")}</h4>
                                                <textarea name='KioskBicycleRentScreenTurkish' id="KioskBicycleRentScreenTurkish" className="summernote-height" />

                                                <h4>{t("kioskBicycleRentContractEnglish")}</h4>
                                                <textarea name='KioskBicycleRentScreenEnglish' id="KioskBicycleRentScreenEnglish" className="summernote-height" />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="notification-settings">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>{t("notificationControlInterval")}</h4>
                                            <div className="form-group">
                                                <label>{t("notificationControlIntervalDescription")}</label>
                                                <div className="input-group input-group-lg">
                                                    <select name='notificationControlIntervalDescription' id='notificationControlIntervalDescription' className='form-control' defaultValue="-1">
                                                        <option value="0">0</option>
                                                        <option value="30">30</option>
                                                        <option value="60">60</option>
                                                        <option value="90">90</option>
                                                        <option value="120">120</option>
                                                        <option value="150">150</option>
                                                        <option value="180">180</option>
                                                        <option value="210">210</option>
                                                        <option value="240">240</option>
                                                        <option value="270">270</option>
                                                        <option value="300">300</option>
                                                        <option value="330">330</option>
                                                        <option value="360">360</option>
                                                        <option value="390">390</option>
                                                        <option value="420">420</option>
                                                        <option value="450">450</option>
                                                        <option value="480">480</option>
                                                        <option value="510">510</option>
                                                        <option value="540">540</option>
                                                        <option value="570">570</option>
                                                        <option value="600">600</option>
                                                        <option value="630">630</option>
                                                        <option value="660">660</option>
                                                        <option value="690">690</option>
                                                        <option value="720">720</option>
                                                        <option value="750">750</option>
                                                        <option value="780">780</option>
                                                        <option value="810">810</option>
                                                        <option value="840">840</option>
                                                        <option value="870">870</option>
                                                        <option value="900">900</option>
                                                        <option value="930">930</option>
                                                        <option value="960">960</option>
                                                        <option value="990">990</option>
                                                        <option value="1020">1020</option>
                                                        <option value="1050">1050</option>
                                                        <option value="1080">1080</option>
                                                        <option value="1110">1110</option>
                                                        <option value="1140">1140</option>
                                                        <option value="1170">1170</option>
                                                        <option value="1200">1200</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <h4>{t("station")}</h4>
                                            <div className="form-group">
                                                <label>{t("connectionTimeoutInterval")}</label>
                                                <div className="input-group input-group-lg">
                                                    <select name='connectionTimeoutIntervalStation' id='connectionTimeoutIntervalStation' className='form-control select1' defaultValue="-1">

                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                        <option value="32">32</option>
                                                        <option value="33">33</option>
                                                        <option value="34">34</option>
                                                        <option value="35">35</option>
                                                        <option value="36">36</option>
                                                        <option value="37">37</option>
                                                        <option value="38">38</option>
                                                        <option value="39">39</option>
                                                        <option value="40">40</option>
                                                        <option value="41">41</option>
                                                        <option value="42">42</option>
                                                        <option value="43">43</option>
                                                        <option value="44">44</option>
                                                        <option value="45">45</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <h4>{t("park")}</h4>
                                            <div className="form-group">
                                                <label>{t("connectionTimeoutInterval")}</label>
                                                <div className="input-group input-group-lg">
                                                    <select name='connectionTimeoutIntervalPark' id='connectionTimeoutIntervalPark' className='form-control select1' defaultValue="-1">
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                        <option value="32">32</option>
                                                        <option value="33">33</option>
                                                        <option value="34">34</option>
                                                        <option value="35">35</option>
                                                        <option value="36">36</option>
                                                        <option value="37">37</option>
                                                        <option value="38">38</option>
                                                        <option value="39">39</option>
                                                        <option value="40">40</option>
                                                        <option value="41">41</option>
                                                        <option value="42">42</option>
                                                        <option value="43">43</option>
                                                        <option value="44">44</option>
                                                        <option value="45">45</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <h4>{t("bicycle")}</h4>
                                            <div className="form-group">
                                                <label>{t("bicycleCountControlInterval")}</label>
                                                <div className="input-group input-group-lg">
                                                    <select name='bicycleCountControlInterval' id='bicycleCountControlInterval' className='form-control' defaultValue="-1">
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="31">31</option>
                                                        <option value="32">32</option>
                                                        <option value="33">33</option>
                                                        <option value="34">34</option>
                                                        <option value="35">35</option>
                                                        <option value="36">36</option>
                                                        <option value="37">37</option>
                                                        <option value="38">38</option>
                                                        <option value="39">39</option>
                                                        <option value="40">40</option>
                                                        <option value="41">41</option>
                                                        <option value="42">42</option>
                                                        <option value="43">43</option>
                                                        <option value="44">44</option>
                                                        <option value="45">45</option>
                                                        <option value="46">46</option>
                                                        <option value="47">47</option>
                                                        <option value="48">48</option>
                                                        <option value="49">49</option>
                                                        <option value="50">50</option>
                                                        <option value="51">51</option>
                                                        <option value="52">52</option>
                                                        <option value="53">53</option>
                                                        <option value="54">54</option>
                                                        <option value="55">55</option>
                                                        <option value="56">56</option>
                                                        <option value="57">57</option>
                                                        <option value="58">58</option>
                                                        <option value="59">59</option>
                                                        <option value="60">60</option>
                                                        <option value="61">61</option>
                                                        <option value="62">62</option>
                                                        <option value="63">63</option>
                                                        <option value="64">64</option>
                                                        <option value="65">65</option>
                                                        <option value="66">66</option>
                                                        <option value="67">67</option>
                                                        <option value="68">68</option>
                                                        <option value="69">69</option>
                                                        <option value="70">70</option>
                                                        <option value="71">71</option>
                                                        <option value="72">72</option>
                                                        <option value="73">73</option>
                                                        <option value="74">74</option>
                                                        <option value="75">75</option>
                                                        <option value="76">76</option>
                                                        <option value="77">77</option>
                                                        <option value="78">78</option>
                                                        <option value="79">79</option>
                                                        <option value="80">80</option>
                                                        <option value="81">81</option>
                                                        <option value="82">82</option>
                                                        <option value="83">83</option>
                                                        <option value="84">84</option>
                                                        <option value="85">85</option>
                                                        <option value="86">86</option>
                                                        <option value="87">87</option>
                                                        <option value="88">88</option>
                                                        <option value="89">89</option>
                                                        <option value="90">90</option>
                                                        <option value="91">91</option>
                                                        <option value="92">92</option>
                                                        <option value="93">93</option>
                                                        <option value="94">94</option>
                                                        <option value="95">95</option>
                                                        <option value="96">96</option>
                                                        <option value="97">97</option>
                                                        <option value="98">98</option>
                                                        <option value="99">99</option>
                                                        <option value="100">100</option>
                                                        <option value="101">101</option>
                                                        <option value="102">102</option>
                                                        <option value="103">103</option>
                                                        <option value="104">104</option>
                                                        <option value="105">105</option>
                                                        <option value="106">106</option>
                                                        <option value="107">107</option>
                                                        <option value="108">108</option>
                                                        <option value="109">109</option>
                                                        <option value="110">110</option>
                                                        <option value="111">111</option>
                                                        <option value="112">112</option>
                                                        <option value="113">113</option>
                                                        <option value="114">114</option>
                                                        <option value="115">115</option>
                                                        <option value="116">116</option>
                                                        <option value="117">117</option>
                                                        <option value="118">118</option>
                                                        <option value="119">119</option>
                                                        <option value="120">120</option>
                                                        <option value="121">121</option>
                                                        <option value="122">122</option>
                                                        <option value="123">123</option>
                                                        <option value="124">124</option>
                                                        <option value="125">125</option>
                                                        <option value="126">126</option>
                                                        <option value="127">127</option>
                                                        <option value="128">128</option>
                                                        <option value="129">129</option>
                                                        <option value="130">130</option>
                                                        <option value="131">131</option>
                                                        <option value="132">132</option>
                                                        <option value="133">133</option>
                                                        <option value="134">134</option>
                                                        <option value="135">135</option>
                                                        <option value="136">136</option>
                                                        <option value="137">137</option>
                                                        <option value="138">138</option>
                                                        <option value="139">139</option>
                                                        <option value="140">140</option>
                                                        <option value="141">141</option>
                                                        <option value="142">142</option>
                                                        <option value="143">143</option>
                                                        <option value="144">144</option>
                                                        <option value="145">145</option>
                                                        <option value="146">146</option>
                                                        <option value="147">147</option>
                                                        <option value="148">148</option>
                                                        <option value="149">149</option>
                                                        <option value="150">150</option>
                                                        <option value="151">151</option>
                                                        <option value="152">152</option>
                                                        <option value="153">153</option>
                                                        <option value="154">154</option>
                                                        <option value="155">155</option>
                                                        <option value="156">156</option>
                                                        <option value="157">157</option>
                                                        <option value="158">158</option>
                                                        <option value="159">159</option>
                                                        <option value="160">160</option>
                                                        <option value="161">161</option>
                                                        <option value="162">162</option>
                                                        <option value="163">163</option>
                                                        <option value="164">164</option>
                                                        <option value="165">165</option>
                                                        <option value="166">166</option>
                                                        <option value="167">167</option>
                                                        <option value="168">168</option>
                                                        <option value="169">169</option>
                                                        <option value="170">170</option>
                                                        <option value="171">171</option>
                                                        <option value="172">172</option>
                                                        <option value="173">173</option>
                                                        <option value="174">174</option>
                                                        <option value="175">175</option>
                                                        <option value="176">176</option>
                                                        <option value="177">177</option>
                                                        <option value="178">178</option>
                                                        <option value="179">179</option>
                                                        <option value="180">180</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>{t("extraOrLessBicycleCount")}</label>
                                                <div className="input-group input-group-lg">
                                                    <select name='extraOrLessBicycleCount' id='extraOrLessBicycleCount' className='form-control' defaultValue="-1">
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h4>{t("bankProvisionSettings")}</h4>
                                            <div className="form-group">
                                                <label>{t("bankProvisionClosingReceivers")}</label>
                                                <div className="input-group input-group-lg">
                                                    <input id="bankProvisionReceivers" name="bankProvisionReceivers" className='form-control ' data-fouc />

                                                </div>
                                            </div>
                                            <h4>{t("accountantSettings")}</h4>
                                            <div className="form-group">
                                                <label>{t("accountantEntegrationReceivers")}</label>
                                                <div className="input-group input-group-lg">
                                                    <input id="accountantReceivers" name="accountantReceivers" className='form-control ' data-fouc />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="tab-pane fade" id="parameters">
                                    <h4>{t("calorieSettings")}</h4>
                                    <div className="form-group">
                                        <label>{t("calorieSettingsDescription")}</label>
                                        <div className="input-group input-group-lg">
                                            <input name='calorieSettings' id="calorieSettings" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <h4>{t("googleSettings")}</h4>
                                    <div className="form-group">
                                        <label>{t("googleApiKey")}</label>
                                        <div className="input-group input-group-lg">
                                            <input name='googleApiKey' id="googleApiKey" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("googleMapCenter")}</label>
                                        <div className="input-group input-group-lg">
                                            <input name='googleMapCenter' id="googleMapCenter" type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="z-report-and-bill">
                                    <h4>{t("zReportHeaderSettings")}</h4>
                                    <textarea name='zReportHeaderSettings' id="zReportHeaderSettings" className="summernote-height" />

                                    <h4>{t("billHeaderSettings")}</h4>
                                    <textarea name='billHeaderSettings' id="billHeaderSettings" className="summernote-height" />

                                    <h4>{t("billFooterSettings")}</h4>
                                    <textarea name='billFooterSettings' id="billFooterSettings" className="summernote-height" />
                                </div>
                            </div>
                        </div>
                        {Functions.RoleControl(39, 4) == true ? <button type='submit' className="btn btn-primary mt-2">{t("save")}</button> : ""}
                    </form>
                </div>
            </div>
        );
    }
}

export default withTranslation(["managementSystemSettings", "translations"])(SystemSettings);
function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}