import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";

import { getEndPoint } from "../../class/api";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import $ from "jquery"
const cookies = new Cookies();
var paymentData = {
    Name: "",
    Name2: "",
    TagId: "",
    Description: "",
    Order: 0,
    Status: 1
}
class PaymentTypes extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);

        this.cellPhoneRef = React.createRef(null);
        this.TableRef = React.createRef(null);
    }
    tableInstall = () => {
        const { t } = this.props;
        const _this = this;
        if (this.payments != undefined) {

            this.payments.destroy();
        }

        this.payments = $('#payments-table').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Finance/PaymentMethods',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#payments-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    ParentId: 0
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "searching": false,
            "columns": [

                { data: "Order" },
                { data: "FinancePaymentMethodId", orderable: false },
                {
                    data: "Name", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return data
                    }
                },
                {
                    data: "Status", render: function (data, type, row) {
                        if (!data) {
                            return '<a class="text-danger"><i class="icon-dash "></i></a>';
                        }
                        else {
                            return ' <a class="text-success">  <i class="icon-check"></i></a>'
                        }
                    }
                },
                {
                    width: "250px",
                    className: 'operation',
                    data: "FinancePaymentMethodId", render: function (data, type, row) {
                        return `
                <div class="form-group">
                        <div class="d-flex justify-content-between align-items-center">
                            ${Functions.RoleControl(26, 2) == true ? ` <button type="button" class="btn btn-warning" data-toggle="modal" id="paymentEdit" data-target="#paymentModalEdit"><i class="icon-pencil5 mr-2"></i>${t("table.paymentEdit")}</button>` : ""}
                            ${Functions.RoleControl(26, 4) == true ? ` <button type="button" class="btn btn-danger"  id="paymentDelete" ><i class="icon-trash "></i></button>` : ""}
                            </div> 
                    </div>`
                    }, "orderable": false
                },

            ]

        });
        new $.fn.dataTable.Responsive(this.payments, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        this.tableInstall();
        $('#payments-table tbody').on('click', 'td #paymentEdit', function () {//payment edit butonu
            var tr = $(this).closest('tr');
            var row = _this.payments.row(tr);
            var data = row.data()
            $('#EditID').val(data.FinancePaymentMethodId)
            $('#EditName').val(data.Name)
            $('#EditName2').val(data.Name2)
            $('#EditTagId').val(data.TagId)
            $('#EditDescription').val(data.Description)
            $('#EditOrder').val(data.Order)
            $('#EditStatus').val(String(data.Status))
        });

        $('#payments-table tbody').on('click', 'td #paymentDelete', function () {//status delete butonu
            var tr = $(this).closest('tr');
            var row = _this.payments.row(tr);
            swal.fire({
                title: 'Silmek İstediğinize Emin Misiniz?',
                showDenyButton: true,
                confirmButtonText: 'Kaydet.',
                denyButtonText: `Vazgeç.`,
            }).then((result) => {
                if (result.isConfirmed) {
                    var config = {
                        method: 'delete',
                        url: getEndPoint() + '/api/Admin/Finance/PaymentMethods',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({
                            "FinancePaymentMethodId": row.data().FinancePaymentMethodId
                        })
                    };

                    axios(config)

                        .then(function (response) {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            _this.payments.ajax.reload()
                        })
                        .catch(function (error) {
                            Functions.requestAxiosErrorFunction(error);
                        });
                }
            })



        });

    }
    componentDidUpdate = () => this.componentDidMount();
    handleClickEdit = () => {
        const _this = this;
        const { t } = this.props
        if ($('#EditName').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("Ad") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return false
        }

        var data = JSON.stringify({
            "FinancePaymentMethodId": $('#EditID').val(),
            "Name": $('#EditName').val(),
            "Name2": $('#EditName2').val(),
            "TagId": $('#EditTagId').val(),
            "Description": $('#EditDescription').val(),
            "Order": $('#EditOrder').val(),
            "Status": $('#EditStatus').val()
        });
        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Finance/PaymentMethods',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.payments.ajax.reload()
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    handleClickAdd = () => {
        const _this = this;
        const { t } = this.props

        if ($('#Name').val() == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("Ad") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return false
        }

        var data = JSON.stringify({
            "Name": $('#Name').val(),
            "Name2": $('#Name2').val(),
            "TagId": $('#TagId').val(),
            "Description": $('#Description').val(),
            "Order": $('#Order').val(),
            "Status": $('#Status').val()
        });
        var config = {
            method: 'post',
            url: getEndPoint() + '/api/Admin/Finance/PaymentMethods',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {

                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.payments.ajax.reload()
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props;

        return (
            <div className="payment-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("payments")}</h6>

                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(26, 3) == true ? <button type="button" className="btn btn-success" data-toggle="modal" data-target="#paymentModalAdd">{t("paymentTypeAdd")}</button> : ""}
                                <a onClick={() => { this.payments.ajax.reload() }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="payments-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.order")}</th>
                                            <th>{t("table.paymentId")}</th>
                                            <th>{t("table.paymentName")}</th>
                                            <th>{t("table.paymentStatus")}</th>
                                            <th>{t("table.operation")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div id="paymentModalAdd" className="modal fade" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">
                                    {t("paymentEdit.paymentEdit")} Ekle
                                </h6>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("table.paymentName")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="Name" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Name")} 2</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="Name2" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.TagId")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="TagId" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Description")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="Description" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Order")}</label>
                                    <div className="col-lg-10">
                                        <input type="number" id="Order" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("selectStatus.selectHead", { ns: "translations" })}</label>
                                    <div className="col-lg-10">
                                        <select id="Status" className="form-control">
                                            <option value="true">{t("selectStatus.select1", { ns: "translations" })}</option>
                                            <option value="false">{t("selectStatus.select2", { ns: "translations" })}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("close")}</button>
                                <button type="button" data-dismiss="modal" onClick={this.handleClickAdd} className="btn btn-primary">{t("saveChanges")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="paymentModalEdit" className="modal fade" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">
                                    {t("paymentEdit.paymentEdit")}
                                </h6>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.ID")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="EditID" disabled readOnly className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Name")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="EditName" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Name")} 2</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="EditName2" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.TagId")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="EditTagId" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Description")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" id="EditDescription" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("modal.Order")}</label>
                                    <div className="col-lg-10">
                                        <input type="number" id="EditOrder" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("selectStatus.selectHead", { ns: "translations" })}</label>
                                    <div className="col-lg-10">
                                        <select id="EditStatus" className="form-control">
                                            <option value="true">{t("selectStatus.select1", { ns: "translations" })}</option>
                                            <option value="false">{t("selectStatus.select2", { ns: "translations" })}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("close")}</button>
                                <button type="button" data-dismiss="modal" onClick={this.handleClickEdit} className="btn btn-primary">{t("saveChanges")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['paymentsType', 'translations'])(PaymentTypes);
