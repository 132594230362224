import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import axios from 'axios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getEndPoint } from '../../class/api'
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Functions from '../../class/functions'; 
// data Table 
import TableData from "../../components/table.js";
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net-buttons/js/buttons.print';
import 'bootstrap/dist/js/bootstrap';
import jsZip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import '../../css/loadingControl.css';
//
import { swal } from "sweetalert2/dist/sweetalert2.all";
import { ReactSession } from 'react-client-session'
import Cookies from 'universal-cookie'
const cookies = new Cookies();

class GpsGetDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stationData: [],
            vehicleData: [],
            vehicleSafeData: [],
            vehicleLastProcessingTripStationName : "-",
            vehicleLastProcessingTripUser : [],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.backList = this.backList.bind(this);
        this.openWindowMaps = this.openWindowMaps.bind(this);
    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;

        
        document.getElementById("page-header").remove();
        document.getElementById("navigation").remove();
        document.getElementById("sidebar").parentElement.remove();

        var data = JSON.stringify({
            "per_page": 1, 
            "Filter": {
                "VehicleNo": this.props.router.params.VehicleNo
            }
        });

        var config = {
            method: 'PUT',
            url: getEndPoint() + '/api/Admin/OtherModules/GPS/Local',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        }; 
        axios(config)
            .then(function (response) {
                // $('.statusSelect').val(response.data.data[0].Status === false ? 0 : 1)
                // $('.showOnMapSelect').val(response.data.data[0].ShowOnMap)
                _this.setState({
                    vehicleData: response.data.data[0]
                })

                if ( response.data.pagingData.totalCount === 0 ) { 
                                Swal.fire({
                                    title: 'HATA!',
                                    text: `Erişmeye çalıştığınız cihaz bulunamadı.`,
                                    confirmButtonText: 'Kapat',
                                    icon: 'error'
                                })
                                return false ;  
                }

                




                            // DETAY   
                            var data2 = JSON.stringify({
                                "per_page": 1, 
                                "Filter": {
                                    "VehicleId": response.data.data[0]?.vehicle?.VehicleId
                                }
                            });
                            var config2 = {
                                method: 'PUT',
                                url: getEndPoint() + '/api/Admin/Station/Vehicle',
                                headers: {
                                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                data: data2
                            }; 
                            axios(config2)
                                .then(function (response) {
                                    _this.setState({
                                        vehicleSafeData: response.data.data[0],
                                        vehicleLastProcessingTripUser : response.data.data[0].User,
                                        vehicleLastProcessingTripStationName : response.data.data[0].LastProcessingTrip.StationParkUnitEntry.StationName + " istasyonu, " + response.data.data[0].LastProcessingTrip.StationParkUnitEntry.StationNumber + " numaralı park"
                                    })


                                // document.getElementById("sidebar").parentElement.remove();
                                $("body").find(".div-loader").removeClass("div-loader");
                                $("body").find(".div-loader-loading").addClass("div-loader");

 
                                })
                                .catch(function (error) {
                                    Functions.requestAxiosErrorFunction(error);
                                });

                                // DETAY END 


            })
            .catch(function (error) { 
                Functions.requestAxiosErrorFunction(error);
            });




        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $('.regionSelect').select2({
            language: selectLanguage
        });

        // var config = {
        //     method: 'PUT',
        //     url: getEndPoint() + '/api/Admin/OtherModules/GPS/Local',
        //     headers: {
        //         'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     data: JSON.stringify({ "per_page": -1 })
        // };
        // axios(config)
        //     .then(function (response) {
        //         $('.regionSelect').empty().trigger("change");
        //         var regionSelect = $('.regionSelect');

        //         response.data.data.forEach((elm) => {
        //             var option = new Option(
        //                 elm.Name,
        //                 elm.StationRegionId,
        //                 (_this.state.stationData.RegionId == elm.StationRegionId),
        //                 (_this.state.stationData.RegionId == elm.StationRegionId)
        //             );
        //             regionSelect.append(option).trigger('change');
        //         });

        //     })
        //     .catch(function (error) {
        //         Functions.requestAxiosErrorFunction(error);
        //     });


    }
    handleSubmit(e) {
        e.preventDefault();
        const _this = this;
        const { t } = this.props;

        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
 
        window.location.href = '/bicycles/get/' + $("#idVehicleNo").val();

    }
    backList() {
        window.close()
        self.close()
        window.location.href = '/stations/list/' + this.state.stationData.StationId;
    }
    openWindowMaps() { 
        var lat =  this.state.vehicleData?.Lat ;
        var long =  this.state.vehicleData?.Long ;
        // var lat =  0 ;
        // var long =  0;
        window.open('https://www.google.com/maps/search/?api=1&query='+lat+','+long+'','KOBİS MAP','width=800,height=600,status=no,scrollbars=yes,toolbar=0,menubar=no,resizable=yes,top=460,left=600');
    }

     render() {
        const { t } = this.props;
        const divH1Style = {
            fontSize: '32px',
            textAlign: 'center',
            margin : 0 
          }; 

          

        const kobisornekBisiklet = require("../../img/vehicleB.png");
        return (
            <div className="p-2 ">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <form onSubmit={this.handleSubmit}>
                                <Division className="form-group row">
                                    <Division className="col" >
                                        <input defaultValue={this.props.router.params.VehicleNo} type="text" required name="VehicleNo" id="idVehicleNo" className="form-control form-control-lg" />
                                        </Division>
                                    <Division className="col-2">
                                        <button onClick={this.handleClick} type="submit" className="btn btn-secondary w-100 h-100">{t("GETİR", { ns: "translations" })}</button>
                                    </Division>
                                    <Division className="col-2">
                                        <button type="button" onClick={this.backList} className="btn btn-danger w-100 h-100">{t("KAPAT", { ns: "translations" })}</button>
                                    </Division>
                                </Division>

                            </form>

                            
                            <Division className="form-group row div-loader-loading text-center"> 
                                   <h5> Yükleniyor...</h5>
                                </Division>
                            <Division className="form-group row div-loader"> 
                                    <Division className="col text-center">
                                    {/* <a href='javascript:void(0)' onclick="window.open('https://www.google.com/maps/search/?api=1&query=" + row.Lat + "," + row.Long + "','KOBİS','width=800,height=600,status=no,scrollbars=yes,toolbar=0,menubar=no,resizable=yes,top=460,left=600')\">Konumun alındığı Tarih:  5.03.2023 12:38:02</a> */}
                                    {/* <a href='javascript:void(0)' onClick="window.open('https://www.google.com/maps/search/?api=1&query={this.state.vehicleData?.Lat},{this.state.vehicleData?.Long}','KOBİS','width=800,height=600,status=no,scrollbars=yes,toolbar=0,menubar=no,resizable=yes,top=460,left=600')">Konumun alındığı Tarih:  {this.state.vehicleData?.updated_at}</a> */}
                                    <a href='javascript:void(0)' onClick={this.openWindowMaps}>Konumun alındığı Tarih:  {moment(this.state.vehicleData?.updated_at).format('YYYY-MM-DD HH:mm:ss')}</a>
                                    </Division> 
                                </Division>

                            {/* <a href="javascript:void(0)" onclick="openWindow('https://www.google.com/maps/search/?api=1&amp;query=40.760731,29.887753')">
                            <img style="width: 100%;" src="http://osm-static-maps.herokuapp.com/?geojson=[{'type':'Point','coordinates':[29.887753,40.760731]}]&amp;height=300&amp;width=600&amp;zoom=13">
                            Konumun alındığı Tarih:  5.03.2023 12:38:02
                        </a> */}


                                <Division className="form-group row div-loader"> 
                                    <Division className="col-3">
                                    
                                    <Division className="col bg-success p-2 text-center text-light">
                                       
                                        <h1  style={divH1Style} > {this.props.router.params.VehicleNo}  </h1> 
                                    
                                     </Division> 
                                    <Division className="col bg-info p-1 text-center text-light"> 
                                        <h5>{this.state.vehicleSafeData.StatusIdTxt}</h5>
                                    
                                     </Division> 
                                     </Division> 
                                    <Division className="col-6">
 
                                    <img width={"100%"} src={kobisornekBisiklet} />
                                     </Division> 
                                    <Division className="col-3">
  
                                     </Division> 
                                </Division>

 

                                <Division className="form-group row div-loader">
                                    <Division className="col-6">
                                    <Text Tag="label" className="col-form-label col text-center"><b>{t("SON BAĞLANTI")}</b></Text> 
                                    </Division>
                                    <Division className="col-6">
                                    <Text Tag="label" className="col-form-label col text-center"><b>{t("SON KİRALAMA")}</b></Text> 
                                     </Division>
                                </Division>


                                <Division className="form-group row div-loader">
                                    <Division className="col-6"> 
                                        <Division className="form-group form-group-feedback form-group-feedback-left"> 
                                        { this.state.vehicleLastProcessingTripStationName}
                                         <br/>
                                        {moment(this.state.vehicleData?.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                                        </Division>
                                    </Division>
                                    <Division className="col-6"> 
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <a href={ '/users/edit/' + this.state.vehicleLastProcessingTripUser?.id} target="_blank">
                                            { this.state.vehicleLastProcessingTripUser?.Name} { this.state.vehicleLastProcessingTripUser?.Surname} 
                                            </a>
                                        </Division>
                                    </Division>
                                </Division>

                                <Division className="form-group row div-loader">
                                    <Division className="col-6"> 
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                        GPS <br/>
                                        <h2>% {this.state.vehicleData?.BatteryLevel}  </h2>
                                        Son Bağlantı: {moment(this.state.vehicleData?.updated_at).format('YYYY-MM-DD HH:mm:ss')} <br/>
                                        Konum : {this.state.vehicleData?.Lat},{this.state.vehicleData?.Long}<br/>
                                        GSM Sinyal: {this.state.vehicleData?.GSMSignal}
                                        </Division>
                                    </Division>
                                    <Division className="col-6"> 
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                        Kiralama {moment(this.state.vehicleSafeData.ProcessDate).format('YYYY-MM-DD HH:mm:ss')}  ( {this.state.vehicleSafeData.ProcessDateDiff} )<br/>
                                        Teslim Tarihi: {moment(this.state.vehicleSafeData?.LastProcessingTrip?.ExitDate).format('YYYY-MM-DD HH:mm:ss')}
                                        </Division>
                                    </Division>
                                </Division>

                                {/* 
                                // son 10 bisiklet hareketi getirilecek . 
                                <Division className="form-group row">
                                    <TableData
                                        cardTitle={t("GPS Listesi")}
                                        abilityid={7}
                                        auth={0}
                                        length="100"
                                        ordering={false}
                                        customOrder={[0, "ASC"]}
                                        searching ={false} 
                                        customClass="col-12"
                                        ajaxUrl="/api/Admin/OtherModules/GPS/Local"
                                        tableName="bicycle-gps-table"
                                        // filter={this.gpsListFilter}
                                        // columns={this.gpsListColumn}
                                        />
                                </Division> */}


                        </div>
                    </div>
                </div>




            </div>
        )
    }
}
const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
export default withTranslation(['stationEdit', 'translations'])(withRouter(GpsGetDetail));
function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}