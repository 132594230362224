import $ from "jquery";

export default class Functions {
    static createScriptFromSource(src, async = false) {
        const script = document.createElement("script");
        script.src = src;
        script.async = async;
        document.body.appendChild(script);
    }

    static createScriptFromCode(code, async) {
        const script = document.createElement("script");
        script.innerHTML = code;
        script.type = "text/javascript";
        script.async = async;
        document.body.appendChild(script);
    }
}