import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";
import jq from 'jquery'
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../class/functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import { ReactSession } from 'react-client-session';
import DatePicker from "../../components/elements/DatePicker";
import { Select } from "../../components/Select";
import moment from "moment";
let firstDateHours = "00:00:00";
let lastDateHours = "23:59:59";
const cookies = new Cookies();

class BicycleMovements extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);
        this.state = {
            VehicleTypeId: "",
            StationId: "",
            MemberId: "",
        }
        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
    }

    tableInstall = () => {
        const { t } = this.props
        var _this = this
        if (this.bicycleTable != null)
            this.bicycleTable.destroy();

        this.bicycleTable = jq('#bicycle-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            searching: false,
            serverSide: true,
            order: [4, 'desc'],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Station/Vehicle/Steps',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;

                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#bicycle-table`).removeClass('table-loader').show()
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    dateRange: [
                        Functions.getFormatDBDateHours($(this.sonKullanmaTarih1Ref.current).val() + ' ' + firstDateHours),
                        Functions.getFormatDBDateHours($(this.sonKullanmaTarih2Ref.current).val() + ' ' + lastDateHours)
                    ],
                }
                if ($('#memberID').val() != "0") {
                    aoData['Filter']['MemberId'] = $('#memberID').val()
                }
                if ($('#stationList').val() != "0") {
                    aoData['Filter']['StationId'] = $('#stationList').val()
                }
                if ($('#VehicleTypeList').val() != "0") {
                    aoData['Filter']['VehicleTypeId'] = $('#VehicleTypeList').val()
                }
                if ($('#VehicleNo').val() != "") {
                    aoData['Filter']['VehicleNo'] = $('#VehicleNo').val()
                }

                // MemberId: _this.state.MemberId,
                // StationId: _this.state.StationId,
                // VehicleTypeId: _this.state.VehicleTypeId,

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },

            "columns": [
                { title: t("table.registrationNo"), data: "MemberTripId" },
                {
                    title: t("table.type"),
                    data: "ProcessStatusId", render: function (data, type, row) {
                        if (data === 1) {
                            return "Üye Üzerinde Kullanımda";
                        }
                        else if ((data == 2) || (data == 3)) {
                            return "Rezerve Kiralama Talebi";
                        }
                        else if (data == 7) {

                            return "Sürüş Tamamlandı";
                        }
                        else {
                            return " -- "
                        }
                    }
                },
                {
                    title: t("table.member"),

                    data: "MemberTripId", render: function (data, type, row) {
                        var user;
                        var userID;
                        if (row.member.user != null) {
                            user = row.member.user.Name + ' ' + row.member.user.Surname
                            userID = '/users/edit/' + row.member.user.id
                            userID = Functions.RoleControl(11, 4) == false ? '#' : userID

                            return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.member.user.id + '">' + user + '</a>';
                        }
                        else {
                            user = "Kullanıcı Bulunamadı";
                            return '<a class="btn getUserBtn">' + user + '</a>';

                        }

                    }, orderable: false
                },
                {
                    title: t("table.bicycleNo"),

                    className: "vehicleNo",
                    data: "vehicle.VehicleNo", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return (`<a class="vehicleNo" href="#" >${data}</a>`);
                    }
                },

                {
                    title: t("table.releaseDate"),

                    data: "EntryDate", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data)
                    }
                },
                {
                    title: t("table.exitStation"),

                    data: "station_park_unit_entry", render: function (data, type, row) {
                        if (data == null) {
                            return ' ';
                        }
                        return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${data.StationId}` : `#`} ">${data.station.Name} -> ${data.ParkOrder}</a> `;
                    }
                },
                {
                    title: t("table.dateOfEntry"),


                    data: "ExitDate", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data)
                    }
                },

                {
                    title: t("table.entranceStation"),

                    data: "station_park_unit_exit", render: function (data, type, row) {

                        if (data.ParkId == null) {
                            return 'Kullanımda';
                        }
                        return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${data.StationId}` : `#`} ">${data.station.Name} -> ${data.ParkOrder}</a> `;
                    }
                },

                {
                    title: t("table.time"),
                    data: "TotalDuration", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return (data);

                    }
                },
                {
                    title: t("table.freeTime"),
                    data: "FreeDuration", render: function (data, type, row) {

                        if (data == null) {
                            return ' ';
                        }
                        return (data);

                    }
                },
                {
                    title: t("table.price"),

                    data: "MemberTripId", render: function (data, type, row) {

                        var price = parseFloat(row.Fee).toFixed(2)

                        return price + ' ' + row.Currency;

                    }
                },
            ]
        });
        new jq.fn.dataTable.Responsive(this.bicycleTable, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;



        if (ReactSession.get('bikeDetails') != null) {
            $('#VehicleNo').val(ReactSession.get('bikeDetails').VehicleNo);

            ReactSession.remove('bikeDetails');
        }


        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,

            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            startDate: moment('2000/01/01','YYYY/MM/DD').format('DD/MM/YYYY'),
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        this.tableInstall()
        $('#bicycle-table tbody').on('click', 'td .vehicleNo', function () {
            var tr = jq(this).closest('tr');
            var row = _this.bicycleTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.bicycleTable.row(this);
                data = row.data();
            }
            ReactSession.set('bikeSearchData', data.vehicle.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";


        });
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Vehicle/Types',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        axios(config)
            .then(function (response) {
                $('#VehicleTypeList').empty();

                response.data.data.forEach((element, index) => {

                    $('#VehicleTypeList')
                        .append($("<option></option>")
                            .attr("value", element.VehicleTypeId)
                            .text(element.Name));
                });
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "per_page": -1,
                "order": "Order",
            })
        };

        axios(config)
            .then(function (response) {
                $('#stationList').empty();
                $('#stationList').select2();
                $('#stationList')
                    .append($("<option></option>")
                        .attr("value", " ")
                        .text(t("all", { ns: "translations" })));
                response.data.data.forEach((element, index) => {

                    $('#stationList')
                        .append($("<option></option>")
                            .attr("value", element.StationId)
                            .text(element.Name));
                });
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    componentDidUpdate = () => this.componentDidMount();
    parentValueChange = (valueType, type) => {
        if (type == "first") {
            firstDateHours = valueType
        }
        else {
            lastDateHours = valueType
        }
    }
    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filterHead")}</h6>
                        {/* <div className="header-elements">
                            <label className="custom-control custom-switch custom-control-right">
                                <input type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">Live update</span>
                            </label>
                        </div> */}
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker type="first" parentValueChange={this.parentValueChange} defaultValue={moment().startOf('day')} />

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("bicycleType")} </label>
                                    <div className="col-lg-8">
                                        <select id="VehicleTypeList" className="form-control">
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("Bisiklet No")} </label>
                                    <div className="col-lg-8">
                                        <input id="VehicleNo" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                            <DatePicker parentValueChange={this.parentValueChange} defaultValue={moment().endOf('day')} />

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("memberID")}</label>
                                    <div className="col-lg-8">
                                        <Select
                                            selectID="memberID"
                                            customCss={"userList"}
                                            resultID={["MemberId"]}
                                            result={["Name", "Surname", "Phone"]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("stations")}</label>
                                    <div className="col-lg-8">

                                        <select id="stationList" className="form-control">
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button type="button" onClick={() => this.bicycleTable.ajax.reload()} className="btn btn-primary w-100">{t("btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.head")}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={this.tableInstall} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="bicycle-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">

                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['bicycleMovements', 'translations'])(BicycleMovements);

function getFormatDBDate(date) {
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}