import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Button from '../../components/elements/button';
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';
import LoopIcon from '@mui/icons-material/Loop';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';

class GpsCommands extends Component {
    constructor(props) {
        super(props);

        this.commandTableRef = React.createRef();
        this.dateRangeRef = React.createRef();

        this.tables = [];
    }

    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }

    componentDidMount() {
        const { t } = this.props;
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
            var $this = $(this);
            if ($this.data('bs.inputmask')) return;
            var inputMask = new InputMask($this, $this.data());
        });

        $('.select-search').select2({
            language: selectLanguage
        });

        this.processDataTable(this.commandTableRef, "this.bicycleTable");

        DateRangePickerHandler(this.dateRangeRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters", { ns: "translations" })}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-md-4">
                                <Division className="form-group">
                                    <Text Tag="label">{t("device")}</Text>
                                    <select className='select-search form-control' defaultValue={-1}>
                                        <option value={-1}>{t("all", { ns: "translations" })}</option>
                                    </select>
                                </Division>
                            </Division>
                            <Division className="col-md-4">
                                <Division className="form-group">
                                    <Text Tag="label">{t("status")}</Text>
                                    <select className='select-search form-control' defaultValue={-1}>
                                        <option value={-1}>{t("all", { ns: "translations" })}</option>
                                    </select>
                                </Division>
                            </Division>
                            <Division className="col-md-4"></Division>
                            <Division className="col-md-4">
                                <Text Tag="label" className="col-form-label">{t("dateRange")}</Text>
                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                    <input ref={this.dateRangeRef} type="text" className="cursor-pointer form-control form-control-lg" />
                                    <Division className="form-control-feedback form-control-feedback-lg">
                                        <i className="icon-calendar"></i>
                                    </Division>
                                </Division>
                            </Division>
                            <Division className="col-md-12">
                                <Button className="btn btn-primary">
                                    <SearchIcon className="mr-2" />
                                    {t("search")}
                                </Button>
                            </Division>
                        </Division>

                    </Division>
                </Division>

                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("addCommand")}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-md-4">
                                <Division className="form-group">
                                    <Text Tag="label">{t("device")}</Text>
                                    <select className='select-search form-control' defaultValue={-1}>
                                        <option value={-1}>{t("all", { ns: "translations" })}</option>
                                    </select>
                                </Division>
                            </Division>
                            <Division className="col-md-4">
                                <Division className="form-group">
                                    <Text Tag="label">{t("commands")}</Text>
                                    <select className='form-control'>
                                    </select>
                                </Division>
                            </Division>
                            <Division className="col-md-4"></Division>
                            <Division className="col-md-4">
                                <Division className="form-group">
                                    <Text Tag="label">{t("command")}</Text>
                                    <input type="text" className="form-control" />
                                </Division>
                            </Division>
                            <Division className="col-md-12">
                                <Button className="btn btn-primary"><SendIcon className="mr-2" />{t("sendCommand")}</Button>
                            </Division>
                        </Division>
                    </Division>
                </Division>

                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.gps.gpsHistory", { ns: "translations" })}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <table id="bicycle-gps-table" className="mt-2" ref={this.commandTableRef} style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>{t("table.ID")}</th>
                                    <th>{t("table.command")}</th>
                                    <th>{t("table.retval")}</th>
                                    <th>{t("table.status")}</th>
                                    <th>{t("table.firstDate")}</th>
                                    <th>{t("table.receivedDate")}</th>
                                    <th>{t("table.operations")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td className="text-center">
                                        <div className="list-icons">
                                            <div className="dropdown">
                                                <a href="#" className="list-icons-item" data-toggle="dropdown">
                                                    <i className="icon-menu9"></i>
                                                </a>

                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <a href="#" className="dropdown-item"><i className="icon-pencil"></i> ...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Division>
                </Division>
            </div>
        );
    }
}

export default withTranslation(['gpsCommands', 'translations'])(GpsCommands);