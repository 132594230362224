import React from "react";
import { withTranslation } from "react-i18next";
import TableData from "../../components/table";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import Division from "../../components/elements/content-division";
import $ from "jquery";
import axios from "axios";
import { getEndPoint } from "../../class/api";
import Cookies from "universal-cookie";
import moment from "moment";
import Functions from "../../class/functions";
const cookies = new Cookies();

class CardCreditSalesReport extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];


        this.summaryTableRef = React.createRef(null);
        this.detailsTableRef = React.createRef(null);

        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
        this.sonKullanmaTarih3Ref = React.createRef(null);
        this.sonKullanmaTarih4Ref = React.createRef(null);
        const { t } = this.props;
        this.summaryColumns = [
            {
                title: t("table1.processType"),
                data: "ProvisionType"
            },
            {
                title: t("table1.numberOfTransactions"),
                data: "Process"
            },
            {
                title: t("table1.totalAmount"),
                data: "Fee",
                render: function (data) {
                    return parseFloat(data).toFixed(2);
                }
            }
        ]

        this.detailsColumns = [
            {
                title: t("table2.no"),
                data: "MemberPaymentHistoryId"

            },

            {
                title: t("table2.date"),
                data: "created_at",
                render: function (data, type, row) {
                    return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
                }
            },
            {
                title: t("table2.processedBy"),
                data: "TransactingUserId",
                render: function (data, type, row) {
                    return `${row.transacting_user?.Name} ${row.transacting_user?.Surname} `
                }
            },
            {
                title: t("table2.memberNameSurname"),
                data: "MemberId",
                render: function (data, type, row) {
                    return `${row.member.user.Name} ${row.member.user.Surname} `
                }
            },
            {
                title: t("table2.paymentOrderID"),
                data: "Description"
            },
            {
                title: t("table2.processType"),
                data: "ProvisionTypeId",
                render: function (data, type, row) {
                    return row.type.Name
                }
            },
            {
                title: t("table2.amount"),
                data: "Fee"
            }
        ]


        var firstDate = moment($('#firstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        var lastDate = moment($('#lastDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.summaryFilter = [{
            ProvisionTypeId: "1",
            dateRange: [
                firstDate == "Invalid date" ? moment().startOf('day').format('YYYY-MM-DD HH:mm:ss') : firstDate,
                lastDate == "Invalid date" ? moment().endOf('day').format('YYYY-MM-DD HH:mm:ss') : lastDate,
            ]
        }]

    }


    componentDidMount() {
        const { t } = this.props;

        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            startDate: moment().add(-1, 'days').format('DD/MM/YYYY'),
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.sonKullanmaTarih3Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            startDate: moment().add(-1, 'days').format('DD/MM/YYYY'),
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.sonKullanmaTarih4Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        this.handlesummaryGetData()
        if (this.detailsTable != undefined)
            this.detailsTable.destroy();

        this.detailsTable = $('#details-table').DataTable({
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            serverSide: true,
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Member/PaymentHistory',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#details-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    ProvisionTypeId: "1",
                    dateRange: [
                        moment($('#detailsFirstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                        moment($('#detailsLastDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
                    ]
                };

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": this.detailsColumns,
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 10,
        });
        new $.fn.dataTable.Responsive(this.detailsTable, { details: true, Responsive: true },);
    }

    componentDidUpdate = () => this.componentDidMount();
    handlesummaryGetData = () => {
        const { t } = this.props;
        var firstDate = moment($('#firstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        var lastDate = moment($('#lastDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        var data = JSON.stringify({
            "Filter": {
                "ProvisionTypeId": "1",
                "dateRange": [
                    firstDate == "Invalid date" ? moment().startOf('day').format('YYYY-MM-DD HH:mm:ss') : firstDate,
                    lastDate == "Invalid date" ? moment().endOf('day').format('YYYY-MM-DD HH:mm:ss') : lastDate,
                ]
            }
        });

        var config = {
            method: 'put',
            url: `${getEndPoint()}/api/Admin/Reports/CreditCardSalesReport`,
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then((response) => {
                if (this.tableSummary != undefined) {
                    this.tableSummary.destroy();
                }
                this.tableSummary = $('#summary-table').DataTable({
                    language: {
                        ...(t("tableLocalization", { ns: "translations" })),
                        paginate: {
                            'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                            'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                        }
                    },
                    data: Array(response.data.data),
                    columns: this.summaryColumns,
                });
                $(`#summary-table`).removeClass('table-loader').show()
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters.filterHead")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="firstDate" ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6">
                                {/* <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.processType")}</label>
                                    <div className="col-lg-8">

                                        <select id="finans_islem_turu" className="form-control">
                                            <option value="">{t("all", { ns: "translations" })}</option>

                                            <option value="1">ABONELİK ÜCRETİ 10TL</option>

                                            <option value="2">3-KREDİ YÜKLE 10TL</option>

                                            <option value="3">6-KREDİ YÜKLE 25TL</option>

                                            <option value="4">7-KREDİ YÜKLE 50TL</option>

                                            <option value="5">8-KREDİ YÜKLE 75TL</option>

                                            <option value="6">9-KREDİ YÜKLE 100TL</option>

                                            <option value="7">2-KREDİ YÜKLE 5TL</option>

                                            <option value="8">4-KREDİ YÜKLE 15TL</option>

                                            <option value="9">5-KREDİ YÜKLE 20TL</option>

                                            <option value="10">ABONELİK ÜCRETİ  10TL</option>

                                            <option value="11">YILLIK ABONELİK 59 TL</option>

                                            <option value="12">YILLIK ABONELİĞE GEÇİŞ 49 TL</option>

                                            <option value="13">0</option>

                                            <option value="14">KREDİ YÜKLE 20TL</option>

                                            <option value="15">KREDİ YÜKLE 30TL</option>

                                            <option value="16">KREDİ YÜKLE 35TL</option>

                                            <option value="17">KREDİ YÜKLE 40TL</option>

                                            <option value="18">KREDİ YÜKLE 45TL ğçÖŞİ</option>

                                            <option value="19">MOBİL ÖDEME ABONELİK</option>

                                            <option value="20">41 saat ücretsiz</option>

                                        </select>
                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="lastDate" ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={this.handlesummaryGetData} type="button" className="btn btn-primary w-100">{t("filters.btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
                        <div className="card border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                            <div className="card-custom-color card-header header-elements-inline">
                                <h6 className="card-title">
                                    <a data-toggle="collapse" className="text-body" aria-expanded="true"> {t("table1.head")}</a>

                                </h6>
                                <div className="header-elements">
                                    <div className="list-icons">
                                        <a onClick={this.handlesummaryGetData} className="list-icons-item" data-action="reload"></a>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body py-2">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <table id="summary-table" className="table-loader dataTable display  compact  w-100">
                                        </table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <TableData
                        cardTitle={t("table1.head")}
                        abilityid={4}
                        auth={0}
                        searching={false}
                        length="5"
                        customClass="col-12"
                        customOrder={[1, "desc"]}
                        ajaxUrl="/api/Admin/Reports/CreditCardSalesReport"
                        tableName="summary-table"
                        filter={this.summaryFilter}
                        columns={this.summaryColumns}
                    /> */}
                </div>
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters.filterHead")}</h6>

                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                {/* <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.processedBy")} </label>
                                    <div className="col-lg-8">
                                        <select className="form-control">
                                            <option value="">{t("all", { ns: "translations" })}</option>

                                            <option value="18">Baksi Destek</option>

                                            <option value="3">Sanal Pos Hesabı</option>

                                            <option value="1">Sistem Yöneticisi</option>

                                            <option value="12">Baksi Destek</option>

                                            <option value="14">Salih Kumbar</option>

                                            <option value="16">Aysegül SEYHAN</option>

                                            <option value="4">Uğur DEMİR</option>

                                            <option value="5">Övünç Yılmaz</option>

                                            <option value="6">Yavuz Salih Şahin</option>

                                            <option value="8">SERKAN ÇETKİN</option>

                                            <option value="9">ENİS ATILIR</option>

                                            <option value="19">Erol DEMİR</option>

                                            <option value="20">Tuba ÖZDAL</option>

                                            <option value="21">Muharrem SARI</option>

                                            <option value="23">SEYAHAT STAJER</option>

                                            <option value="24">GÜRKAN YILMAZ</option>

                                            <option value="27">Mehmet Yavuz ÜREYEN</option>

                                            <option value="28">Saha Ekibi</option>

                                            <option value="30">Fatih Kurtaran</option>

                                            <option value="31">FATİH SİRMEN</option>

                                            <option value="32">Deniz Bey DUMAN</option>

                                            <option value="33">Şaban ÖZKAN</option>

                                            <option value="34">KOBİSTR</option>

                                            <option value="36">Çağrı Merkezi</option>

                                            <option value="37">Suat MANSUROĞLU</option>

                                            <option value="38">Bünyamin AYDIN</option>

                                            <option value="39">ADEM BALTÜRK</option>

                                            <option value="40">Murat KOLCU</option>

                                            <option value="41">HASAN ORAK</option>

                                            <option value="42">KOBİS ATÖLYE</option>

                                            <option value="29">CardTek</option>

                                            <option value="26">Mobil Ödeme</option>

                                            <option value="25">Kiosk Sanal Pos Ödeme</option>

                                            <option value="17">Kent Kart Ödeme</option>

                                            <option value="2">Ödeme Noktası 1</option>

                                            <option value="13">Zübeyir KARAOSMANOĞLU</option>

                                            <option value="15">Osman Aygüç</option>

                                            <option value="10">Meltem ALEMDAR</option>

                                            <option value="11">Engin SARAL</option>

                                            <option value="7">Belediye Ödeme Noktası 1</option>

                                            <option value="22">MUHARREM SARI</option>

                                            <option value="35">ÖZDEN ZORTEYMUR</option>

                                        </select>
                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.firstDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="detailsFirstDate" ref={this.sonKullanmaTarih3Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.lastDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input id="detailsLastDate" ref={this.sonKullanmaTarih4Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <Division className="col-sm-12 mt-2">
                                <button onClick={() => { this.detailsTable.ajax.reload() }} type="button" className="btn btn-primary w-100">{t("filters.btnValue")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
                        <div className="card border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                            <div className="card-custom-color card-header header-elements-inline">
                                <h6 className="card-title">
                                    <a data-toggle="collapse" className="text-body" aria-expanded="true"> {t("table2.head")}</a>

                                </h6>
                                <div className="header-elements">
                                    <div className="list-icons">
                                        <a onClick={() => { this.detailsTable.ajax.reload() }} className="list-icons-item" data-action="reload"></a>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body py-2">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <table id="details-table" className="table-loader dataTable display  compact  w-100">
                                        </table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['CardCreditSalesReport', 'translations'])(CardCreditSalesReport);
