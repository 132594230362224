import React from "react";

import Anchor from "./elements/anchor.js";
import Image from "./elements/image.js";
import Button from "./elements/button.js";
import Division from "./elements/content-division.js";
import UnorderedList, {ListItem} from "./elements/unordered-list.js";
import Text from "./elements/text.js";

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>            
                {this.props.children}
            </>
        );
    }
}
