import * as React from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { FooterSection, HeaderSection, NavigationBar, SideSection, UserSection } from "./custom-components.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Main from "./pages/main";
import Login from "./pages/login";
import User from "./pages/user/list";
import UserEdit from './pages/user/edit';
import UserAdd from './pages/user/add';
import UserAddCredit from './pages/user/add-credit';
import Stations from "./pages/stations/stations";
import EnergyTracking from './pages/stations/energy-tracking'; 
import Bicycles from './pages/bike/bicycles';
import RetrieveBicycle from "./pages/bike/retrieve-back-user-defined-bicycle";
import ActiveUsers from "./pages/management/active-users";
import SystemLogs from "./pages/management/system-logs";
import SystemSettings from './pages/management/system-settings';
import EventNotificationDefinitions from './pages/management/event-notification-definitions';
import EventNotificationDefinitionsList from './pages/management/event-notification-definitions-list.js';
import ManagerDuties from './pages/management/manager-duties';
import Managers from './pages/management/managers';
import GpsList from "./pages/bike/gps-list";
import GpsHistoryList from "./pages/gps/gps-list-history"; 
import GpsGetDetail from "./pages/gps/gps-get-detail"; 
import GpsPair from './pages/gps/gps-pair';
import GpsCommands from './pages/gps/commands';
import Edit from './pages/stations/edit';
import GpsZoneAlarmDefinitions from "./pages/gps/zone-alarm-definitions";
import TagChange from "./pages/bike/tag-change";
import ComingSoon from "./pages/comingSoon";
import RecipeDefinitions from "./pages/finance/recipe-definitions";
import RecipeAdd from "./pages/finance/add-recipe";
import RecipeEdit from "./pages/finance/edit-recipe";
import PaymentTypes from "./pages/finance/payment-types";
import ProcessTypes from "./pages/finance/process-types.js";
import ZReports from "./pages/finance/z-reports";
import BicyclePriceEdit from "./pages/user/bicycle-usage-time-price-edit";
import CreditCardProvisionReport from "./pages/reports/credit-card-provision-report";
import CardCreditSalesReport from "./pages/reports/card-credit-sales-report";
import BankConsensus from "./pages/reports/bank-consensus";
import MonthlyGiro from "./pages/reports/monthly-giro";
import GiroUsageSummaryReport from "./pages/reports/giro-usage-summary-report";
import BicycleMovements from "./pages/bike/bicycle-movements";
import ExpeditionsBetweenStations from "./pages/reports/expeditions-between-stations";
import StationIntensity from "./pages/reports/station-intensity";
import TopCyclists from "./pages/user/top-cyclists";
import FaultTracking from "./pages/bike/fault-tracking";
import SupportList from "./pages/support/list";
import ParkList from "./pages/stations/park-list";
import ParkEdit from "./pages/stations/parkEdit";
import "./config";
import AuthControl from './authControl';
import NotFoundPage from './pages/NotFoundPage';
import { ReactSession } from "react-client-session";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import ParkAdd from './pages/stations/parkAdd';
import StationAdd from './pages/stations/station-add';
import FaultDefinitions from './pages/fault/fault-definitions';
import Faults from './pages/fault/faults';
import NotificationPost from './pages/management/notification-post-list';
import NotificationPostAdd from './pages/management/notification-post-add';
import NotificationPostEdit from './pages/management/notification-post-edit';
import StationZoneDefinitionsEdit from './pages/stations/station-zone-definitions-edit';
import StationZoneDefinitions from './pages/stations/station-zone-definitions';
import HeadCheck from './pages/headcheck/headcheck';
import { UserCreditTransfer } from './pages/user/user-credit-transfer';
import { UserMerge } from './pages/user/user-merge';
import { BicyclesDetails } from './pages/bike/bicycles-details';

if (ReactSession.get('auth') == 'true') {

  ReactDOM.render(
    <NavigationBar />,
    document.getElementById("navigation")
  );
  ReactDOM.render(
    <SideSection />,
    document.getElementById("sidebar")
  );
  ReactDOM.render(
    <FooterSection />,
    document.getElementById("footer")
  );
  ReactDOM.render(
    <HeaderSection />,
    document.getElementById("page-header")
  );

  ReactDOM.render(
    <Provider store={store}>
      <Router >
        <Routes>
          <Route path='/' element={<AuthControl />}>
            <Route path='/' element={<Main />} />
            <Route path='/headCheck' element={<HeadCheck/>}/>
            <Route path="/users/list" element={<User />} />
            <Route path="/users/add" element={<UserAdd />} />
            <Route path="/users/add-credit" element={<UserAddCredit />} />
            <Route path="/users/edit/:userId" element={<UserEdit />} />
            {/* <Route path="/users/credit-transfer" element={<UserCreditTransfer />} /> */}
            <Route path="/users/merge" element={<UserMerge />} />
            <Route path="/users/top-cyclists" element={<TopCyclists />} />
            <Route path="/users/bicycle-usage-time-price-edit" element={<BicyclePriceEdit />} />
            <Route path="/users/finance-record-update" element={<ComingSoon />} />

            <Route path="/stations" element={<Stations />} />
            <Route path="/stations/station-add" element={<StationAdd />} />
            <Route path="/stations/list/:stationId" element={<ParkList />} />
            <Route path="/stations/edit/:stationId" element={<Edit />} />
            <Route path="/stations/park-edit/:stationId" element={<ParkEdit />} />
            <Route path="/stations/park-add" element={<ParkAdd />} />
            <Route path="/stations/station-zone-definitions" element={<StationZoneDefinitions />} />
            <Route path="/stations/station-zone-definitions/:id" element={<StationZoneDefinitionsEdit />} />
            <Route path="/stations/energy-tracking" element={<EnergyTracking />} />
            <Route path="/stations/station-zone-definitions/:id" element={<StationZoneDefinitionsEdit />} />

            <Route path="/bicycles/bicycle-list" element={<Bicycles />} />
            <Route path="/bicycles/bicycle-movements" element={<BicycleMovements />} />
            <Route path="/bicycles/tag-change" element={<TagChange />} />
            <Route path="/bicycles/gps" element={<GpsList />} />
            <Route path="/bicycles/gpsHistory" element={<GpsHistoryList />} />

            
            <Route path="/bicycles/get/:VehicleNo" element={<GpsGetDetail />} />
            <Route path="/bicycles/fault-tracking" element={<FaultTracking />} />
            <Route path="/bicycles/retrieve-back-user-defined-bicycle" element={<RetrieveBicycle />} />


            <Route path="/bicycles/gps-pair" element={<GpsPair />} />
            <Route path="/gps/commands" element={<GpsCommands />} />
            <Route path="/gps/zone-alarm-definitions" element={<GpsZoneAlarmDefinitions />} />

            <Route path="/fault/faults" element={<Faults />} />
            <Route path="/fault/definitions" element={<FaultDefinitions />} />

            <Route path="/finance/recipe-definitions" element={<RecipeDefinitions />} />
            <Route path="/finance/recipe-add" element={<RecipeAdd />} />
            <Route path="/finance/recipe-edit/:id" element={<RecipeEdit />} />
            <Route path="/finance/payment-types" element={<PaymentTypes />} />
            <Route path="/finance/process-types" element={<ProcessTypes />} />
            <Route path="/finance/z-reports" element={<ZReports />} />

            <Route path="/reports/credit-card-provision-report" element={<CreditCardProvisionReport />} />
            <Route path="/reports/bank-consensus" element={<BankConsensus />} />
            <Route path="/reports/card-credit-sales-report" element={<CardCreditSalesReport />} />
            <Route path="/reports/monthly-giro" element={<MonthlyGiro />} />
            <Route path="/reports/giro-usage-summary-report" element={<GiroUsageSummaryReport />} />
            <Route path="/reports/expeditions-between-stations" element={<ExpeditionsBetweenStations />} />
            <Route path="/reports/station-intensity" element={<StationIntensity />} />

            <Route path="/management/active-users" element={<ActiveUsers />} />
            <Route path="/management/system-logs" element={<SystemLogs />} />
            <Route path="/management/system-settings" element={<SystemSettings />} />
            <Route path="/management/event-notification-definitions-list" element={<EventNotificationDefinitionsList />} />
            <Route path="/management/event-notification-definitions/:eventID" element={<EventNotificationDefinitions />} />
            <Route path="/management/manager-duties" element={<ManagerDuties />} />
            <Route path="/management/managers" element={<Managers />} />
            <Route path="/management/notification-post" element={<NotificationPost />} />
            <Route path="/management/notification-post-add" element={<NotificationPostAdd />} />
            <Route path="/management/notification-post-edit/:postID" element={<NotificationPostEdit />} />

            <Route path="/support/list" element={<SupportList />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
    , document.getElementById('root')
  );

} else {
  ReactDOM.render(
    <Provider store={store}>
      <Router >
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path='*' element={<Navigate to={'/login'} />} />
        </Routes>
      </Router>
    </Provider>
    ,
    document.getElementById('root')
  );
}
