import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import axios from 'axios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getEndPoint } from '../../class/api'
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Functions from '../../class/functions';
import { ReactSession } from 'react-client-session'
import Cookies from 'universal-cookie'
const cookies = new Cookies();

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stationData: []
        }
        this.handleSubmit = this.handleSubmit.bind(this); 
        
    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        // _this.stationTypeLoad();
        var data = JSON.stringify({
            "Filter": {
                "StationId": this.props.router.params.stationId
            }
        });

        var config = {
            method: 'PUT',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                $('.statusSelect').val(response.data.data[0].Status === false ? 0 : 1)
                $('.showOnMapSelect').val(response.data.data[0].ShowOnMap)
                _this.setState({
                    stationData: response.data.data[0]
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $('.regionSelect').select2({
            language: selectLanguage
        });

        $('.stationTypeSelect').select2({
            language: selectLanguage
        });
 
        var config = {
            method: 'PUT',
            url: getEndPoint() + '/api/Admin/Station/Region',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({ "per_page": -1 })
        };
        axios(config)
            .then(function (response) {
                $('.regionSelect').empty().trigger("change");
                var regionSelect = $('.regionSelect');

                response.data.data.forEach((elm) => {
                    var option = new Option(
                        elm.Name,
                        elm.StationRegionId,
                        (_this.state.stationData.RegionId == elm.StationRegionId),
                        (_this.state.stationData.RegionId == elm.StationRegionId)
                    );
                    regionSelect.append(option).trigger('change');
                });
                _this.stationTypeLoad();

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });

            


    }
    handleSubmit(e) {
        e.preventDefault();
        const _this = this;
        const { t } = this.props;

        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
        var StationDataEdit = _this.state.stationData;
        var proccessStatus = 0;
        var station_list = ReactSession.get("stationList")
        station_list.filter(x => x.Number == StationDataEdit.Number).forEach(element => {
            if (element.StationId != StationDataEdit.StationId) {
                console.log(element);
                console.log(StationDataEdit);
                proccessStatus = proccessStatus + 1
            }
        });
        console.log(proccessStatus);
        if (proccessStatus > 0) {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: 'Mevcut Olan Bir İstasyon Adı Veya Numarası Girdiniz!',
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }

        if (StationDataEdit.StationId == 341) {

            if (formDataEdit.Name != "İstasyon B.A") {
                swal.fire({
                    title: t("swal.error.head", { ns: "translations" }),
                    text: 'Bünyamin Beyin İstasyonunda İsim Değişikliği Yapılamaz!!!',
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.error.icon", { ns: "translations" })
                })
                return
            }
        }
        StationDataEdit.ProgramVersion = "0"
        StationDataEdit.StationParkUnits = [];
        StationDataEdit.Address = formDataEdit.Address
        StationDataEdit.BikeCountMax = parseInt(formDataEdit.BikeCountMax)
        StationDataEdit.BikeCountMin = parseInt(formDataEdit.BikeCountMin)
        StationDataEdit.Coordinate = formDataEdit.Coordinate
        StationDataEdit.IpActive = formDataEdit.IpActive
        StationDataEdit.IpAddress = formDataEdit.IpAddress
        StationDataEdit.Name = formDataEdit.Name
        StationDataEdit.RegionId = parseInt(formDataEdit.RegionId)  
        StationDataEdit.StationTypeId = parseInt(formDataEdit.StationTypeId)

        StationDataEdit.SimPhoneNumber = "05"
        StationDataEdit.SimPin = "5051"
        StationDataEdit.SimPuk = "5051"
        StationDataEdit.SimSerialNumber = "5051"
        StationDataEdit.Status = parseInt(formDataEdit.Status)
        StationDataEdit.InformationMessage = formDataEdit.InformationMessage
        StationDataEdit.PlugSize = "1.00"
        StationDataEdit.Order = parseInt($('#order').val())

        StationDataEdit.NewPlugSize = "1.00"
        StationDataEdit.ShowOnMap = parseInt(formDataEdit.ShowOnMap)
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),

        }).then((result) => {

            if (result.isConfirmed) {

                var config = {
                    method: 'post',
                    url: getEndPoint() + "/api/Admin/Station",
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(StationDataEdit)
                };
                if (proccessStatus == 0) {
                    axios(config)
                        .then(function (response) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: t("swal.success.successMessage", { ns: "translations" }),
                                showConfirmButton: false,
                                timer: 2500,
                                willClose: () => {
                                    window.location.href = `/stations/list/${response.data.data[0].StationId}`
                                }
                            })
                        })
                        .catch(function (error) {
                            Functions.requestAxiosErrorFunction(error);
                        });
                }



            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }

        })
    }
    backList() {
        window.location.href = '/stations/list/' + this.state.stationData.StationId;
    }

    stationTypeLoad () { 


        var stationTypeSelect = $('.stationTypeSelect');

        var stationTypeList = []; 

        stationTypeList.push(
            {
                StationTypeId : 1 ,
                Name : "Genel"
            },
            {
                StationTypeId : 2 ,
                Name : "Mobil KIOSK"
            },
            {
                StationTypeId : 3 ,
                Name : "Ferofen KIOSK"
            },
        ); 

        $('.stationTypeSelect').empty().trigger("change");

        stationTypeList.forEach((elm) => {
            var option = new Option(
                elm.Name,
                elm.StationTypeId,
                (this.state.stationData.StationTypeId == elm.StationTypeId),
                (this.state.stationData.StationTypeId == elm.StationTypeId)
            );
            stationTypeSelect.append(option).trigger('change');
        });

    }
    render() {
        const { t } = this.props;
        return (
            <div className="p-2 ">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <form onSubmit={this.handleSubmit}>

                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("id")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input disabled readOnly defaultValue={this.state.stationData.StationId} name="StationParkUnitId" type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-seven-segment-9"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Name")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.Name} type="text" required name="Name" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-profile"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>

                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("IpAddress")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.IpAddress} type="text" required name="IpAddress" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("IpActive")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.IpActive} type="text" required name="IpActive" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("BikeCountMin")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.BikeCountMin} type="text" required name="BikeCountMin" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("BikeCountMax")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.BikeCountMax} type="text" required name="BikeCountMax" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Station Type")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select type="text" required name="StationTypeId" className="stationTypeSelect form-control form-control-lg" >
                                                <option value="0"> {t("loading", { ns: "translations" })}</option>

                                            </select>

                                        </Division>
                                    </Division>

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("RegionId")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select type="text" required name="RegionId" className="regionSelect form-control form-control-lg" >
                                                <option value="0"> {t("loading", { ns: "translations" })}</option>

                                            </select>

                                        </Division>
                                    </Division>
                                    




                                </Division>
                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ShowOnMap")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select defaultChecked={this.state.stationData.ShowOnMap} type="text" required name="ShowOnMap" className="showOnMapSelect form-control form-control-lg" >
                                                <option value={1} >{t("active", { ns: "translations" })}</option>
                                                <option value={0} >{t("passive", { ns: "translations" })}</option>
                                            </select>

                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("InformationMessage")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.InformationMessage} type="text" required name="InformationMessage" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>

                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Sırası")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.Order} type="text" id='order' required name="Order" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Number")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.Number} disabled readOnly type="text" name="Number" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Coordinate")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue={this.state.stationData.Coordinate} type="text" required name="Coordinate" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>


                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Status")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <select defaultValue={Boolean(this.state.stationData.Status)} type="text" required name="Status" className="statusSelect form-control form-control-lg" >
                                                <option value={1} >{t("active", { ns: "translations" })}</option>
                                                <option value={0} >{t("passive", { ns: "translations" })}</option>
                                            </select>

                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>

                                </Division>
                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Address")}</Text>
                                    <Division className="col-lg-10">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <textarea defaultValue={this.state.stationData.Address} type="text" required name="Address" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>

                                </Division>

                                <Division className="form-group row">
                                    <Division className="col-lg-3" />
                                    <Division className="col-lg-3">
                                        <button onClick={this.handleClick} type="submit" className="btn btn-secondary w-100 h-100">{t("Save", { ns: "translations" })}</button>
                                    </Division>
                                    <Division className="col-lg-3">
                                        <button type="button" onClick={this.backList} className="btn btn-danger w-100 h-100">{t("Cancel", { ns: "translations" })}</button>
                                    </Division>
                                </Division>

                            </form>

                        </div>
                    </div>
                </div>




            </div>
        )
    }
}
const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
export default withTranslation(['stationEdit', 'translations'])(withRouter(Edit));
function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}