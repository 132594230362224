export enum BicycleStatus {
    All = -1,
    Active = 0,
    OutOfService = 1,
    ParkError = 6,
    OnCarry = 7,
    OnMaintenance = 2,
    Unknown = 5,
    Stolen = 4
};

export enum ParkUserStatus {
    All = -1,
    AtPark = 0,
    OnUseSubscribe = 1,
    OnUseOnlyPassword = 2,
    OnUseCreditCard = 3,
    OnUseKentCard = 4,
    OnUseSuperVisor = 9
}

export enum ReportType {
    BicycleFaultReport = "bisiklet",
    ParkFaultReport = "park"
}

export enum DeviceQueryType {
    OutOfBattery = 0,
    AlmostOutOfBattery = 1,
    DisconnectedInLast24Hours = 2,
    BatteryModuleFaultInLast24Hours = 3
}

export enum DeviceConnectionType {
    NotConnected = 0,
    Connected = 1
}

export enum DeviceStateType {
    Stand = 0,
    InUse = 1,
    InTransport = 2
}   

export enum DeviceChargeType {
    DoesNotCharging = 0,
    ChargingWithDynamo = 1,
    ChargingWithPark = 2
}

export enum DeviceCommandType {

}

export enum DeviceCommandStatusType {
    Awaiting = 0,
    Sent = 1,
    ResponseReceived = 2
}

export default null;