import React, { Component } from 'react';
import i18n from '../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import Division from '../../components/elements/content-division';
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import axios from 'axios';
import { getEndPoint } from "../../class/api";
import jq from 'jquery'
import Functions from '../../class/functions'
import Swal from 'sweetalert2/dist/sweetalert2.all';
import Cookies from 'universal-cookie';
import { ReactSession } from 'react-client-session';
const cookies = new Cookies();
var _this = this;

class Managers extends Component {
    constructor(props) {
        super(props);

        this.tables = [];
        this.managersTableRef = React.createRef();
        this.managerDutiesTableRef = React.createRef();
        this.Name = React.createRef(null);
        this.surName = React.createRef(null);
        this.eMail = React.createRef(null);
        this.Phone = React.createRef(null);
        this.PhoneNoCountryCode = React.createRef(null);
        this.password = React.createRef(null);
        this.roleID = React.createRef(null);
        this.status = React.createRef(null);
        this.authTable = React.createRef(null);
        this.managerData = "";
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;


        var phoneInputID = "#user-phone-Edit";
        var input = document.querySelector("#user-phone-Edit");
        var iti = intlTelInput(input,
            {

                autoPlaceholder: "polite",
                geoIpLookup: (success, failure) => {
                    //success(countryCode)
                },
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,


            }
        )
        $(phoneInputID).on("countrychange", function (event) {

            var selectedCountryData = iti.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");
            $(this).mask(mask);
        });
        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });


        var phoneEditInputID = "#editPhone";
        var inputEdit = document.querySelector("#editPhone");
        var itiEdit = intlTelInput(inputEdit,
            {
                autoPlaceholder: "polite",
                geoIpLookup: (success, failure) => {
                    //success(countryCode)
                },
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,
            }
        )
        $(phoneEditInputID).on("countrychange", function (event) {

            var selectedCountryData = itiEdit.getSelectedCountryData();

            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");

            $(this).mask(mask);
        });
        itiEdit.promise.then(function () {
            $(phoneEditInputID).trigger("countrychange");
        });




        $('.roleSelect').select2();
        var data = JSON.stringify({
            "per_page": -1,
            "page": 1,

        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Manager/Roles',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                $('.roleSelect').empty().trigger("change");
                var selectRole = $('.roleSelect');
                response.data.data.forEach(data => {

                    var opt = '<optgroup value="' + data.UserRoleId + '" label="' + data.Name + '">';

                    data.child.forEach((elm, index) => {
                        opt += '<option data-id="' + elm.UserRoleId + '" value="' + elm.UserRoleId + '">' + elm.Name + ' </option>'
                    });
                    opt += '</optgroup>';
                    selectRole.append(opt).trigger('change');

                });
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
        if (this.managerTable != null)
            this.managerTable.destroy();
        this.managerTable = jq('#managers-table').DataTable({
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            dom: 'Blfrtip',
            "searching": false,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            order: [0, 'ASC'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Manager',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#managers-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },
            "columns": [
                { data: "id", "orderable": false },
                {
                    data: "Name", render: function (data, type, row) {
                        return (data + ' ' + row.Surname);
                    }, "orderable": false
                },
                { data: "Email", "orderable": false },
                { data: "Phone", "orderable": false },
                { data: "RoleType", "orderable": false },
                {
                    data: "Status", render: function (data, type, row) {
                        var statusData =
                            data == true ? 'Aktif' : 'Pasif';
                        return statusData;
                    }, "orderable": false
                },
                {
                    data: null,
                    className: "operation",
                    render: function (data, type, row) {
                        var userID = '/users/edit/' + row.id
                        return `
                        <div class="form-group">
                                <div class="d-flex justify-content-between align-items-center">
                                ${Functions.RoleControl(34, 3) == true ? `   <button type="button" id="ruleEdit"  class="btn btn-info ">${t("ruleEdit")}</button> ` : ""}  
                                  ${Functions.RoleControl(34, 3) == true ? ` <button type="button" id="editManager" data-target="#manager-edit" data-toggle="modal" class="btn btn-warning ">${t("table.edit")}</button> ` : ""}  
                                    ${Functions.RoleControl(41, 3) == true ? ` <a type="button" href="${userID}"   class="btn btn-primary ">${t("Kullanıcı Detay")}</a> ` : ""}   
                                     </div> 
                            </div>`
                    }, "orderable": false

                }
            ],
        });
        new jq.fn.dataTable.Responsive(this.managerTable, { details: true, Responsive: true },);
        jq('#managers-table tbody').on('click', 'td #editManager', function () {

            var tr = jq(this).closest('tr');
            var row = _this.managerTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.managerTable.row(this);
                data = row.data();
            }
            var input = document.querySelector("#editPhone");
            var iti = window.intlTelInputGlobals.getInstance(input);
            this.managerData = data
            $('#editID').val(data.id)
            $('#editName').val(data.Name)
            $('#editSurname').val(data.Surname)
            $('#editMail').val(data.Email)
            // $('#editPhone').val(data.PhoneNoCountryCode)
            iti.setNumber(data.PhoneNoCountryCode);

            $('#editPassword').val()
            $('#editRole').val()
            $('#editStatus').val(data.Status == false ? "false" : "true")

        });
        jq('#managers-table tbody').on('click', 'td #ruleEdit', function () {
            var tr = jq(this).closest('tr');

            $('#authCard').show();
            var row = _this.managerTable.row(tr);
            var dataRow = row.data();
            if (dataRow == undefined) {

                row = _this.managerTable.row(this);
                dataRow = row.data();
            }
            jq('.ruleUserName').val(dataRow.Name + ' ' + dataRow.Surname)
            jq('.ruleUserName').attr("id", dataRow.id)
            var data = JSON.stringify({
                "per_page": -1,
                "page": 1,
            });

            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Manager/Abilities',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    $("#authTable > tbody").empty();
                    var useAuth = dataRow.user_auths;
                    var tableRule = $('#authTable > tbody');
                    response.data.data.forEach(data => {
                        var opt = "";
                        var auth = useAuth.filter(x => x.AbilitiyId == data.AbilitiyId)[0]
                        var authRule = ""
                        if (auth) {
                            authRule = String(auth.Auth).split("");

                        }
                        opt = `<tr>
                        <td>
                            ${data.AbilitiyId}
                      
                        </td> 
                        <td>
                        ${data.Name}
                        </td> 

                        <td class="operation text-left">
                        <input class="all_select" ${authRule[0] != undefined ? authRule.toString() == "1,1,1,1,1,1,1,1" ? "checked" : "" : ""} id="select-all-${data.AbilitiyId}" type="checkbox"   data-id="-1">
                        </td > 



                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox listCheck" data-id="${data.AbilitiyId}" ${authRule[0] != undefined ? authRule[0] == 1 ? "checked" : "" : ""} >
                        <span class="custom-control-label"></span>
                        </label >
                        </td > 
                        
                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox detailsCheck " data-id="${data.AbilitiyId}" ${authRule[1] != undefined ? authRule[1] == 1 ? "checked" : "" : ""} >
                        <span class="custom-control-label"></span>
                        </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox editCheck" data-id="${data.AbilitiyId}"  ${authRule[2] != undefined ? authRule[2] == 1 ? "checked" : "" : ""} >
                        <span class="custom-control-label"></span>
                        </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox addCheck" data-id="${data.AbilitiyId}" ${authRule[3] != undefined ? authRule[3] == 1 ? "checked" : "" : ""}  >
                        <span class="custom-control-label"></span>
                    </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox removeCheck" data-id="${data.AbilitiyId}" ${authRule[4] != undefined ? authRule[4] == 1 ? "checked" : "" : ""}  >
                        <span class="custom-control-label"></span>
                    </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox approveCheck" data-id="${data.AbilitiyId}" ${authRule[5] != undefined ? authRule[5] == 1 ? "checked" : "" : ""} >
                        <span class="custom-control-label"></span>
                    </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox uploadCheck" data-id="${data.AbilitiyId}" ${authRule[6] != undefined ? authRule[6] == 1 ? "checked" : "" : ""}  >
                        <span class="custom-control-label"></span>
                    </label>
                        </td> 

                        <td>
                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                        <input type="checkbox" class="custom-control-input control-checkbox downloadCheck" data-id="${data.AbilitiyId}" ${authRule[7] != undefined ? authRule[7] == 1 ? "checked" : "" : ""}  >
                        <span class="custom-control-label"></span>
                    </label>
                        </td> 


                     
                        
                    </tr > `;
                        if (data.child.length) {
                            data.child.forEach((elm, index) => {
                                var auth = useAuth.filter(x => x.AbilitiyId == elm.AbilitiyId)[0]
                                var authRule = ""
                                if (auth) {
                                    authRule = String(auth.Auth).split("");
                                }
                                opt += `<tr>
                                <td>
                                            ${elm.AbilitiyId}

                                        </td> 
                                        <td>
                                        - ${elm.Name}
                                        </td> 
                                  
                                        <td class="operation text-left">
                                                <input class="all_select " ${authRule[0] != undefined ? authRule.toString() == "1,1,1,1,1,1,1,1" ? "checked" : "" : ""} id="select-all-${elm.AbilitiyId}" type="checkbox"   data-id="-1">
                                        </td > 


                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                       <input type="checkbox"  class="custom-control-input control-checkbox listCheck" data-id="${elm.AbilitiyId}" ${authRule[0] != undefined ? authRule[0] == 1 ? "checked" : "" : ""} >
                                    <span class="custom-control-label"></span>
                                                </label >
                                        </td > 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                                    <input type="checkbox" class="custom-control-input control-checkbox detailsCheck" data-id="${elm.AbilitiyId}" ${authRule[1] != undefined ? authRule[1] == 1 ? "checked" : "" : ""} >
                                                    <span class="custom-control-label"></span>
                                                </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                                    <input type="checkbox" class="custom-control-input control-checkbox editCheck" data-id="${elm.AbilitiyId}"  ${authRule[2] != undefined ? authRule[2] == 1 ? "checked" : "" : ""} >
                                                    <span class="custom-control-label"></span>
                                                </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                        <input type="checkbox" class="custom-control-input control-checkbox addCheck" data-id="${elm.AbilitiyId}" ${authRule[3] != undefined ? authRule[3] == 1 ? "checked" : "" : ""}  >
                                        <span class="custom-control-label"></span>
                                    </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                        <input type="checkbox" class="custom-control-input control-checkbox removeCheck" data-id="${elm.AbilitiyId}" ${authRule[4] != undefined ? authRule[4] == 1 ? "checked" : "" : ""}  >
                                        <span class="custom-control-label"></span>
                                    </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                        <input type="checkbox" class="custom-control-input control-checkbox  approveCheck" data-id="${elm.AbilitiyId}" ${authRule[5] != undefined ? authRule[5] == 1 ? "checked" : "" : ""} >
                                        <span class="custom-control-label"></span>
                                    </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                        <input type="checkbox" class="custom-control-input control-checkbox uploadCheck" data-id="${elm.AbilitiyId}" ${authRule[6] != undefined ? authRule[6] == 1 ? "checked" : "" : ""}  >
                                        <span class="custom-control-label"></span>
                                    </label>
                                        </td> 

                                        <td>
                                        <label class="custom-control custom-control-success custom-checkbox mb-2">
                                        <input type="checkbox" class="custom-control-input control-checkbox downloadCheck" data-id="${elm.AbilitiyId}" ${authRule[7] != undefined ? authRule[7] == 1 ? "checked" : "" : ""}  >
                                        <span class="custom-control-label"></span>
                                    </label>
                                        </td> 
                                    </tr > `;
                            });

                        }

                        tableRule.append(opt);
                    });
                    _this.authTable.current.scrollIntoView({ behavior: 'smooth' });

                })
                .catch(function (error) {
                    Functions.requestAxiosErrorFunction(error);
                });
        });

        $("#authTable").on("click", "td .all_select", function () {
            if ($(this).is(':checked')) {
                $(this).parent().parent().children('td').children().children().prop("checked", true)
            }
            else {
                $(this).parent().parent().children('td').children().not($(this)).children().prop("checked", false)
            }
        });
    }

    printRuleList = () => {
        var printWin = window.open('', '', 'width=755,height=1096')
        var content = `
        <html dir="ltr" lang="tr" class="focus-outline-visible">
                    <head>
                        <meta charset="utf-8">
                            <link rel="stylesheet"href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
                            <link rel="stylesheet"href=" https://demo.interface.club/limitless/demo/Template/layout_1/LTR/default/full/assets/css/all.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
                            </head>
    
                            <body>
                            ${$(".tablePrintDivision").html()}
                            </body>
                        </html>`
        content = content.replace(/<input/, `<input value=\"${$(".ruleUserName").val()} \"`)
        printWin.document.write(content)

        printWin.addEventListener(
            'load',
            function () {
                printWin.print()
            },
            true
        )

    }
    componentDidUpdate = () => this.componentDidMount();
    clickRuleSave = () => {
        const { t } = this.props
        const _this = this;
        var checkedList = $('.control-checkbox:checkbox:checked')
        var ruleList = new Array;
        var processManager = ReactSession.get('user')
        for (let elm = 0; elm < checkedList.length; elm++) {
            const element = checkedList[elm];
            const element_id = $(element).data("id");
            let rule = {
                AbilitiyId: element_id,
                Auth: [0, 0, 0, 0, 0, 0, 0, 0],
                Description: `${processManager.Name} ${processManager.Surname}`,
                Status: true
            };
            let rule_exists = ruleList.find(x => x.AbilitiyId === element_id);
            if (rule_exists !== undefined)
                rule = rule_exists;
            switch ($(element)[0].classList[2]) {
                case "listCheck":
                    rule.Auth[0] = 1;
                    break;
                case "detailsCheck":
                    rule.Auth[1] = 1;
                    break;
                case "editCheck":
                    rule.Auth[2] = 1;
                    break;
                case "addCheck":
                    rule.Auth[3] = 1;
                    break;
                case "removeCheck":
                    rule.Auth[4] = 1;
                    break;
                case "approveCheck":
                    rule.Auth[5] = 1;
                    break;
                case "uploadCheck":
                    rule.Auth[6] = 1;
                    break;
                case "downloadCheck":
                    rule.Auth[7] = 1;
                    break;
            }

            if (ruleList.filter(x => x.AbilitiyId == $(element).data("id")).length == 0) {
                ruleList.push(rule);
            }


        }

        for (let i = 0; i < ruleList.length; i++) {
            let element = ruleList[i];
            ruleList[i].Auth = String(element.Auth).replaceAll(',', '');
        }

        var data = JSON.stringify({
            "UserId": jq('.ruleUserName').attr("id"),
            "Auths": ruleList
        });

        var config = {
            method: 'post',
            url: getEndPoint() + "/api/Admin/Manager/Auths",
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: data
        };
        axios(config)
            .then(function (response) {
                if (jq('.ruleUserName').attr("id") == ReactSession.get('user').id) {
                    Functions.profileCheck()
                }
                Swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.managerTable.ajax.reload()


            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    managerAddClick = () => {
        const _this = this
        const { t } = this.props
        var input = document.querySelector("#user-phone-Edit");
        var iti = window.intlTelInputGlobals.getInstance(input);
        var phoneNumbers = iti.getNumber();
        if ((phoneNumbers.search("90500") == -1) && (!iti.isValidNumber())) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.phone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (this.Name.current.value == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.name") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (this.surName.current.value == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.surname") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (this.eMail.current.value == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.email") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (this.password.current.value == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.password") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        var data = JSON.stringify({
            Name: this.Name.current.value,
            Surname: this.surName.current.value,
            Email: this.eMail.current.value,
            CountryCode: '+' + iti.selectedCountryData.dialCode,
            Phone: `${iti.getNumber()}`,
            PhoneNoCountryCode: Functions.phoneControl(iti.getNumber(), iti.selectedCountryData),
            Sifre: this.password.current.value,
            TimeZone: "UTC+3",
            RoleId: this.roleID.current.value,
            Status: this.status.current.value
        });
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/Manager',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t("swal.success.successMessage", { ns: "translations" }),
                    showConfirmButton: false,
                    timer: 1500
                }).then(function () {
                    _this.managerTable.ajax.reload()
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    managerEditClick = async () => {
        const _this = this;
        const { t } = this.props
        var input = document.querySelector("#editPhone");
        var iti = window.intlTelInputGlobals.getInstance(input);
        console.log(iti.getNumber(), iti.isValidNumber());
        var phoneNumbers = iti.getNumber();
        if ((phoneNumbers.search("90500") == -1) && (!iti.isValidNumber())) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.phone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('#editName').val() == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.name") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('#editSurname').val() == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.surname") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('#editMail').val() == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.email") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        var data = {
            id: parseInt($('#editID').val()),
            Name: $('#editName').val(),
            Surname: $('#editSurname').val(),
            Email: $('#editMail').val(),
            Phone: `${iti.getNumber()}`,
            PhoneNoCountryCode: Functions.phoneControl(iti.getNumber(), iti.selectedCountryData),
            TimeZone: "UTC+3",
            RoleId: $('#editRole').val(),
            Status: $('#editStatus').val()
        };
        if ($('#editPassword').val() != "") {
            data.Sifre = $('#editPassword').val();
        }
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/Manager',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: JSON.stringify(data),
        };
        axios(config)
            .then(function (response) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t("swal.success.successMessage", { ns: "translations" }),
                    showConfirmButton: false,
                    timer: 1500
                }).then(function () {
                    _this.managerTable.ajax.reload()
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    render() {
        const { t } = this.props;

        return (
            <div className="p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.management.managers", { ns: "translations" })}</h6>

                        <div className="header-elements">
                            <div className="list-icons">
                                {Functions.RoleControl(34, 3) == true ? <a href='#' data-toggle="modal" data-target="#manager-add"><button className="btn btn-success mb-2">{t("addManager")}</button></a> : ""}

                                <a onClick={() => {
                                    this.componentDidMount()
                                }} className="list-icons-item" data-action="reload"></a>

                            </div>
                        </div>
                    </div>

                    <div className="card-body mt-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table ref={this.managersTableRef} id="managers-table" className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.ID")}</th>
                                            <th>{t("table.name")} </th>
                                            <th>{t("table.email")}</th>
                                            <th>{t("table.phone")}</th>
                                            <th>{t("table.duty")}</th>
                                            <th>{t("table.status")}</th>
                                            <th>{t("table.operations")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </Division>
                        </Division>
                    </div>
                </div>
                <div id='authCard' style={{ display: "none" }} className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header  header-elements-lg-inline">
                        <h6 className="card-title">{t("Yetki Tanımla")}</h6>
                        <div className="header-elements">
                            <button onClick={this.printRuleList} type="button" className="btn btn-primary btn-icon"><i className="icon-printer2"></i></button>
                        </div>
                    </div>

                    <div className="tablePrintDivision card-body mt-2">
                        <div className="form-group row">
                            <div className="d-flex col-lg-12">
                                <label className="form-label w-100 col-sm-2">
                                    {t("table.name")}
                                </label>
                                <div className="col-sm-8">
                                    <input readOnly type="text" className="form-control w-100 ruleUserName" />

                                </div>

                            </div>
                        </div>
                        <div className='table-responsive '>

                            <table id='authTable' ref={this.authTable} className=' table'>
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Role İsmi</td>
                                        <td>Hepsini Seç</td>
                                        <td>listeleme</td>
                                        <td>Detay görüntüleme</td>
                                        <td>düzenleme / güncelleme</td>
                                        <td>ekleme</td>
                                        <td>silme</td>
                                        <td>Onaylama / Kabul Etme</td>
                                        <td>yükleme</td>
                                        <td>indirme</td>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <button className='btn btn-success' onClick={this.clickRuleSave}>Yetkileri Kaydet</button>
                </div>
                <div id="manager-add" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("modal.addManager")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <Division className="row">
                                    <Division className="col-md-6">
                                        <Division className="form-group">
                                            <label>{t("modal.accountStatus")}</label>
                                            <select ref={this.status} className="form-control select-search" defaultValue={1}>
                                                <option value={-1} disabled>{t("choose")}</option>
                                                <option value={1}>{t("active", { ns: "translations" })}</option>
                                                <option value={0}>{t("passive", { ns: "translations" })}</option>
                                            </select>
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.name")}</label>
                                            <input ref={this.Name} className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.email")}</label>
                                            <input ref={this.eMail} className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.phone")}</label>
                                            <input
                                                type="tel"
                                                required
                                                ref={this.Phone}
                                                name="Phone"
                                                id="user-phone-Edit"
                                                className="form-control form-control-lg" />
                                        </Division>

                                        <Division className="form-group"></Division>
                                    </Division>
                                    <Division className="col-md-6">
                                        <Division className="form-group">
                                            <label>{t("modal.managerType")}</label>
                                            <select ref={this.roleID} className="roleSelect form-control select-search" defaultValue={1}>

                                            </select>
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.surname")}</label>
                                            <input ref={this.surName} className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.password")}</label>
                                            <input type="password" ref={this.password} className="form-control" />
                                        </Division>


                                    </Division>
                                </Division>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button onClick={this.managerAddClick} type="button" className="btn btn-primary" data-dismiss="modal">{t("modal.add")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="manager-edit" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("Yönetici Düzenle")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <Division className="row">
                                    <Division className="col-md-6">
                                        <Division className="form-group">
                                            <label>{t("modal.ID")}</label>
                                            <input disabled readOnly id='editID' className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.name")}</label>
                                            <input id='editName' className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.email")}</label>
                                            <input id='editMail' className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.phone")}</label>
                                            <input
                                                id="editPhone"
                                                type="tel"
                                                required
                                                name="Phone"
                                                className="form-control form-control-lg" />
                                        </Division>

                                        <Division className="form-group"></Division>
                                    </Division>
                                    <Division className="col-md-6">
                                        <Division className="form-group">
                                            <label>{t("modal.managerType")}</label>
                                            <select id='editRole' className="roleSelect form-control select-search" defaultValue={1}>

                                            </select>
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.surname")}</label>
                                            <input id='editSurname' className="form-control" type="text" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.password")}</label>
                                            <input id='editPassword' type="password" className="form-control" />
                                        </Division>
                                        <Division className="form-group">
                                            <label>{t("modal.accountStatus")}</label>
                                            <select id='editStatus' className="form-control select-search" defaultValue={1}>
                                                <option value={-1} disabled>{t("choose")}</option>
                                                <option value={"true"}>{t("active", { ns: "translations" })}</option>
                                                <option value={"false"}>{t("passive", { ns: "translations" })}</option>
                                            </select>
                                        </Division>
                                    </Division>
                                </Division>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                                <button onClick={this.managerEditClick} type="button" className="btn btn-primary" data-dismiss="modal">{t("Save", { ns: "translations" })}</button>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="manager-member-trips" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("Yönetici Düzenle")}</h5>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div className="modal-body">
                                <Division className="row">
                                    
                                </Division>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("modal.cancel")}</button>
                             </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation(["managementManagers", "translations"])(Managers);