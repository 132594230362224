import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { ReactSession } from 'react-client-session';


const AuthControl = () => {
    let auth = null;
    if (cookies.get('auth') == undefined) {
        ReactSession.remove('auth')
        location.href = '/login';
    }
    ReactSession.get('auth') == 'true' ? auth = true : auth = null;
    return auth ? <Outlet /> : <Navigate to="/login" />;
}

export default AuthControl;
