import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Text from "../../components/elements/text";
import Button from "../../components/elements/button";
import $ from 'jquery'
import AbcIcon from '@mui/icons-material/Abc';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import axios from 'axios'
import Cookies from 'universal-cookie';
import { getEndPoint } from "../../class/api";
import { ReactSession } from "react-client-session";
import Anchor from "../../components/elements/anchor";
import { swal } from "sweetalert2/dist/sweetalert2.all";
import Functions from "../../class/functions";
const cookies = new Cookies();
let userData = [];
let statusFilterID = null;
class Bicycles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            userList: [],
            userData: [],
            StatusId: null
        };
        this.bicycleTable = null;
        this.modalBicycleRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.filterHandleClick = this.filterHandleClick.bind(this);
    }
    tableInstall = () => {
        const { t } = this.props
        var _this = this;
        if (this.bicycleTable != null) {
            $('#bicycles-table').empty()
            this.bicycleTable.destroy();
        }

        this.bicycleTable = $('#bicycles-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
     
 
            serverSide: true,

            order: [5, 'asc'],
            processing: true,
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,

            ajax: {
                url: getEndPoint() + '/api/Admin/Station/Vehicle',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#bicycles-table`).removeClass('table-loader').show()
                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                var filterStatus = false ;
                // var l = aoData.length;  

 
                if (ReactSession.get('bikeSearchData') != null && ReactSession.get('bikeSearchData').length > 2 ) {
                    $('input[type="search"]').val(ReactSession.get('bikeSearchData'))

                    aoData['Filter'] = {
                        q: ReactSession.get('bikeSearchData'),
                        order: aoData.columns[aoData.order[0].column].data,
                        sort: aoData.order[0].dir,
                        StatusId: status,

                    };
                    ReactSession.remove('bikeSearchData'); 
                    filterStatus = true ;
                } else if ( aoData.search.value.length > 2 ) { 
                    aoData['Filter'] = {
                        q: aoData.search.value,
                        order: aoData.columns[aoData.order[0].column].data,
                        sort: aoData.order[0].dir,
                        StatusId: status,
                    };
                    filterStatus = true ;
                } else { 

                }
                if ($('.statusFilter').val() != 0) { 
                    if (typeof aoData['Filter'] == 'undefined') {
                        // your code here
                        aoData['Filter'] = { 
                            StatusId: status,
                        }; 
                      }
                      
                    aoData['Filter']['StatusId'] = [parseInt($('.statusFilter').val())]
                    filterStatus = true ;
                } 
                
                aoData["per_page"] = aoData.length;

                if ( !filterStatus ){ 
                    if ( aoData.search.value.length > 0 ) { 
    
                        this.bicycleTable.bDestroy = true ; 
                        this.bicycleTable.ajax.bDestroy = true ;
                        aoData["per_page"] = 1;  
                        aoData.push(null); // hata verdirip, devam etmemesini sağlıyor 

                    }
                    aoData.bDestroy = true; 
                    return false ;
                }  
                

                aoData["page"] = aoData.start / aoData.length + 1;
            },

            "columns": [
                {
                    title: t("table.BicycleType"), data: "VehicleTypeId", render: function (data, type, row) {
                        return row.VehicleType
                    }
                },
                {
                    title: t("table.VehicleNo"),
                    data: "VehicleNo", render: function (data, type, row) {
                        return (`<a class="vehicleNo" href="#" >${data}</a>`);
                    }
                },
                {
                    title: t("table.userID"),

                    data: "User", render: function (data, type, row) {

                        var user;
                        var userID;


                        if (row.User != null) {
                            user = row.User.Name + ' ' + row.User.Surname
                            userID = '/users/edit/' + row.UserId
                            userID = Functions.RoleControl(11, 4) == false ? '#' : userID

                            return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.UserId + '">' + user + '</a>';
                        }
                        else {
                            var stationName = row.Station
                            if (row.Station == null) {
                                return "İstasyon Bulunamadı"
                            }
                            var stationList = ReactSession.get('stationList');
                            return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${stationList.find(x => x.Name == stationName).StationId}` : `#`} ">${stationName} > ${row.StationParkUnitId} </a> `;
                        }

                    }, orderable: false
                },
                { title: t("table.RFID"), data: "RFID" },
                {
                    title: t("table.UsedMinute"),
                    data: "ProcessDate"
                    , render: function (data, type, row) {
                        if (row.User != null) {

                            return '<p>' + row.ProcessDateDiff + ' </p>'
                        }
                        else {

                            return "00:00:00 00:00"
                        }
                    }
                },
                {
                    title: "Son Çıkış / Giriş İstasyonu", data: "LastProcessingTrip", render: function (data, type, row) {

                        var StationList = ReactSession.get('stationList');
                        var firstData = StationList.find(x => x.Name == data.StationParkUnitEntry.StationName)
                        var lastData = StationList.find(x => x.Name == data.StationParkUnitExit.StationName)
                        console.log(firstData);
                        var edittedData = `
                        <p>
                            <a href="/stations/list/${firstData != undefined ? firstData.StationId : "#"}">
                                ${data.StationParkUnitEntry.StationName === null ? "İstasyon Bulunamadı" : data.StationParkUnitEntry.StationName}
                            </a>
                         </p> `
                        if (data.StationParkUnitExit.StationName != null) {
                            edittedData += `   <p>
                <a href="/stations/list/${lastData != undefined ? lastData.StationId : "#"}">
                    ${data.StationParkUnitExit?.StationName === null ? " - " : data.StationParkUnitExit?.StationName}
                </a> 
                         </p> `
                        }
                        return edittedData
                    }
                },
                {
                    title: t("table.VehicleTypeId"), data: "StatusId", render: function (data, type, row) {
                        return row.StatusIdTxt
                    }
                },

                Functions.RoleControl(18, 2) ?
                    {
                        title: t("table.operations"),
                        data: "VehicleId", render: function (data, type, row) {
                            var test1 = '<a href="#" id="bike-editBTN" type="button"  data-id="' + data + '" data-target="#bicycle-edit" data-toggle="modal" class="bike-edit bikeEditClasses dropdown-item"><i class="icon-pencil"> </i></a>'
                            return test1
                        }
                    }
                    : ""

            ]
        });
        new $.fn.dataTable.Responsive(this.bicycleTable, { details: true, Responsive: true },);

    }
    componentDidMount() {
        const { t } = this.props;
        const _this = this;



        this.tableInstall()
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Vehicle/Types',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            }
        };

        axios(config)
        .then(function (response) {
            var selectType = $('.selectTypeBike');
            selectType.empty();
            response.data.data.forEach(elm => {

                var options = new Option
                    (
                        elm.Name,
                        elm.VehicleTypeId,
                        true,
                        true
                    )

                selectType.append(options).trigger('change');

            });
        })
        .catch(function (error) {
            Functions.requestAxiosErrorFunction(error);
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Vehicle/Status',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            }
        };

        axios(config)
        .then(function (response) {
            var selectStatus = $('#selectStatus');
            var statusFilter = $('.statusFilter');
            selectStatus.empty();
            statusFilter.empty();


            var options = new Option
                (
                    "Lütfen Seçim Yapınız",
                    "0",
                    true,
                    true
                )

            selectStatus.append(options).trigger('change');
            statusFilter.append(options).trigger('change');
            response.data.data.forEach(elm => {

                var options = new Option
                    (
                        elm.Name,
                        elm.VehicleStatuId,
                        false,
                        false
                    )

                var optionss = new Option
                    (
                        elm.Name,
                        elm.VehicleStatuId,
                        false,
                        false
                    )
                statusFilter.append(options).trigger('change');
                selectStatus.append(optionss).trigger('change');

            });
        })
        .catch(function (error) {
            Functions.requestAxiosErrorFunction(error);
        });

        $(document).on('focus.bs.inputmask.data-api', '[data-mask]', function (e) {
            var $this = $(this);
            if ($this.data('bs.inputmask')) return;
            var inputMask = new InputMask($this, $this.data());
        });

        $('#bicycles-table tbody').on('click', 'td .vehicleNo', function () {
            var tr = $(this).closest('tr');
            var row = _this.bicycleTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.bicycleTable.row(this);
                data = row.data();
            }
            ReactSession.set('bikeDetails', data);
            window.location.href = "/bicycles/bicycle-movements";


        });

        $('#bicycles-table tbody').on('click', 'td #bike-editBTN', function () {
            var tr = $(this).closest('tr');
            var row = _this.bicycleTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.bicycleTable.row(this);
                data = row.data();
            }


            userData = data
            
            $('.bicycleNum').val(data.VehicleNo)
            $('.newBikeNumber').val(data.VehicleNo)
            $(".selectStatus").val(String(data.StatusId))
            $('.selectStatus').trigger('change');
            $(".selectTypeBike").val(data.VehicleTypeId)
            $('.selectTypeBike').trigger('change');

            $("#idRFID").val(data.RFID)
            $('#idRFID').trigger('change');

        });

    }
    filterHandleClick(e) {
        e.preventDefault();
        const _this = this;
        const { t } = this.props
        statusFilterID = parseInt($('.statusFilter').val())


        this.bicycleTable.ajax.reload()
    }
    handleSubmit(e) {
        e.preventDefault();

        const _this = this;
        const { t } = this.props

        Swal.fire({
            title: t("swal.info.subjectNoData", { ns: "translations" }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {

                var userEditData = userData;
                userEditData.VehicleNo = $('.newBikeNumber').val() == "" ? userEditData.VehicleNo : $('.newBikeNumber').val()
                userEditData.StatusId = parseInt($('.selectStatus').val() == "" ? userEditData.StatusId : $('.selectStatus').val())
                userEditData.VehicleTypeId = parseInt($('.selectTypeBike').val() == "" ? userEditData.VehicleTypeId : $('.selectTypeBike').val())
                userEditData.RFID = parseInt($('#idRFID').val() == "" ? userEditData.RFID : $('#idRFID').val())

                var data = {
                    "VehicleId": userEditData.VehicleId,
                    "VehicleTypeId": userEditData.VehicleTypeId,
                    "StationParkUnitId": userEditData.StationParkUnitId,
                    "VehicleNo": userEditData.VehicleNo,
                    "RFID": userEditData.RFID,
                    "Name": userEditData.Name,
                    "LastUsedMT": parseFloat(userEditData.LastUsedMT).toFixed(4),
                    "LastUsedKM": parseFloat(userEditData.LastUsedKM).toFixed(4),
                    "UsedKM": parseFloat(userEditData.UsedKM).toFixed(4),
                    "UsedMinute": userEditData.UsedMinute,
                    "LastConnect": userEditData.LastConnect,
                    "StatusId": userEditData.StatusId,
                    "Status": userEditData.Status
                }

                var config = {
                    method: 'POST',
                    url: getEndPoint() + '/api/Admin/Station/Vehicle',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            $("#bicycle-edit .close").trigger("click");
                            _this.bicycleTable.ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });


            } else if (result.isDenied) {
                Swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <Division className="user-page p-2">

                <Division className="card mb-1">


                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-md-6">
                                <Division className="row mt-2">
                                    <Division className="col-md-4  d-flex justify-content-start align-content-center align-items-center">
                                        <Text className="mb-4" Tag="span">{t("bicycleStatus.bicycleStatus", { ns: "translations" })}</Text>
                                    </Division>
                                    <Division className="col-md-6">
                                        <select className="form-control w-100 statusFilter  notSearch" selected="0">
                                            <option value="0"> {t("loading", { ns: "translations" })}</option>

                                        </select>
                                    </Division>
                                    <Division className="col-md-2 mb-4">

                                        <Button onClick={this.filterHandleClick} className="btn btn-secondary w-100 ">{t("filter")}</Button>
                                    </Division>
                                </Division>


                            </Division>
                        </Division>
                    </Division>
                </Division>

                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("VehicleList")}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={() => { this.bicycleTable.ajax.reload() }} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table id="bicycles-table" ref={this.usersTableRef} className="table-loader dataTable display  compact  w-100">
                                </table>
                            </Division>
                        </Division>
                    </Division>
                </div>

                <div id="bicycle-edit" ref={this.modalBicycleRef} className="modal fade" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={this.handleSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title">{t("modal.editBicycle")}</h5>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.bicycleNum")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" className="form-control form-control-lg bicycleNum" readOnly placeholder={t("modal.bicycleNum")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <AbcIcon />
                                                    </Division>
                                                </Division>
                                            </Division>

                                        </div>
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.newBicycleNumber")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="text" className="form-control form-control-lg newBikeNumber" placeholder={t("modal.newBicycleNumber")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <AbcIcon />
                                                    </Division>
                                                </Division>
                                                <Button type="submit" className="btn btn-secondary w-100">{t("modal.changeNumberOfBicycle")}</Button>
                                            </Division>

                                        </div>
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.newBicycleStatus")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <div className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-start align-items-center mb-2">
                                                        <select defaultChecked="0" id="selectStatus" className="form-control w-100 notSearch selectStatus " placeholder={t("modal.newBicycleStatus")}>
                                                            <option value="0"> {t("loading", { ns: "translations" })}</option>

                                                        </select>
                                                        <div className="form-control-feedback form-control-feedback-lg">
                                                            <DirectionsBikeIcon />
                                                        </div>
                                                    </div>
                                                </Division>
                                                <Button type="submit" className="btn btn-secondary w-100">{t("modal.changeStatusOfBicycle")}</Button>
                                            </Division>

                                        </div>
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.newBicycleType")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <div className="form-group form-group-feedback form-group-feedback-left d-flex justify-content-start align-items-center mb-2">
                                                        <select defaultChecked='0' className="form-control w-100 selectTypeBike" placeholder={t("modal.newBicycleType")}>
                                                            <option value="0">Lütfen Seçim Yapınız.</option>
                                                        </select>
                                                        <div className="form-control-feedback form-control-feedback-lg">
                                                            <DirectionsBikeIcon />
                                                        </div>
                                                    </div>
                                                    <Button type="submit" className="btn btn-secondary w-100">{t("modal.changeTypeOfBicycle")}</Button>
                                                </Division>
                                            </Division>

                                        </div>
                                        <div className="col-lg-12">
                                            <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("modal.bicycleRFID")}</Text>

                                            <Division className="col-lg-12 align-self-center">
                                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                                    <input type="number" id="idRFID" className="form-control form-control-lg " placeholder={t("modal.bicycleRFID")} />
                                                    <Division className="form-control-feedback form-control-feedback-lg">
                                                        <DirectionsBikeIcon />
                                                    </Division>
                                                </Division>
                                                <Button type="submit" className="btn btn-secondary w-100">{t("modal.changeBicycleRFID")}</Button>
                                            </Division>

                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" id="deneme" data-dismiss="modal">{t("modal.cancel")}</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </Division>
        )
    }
}

export default withTranslation(['systemBicycles', 'translations'])(Bicycles);

