import React, { Component , useState, useEffect, useCallback } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper,Polyline } from 'google-maps-react';
import MarkerYes from "../img/marker_yes.png";
import MarkerNo from "../img/marker_no.png";


import { getEndPoint } from "../class/api"; 
import Cookies from 'universal-cookie';
import axios from "axios";
const cookies = new Cookies();
class MapsLine extends Component {
  constructor(props) {
    const { filter , ajaxUrl , initialCenter } = props;
    super(props); 
    var self = this;
    this.state = {
      labelSize : { width: 220} , 
      labelPadding : 8 , 
      places: [], 
      markers: [], 
      markersTest: [
        { lat: 40.7042346, lng: 29.8843142, status: true },
        { lat: 40.7012323, lng: 29.8143142, status: true },
        { lat: 40.7082337, lng: 29.8643142, status: false },
        { lat: 40.7102350, lng: 29.8843142, status: true },
        { lat: 40.7502310, lng: 29.8343142, status: false }
      ],
      coords:[],
    }
    // console.log(filter[0]); 
    // 
    this.getAllMarkers ( self )

    }



    addMarkerLoad( e ) { 
      console.log(e); 
    this.setState({
      //markers: [...this.state.markers, newPlace2], 
      markers: [ ...this.state.markers ],  
      //markers: [ newPlace2 ]
    });
    console.log ( this.state.markers );
  }

 

  onMapClick= (e) => this.setState({
    markerPosition: e.latLng,
    isMarkerShown:true}
);

    getAllMarkers ( selfV ) { 
      var self = this 
       

    var data = JSON.stringify({
      "Filter": self.props.filter[0] 
  });
    var config = {
      method: 'put',
      url: `${getEndPoint()}${ self.props.ajaxUrl }`, 
      headers: {
          'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      data: data,
      withCredentials: true,
    }; 
 
    axios(config)
    .then(function (response) { 
      self.setState({ 
        markers: [   ] 
      }) 
        response.data.data.forEach((element, index) => {  
          // console.log ( element );
          const newPlace3 = {
            //id: self.state.markers.length, 
            id: element._id,  
            title: element?.VehicleNo + " - IMEI : " + element?.imei, 
            label : element?.VehicleNo,
            lat: element.lat, 
            lng: element.lon,  
            status: true 
          };
          self.setState({ 
            markers: [  ...self.state.markers,newPlace3 ] 
          }) 

          const newCoord = {
            lat: element.lat,
            lng: element.lon
          }
          self.setState({ 
            coords: [  ...self.state.coords,newCoord ] 
          }) 
        }); 
    }); 

    }


  reRender = () => {
    // calling the forceUpdate() method
    this.forceUpdate();
  };

 
  render() {
    const sortedMarkers = this.state.markers.sort((a, b) => a.lat - b.lat);
    const coordinates = sortedMarkers.map(marker => ({ lat: marker.lat, lng:marker.lng}));
    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '450px'
    }
 

    return (
      <div>
        <Map
          //onClick={this.mapClicked}
          //  onClick={this.addMarkerLoad.bind(this)}
          // onClick={() => this.forceUpdate()}
           onClick={this.getAllMarkers.bind(this)}

       

          initialCenter={{
            lat: this.props.initialCenter[0] ?? 40.7032331, lng: this.props.initialCenter[1] ?? 29.8843142
          }}
          containerStyle={containerStyle}
          google={this.props.google} zoom={11}>
          {this.state.markers.map((elm, index) => {  
             const firstMarker= index===0
             const lastMarker = index === this.state.markers.length - 1;
             let icon;
             if(index=firstMarker){
              icon=MarkerYes;
             }else if(index = lastMarker ){
              icon = MarkerNo
             }
            return <Marker key={index} 
      
              position={{ lat: elm.lat, lng: elm.lng }} 
              title= { elm.title }
              label= { elm.label }
              options={{
                icon:icon
              }}
              
              >   
              </Marker>

          })
          }
 <Polyline
  path={coordinates}
  strokeColor="#0000FF"
  strokeOpacity={0.8}
  strokeWeight={2}
/>
        </Map>
      </div>
    )
  }
}
export default GoogleApiWrapper({ apiKey: ("AIzaSyD13goluYdOUCH2z3zVYpSFZZa3RxgxKsY") })(MapsLine)