import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../functions";
import Cookies from 'universal-cookie';
import axios from "axios";
import moment from "moment";
const cookies = new Cookies();

class FaultTracking extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
        this.tableRef = React.createRef(null);
        this.state = {
            status: false,
            vehicleList: [],
        };
        this.sonKullanmaTarih1Ref = React.createRef(null);
        this.sonKullanmaTarih2Ref = React.createRef(null);
        this.sonKullanmaTarih3Ref = React.createRef(null);
        this.sonKullanmaTarih4Ref = React.createRef(null);

    }
    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            }
        });
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        DateRangePickerHandler(this.sonKullanmaTarih1Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });



        DateRangePickerHandler(this.sonKullanmaTarih2Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        DateRangePickerHandler(this.sonKullanmaTarih3Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
        DateRangePickerHandler(this.sonKullanmaTarih4Ref.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            singleDatePicker: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });

        this.processDataTable(this.tableRef, "#fault-table");

        if (_this.state.status != true) {

            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Station/Vehicle',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({ "per_page": -1 })
            };

            axios(config)
                .then(function (response) {
                    _this.setState({
                        status: true,
                        vehicleList: response.data.data
                    })
                    var selectBike = $('#bikeList');
                    response.data.data.forEach(data => {
                        var option =
                            new Option(data.VehicleId + ' - (No:' + data.VehicleNo + ') - ' + data.Name + ' - ' + data.RFID + ' - (' + data.VehicleType + ' | ' + data.StatusIdTxt + ')', data.VehicleId, false, false);
                        selectBike.append(option).trigger('change');

                    });
                })
        }
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="retail-page p-2">
                <div className="card mb-1   ">
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.firstReceiptsDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih1Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.lastReceiptsDate")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <span className="input-group-prepend">
                                                <span className="input-group-text"><i className="icon-calendar22"></i></span>
                                            </span>
                                            <input ref={this.sonKullanmaTarih2Ref} type="text" className="form-control w-100 daterange-single" defaultValue="" />
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-4">{t("filters.bikeList")}</label>
                                    <div className="col-lg-8">
                                        <div className="d-flex">
                                            <select id="bikeList" className="form-control w-100" selected="">
                                                <option value="">{t("all", { ns: "translations" })}</option>


                                            </select>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Division className="col-sm-4 mb-4">
                                <button type="button" className="btn btn-primary w-100">{t("filter")}</button>
                            </Division>
                        </div>
                    </div>
                </div>

                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="fault-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.bikeNo")}</th>
                                            <th>{t("table.bikeFaultStatus")}</th>
                                            <th>{t("table.operations")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td><a type="button">25460</a></td>


                                            <td className="text-center">
                                                <div className="list-icons">
                                                    <a className="btn btn-light" data-toggle="modal" data-target="#retailModal">
                                                        <i className="icon-menu9"></i>

                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>


                <script src="js/plugins/ui/moment/moment.min.js"></script>
                <script src="js/plugins/pickers/daterangepicker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.date.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.time.js"></script>
                <script src="js/plugins/pickers/pickadate/legacy.js"></script>
                <script src="js/plugins/notifications/jgrowl.min.js"></script>
            </div>
        )
    }
}

export default withTranslation(['retailSalereceipts', 'translations'])(FaultTracking);
