import React from "react";
import enums from "../../class/enum.json";
import CustomElement from "./custom-element";
import { ReactSession } from 'react-client-session';
import Functions from "../../class/functions";

//<a href="" _target="blank|none"></a>
export default function Anchor(props) {
    // rota ve yetki listesi aç 
  

    return (
        props.abilityid !== undefined && props.abilityid !== null ?
            <CustomElement style={{ display: Functions.RoleControl(props.abilityid, props.auth) == false ? "none" : "" }}  {...{ Tag: "a", ...props }}> {props.children}</CustomElement >
            : <CustomElement  {...{ Tag: "a", ...props }}> {props.children}</CustomElement >
    );
}