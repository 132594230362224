import React from "react";

export default function Dropdown(props) {
    return (
        <div className={`d-down ${props.ClassName}`}>  
            <a href="#" className={`navbar-nav-link navbar-nav-link-toggler dropdown-toggle ${props.ButtonClass}`} data-toggle="dropdown">
                {props.Button && props.Button}
            </a>

            <div className={`dropdown-menu dropdown-menu-right ${props.ContentClass}`}>
                {props.Content && props.Content}
            </div>
        </div>
    );
}

/*
   <img src="img/placeholders/placeholder.jpg" className="rounded-pill mr-lg-2" height="34" alt=""/>
   <span className="d-none d-lg-inline-block">Murat</span>
*/

/*
    <a href="#" className="dropdown-item"><i className="icon-user-plus"></i> Hesap</a>
    <a href="#" className="dropdown-item">İstasyonlar</a>
    <a href="#" className="dropdown-item"><i className="icon-comment-discussion"></i> Mesajlar <span className="badge badge-primary badge-pill ml-auto">1</span></a>
    <div className="dropdown-divider"></div>
    <a href="#" className="dropdown-item"><i className="icon-cog5"></i> Hesap Ayarları</a>
    <a href="#" className="dropdown-item"><i className="icon-switch2"></i> Çıkış Yap</a>
*/