import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import Safe from "react-safe";

import {getEndPoint} from "../../class/api";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Functions from "../../functions";

class ProcessTypes extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];  
        this.tableRef = React.createRef(null);

    }
    processDataTable(tableRef, tableKey) {
        const { t } = this.props;

        if (tableRef == undefined)
            return;

        if (this.tables[tableKey] != null)
            this.tables[tableKey].destroy();

        this.tables[tableKey] = $(tableRef.current).DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", {ns:"translations"}) + '&larr;' : t("tableLocalization.paginate.next", {ns:"translations"}) + ' &rarr;', 
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", {ns:"translations"}) : '&larr; ' + t("tableLocalization.paginate.previous", {ns:"translations"}) 
                }   
            }
        });
    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props
        
        this.processDataTable(this.tableRef, "#process-table");   
    }

    componentDidUpdate = () => this.componentDidMount();

    render() {
        const { t } = this.props;

        return (
            <div className="process-page p-2">

                <div className="card">
                    <div style={{backgroundColor:"#c9cacb"}} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("process")}</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#processModal">{t("processTypeAdd")}</button>

                            </div>

                        </div>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="process-table" ref={this.tableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>
                                            <th>{t("table.processId")}</th>
                                            <th>{t("table.processStatus")}</th>
                                            <th>{t("table.processName")}</th>
                                            <th>{t("table.processExtendTime")}</th>
                                            <th>{t("table.processNormalPrice")}</th>
                                            <th>{t("table.processDiscountPrice")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td ><a className="text-success">  <i className="icon-check"></i></a></td>
                                            <td>ABONELİK ÜCRETİ 10 TL</td>
                                            <td>
                                                365
                                            </td>
                                            <td>
                                                10
                                            </td>
                                            <td>
                                                10
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td><a className="text-success">  <i className="icon-check"></i></a></td>
                                            <td>3-KREDİ YÜKLE 10TL</td>
                                            <td>
                                                365
                                            </td>
                                            <td>
                                                10
                                            </td>
                                            <td>
                                                10
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td><a className="text-danger"><i className="icon-dash "></i></a></td>
                                            <td>7-KREDİ YÜKLE 50 TL</td>
                                            <td>
                                                365
                                            </td>
                                            <td>
                                                50
                                            </td>
                                            <td>
                                                50
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div id="processModal" className="modal fade" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">
                                    {t("processEdit.processEdit")}
                                </h6>
                                <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">  {t("processEdit.processName")}</label>
                                    <div className="col-lg-10">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">  {t("processEdit.processNormalPrice")}</label>
                                    <div className="col-lg-10">
                                        <input type="number" name="number" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">  {t("processEdit.processDiscountPrice")}</label>
                                    <div className="col-lg-10">
                                        <input type="number" name="number" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">  {t("processEdit.processExtendTime")}</label>
                                    <div className="col-lg-10">
                                        <input type="number" name="number" className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("processEdit.active.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("processEdit.active.select1")}</option>
                                            <option value="opt2">{t("processEdit.active.select2")}</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("processEdit.processCardFee.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("processEdit.processCardFee.select1")}</option>
                                            <option value="opt2">{t("processEdit.processCardFee.select2")}</option>
                                        </select>
                                    </div>
                                </div>

                                
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-2">{t("processEdit.processWebShow.label")}</label>
                                    <div className="col-lg-10">
                                        <select className="form-control">
                                            <option value="opt1">{t("processEdit.processWebShow.select1")}</option>
                                            <option value="opt2">{t("processEdit.processWebShow.select2")}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-link" data-dismiss="modal">{t("close")}</button>
                                <button type="button" className="btn btn-primary">{t("saveChanges")}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <script src="js/plugins/ui/moment/moment.min.js"></script>
                <script src="js/plugins/pickers/daterangepicker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.date.js"></script>
                <script src="js/plugins/pickers/pickadate/picker.time.js"></script>
                <script src="js/plugins/pickers/pickadate/legacy.js"></script>
                <script src="js/plugins/notifications/jgrowl.min.js"></script>
            </div>
        )
    }
}

export default withTranslation(['processsType', 'translations'])(ProcessTypes);
