import React from "react";
import { withTranslation } from "react-i18next";
import Functions from "../../class/functions";
import { getEndPoint } from "../../class/api";
import jq from 'jquery'
import { ReactSession } from 'react-client-session';
import Cookies from 'universal-cookie';
import axios from "axios";
import 'datatables.net-responsive'
const cookies = new Cookies();
class Stations extends React.Component {
    constructor(props) {
        super(props);
        this.tables = [];
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;
        let dirAttr = 'ltr';
        if (jq('html') !== undefined)
            dirAttr = jq('html').attr('dir');

        let selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        if (this.stationsTable != null)
            this.stationsTable.destroy();

        this.stationsTable = jq('#stations-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            pagingType: 'simple_numbers',
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            serverSide: true,
            order: [0, 'desc'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Station',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#stations-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,


                };
                if ($('.regionSelect').val() != "0") {
                    aoData["Filter"]["RegionId"] = parseInt($('.regionSelect').val())
                }

                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },


            "columns": [
                {
                    data: "StationId", title: t("table.id")
                },
                { data: "Number", title: t("table.number") },
                {
                    data: "Name", render: function (data, type, row) {
                        return `<a href="/stations/list/${row.StationId}">${data}</a>`
                    }, title: t("table.name")
                },
                {
                    title: t("table.ipAddress"),
                    data: "IpActive", render: function (data, type, row) {
                        // 
                        return `<div class="text-center" style="background-color: ${row.Status == true ? '#A2CF44' : '#ff3b3b'};"><p style="color:white;">${data}</p> </div>`
                    }
                },
                { data: "Address", title: t("table.address") },
                { data: "RegionTxt", title: t("table.regionName") },
                {
                    title: t("table.lastConnectionDate"),
                    data: "LastConnect", render: function (data) {
                        var dateData = moment(data).format('DD/MM/YYYY HH:mm:ss');
                        return dateData;
                    }
                },
                {
                    title: t("Program Versiyonu"),
                    data: "ProgramVersion",
                    render: function (data, type, row) {
                        var ver = data ?? "Bilinmiyor";
                        if (ver == 0) ver = "Bilinmiyor";
                        return ver;
                    },
                    orderable: false

                },
                {
                    title: t("table.operations"),

                    data: "StationId", render: function (data, type, row) {
                        return `<div class="list-icons">
                            <div class="dropdown">
                                <a href="#" class="list-icons-item" data-toggle="dropdown">
                                    <i class="icon-menu9"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    
                                ${Functions.RoleControl(15, 2) == true ? `<a href="/stations/list/${data} " class="dropdown-item">
                                <i class="icon-pencil"></i>
                                ${t("Details", { ns: "translations" })}
                            </a> `: ""}
                            ${Functions.RoleControl(15, 3) == true ? `<a href="/stations/edit/${data} " class="dropdown-item">
                                <i class="fa fa-info-circle"> </i>
                                ${t("Edit", { ns: "translations" })}
                            </a>`
                                : ""}
                                ${Functions.RoleControl(15, 4) == true ? `<a href="#" style="" id="remove" type="button" class="dropdown-item">
                                <i class="fas fa-trash "> </i>
                                ${t("Delete", { ns: "translations" })}
                            </a>`
                                : ""}
                                </div>
                            </div>
                        </div>`
                    }
                }




            ],
        });
        new jq.fn.dataTable.Responsive(this.stationsTable, { details: true, Responsive: true },);
        jq('#stations-table tbody').on('click', 'td #remove', function () {
            var tr = jq(this).closest('tr');
            var row = _this.stationsTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.stationsTable.row(this);
                data = row.data();
            }
            swal.fire({
                title: t("swal.info.head", { ns: "translations" }),
                text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.delete", { ns: "translations" }) }),
                showDenyButton: true,
                confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
                denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
            }).then((result) => {
                if (result.isConfirmed) {
                    var config = {
                        method: 'delete',
                        url: getEndPoint() + '/api/Admin/Station',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            "Accept": "application/json",
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({
                            "StationId": row.data().StationId
                        })
                    };

                    axios(config)

                        .then(function (response) {
                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            setTimeout(() => {
                                window.location.reload()
                            }, 3000);
                        })
                        .catch(function (error) {
                            swal.fire({
                                title: t("swal.error.head", { ns: "translations" }),
                                text: t("swal.error.subject", { ns: "translations" }) + error.message,
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.error.icon", { ns: "translations" })
                            })
                        });
                }
            })



        });
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Region',
            headers: {

                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then(function (response) {
                const responseData = response.data.data;
                jq('.regionSelect').empty().trigger("change");
                $('.regionSelect').select2();
                var regions = jq('.regionSelect');
                var option = new Option(
                    t("all", { ns: "translations" }),
                    "0",
                    true,
                    true);
                regions.append(option).trigger('change');


                response.data.data.forEach(data => {
                    var option = new Option(
                        data.Name,
                        data.StationRegionId,
                        false,
                        false);
                    regions.append(option).trigger('change');

                });

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
        $('.regionSelect').on('select2:select', function (e) {
            _this.stationsTable.ajax.reload()
        });

    }

    componentDidUpdate = () => this.componentDidMount();
    deleteStation = () => {
        const _this = this;
        const { t } = this.props;

        var data = JSON.stringify({
            "StationId": parseInt(jq('#stationID').val())
        });

        var config = {
            method: 'delete',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                jq("#delete-modal .close").trigger("click");
                swal.fire({
                    title: t("swal.success.head", { ns: "translations" }),
                    text: t("swal.success.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.success.icon", { ns: "translations" })
                })
                _this.stationsTable.ajax.reload();
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    render() {
        const { t } = this.props;

        return (
            <div className="station-page p-2">
                <div className="card">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("filters")}</h6>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-md-12 d-flex">
                                <div className="col-md-4">
                                    {t("region")}
                                </div>
                                <div className="col-md-8">
                                    <select className="form-control w-100 select-search regionSelect">
                                        <option value="0">  {t("loading", { ns: "translations" })} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("stations")}</h6>
                    </div>

                    <div className="card-body py-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <table id="stations-table" ref={this.usersTableRef} className="table-loader dataTable display  compact  w-100">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="delete-modal" tabIndex="-1" role="dialog" aria-labelledby="delete-modalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="delete-modalLabel">Uyarı</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <strong><input readOnly className="form-control form-control-lg" id="stationID" /></strong> <p> Adlı İstasyon Silinecek Emin Misiniz?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                                <button type="button" onClick={this.deleteStation} className=" btn btn-primary stationDeleteClass ">SİL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation(['systemStations', 'translations'])(Stations);
