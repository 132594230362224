import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { getEndPoint } from '../../class/api';
import Division from '../../components/elements/content-division';
import Text from '../../components/elements/text';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import SelectCordinate from '../../components/region-map';
import Functions from '../../class/functions';
import moment from 'moment';
const cookies = new Cookies();
let cordinate = "";

class StationAdd extends Component {

    constructor(props) {
        super(props);

        this.parksTableRef = React.createRef();
        this.parkFaultsHistoryTableRef = React.createRef();
        this.parkFaultsTableRef = React.createRef();
        // this.LastConnectRef = React.createRef();
        // this.LastSuccessfulBootRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            parkData: [],
            status: true,
            stationId: 0,
        };
    }

    cordinateDataFunc(data) {
        cordinate = String(data[0].lat + ',' + data[0].lng);
    }

    componentDidMount() {
        const { t } = this.props;
        const _this = this;

        if (this.state.status != false) {
            DateRangePickerHandler($('.LastConnect'), {
                parentEl: '.content-inner',
                showDropdowns: true,
                singleDatePicker: true,
                locale: t("dateRangePickerLocalization", { ns: "translations" }),
                "autoApply": true,
                ranges: {
                    'Bugün': [moment(), moment()],
                },
            });
            DateRangePickerHandler($('.LastSync'), {
                parentEl: '.content-inner',
                showDropdowns: true,
                singleDatePicker: true,
                locale: t("dateRangePickerLocalization", { ns: "translations" }),
                "autoApply": true,
                ranges: {
                    'Bugün': [moment(), moment()],
                },
            });
            const selectLanguage = {
                noResults: () => t("select2Localization.noResults", { ns: "translations" }),
                searching: () => t("select2Localization.searching", { ns: "translations" }),
                maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
                loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
                inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
                inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
                errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
            };

            $('.RegionId').select2({
                language: selectLanguage
            });


            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Station/Region',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                }
            };
            axios(config)
                .then(function (response) {

                    $('.RegionId').empty().trigger("change");
                    var region = $('.RegionId');

                    var option = new Option(
                        t("selectDefaultValue", { ns: "translations" }),
                        "0",
                        (true),
                        (true)
                    );
                    region.append(option).trigger('change');

                    response.data.data.forEach((elm) => {
                        var option = new Option(
                            elm.Name,
                            elm.StationRegionId,
                            (false),
                            (false)
                        );
                        region.append(option).trigger('change');
                    });
                })
                .catch(function (error) {
                    Functions.requestAxiosErrorFunction(error);
                });

        }
    }
    componentDidUpdate = () => this.componentDidMount();


    handleSubmit(e) {
        e.preventDefault();
        const _this = this;
        const { t } = this.props;
        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);
        if (cordinate == "") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("Coordinate") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('.RegionId').val() == "0") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("RegionId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {

            if (result.isConfirmed) {

                var data = JSON.stringify(
                    {
                        "Number": formDataEdit.Number,
                        "IpAddress": formDataEdit.IpAddress,
                        "IpActive": formDataEdit.IpActive,
                        "Name": formDataEdit.Name,
                        "LastConnect": Functions.getFormatDBDate(formDataEdit.LastConnect),
                        "LastSync": Functions.getFormatDBDate(formDataEdit.LastConnect),
                        "BikeCountMax": formDataEdit.BikeCountMax,
                        "BikeCountMin": formDataEdit.BikeCountMin,
                        "PlugSize": "0.00",
                        "NewPlugSize": "0.00",
                        "SimPhoneNumber": "05",
                        "SimSerialNumber": "05",
                        "SimPin": "5050",
                        "SimPuk": "5050",
                        "Coordinate": cordinate,
                        "ProgramVersion": "1.3",
                        "ShowOnMap": formDataEdit.ShowOnMap,
                        "RegionId": $('.RegionId').val(),
                        "Status": formDataEdit.Status,
                        "Address": formDataEdit.Address,
                        "InformationMessage": formDataEdit.InformationMessage,
                        "StationParkUnits": [],
                        "StationDevices": []
                    }
                );
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Station',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        new Promise(function (resolve, reject) {
                            var data = Functions.stationData()
                            resolve(data)
                        }).then((result) => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: t("swal.success.successMessage", { ns: "translations" }),
                                showConfirmButton: false,
                                timer: 1500
                            }).then(function () {
                                window.location.href = `/stations/list/${response.data.data[0].StationId}`
                            })
                        })

                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }

        })
    }
    render() {
        const { t } = this.props;
        return (
            <div className="p-2 ">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <form onSubmit={this.handleSubmit}>

                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Number")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="number" required name="Number" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-profile"></i>
                                            </Division>
                                        </Division>
                                    </Division>

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Name")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="Name" required type="text" className="form-control form-control-lg" />

                                        </Division>
                                    </Division>
                                </Division>


                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("IpAddress")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="IpAddress" required type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-more"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("IpActive")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" required name="IpActive" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-list-ordered"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>





                                {/* <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("SimPhoneNumber")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="SimPhoneNumber"  type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="fa-solid fa-square-parking"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("SimSerialNumber")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text" name="SimSerialNumber" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-new"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division> */}




                                {/* <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("SimPin")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input name="SimPin" type="text" className="select-search form-control form-control-lg" />

                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("SimPuk")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input type="text"  name="SimPuk" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-bike"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division> */}


                                <Division className="form-group row">

                                    <Text Tag="label" className="col-form-label col-lg-2">{t("BikeCountMin")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue="0" type="number" name="BikeCountMin" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-new"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("BikeCountMax")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue="500" name="BikeCountMax" type="number" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-seven-segment-9"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>







                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("LastConnect")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback ">
                                            <input name="LastConnect" className="LastConnect form-control w-100" />

                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ShowOnMap")}</Text>
                                    <Division className="col-lg-4">
                                        <select type="text" name="ShowOnMap" className="statusSelect form-control form-control-lg" >
                                            <option value={1} >{t("active", { ns: "translations" })}</option>
                                            <option value={0} >{t("passive", { ns: "translations" })}</option>
                                        </select>
                                    </Division>

                                    {/* <Text Tag="label" className="col-form-label col-lg-2">{t("LastSync")}</Text>
                                    <Division className="col-lg-4">
                                        <input name="LastSync" className="LastSync form-control w-100" />
                                    </Division> */}


                                </Division>



                                {/* <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("PlugSize")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue="1.00" name="PlugSize"  type="text" className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="icon-circle-code"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("NewPlugSize")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <input defaultValue="1.00" type="number" name="NewPlugSize"  className="form-control form-control-lg" />
                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                <i className="fa-solid fa-exclamation"></i>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division> */}





                                {/* <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("ProgramVersion")}</Text>
                                    <Division className="col-lg-4">
                                        <input name="ProgramVersion" className=" form-control w-100" />
                                    </Division>


                                  
                                </Division> */}

                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("RegionId")}</Text>
                                    <Division className="col-lg-4">
                                        <select name="RegionId" required className=" RegionId form-control w-100">
                                            <option>  {t("loading", { ns: "translations" })} </option>
                                        </select>
                                    </Division>


                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Status")}</Text>
                                    <Division className="col-lg-4">
                                        <select type="text" name="Status" className="statusSelect form-control form-control-lg" >
                                            <option value={1} >{t("active", { ns: "translations" })}</option>
                                            <option value={0} >{t("passive", { ns: "translations" })}</option>
                                        </select>
                                    </Division>
                                </Division>

                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Address")}</Text>
                                    <Division className="col-lg-4">
                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                            <textarea name="Address" required className=" form-control w-100" />

                                        </Division>
                                    </Division>


                                    <Text Tag="label" className="col-form-label col-lg-2">{t("InformationMessage")}</Text>
                                    <Division className="col-lg-4">
                                        <textarea name="InformationMessage" required className=" form-control w-100" />
                                    </Division>
                                </Division>

                                <Division className="form-group row">
                                    <Text Tag="label" className="col-form-label col-lg-2">{t("Coordinate")}</Text>
                                    <Division className="col-lg-12">
                                        <SelectCordinate cordinate={this.cordinateDataFunc} />
                                    </Division>
                                </Division>


                                <Division className="form-group row">

                                    <Division className="col-lg-12">
                                        <button onClick={this.handleClick} type="submit" className="btn btn-secondary w-100 h-100">{t("Save", { ns: "translations" })}</button>
                                    </Division>
                                </Division>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default withTranslation(['stationsAdd', 'translations'])(StationAdd);

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}
function getFormattedDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [month, day, year].join('-');
}
function getFormatDBDate(date) {
    var d = new Date(date),
        year = d.getFullYear(),

        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}