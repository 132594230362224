import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Functions from "../../class/functions";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getEndPoint } from "../../class/api";
import TableData from "../../components/table.js";
import jq from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons/js/buttons.print";
import "bootstrap/dist/js/bootstrap";
import jsZip from "jszip";
import "core-js/actual/array/group-by";
import "datatables.net-buttons/js/buttons.colVis.min";
import "datatables.net-buttons/js/dataTables.buttons.min";
import "datatables.net-buttons/js/buttons.flash.min";
import "datatables.net-buttons/js/buttons.html5.min";

import "./style.css";
import "../../css/stationTest.css";
window.JSZip = jsZip;

import { ReactSession } from "react-client-session";
import Cookies from "universal-cookie";
import moment from "moment";
const cookies = new Cookies();
let parkdata = "";
class ParkList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.parksTableRef = React.createRef();
    this.parkFaultsHistoryTableRef = React.createRef();
    this.state = {
      userList: [],
      status: true,
      table: [],
      taraStatus: false,
      activeIP: "",
      StationData: "",
      stationNumber: "",
      stationConfigDownloadUrl: "#",
    };
    this.movement_filter = [
      {
        StationId: this.props.router.params.stationId,
      },
    ];
    this.movement_columns = [
      { title: t("Kayıt no"), data: "MemberTripId" },
      {
        title: t("Durum"),
        data: "ProcessStatusId",
        render: function (data, type, row) {
          if (data === 1) {
            return "Üye Üzerinde Kullanımda";
          } else if (data == 2 || data == 3) {
            return "Rezerve Kiralama Talebi";
          } else if (data == 7) {
            return "Sürüş Tamamlandı";
          } else {
            return " -- ";
          }
        },
      },
      {
        title: t("Üye"),

        data: "MemberTripId",
        render: function (data, type, row) {
          var user;
          var userID;
          if (row.member.user != null) {
            user = row.member.user.Name + " " + row.member.user.Surname;
            userID = "/users/edit/" + row.member.user.id;
            userID = Functions.RoleControl(11, 4) == false ? "#" : userID;

            return (
              '<a class="getUserBtn" href="' +
              userID +
              '" type="button" data-id="' +
              row.member.user.id +
              '">' +
              user +
              "</a>"
            );
          } else {
            user = "Kullanıcı Bulunamadı";
            return '<a class="btn getUserBtn">' + user + "</a>";
          }
        },
        orderable: false,
      },
      {
        title: t("Bisiklet No"),

        className: "vehicleNo",
        data: "vehicle.VehicleNo",
        render: function (data, type, row) {
          if (data == null) {
            return " ";
          }
          return `<a class="vehicleNo" href="#" >${data}</a>`;
        },
      },

      {
        title: t("Çıkış Tarihi"),

        data: "EntryDate",
        render: function (data) {
          return Functions.getFormatLocaleDateHours(data);
        },
      },
      {
        title: t("Çıkış İstasyonu"),

        data: "station_park_unit_entry",
        render: function (data, type, row) {
          if (data == null) {
            return " ";
          }
          return `<a href="${
            Functions.RoleControl(17, 0) == true
              ? `/stations/list/${data.StationId}`
              : `#`
          } ">${data.station.Name} -> ${data.ParkOrder}</a> `;
        },
      },
      {
        title: t("Giriş Zamanı"),

        data: "ExitDate",
        render: function (data) {
          return Functions.getFormatLocaleDateHours(data);
        },
      },

      {
        title: t("Giriş İstasyonu"),

        data: "station_park_unit_exit",
        render: function (data, type, row) {
          if (data.ParkId == null) {
            return "Kullanımda";
          }
          return `<a href="${
            Functions.RoleControl(17, 0) == true
              ? `/stations/list/${data.StationId}`
              : `#`
          } ">${data.station.Name} -> ${data.ParkOrder}</a> `;
        },
      },

      {
        title: t("Süre (DK)"),
        data: "TotalDuration",
        render: function (data, type, row) {
          if (data == null) {
            return " ";
          }
          return data;
        },
      },
      {
        title: t("Ücretsiz Süre (DK)"),
        data: "FreeDuration",
        render: function (data, type, row) {
          if (data == null) {
            return " ";
          }
          return data;
        },
      },
      {
        title: t("Fiyat"),

        data: "MemberTripId",
        render: function (data, type, row) {
          var price = parseFloat(row.Fee).toFixed(2);

          return price + " " + row.Currency;
        },
      },
    ];
    // var firstDate = moment($('#firstDate').val(), 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss')
    // var lastDate = moment($('#lastDate').val(), 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
    var firstDate = moment()
      .subtract(7, "days")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    var lastDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    console.log("____");
    console.log(
      moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    this.systemLogsStatistics_filter = [
      {
        StationId: this.props.router.params.stationId,
        ModuleCodeNot: ["PD_H", "PD_I", "BS_9", "BY_1"],
        // dateRange : ["2020-01-01 00:00:00", "2022-11-28 00:00:00"],
        dateRange: [firstDate, lastDate],
      },
    ];
    this.systemLogsStatistics_columns = [
      {
        title: t("table.parkFaultReports.name"),
        data: "ParkUnitId",
        render: function (data, type, row) {
          return row.park_unit.ParkId;
        },
      },
      { title: t("table.parkFaultReports.faultType"), data: "ModuleCode" },
      { title: "Durum", data: "ModuleName" },
      { title: t("table.parkFaults.description"), data: "Description" },
      { title: t("Toplam"), data: "count" },
    ];

    this.parkListFilter = [{}];
  }
  tableInstallV2 = () => {
    const { t } = this.props;
    const _this = this;
    var translate = {
      ...t("tableLocalization", { ns: "translations" }),
      paginate: {
        next:
          jq("html").attr("dir") == "rtl"
            ? t("tableLocalization.paginate.next", { ns: "translations" }) +
              "&larr;"
            : t("tableLocalization.paginate.next", { ns: "translations" }) +
              " &rarr;",
        previous:
          jq("html").attr("dir") == "rtl"
            ? "&rarr; " +
              t("tableLocalization.paginate.previous", { ns: "translations" })
            : "&larr; " +
              t("tableLocalization.paginate.previous", { ns: "translations" }),
      },
    };

    if (this.parkList != undefined) {
      this.parkList.destroy();
    }
    // $(`#parks-table`).addClass("table-loader dataTable display  compact  w-100");

    if (this.parkFaultsTableRef != null) {
      this.parkFaultsTableRef.destroy();
      jq("#park-faults-table").empty();
    }
  };
  tableInstall = () => {
    const { t } = this.props;
    const _this = this;
    var translate = {
      ...t("tableLocalization", { ns: "translations" }),
      paginate: {
        next:
          jq("html").attr("dir") == "rtl"
            ? t("tableLocalization.paginate.next", { ns: "translations" }) +
              "&larr;"
            : t("tableLocalization.paginate.next", { ns: "translations" }) +
              " &rarr;",
        previous:
          jq("html").attr("dir") == "rtl"
            ? "&rarr; " +
              t("tableLocalization.paginate.previous", { ns: "translations" })
            : "&larr; " +
              t("tableLocalization.paginate.previous", { ns: "translations" }),
      },
    };

    if (this.parkList != undefined) {
      this.parkList.destroy();
    }
    // $(`#parks-table`).html(" ");
    // $(`#parks-table`).addClass("    display  w-100");

    this.parkList = jq("#parks-table").DataTable({
      createdRow: function (row, data, dataIndex) {
        var color = "";
        switch (data.Status) {
          case 1:
            color = "indigo";
            break;

          case 2:
            color = "danger";
            break;

          case 3:
            color = "secondary";
            break;

          case 4:
            color = "dark";
            break;

          case 5:
            color = "yellow";
            break;

          case 6:
            color = "warning";
            break;

          default:
            color = "danger";
            break;
        }

        $(row).addClass(`table-${color} `);
      },
      dom: "Blfrtip",
      buttons: ["copy", "csv", "excel", "pdf", "print"],
      order: [0, "asc"],
      language: translate,
      serverSide: true,
      processing: true,
      ajax: {
        url: getEndPoint() + "/api/Admin/Station/ParkUnit",
        type: "GET",
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        headers: {
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        dataFilter: function (data) {
          var json = jQuery.parseJSON(data);
          json.data = json.data;
          json.recordsFiltered = Object.keys(json.data).length;
          json.recordsTotal = Object.keys(json.data).length;
          $(`#parks-table`).removeClass("table-loader").show();

          return JSON.stringify(json);
        },
      },
      fnServerParams: (aoData) => {
        aoData["Filter"] = {
          StationId: parseInt(this.props.router.params.stationId),
          order: aoData.columns[aoData.order[0].column].data,
          sort: aoData.order[0].dir,
        };
        aoData["page"] = aoData.start / aoData.length + 1;
        aoData["per_page"] = aoData.length;
      },

      columns: [
        {
          data: "ParkOrder",
          title: t("table.parks.ParkOrder"),
        },
        {
          data: "StationParkUnitId",
          title: t("table.parks.id"),
        },
        {
          title: t("table.parks.vehicleNo"),
          data: "Vehicle",
          render: function (data, type, row) {
            if (data == null) {
              return " ";
            }
            return `<a id="vehicles" href="#"> ${data.VehicleNo} </a>`;
          },
        },
        {
          title: t("table.parks.activeBikeRfId"),
          data: "ActiveBikeRfId",
        },
        {
          title: t("table.parks.ParkIpAddress"),
          data: "ParkIpAddress",
        },
        {
          title: t("table.parks.ParkId"),
          data: "ParkId",
        },
        {
          title: t("Mac Adresi"),
          data: "ParkMacAddress",
        },
        {
          title: t("table.parks.LastConnect"),
          data: "LastConnect",
          render: function (data) {
            return Functions.getFormatLocaleDateHours(data);
          },
        },
        {
          title: t("table.parks.StatusTxt"),
          data: "Status",
          render: function (data, type, row) {
            var color = "";
            switch (data) {
              case 1:
                color = "indigo";
                break;

              case 2:
                color = "danger";
                break;

              case 3:
                color = "secondary";
                break;

              case 4:
                color = "dark";
                break;

              case 5:
                color = "yellow";
                break;

              case 6:
                color = "warning";
                break;

              default:
                color = "danger";
                break;
            }
            return `<p id="systemlogs" class=" bg-${color} text-center" style="color:white;"> ${row.StatusTxt}</p>`;
          },
        },
        Functions.RoleControl(17) == true
          ? {
              title: t("table.parks.operations"),
              data: function (data, type, row) {
                // console.log ( data );

                return `<div class="list-icons">
                            <div class="dropdown">
                                <a href="#" class="list-icons-item" data-toggle="dropdown">
                                    <i class="icon-menu9"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                ${
                                  Functions.RoleControl(17, 2) == true
                                    ? `<a href="/stations/park-edit/${
                                        data.StationParkUnitId
                                      }" class="dropdown-item"><i class="icon-pencil"></i> ${t(
                                        "table.edit"
                                      )}</a>`
                                    : ""
                                }
                                  
                                  ${
                                    Functions.RoleControl(17, 4) == true
                                      ? ` <a id="removeParks" href="#" class="dropdown-item"> <i class="icon-bin"></i> ${t(
                                          "table.remove"
                                        )}</a> `
                                      : ""
                                  } 

                                  ${
                                    Functions.RoleControl(17, 2) == true &&
                                    data.StationParkUnitTypeId == 4
                                      ? ` <a id="restartFerofenPark" href="#" class="dropdown-item"> <i class="icon-loop"></i> ${t(
                                          "Parkı Resetle"
                                        )}</a> `
                                      : ""
                                  } 
                                  ${
                                    Functions.RoleControl(17, 2) == true &&
                                    data.StationParkUnitTypeId == 4
                                      ? ` <a id="statusReportFerofenPark" href="#" class="dropdown-item"> <i class="icon-soundcloud"></i> ${t(
                                          "Tara"
                                        )}</a> `
                                      : ""
                                  } ${
                                    Functions.RoleControl(17, 2) == true 
                                      ? ` <a id="open" href="#" class="dropdown-item"> <i class="icon-lock"></i> ${t(
                                          "Aç"
                                        )}</a> `
                                      : ""
                                  }
                                </div >
                            </div >
                        </div > `;
              },
            }
          : null,

        Functions.RoleControl(17, 3) == true
          ? {
              title: "Yaz",
              data: function (data, type, row) {
                return `
                                <a type="button" id="write" class="btn btn-info ">Yaz</a>
                            `;
              },
            }
          : null,
       
      ],

      lengthMenu: [
        [5, 10, 20, 50, 100, -1],
        [5, 10, 20, 50, 100, t("all", { ns: "translations" })],
      ],
      pageLength: 50,
    });
    new jq.fn.dataTable.Responsive(this.parkList, {
      details: true,
      Responsive: true,
    });

    if (this.parkFaultTable != undefined) {
      this.parkFaultTable.destroy();
      jq(`#park-faults-history-table`).empty();
    }
    this.parkFaultTable = jq("#park-faults-history-table").DataTable({
      dom: "Blfrtip",
      buttons: ["copy", "csv", "excel", "pdf", "print"],
      order: [2, "desc"],
      language: translate,
      serverSide: true,
      processing: true,
      ajax: {
        url: getEndPoint() + "/api/Admin/Reports/SystemLogs",
        type: "GET",
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        headers: {
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        dataFilter: function (data) {
          var json = jQuery.parseJSON(data);
          json.data = json.data;
          json.recordsFiltered = json.pagingData.totalCount;
          json.recordsTotal = json.pagingData.totalCount;
          $(`#park-faults-history-table`).removeClass("table-loader").show();

          return JSON.stringify(json);
        },
      },
      fnServerParams: (aoData) => {
        aoData["Filter"] = {
          q: aoData.search.value,
          order: aoData.columns[aoData.order[0].column].data,
          sort: aoData.order[0].dir,
          ModuleCodeNot: ["PD_H", "PD_I", "BS_9", "BY_1"],
        };
        aoData["Filter"]["StationId"] = _this.props.router.params.stationId;
        aoData["page"] = aoData.start / aoData.length + 1;
        aoData["per_page"] = aoData.length;
      },

      columns: [
        { title: t("table.parkFaults.ID"), data: "LogSystemId" },
        {
          title: t("table.parkFaults.dateOfDevice"),
          data: "DeviceDate",
          render: function (data) {
            return Functions.getFormatLocaleDateHours(data);
          },
        },
        {
          title: t("table.parkFaults.dateOfSystem"),
          data: "created_at",
          render: function (data) {
            return Functions.getFormatLocaleDateHours(data);
          },
        },
        { title: t("table.parkFaults.description"), data: "Description" },
        {
          title: t("table.parkFaults.park"),
          data: "park_unit",
          render: function (data, type, row) {
            if (data == null) {
              return " ";
            } else if (data == 0) {
              return " ";
            } else if (data == undefined) {
              return " ";
            }
            return `${row?.station?.Name} > ${data?.ParkOrder} (${data?.ParkIpAddress})`;
          },
        },
        {
          title: t("table.parkFaults.bicycle"),
          data: "vehicle",
          render: function (data, type, row) {
            if (data == null) {
              return " ";
            } else if (data == 0) {
              return " ";
            } else if (data == undefined) {
              return " ";
            }
            return `(${row?.vehicle?.VehicleId})(${row?.vehicle?.RFID})(${row?.vehicle?.VehicleNo}) `;
          },
        },
        {
          title: t("table.parkFaults.user"),
          data: "user",
          render: function (data, type, row) {
            if (data == null) {
              return " ";
            } else if (data == 0) {
              return " ";
            } else if (data == undefined) {
              return " ";
            }
            return `${row?.user?.Name} ${row?.user?.Surname} `;
          },
        },
        { title: t("table.parkFaults.usageID"), data: "ModuleId" },
      ],

      lengthMenu: [
        [5, 10, 20, 50, 100, -1],
        [5, 10, 20, 50, 100, t("all", { ns: "translations" })],
      ],
      pageLength: 50,
    });
    new jq.fn.dataTable.Responsive(this.parkFaultTable, {
      details: true,
      Responsive: true,
    });

    if (this.parkFaultsTableRef != null) {
      this.parkFaultsTableRef.destroy();
      jq("#park-faults-table").empty();
    }
    /*
        this.parkFaultsTableRefOldınlinegroup = jq('#park-faults-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],

            language: translate,
            serverSide: true,
            processing: true,
            ajax: {
                // url: getEndPoint() + '/api/Admin/Station/Logs',
                url: getEndPoint() + '/api/Admin/Reports/SystemLogsStatistics',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },

                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;


                    Array.prototype.groupBy = function (field) {
                        let groupedArr = [];
                        this.forEach(function (e) {
                            //look for an existent group
                            let group = groupedArr.find(g => g['field'] === e[field]);
                            if (group == undefined) {
                                //add new group if it doesn't exist
                                group = { field: e[field], groupList: [] };
                                groupedArr.push(group);
                            }

                            //add the element to the group
                            group.groupList.push(e);
                        });

                        return groupedArr;
                    }
                    var groupData = json.data.groupBy('ParkId')
                    groupData.forEach(elm => {
                        var countGroup = elm.groupList.groupBy('Status')[0].groupList.length
                        elm.faultCount = countGroup
                        elm.faultName = elm.groupList.groupBy('Status')[0].groupList[0].station_park_unit_status.Name
                    })
                    json.data = groupData
                    json.recordsFiltered = json.data.length
                    json.recordsTotal = json.data.length
                    $(`#park-faults-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: "ModuleName",
                    StationId: parseInt(_this.props.router.params.stationId),
                    ModuleCodeNot: ["PD_H","PD_I","BS_9","BY_1"],
                    _dateRangeDuzenlenecek : ["2022-01-01 00:00:00", "2022-11-28 00:00:00"],
                    // Status: [1, 3, 4, 5, 6],
                    // Type: 2,
                };

            },


            "columns": [
                {
                    data: "field", render: function (data, type, row) {
                        return data
                    }
                },
                {
                    data: "faultName", render: function (data) {
                        if (data == null) {
                            return " ";
                        }
                        return data
                    }
                },
                { data: "faultCount" },
            ],
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
        });
        */
    /*
        this.parkFaultsTableRef = jq('#park-faults-table').DataTable({
            dom: 'Blfrtip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],

            language: translate,
            serverSide: true,
            processing: true,
            ajax: {
                // url: getEndPoint() + '/api/Admin/Station/Logs',
                url: getEndPoint() + '/api/Admin/Reports/SystemLogsStatistics',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },

                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#park-faults-history-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: "ModuleName",
                    StationId: parseInt(_this.props.router.params.stationId),
                    ModuleCodeNot: ["PD_H","PD_I","BS_9","BY_1"],
                    _dateRangeDuzenlenecek : ["2022-01-01 00:00:00", "2022-11-28 00:00:00"],
                };
                // aoData['Filter']['StationId'] = _this.props.router.params.stationId;
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },


            "columns": [
                { title: t("table.parkFaultReports.faultType"), data: "ModuleCode" }, 
                { title: t("table.parkFaultReports.name"), data: "ModuleName" }, 
                { title: t("table.parkFaults.description"), data: "Description" }, 
                { title: "sayı", data: "count" }  
            ],

            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
        });
        new jq.fn.dataTable.Responsive(this.parkFaultsTableRef, { details: true, Responsive: true },);
*/
  };

  componentDidMount() {
    const { t } = this.props;
    const _this = this;
    const selectLanguage = {
      noResults: () =>
        t("select2Localization.noResults", { ns: "translations" }),
      searching: () =>
        t("select2Localization.searching", { ns: "translations" }),
      maximumSelected: () =>
        t("select2Localization.maximumSelected", { ns: "translations" }),
      loadingMore: () =>
        t("select2Localization.loadingMore", { ns: "translations" }),
      inputTooShort: () =>
        t("select2Localization.inputTooShort", { ns: "translations" }),
      inputTooLong: () =>
        t("select2Localization.inputTooLong", { ns: "translations" }),
      errorLoading: () =>
        t("select2Localization.errorLoading", { ns: "translations" }),
    };
    $("#ParkList").select2({
      language: selectLanguage,
    });

    this.tableInstall();

    this.refreshStationData();

    var responseData = ReactSession.get("stationList");

    jq("#ParkList").empty();
    responseData.forEach((elm) => {
      var newOption = new Option(
        `${elm.Name} ( ${elm.Number} )`,
        elm.StationId,
        parseInt(_this.props.router.params.stationId) == elm.StationId
          ? true
          : false,
        parseInt(_this.props.router.params.stationId) == elm.StationId
          ? true
          : false
      );
      jq("#ParkList").append(newOption).trigger("change");
    });
    $("#ParkList").on("select2:select", function (e) {
      var data = e.params.data;
      window.location.href = "/stations/list/" + data.id;
    });
    jq("#parks-table tbody").on("click", "td #write", function () {
      $("#writeModalParkNumber").text("##");
      if (Functions.RoleControl(17, 2) == false) return;
      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }

      if (data.ParkMacAddress === null) {
        swal.fire({
          title: t("swal.error.head", { ns: "translations" }),
          text: "Parkın Mac Adresi Bulunmamaktadır, Lütfen İlk Önce Tarama İşlemi Gerçekleştiriniz.",
          confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
          icon: t("swal.error.icon", { ns: "translations" }),
        });
        return;
      }
      parkdata = data;
      $("#writeModalParkNumber").text(parkdata?.ParkId);
      let myModal = new bootstrap.Modal(
        document.getElementById("writeModal"),
        {}
      );
      myModal.show();
    });

    $("#parks-table tbody").on("click", "td #vehicles", function () {
      if (Functions.RoleControl(18, 2) == false) return;
      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }

      ReactSession.set("bikeSearchData", data.Vehicle?.VehicleNo);
      window.location.href = "/bicycles/bicycle-list";
    });

    jq("#parks-table tbody").on("click", "td #systemlogs", function () {
      if (Functions.RoleControl(37, 2) == false) return;
      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }
      ReactSession.set("systemlogs", data.StationParkUnitId);
      window.location.href = "/management/system-logs";
    });
    jq("#parks-table tbody").on("click", "td #removeParks", function () {
      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }
      swal
        .fire({
          title: t("swal.info.head", { ns: "translations" }),
          text: t("swal.info.subject", {
            ns: "translations",
            questionData: t("swal.operationType.delete", {
              ns: "translations",
            }),
          }),
          showDenyButton: true,
          confirmButtonText: t("swal.questionSaveButton", {
            ns: "translations",
          }),
          denyButtonText: t("swal.questionCancelButton", {
            ns: "translations",
          }),
        })
        .then((result) => {
          if (result.isConfirmed) {
            var config = {
              method: "delete",
              url: getEndPoint() + "/api/Admin/Station/ParkUnit",
              headers: {
                "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                StationParkUnitId: data.StationParkUnitId,
              }),
            };

            axios(config).then(function (response) {
              jq.fn.dataTable.tables({ api: true }).ajax.reload();

              swal.fire({
                title: t("swal.success.head", { ns: "translations" }),
                text: t("swal.success.subject", { ns: "translations" }),
                confirmButtonText: t("swal.confirmButton", {
                  ns: "translations",
                }),
                icon: t("swal.success.icon", { ns: "translations" }),
              });
            });
          }
        });
    });
    jq("#parks-table tbody").on("click", "td #restartFerofenPark", function () {
      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }
      swal
        .fire({
          title: t("swal.info.head", { ns: "translations" }),
          text: t("swal.info.subject", {
            ns: "translations",
            questionData: data?.ParkId + " nolu Parkı Resetlemek ",
          }),
          showDenyButton: true,
          confirmButtonText: t("swal.questionSaveButton", {
            ns: "translations",
          }),
          denyButtonText: t("swal.questionCancelButton", {
            ns: "translations",
          }),
        })
        .then((result) => {
          if (result.isConfirmed) {
            var config = {
              method: "POST",
              // url: getEndPoint() + '/api/Admin/Station/Control',
              url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",

              headers: {
                "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                ParkNumber: data.ParkId,
                // "StationParkUnitId": data.StationParkUnitId ,
                ProccessType: 3,
                data: {
                  mac: data.ParkMacAddress,
                },
              }),
            };

            axios(config).then(function (response) {
              jq.fn.dataTable.tables({ api: true }).ajax.reload();

              swal.fire({
                title: t("swal.success.head", { ns: "translations" }),
                text: t("swal.success.subject", { ns: "translations" }),
                confirmButtonText: t("swal.confirmButton", {
                  ns: "translations",
                }),
                icon: t("swal.success.icon", { ns: "translations" }),
              });
            });
          }
        });
    });

    jq("#parks-table tbody").on(
      "click",
      "td #statusReportFerofenPark",
      function () {
        var tr = jq(this).closest("tr");
        var row = _this.parkList.row(tr);
        var data = row.data();
        if (data == undefined) {
          row = _this.parkList.row(this);
          data = row.data();
        }

        var config = {
          method: "POST",
          // url: getEndPoint() + '/api/Admin/Station/Control',
          url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",

          headers: {
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            ParkNumber: data?.ParkId,
            // "StationParkUnitId": data.StationParkUnitId ,
            ProccessType: 5,
            data: {
              mac: data.ParkMacAddress,
            },
          }),
        };

        axios(config).then(function (response) {
          jq.fn.dataTable.tables({ api: true }).ajax.reload();

          swal.fire({
            title: t("swal.success.head", { ns: "translations" }),
            text: t("swal.success.subject", { ns: "translations" }),
            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
            icon: t("swal.success.icon", { ns: "translations" }),
          });
        });
      }
    );
    jq("#parks-table tbody").on("click", "td #open", function () {
        $("openModalParkNumber").text("##");

      var tr = jq(this).closest("tr");
      var row = _this.parkList.row(tr);
      var data = row.data();
      if (data == undefined) {
        row = _this.parkList.row(this);
        data = row.data();
      }
      parkdata = data?.ParkId;
      $("#openModalParkNumber").text(parkdata);
      let myModal = new bootstrap.Modal(
        document.getElementById("openModal"),
        
      );
      myModal.show();
    {//   var config = {
    //     method: "POST",
    //     // url: getEndPoint() + '/api/Admin/Station/Control',
    //     url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",

    //     headers: {
    //       "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     data: JSON.stringify({
    //       ParkNumber: data?.ParkId,
    //       // "StationParkUnitId": data.StationParkUnitId ,
    //       ProccessType: 5,
    //       data: {
    //         mac: data.ParkMacAddress,
    //       },
    //     }),
    //   };

    //   axios(config).then(function (response) {
    //     jq.fn.dataTable.tables({ api: true }).ajax.reload();

    //     swal.fire({
    //       title: t("swal.success.head", { ns: "translations" }),
    //       text: t("swal.success.subject", { ns: "translations" }),
    //       confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
    //       icon: t("swal.success.icon", { ns: "translations" }),
    //     });
    //   });
  }
    });

    // iOS kontrolü
    function isIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    // Kontrol işlevi
    function checkAndHideModalBackdrop() {
        if (isIOS()) {
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.style.display = 'none';
            }
        }
    }

    // 2 saniyede bir kontrol edelim (2000 milisaniye)
    this.interval = setInterval(checkAndHideModalBackdrop, 2000);
    
  }
    // Bileşen DOM'dan kaldırıldığında interval'i durdurmalıyız
    componentWillUnmount() {
        clearInterval(this.interval);
    }

  openClicK=()=>{
    const {t}=this.props;
    var _this=this;

    var data={
      ParkNumber:Number(parkdata),
      ProccessType:1,
    }
    var config={
      method:"post",
      url:getEndPoint()+'/api/Admin/Station/ParkUnit/Control',
      headers:{
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data:data
    };
    axios(config).then((res)=>{
      swal.fire({
        title: t("swal.success.head", { ns: "translations" }),
        text: "Kilit açma işlemi başarıyla gerçekleşti",
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: t("swal.success.icon", { ns: "translations" }),
      });
    })
    console.log(data);
  }

  refreshStationData = () => {
    $(".info_table").addClass("loading");
    var configStation = {
      method: "put",
      url: getEndPoint() + "/api/Admin/Station",
      headers: {
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: JSON.stringify({
        Filter: {
          StationId: this.props.router.params.stationId,
          order: "Order",
          sort: "asc",
        },
      }),
    };

    axios(configStation).then((response) => {
      $(".info_table").removeClass("loading");

      this.setState({
        StationData: response.data.data[0],
        status: false,
        stationConfigDownloadUrl:
          getEndPoint() +
          "/cdn/download/StationConfig?s=" +
          response?.data?.data[0]?.Number,
      });
    });
  };
  resetStation = () => {
    const { t } = this.props;
    var _this = this;
    if (!this.state.StationData.Status) {
      swal.fire({
        title: t("swal.error.head", { ns: "translations" }),
        text: "Bu İstasyona Reset İşlemi Gerçekleştirilemez!",
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: "error",
      });
      return;
    }
    var data = JSON.stringify({
      StationNumber: parseInt(this.state.StationData.Number),
      ProccessType: 3,
    });
    var config = {
      method: "POST",
      url: getEndPoint() + "/api/Admin/Station/Control",
      headers: {
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then(function (response) {
      swal.fire({
        title: t("swal.success.head", { ns: "translations" }),
        text: t("swal.success.subject", { ns: "translations" }),
        icon: t("swal.success.icon", { ns: "translations" }),
      });
      _this.parkList.ajax.reload();
    });
  };
  writeClick = () => {
    const { t } = this.props;
    const _this = this;
    if ($("#orderNo").val() == "") {
      swal.fire({
        title: t("swal.error.head", { ns: "translations" }),
        text: t("swal.error.missingParamerter", {
          ns: "translations",
          requiredData: t("Sıra"),
        }),
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: t("swal.error.icon", { ns: "translations" }),
      });
      this.taraClick();
      return;
    }

    var data = JSON.stringify({
      StationNumber: this.state.StationData.Number,
      ProccessType: 4,
      data: {
        order: $("#orderNo").val(),
        mac: parkdata.ParkMacAddress,
      },
    });

    var config = {
      method: "post",
      url: getEndPoint() + "/api/Admin/Station/Control",
      headers: {
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then((response) => {
      swal.fire({
        title: t("swal.success.head", { ns: "translations" }),
        text: t("swal.success.subject", { ns: "translations" }),
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: t("swal.success.icon", { ns: "translations" }),
      });
      this.componentDidMount();
    });
  };
  taraClick = () => {
    const { t } = this.props;
    var _this = this;

    var data = JSON.stringify({
      StationNumber: this.state.StationData.Number,
      ProccessType: 5,
    });

    var config = {
      method: "post",
      url: getEndPoint() + "/api/Admin/Station/Control",
      headers: {
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then((response) => {
      swal.fire({
        title: t("swal.success.head", { ns: "translations" }),
        text: "Tarama İsteği Başarılı Bir Şekilde Gönderildi.",
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: t("swal.success.icon", { ns: "translations" }),
      });
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="p-2 ">
        <div
          className={`card ${
            this.state.StationData?.Status == false ? "bg-danger" : ""
          } `}
        >
          <div className="card-body p-0 ">
            <div className="row">
              <div className="col-md-6 d-md-flex justify-content-center align-items-center align-content-center mb-2">
                <select
                  id="ParkList"
                  className="mt-2 col-12 form-control align-self-center"
                  defaultValue={this.props.router.params.stationId}
                >
                  <option> {t("loading", { ns: "translations" })}</option>
                </select>
                <div className="d-flex mt-2">
                  <div className="col">
                    <button
                      style={{
                        display:
                          Functions.RoleControl(15, 2) == false ? "none" : "",
                      }}
                      onClick={(e) => {
                        window.location.href =
                          "/stations/edit/" +
                          this.props.router.params.stationId;
                      }}
                      className="col btn btn-warning"
                    >
                      {t("edit")}
                    </button>
                  </div>
                  <div className="col ">
                    <button
                      style={{
                        display:
                          Functions.RoleControl(8) == false ? "none" : "",
                      }}
                      onClick={this.resetStation}
                      className="col btn btn-danger-100 border-danger"
                    >
                      {t("reset")}
                    </button>
                  </div>
                  {Functions.RoleControl(17, 3) == true ? (
                    <div className="col ">
                      <button
                        onClick={this.taraClick}
                        className="col btn btn-info "
                      >
                        Tara
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-2 ">
                <table className="info_table d-table table table-responsive w-100">
                  <thead className="table-secondary">
                    <tr>
                      <th className="text-center col-6">{t("activeIP")} </th>
                      <th className="text-center col">{t("stationNo")}</th>
                      <th className="text-center col">
                        <div class="list-icons">
                          <a
                            onClick={() => this.refreshStationData()}
                            className="list-icons-item"
                            data-action="reload"
                          ></a>
                        </div>
                      </th>
                      <th className="text-center col">
                        <div className="list-icons ">
                          <div className="dropdown">
                            <a
                              href="#"
                              className="list-icons-item"
                              data-toggle="dropdown"
                            >
                              <i className="icon-wrench icon-cogs icon-cog"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a
                                href={
                                  getEndPoint() + "/cdn/download/StationSetup"
                                }
                                className="dropdown-item"
                                target="_blank"
                              >
                                {" "}
                                <i className="icon-download"></i> Kurulum
                                Dosyası{" "}
                              </a>
                              <a
                                href={this.state.stationConfigDownloadUrl}
                                className="dropdown-item"
                                target="_blank"
                                download="download"
                              >
                                {" "}
                                <i className="icon-download"></i> Ayar /
                                Konfigürasyon Dosyası{" "}
                              </a>
                              {/* {  Functions.RoleControl(17, 2) == true   ? ' <a  href="#Kurlu" class="dropdown-item"> <i class="icon-loop"></i> Kurulum Dosyası </a> ' : ""} 
                                                            { ( Functions.RoleControl(17, 2) == true  ) ? ` <a  href="#Kurlu" class="dropdown-item"> <i class="icon-loop"></i> Ayar / Konfigürasyon Dosyası </a> ` : ""}  */}
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="py-0"
                        style={{ backgroundColor: "#57dcd5" }}
                      >
                        <p
                          className="text-center m-0"
                          style={{ color: "white", fontSize: "25px" }}
                        >
                          {this.state.StationData?.IpActive == undefined
                            ? "-"
                            : this.state.StationData?.IpActive}
                        </p>
                      </td>
                      <td
                        colSpan="3"
                        className="text-center py-0"
                        style={{ backgroundColor: "#57dcd5" }}
                      >
                        <p
                          className="m-0"
                          style={{ color: "white", fontSize: "25px" }}
                        >
                          {this.state.StationData?.Number == undefined
                            ? "-"
                            : this.state.StationData?.Number}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="4"
                        className="p-0 text-center"
                        style={{ backgroundColor: "#57dcd5" }}
                      >
                        <p
                          className="m-0"
                          style={{ color: "white", fontSize: "15px" }}
                        >
                          {t("table.parks.LastConnect")} :{" "}
                          {moment(this.state.StationData?.LastConnect).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}{" "}
                          Versiyon:{" "}
                          {this.state.StationData?.ProgramVersion ??
                            "Bilinmiyor"}{" "}
                          Tip:{" "}
                          {this.state.StationData?.StationTypeId ??
                            "Bilinmiyor"}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
          <div
            style={{ backgroundColor: "#c9cacb" }}
            className="card-header header-elements-inline"
          >
            <h6 className="card-title">{t("table.parks.title")}</h6>

            <div className="header-elements">
              <div className="list-icons">
                <a
                  onClick={this.tableInstall}
                  className="list-icons-item"
                  data-action="reload"
                ></a>
              </div>
            </div>
          </div>
          <div className="card-body ">
            <table
              id="parks-table"
              className="table-loader dataTable display  compact  w-100"
            ></table>
          </div>
        </div>
        <div id="tab-2">
          <TableData
            cardTitle={t("İstasyona Ait Bisiklet Hareketleri")}
            abilityid={19}
            auth={0}
            customOrder={[4, "desc"]}
            searching={false}
            length="5"
            customClass="col-12"
            ajaxUrl="/api/Admin/Station/Vehicle/Steps"
            tableName="movements"
            filter={this.movement_filter}
            columns={this.movement_columns}
          />
        </div>
        <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
          <div
            style={{ backgroundColor: "#c9cacb" }}
            className="card-header header-elements-inline"
          >
            <h6 className="card-title">{t("table.parkFaults.title")}</h6>

            <div className="header-elements">
              <div className="list-icons">
                <a
                  onClick={this.tableInstall}
                  className="list-icons-item"
                  data-action="reload"
                ></a>
              </div>
            </div>
          </div>
          <div className="card-body ">
            <table
              id="park-faults-history-table"
              ref={this.parkFaultsHistoryTableRef}
              className="table-loader dataTable display  compact  w-100"
            ></table>
          </div>
        </div>

        {/* <div className="card  border-gray border-left-2 border-top-2 border-right-2  border-bottom-2">
                    <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("table.parkFaultReports.title")}</h6>
                    </div>
                    <div className="card-body">

                        <table id="park-faults-table" className="table-loader dataTable display  compact  w-100">
                            <thead>
                                <tr>
                                    <th>---</th>
                                    <th>{t("table.parkFaultReports.name")}</th>
                                    <th>{t("table.parkFaultReports.faultType")}</th>
                                    <th>{t("table.parkFaultReports.count")}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div> */}

        <div id="tab-3">
          <TableData
            cardTitle={t("table.parkFaultReports.title") + " (1 Hafatalık)"}
            abilityid={19}
            auth={0}
            customOrder={[4, "desc"]}
            searching={true}
            length="5"
            customClass="col-12"
            ajaxUrl="/api/Admin/Reports/SystemLogsStatistics"
            tableName="systemLogsStatistics"
            filter={this.systemLogsStatistics_filter}
            columns={this.systemLogsStatistics_columns}
          />
        </div>
        <div
          className="modal fade"
          id="openModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p id="openModalParkNumber"></p> Numaralı parkı açmak
                istediğinize emin misiniz ?
              </div>
              <div class="modal-footer">
              <button
                  type="button"
                  onClick={this.openClicK}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  {t("Onayla")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("Kapat")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="writeModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <b id="writeModalParkNumber"></b> nolu parkı {t("Yaz")}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <label className="col-form-label col-lg-4">{t("Sıra")}</label>
                  <div className="col-lg-8">
                    <div className="d-flex">
                      <input
                        id="orderNo"
                        type="text"
                        className="form-control "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("Kapat")}
                </button>
                <button
                  type="button"
                  onClick={this.writeClick}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  {t("Kaydet")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
};

export default withTranslation(["stationList", "translations"])(
  withRouter(ParkList)
);