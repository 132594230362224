import React from "react";
import Functions from '../class/functions';
import jq, { isArray } from 'jquery'
import Cookies from 'universal-cookie';
import { withTranslation } from "react-i18next";
import { getEndPoint } from "../class/api";
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import enums from "../class/enum.json";
const cookies = new Cookies();


class TableDataV2 extends React.Component {
    constructor(props) {
        super(props);
    }
    tableInstall = () => {
        const { t } = this.props


        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/v2',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "per_page": -1,
                "order": "Order",
            })
        }; 


        $('#exampleMust tbody').html(""); 
        axios(config)
            .then(function (response) { 
  

                response.data.data.forEach((element, index) => {
                var row = element ; 
                var col1 = `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${element.Name}</a> `;


                var col2 = parseInt(row.ParkEmpty) * 100 / parseInt(row.ParkFull + row.ParkEmpty);
                if (col2 <= 25) {
                    col2 = `<div class="bg-custom-red text-center">${row.ParkEmpty}  </div>`;
                }
                else if (col2 >= 75) {
                    col2 = `<div class="bg-custom-red text-center">${row.ParkEmpty}  </div>`;
                }
                else {
                    col2 = `<div style="text-align: center;">${row.ParkEmpty} </div>`;
                }
                var col3 = parseInt(row.ParkEmpty) * 100 / parseInt(row.ParkFull + row.ParkEmpty);
                if (col3 <= 25) {
                    col3 = `<div class="bg-custom-red text-center">${row.ParkFull}</div>`;
                }
                else if (col3 >= 75) {
                    col3 = `<div class="bg-custom-red text-center">${row.ParkFull}</div>`;
                }
                else {
                    col3 = `<div style="text-align: center;">${row.ParkFull} </div>`;
                }
                var result = parseInt(row.ParkEmpty) * 100 / parseInt(row.ParkFull + row.ParkEmpty);
                if (result <= 25) {
                    result = `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                }
                else if (result >= 75) {
                    result = `<div class="bg-custom-red text-center">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                }
                else {
                    result = `<div style="text-align: center;">${row.ParkEmpty} / ${row.ParkFull}</div>`;
                }

 
                

                    $('#exampleMust tbody')
                        .append( '<tr> <td>'+ col1 +'</td> <td>'+ col2 +'</td> <td>'+ col3 +'</td>   </tr>' );
                });



                $('#exampleMust').DataTable({
                    order: [[1, 'asc']],
                    pageLength : 100
                }); 

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });



        if (this.datatable != undefined) {
            this.datatable.destroy();
        }
        this.datatable = jq(`#${this.props.tableName}`).DataTable({
            lengthMenu: [
                [5, 10, 20, 50, 100, 500, 1000],//lengh menüde gösterilecek sayılar
                [5, 10, 20, 50, 100, 500, 1000] //lengh menüde gösterilen sayının işlevi
            ],
            "pageLength": this.props.length != undefined ? this.props.length : 50,//props değerine göre sayfa uzunluğu
            order: this.props.customOrder != undefined ? this.props.customOrder : [0, "asc"], // props değerine göre sıralama
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': jq('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': jq('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },//tablonun translate alanları bu alan sadece datatable ilk oluştuğunda çalışır
            "searching": this.props.searching == undefined ? true : false, //arama alanı aktif mi pasif mi
            "ordering": this.props.ordering == undefined ? true : false,//sıraşama alanı aktif mi pasif mi

            serverSide: true,
            processing: true,
            deferRender: true,
            ajax: {
                url: `${getEndPoint()}${this.props.ajaxUrl}`, // ajax url getendpoint ile api adresi alınır
                type: 'GET', // datatable da tip her zaman get olmalı put olursa filtreler gönderilmiyor
                xhrFields: {
                    withCredentials: true // csrf hatası almamak için bu ayarı aktif hale getiriyoruz 
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'), // csrf cookie yi veriyoruz
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown); // error durumunda ne tür bir hata olduğunu gösteren fonksiyon
                },
                dataFilter: (data) => {
                    var json = jQuery.parseJSON(data);
                    $(`#${this.props.tableName}`).removeClass('table-loader').show()
                    if (typeof json.data === 'object')
                        json.data = Object.values(json.data); //gelen veri her zaman array olmalı
                    json.recordsFiltered = json.total ?? json.pagingData?.totalCount;// ?? json.data.length;
                    json.recordsTotal = json.pagingData?.totalCount ?? json.total;
                    // gelen verilere göre datatable'ın verilerini ayarlıyoruz

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {

                aoData['Filter'] = {
                    q: aoData.search.value, // apinin q parametresi search için kullanılıyor bu yüzden search.value değerini q ye atıyoruz 
                };

                if (this.props.ordering == undefined) { //props daki order değerine göre sıralama yapılıyor

                    aoData['Filter']['order'] = aoData.columns[aoData.order[0].column].data
                    aoData['Filter']['sort'] = aoData.order[0].dir
                }
                if (this.props.filter != undefined) { //props daki filter değerine göre filtreleme yapılıyor

                    for (Object.key in this.props.filter[0]) {
                        aoData['Filter'][Object.key] = this.props.filter[0][Object.key]
                    }

                }
                if (this.props.customTotal != undefined) { // bu alan sadece tek bir tabloda mevcut 

                    aoData["current_page"] = aoData.start / aoData.length + 1;

                }
                aoData["page"] = aoData.start / aoData.length + 1; //hangi sayfanın verisini istiyorsak burda onun değerini yolluyoruz
                aoData["per_page"] = aoData.length; // kaç veri istiyorsak burda onun değerini yolluyoruz
            },
            "columns": this.props.columns// tablonun kolonları 

        });
    }
    componentDidMount() {
        const { t } = this.props
        if (!Functions.RoleControl(this.props.abilityid, this.props.auth)) {
            //burda role kontrolü yapıyoruz eğer rolü yoksa işlemlere girmiyor
            return

        }

        if (this.props.columns == "")// kolon değeri boşsa işlemlere girmiyor
            return



        this.tableInstall(); //tablo oluşturuluyor

        if (this.props.setTable) {
            this.props.setTable(this.datatable)
        }// propsdan gelen settable fonksiyonunu çalıştırıyoruz ve parametre olarak oluşturduğumuz datatable değerini gönderiyoruz
        new jq.fn.dataTable.Responsive(this.datatable, { details: true, Responsive: true },); // responsive için kullanılıyor
    }

    render() {
        return (
            <>

                {Functions.RoleControl(this.props.abilityid, this.props.auth) != false ?
                    this.props.columns != undefined && this.props.columns != "" ?
                        //burda role kontrolü yapıyoruz eğer rolü yoksa gösterimi sağlamıyor
                        <div className={this.props.customClass == undefined ? "col-12 col-sm-12 col-md-6 col-lg-6" : this.props.customClass}>
                            <div className="card border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                                <div className="card-custom-color card-header header-elements-inline">
                                    <h6 className="card-title">
                                        <a data-toggle="collapse" className="text-body" href={`#${this.props.tableName}1`} aria-expanded="true"> {this.props.cardTitle}</a>

                                    </h6>
                                    <div className="header-elements">
                                        <div className="list-icons">
                                            <a onClick={() => {

                                                this.datatable.ajax.reload()//burda reload butonuna basınca tabloyu yeniliyoruz

                                            }} className="list-icons-item" data-action="reload"></a>
                                        </div>
                                    </div>
                                </div>

                                <div id={`${this.props.tableName}1`} className={`collapse ${ReactSession.get('user').id == 192 ? "" : "show"}  card-body py-2`}>
                                    <div className="row">
                                        <div className="col-lg-12">

<table id="exampleMust"  className="dataTable display compact w-100">
<thead>
<tr>
<th>İstasyonlar</th>
<th>Boş Park </th> 
<th>Dolu Park</th> 
</tr>
</thead>
<tbody> 
<tr>
<td>Yükleniyor</td>
<td>...</td> 
</tr>
</tbody>
<tfoot>
<tr>
<th>İstasyonlar</th>
<th>Boş Park </th> 
<th>Dolu Park</th> 
</tr>
</tfoot>
</table>

                                            <table id={`${this.props.tableName}`} className="table-loader dataTable display compact w-100">
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    : null
                }

            </>
        );
    }
}
export default withTranslation(['translations'])(TableDataV2);