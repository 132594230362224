import axios from 'axios';
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { getEndPoint } from '../../class/api'
import Functions from '../../class/functions';
import { Select } from '../../components/Select'
const cookies = new Cookies();

export const UserMerge = () => {
    const [oldUser, setOldUser] = React.useState({})
    const [newUser, setNewUser] = React.useState({})
    const [oldStatusUser, setOldStatusUser] = React.useState({})

    const { t } = useTranslation()
    const onOldUserSelect = useCallback((e) => {
        var object = e.params.args.data
        var balance = object.text.split(' ')
        object.balance = parseFloat(balance[balance.length - 2])
        console.log(object);
        console.log(balance);
        setOldUser(object)
    }, [oldUser]);

    const onNewUserSelect = useCallback((e) => {
        var object = e.params.args.data
        var balance = object.text.split(' ')
        var newBalance = parseFloat(balance[balance.length - 2])
        console.log(object);
        console.log(newBalance);
        if (!isNaN(newBalance)) {
            object.balance = newBalance
        }
        setNewUser(object);
    }, [oldUser]);

    useEffect(() => {
        $('.oldUser').on('select2:selecting', onOldUserSelect);
        $('.newUser').on('select2:selecting', onNewUserSelect);

        return () => {
            // $('.oldUser').reomve('select2:selecting', onOldUserSelect);
        }
    }, []);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

 

    const transferClick = () => {
        var oldBalance = oldUser.balance
        console.log(oldUser.balance);
        console.log(oldBalance);
        if ($('#oldUser').val() == "-1") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Eski Kullanıcı" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('#newUser').val() == "-1") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Yeni Kullanıcı" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }



        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {


                var oldConfig = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member/Merge',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({ 
                        "OldUserId": oldUser.id,
                        "NewUserId": newUser.id,
                    }),
                    withCredentials: true
                };

                axios.all(
                    [axios(oldConfig) ])
                    .then(axios.spread((oldResponse) => {
                        $('#oldUser').val("-1").trigger('change')
                        $('#newUser').val("-1").trigger('change')
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }))
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

                var oldStatusConfig = {
                    method: 'put',
                    url: getEndPoint() + '/api/Admin/Member',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({
                        "Filter": {
                            "id": oldUser.id
                        }
                    }),
                    withCredentials: true
                };

                axios(oldStatusConfig)
                    .then(function (response) {
                        response.data.data[0].Status = false
                        setOldStatusUser(response.data.data[0])
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);

                    });



            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }


    return (
        <div className="p-2">
            <div className="card">
                <div style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                    <h6 className="card-title">Üye Birleştirme</h6>
                    <div className="header-elements"></div>
                </div>
                <div className="card-body py-2">
                    <div className="row">
                        <div className="col-md-6 mt-2">
                            <div className="form-group form-group-feedback form-group-feedback-left mb-0">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>Eski Kullanıcı</span>
                                        <Select
                                            selectID="oldUser"
                                            defaultValues="true"
                                            customCss={"oldUser"}
                                            resultID={["id"]}
                                            // result={["id", "Name", "Surname", "Balance"]}
                                            resultV2={[{ key : "id", tl : "ID : ", tr : " - "}, { key : "Name", tl : "", tr : ""}, { key : "Surname", tl : "", tr : ""}, { key : "Balance", tl : "", tr : ""} ]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2">
                            <div className="form-group form-group-feedback form-group-feedback-left mb-0">
                                <div className="input-group">
                                    <div className="d-block w-100">
                                        <span>Yeni Kullanıcı</span>

                                        <Select
                                            defaultValues="true"
                                            selectID="newUser"
                                            customCss={"newUser"}
                                            resultID={["id"]}
                                            result={["Name", "Surname", "Balance"]}
                                            ajaxURL="/api/Admin/Member"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-5'>
                            <button type='button' onClick={transferClick} style={{ fontSize: "12px" }} className='btn btn-success btn-lg btn-block'>Üyeleri Birleştir</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
