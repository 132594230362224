import React from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import TableData from "../../components/table.js";
import TableDataV2 from "../../components/tableV2.js";
import { ReactSession } from "react-client-session";
ReactSession.setStoreType("localStorage");
import Anchor from "../../components/elements/anchor.js";
import Division from "../../components/elements/content-division";
import Map from "../../components/map.js";
import Text from "../../components/elements/text";
import Button from "../../components/elements/button";
import Functions from "../../class/functions";
import swal, { Swal } from "../../js/plugins/notifications/sweet_alert.min.js";
import axios from "axios";
import Cookies from "universal-cookie";
import jq from "jquery";
import "datatables.net";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import "datatables.net-buttons/js/buttons.print";
import "bootstrap/dist/js/bootstrap";
import jsZip from "jszip";
import "datatables.net-buttons/js/buttons.colVis.min";
import "datatables.net-buttons/js/dataTables.buttons.min";
import "datatables.net-buttons/js/buttons.flash.min";
import "datatables.net-buttons/js/buttons.html5.min";
import {
  DeviceChargeType,
  DeviceConnectionType,
  DeviceQueryType,
  DeviceStateType,
} from "../../class/enums";
import swalInit from "../../js/plugins/notifications/sweet_alert.min.js";

import "./style.css";
import "../../css/stationTest.css";

window.JSZip = jsZip;
import { getEndPoint } from "../../class/api";
let stationList = [];
const cookies = new Cookies();

let vehicleImei = "";
let vehicleNo = "";
class GpsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
    };
    
    const { t } = this.props;
    this.bicycleTable = null;

    const onItemClick = (value) => {
      alert("onItemClick");
    };


    this.sarjtip = [
      "Şarj olmuyor",
      "Dinamodan şarj ediliyor",
      "Parktan şarj ediliyor",
    ];
    this.statetip = ["Duruyor", "Kullanımda", "Taşınıyor", "Uyudu", "Uyandı"];
    this.gpsListColumn = [
      // {
      //     title: t("İstasyon Numarası"),
      //     data: "Number"
      // },
      // {

      //     title: t("table.passive.stations"),
      //     data: "Order", render: function (data, type, row) {
      //         return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.StationId}` : `#`} ">${row.Name} </a>`
      //     }

      // },

      { title: t("ID"), data: "VehicleGPSId" },
      { title: t("imei"), data: "IMEI" },
        

      /*
            
            {title: t("Park"),   data: "park_cihaz_id", render: function (data, type, row)   {
                    return data > 0 ? data : "" ; 
                }, "orderable": false
            }, 
            {title: t("Bölg.İst."),   data: "bolge_adi", render: function (data, type, row)   {
                    return data + "-" + row.istasyon_adi ; 
                }, "orderable": false
            }, 
            */

      {
        title: t("bisiklet_no "),
        data: "VehicleNo",
        render: function (data, type, row) {
          var gps_konum = "";
          // if (data > 0 && row.Lat > 0 && row.Long > 0) {
          //     //gps_konum = "<a href='javascript:void(0)' onclick=\"openbis(" + data + ")\">" + data + "</a>";
          //     gps_konum = "<a href='javascript:void(0)' onclick=\"window.open('https://www.google.com/maps/search/?api=1&query=" + row.Lat + "," + row.Long + "','KOBİS','width=800,height=600,status=no,scrollbars=yes,toolbar=0,menubar=no,resizable=yes,top=460,left=600')\">" + data + "</a>";

          // } else if (data == 0 && row.Lat > 0 && row.Long > 0) {
          //     gps_konum = `<a href='javascript:void(0)' onclick=\"openWindow('https://www.google.com/maps/search/?api=1&query=" + row.Lat + "," + row.Long + "')\">" + data + "</a>`

          //     gps_konum = "<a href='javascript:void(0)' onclick=\"openWindow('https://www.google.com/maps/search/?api=1&query=" + row.Lat + "," + row.Long + "')\">" + data + "</a>";
          // } else {
          //     gps_konum = data;
          // }
          gps_konum =
            "<a href='javascript:void(0)' onclick=\"window.open('/bicycles/get/" +
            row.VehicleNo +
            "','KOBİS','width=800,height=600,status=no,scrollbars=yes,toolbar=0,menubar=no,resizable=yes,top=460,left=600')\">" +
            data +
            "</a>";

          return gps_konum;

          if (data == null) {
            return " ";
          }
          return `<a class="vehicleNo" href="#" >${data}</a>`;
        },
      },
      { title: t("gsmsinyal"), data: "GSMSignal", orderable: false },
      // { title: t("pilyuzde"), data: "pilyuzde" },
      {
        title: t("pilyuzde "),
        data: "BatteryLevel",
        render: function (data, type, row) {
          var pilyuzde = "";
          if (row.fark < 15) {
            pilyuzde =
              '<span style="background:url(/img/battery' +
              (row.sarjtip > 0 ? "1" : "0") +
              ".gif)\" class='battery'>%" +
              data +
              "</span>";
          } else {
            pilyuzde = "%" + data;
          }

          return pilyuzde;
        },
        orderable: false,
      },
      /*
            {title: t("sarjtip "), 
                data: "sarjtip", render: function (data, type, row)   {
                    var sarktipi = "";
                    switch (data) {
                        case 0:
                            sarktipi = "Şarj olmuyor"; 
                            break;
                        case 1:
                            sarktipi = "Dinamodan şarj ediliyor"; 
                            break;
                        case 2:
                            sarktipi = "Parktan şarj ediliyor"; 
                            break;
                    
                        default:
                            break;
                    }  
                    return sarktipi
                }, "orderable": false
            }, 
            */
      { title: t("Versiyon"), data: "Version", orderable: false },
      {
        title: t("table.passive.lastConnect"),
        data: "updated_at",
        render: function (data) {
          return moment(data).format("YYYY-MM-DD HH:mm:ss");
        },
      },

      {
        title: t("Durum"),
        data: "statetip",
        render: function (data, type, row) {
          var statetip = "";
          switch (data) {
            case 0:
              statetip = "Duruyor";
              break;
            case 1:
              statetip = "Kullanımda";
              break;
            case 2:
              statetip = "Taşınıyor";
              break;
            case 3:
              statetip = "Uyudu";
              break;
            case 4:
              statetip = "Uyandı";
              break;

            default:
              statetip = "-";
              break;
          }
          return statetip;
        },
        orderable: false,
      },
      Functions.RoleControl(17) == true
        ? {
            title: t("İşlemler"),
            data: function (data, type, row) {
              //console.log ( data );

              return `<div class="list-icons">
                              <div class="dropdown">
                                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                                      <i class="icon-menu9"></i>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-right">${
                                    Functions.RoleControl(17, 2) == true
                                      ? ` <a id="openFerofenLock" href="#" class="dropdown-item" data-imei="${data.IMEI}"  data-vehicle-no="${data.VehicleNo}"> <i class="icon-lock"></i> ${t(
                                          "Aç"
                                        )}</a> `
                                      : ""
                                  }
                                
                                  ${
                                    Functions.RoleControl(17, 2) == true 
                                    ? ` <a id="restartFerofenBicycle" href="#" class="dropdown-item" data-imei="${data.IMEI}" data-vehicle-no="${data.VehicleNo}"> <i class="icon-loop"></i> ${t(
                                          "Bisikleti Resetle"
                                        )}</a> `
                                      : ""
                                  } 
                                  ${
                                    Functions.RoleControl(17, 2) == true
                                      ? ` <a id="infoFerofenBicycle" href="#" class="dropdown-item" data-imei="${data.IMEI}" data-vehicle-no="${data.VehicleNo}"> <i class="icon-soundcloud"></i> ${t(
                                          "Bilgi"
                                        )}</a> `
                                      : ""
                                  }
                                  ${
                                    Functions.RoleControl(17, 2) == true 
                                    ? ` <a id="endSessionFerofenBicycle" href="#" class="dropdown-item" data-imei="${data.IMEI}" data-vehicle-no="${data.VehicleNo}"> <i class="icon-loop"></i> ${t(
                                          "Sonlandırma İsteği Gönder"
                                        )}</a> `
                                      : ""
                                  } 
                                  
                             
                                    
                                  </div >
                              </div >
                          </div > `;
            },
          }
        : null,
    ];

    // this.gpsListFilter["Filter"]["sarjtip"] = "-1"
    // this.gpsListFilter["Filter"]["statetip"] = "-1"
    // this.gpsListFilter["Filter"]["bagli"] = "-1"
    // this.gpsListFilter["Filter"]["bolge"] = ""
    // this.gpsListFilter["Filter"]["sorgu"] = ""

    this.gpsListFilter = [
      {
        sarjtip: "-1",
        statetip: "-1",
        bagli: "-1",
        bolge: "",
        sorgu: "",
      },
    ];

    const moment = require("moment");

    const myDate = moment.now();
    const newDate = moment(myDate)
      .add(-6, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

    // this.filterMostRentedFilter = [
    //     {
    //         "EntryDate": moment().startOf('month').format('YYYY-MM-DD HH:MM'),
    //         "ExitDate": moment().endOf('month').format('YYYY-MM-DD HH:MM'),
    //     }
    // ]

    this.modalBicycleRef = React.createRef();
    this.modalBicycleHandleSubmit = this.modalBicycleHandleSubmit.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;

    //  swalInit.fire('hata', 'GPS verisi veri sağlayıcısından getirilemedi', 'warning')
    var _this = this;
    const selectLanguage = {
      noResults: () =>
        t("select2Localization.noResults", { ns: "translations" }),
      searching: () =>
        t("select2Localization.searching", { ns: "translations" }),
      maximumSelected: () =>
        t("select2Localization.maximumSelected", { ns: "translations" }),
      loadingMore: () =>
        t("select2Localization.loadingMore", { ns: "translations" }),
      inputTooShort: () =>
        t("select2Localization.inputTooShort", { ns: "translations" }),
      inputTooLong: () =>
        t("select2Localization.inputTooLong", { ns: "translations" }),
      errorLoading: () =>
        t("select2Localization.errorLoading", { ns: "translations" }),
    };
    $(".selectStation").select2({
      language: selectLanguage,
    });
    stationList = ReactSession.get("stationList");

    jq("#parkingoccupancy-table2 tbody").on(
      "click",
      ".cikisstatus",
      function () {
        //action delete butonu

        var data = {
          module_id: "" + jq(this).attr("data-module-id") + "",
          channelEx: 1,
          channel: Number.parseInt(jq(this).attr("data-channel")),
          desired_status: Number.parseInt(
            jq(this).attr("data-module-new-status")
          ),
        };
        var config = {
          method: "POST",
          url: getEndPoint() + "/api/Admin/OtherModules/EnergyTracking",
          headers: {
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };

        if (confirm(jq(this).attr("data-uyari-mesaji")) == true) {
          axios(config)
            .then(function (response) {
              if (response.data.result.status == "OK") {
                alert("işlem başarılı ");
                jq(
                  "#tab-1 > div > div.col-12 > div > div.card-custom-color.card-header.header-elements-inline > div > div > a"
                ).click();
                _this.bicycleTable.ajax.reload();
              } else {
                alert("Hata oluştu lütfen terkar deneyin");
              }

              Swal.fire({
                position: "top-end",
                icon: "success",
                title: t("swal.success.successMessage", { ns: "translations" }),
                showConfirmButton: false,
                timer: 1500,
              }).then(function () {
                // $("#bicycle-edit .close").trigger("click");
                // _this.bicycleTable.ajax.reload()
              });
            })
            .catch(function (error) {
              console.log("_________________");
              console.log(error);
              // Functions.requestAxiosErrorFunction(error);
            });
        }
      }
    );

    $("#bicycle-gps-table tbody").on("click", "td .vehicleNo", function () {
      var tr = $(this).closest("tr");
      // var row = _this.bicycleTable.row(tr);
      // var data = row.data();
      // if (data == undefined) {

      //     row = _this.bicycleTable.row(this);
      //     data = row.data();
      // }
      // ReactSession.set('bikeDetails', data);
      window.location.href = "/bicycles/bicycle-movements";
    });
    
    jq("#bicycle-gps-table tbody").on(
      "click",
      "td #openFerofenLock",
      function () {
        $("openModalParkNumber").text("##");

        var tr = jq(this).closest("tr"); 

        //var row = _this.bicycleTable.row(tr);
        //   var data = row.data();

        //   if (data == undefined) {
        //     row = _this.bicycleTable.row(this);
        //     data = row.data();
        //   }
        //   parkdata = data?.ParkId;
        vehicleImei = jq(this).attr( "data-imei" ) ;
        vehicleNo = jq(this).attr( "data-vehicle-no" ) ;

        
         $("#openModalParkNumber").text(  vehicleNo + " ( " + vehicleImei + " ) " );

        let myModal = new bootstrap.Modal(document.getElementById("openModal"));
        myModal.show();
      }
    );

    jq("#bicycle-gps-table tbody").on("click", "td #restartFerofenBicycle", function () {
        var tr = jq(this).closest("tr");
        //var row = _this.parkList.row(tr);
        //var data = row.data();
        // if (data == undefined) {
        //   row = _this.parkList.row(this);
        //   data = row.data();
        // }
        vehicleNo = jq(this).attr( "data-vehicle-no" ) ;
        vehicleImei = jq(this).attr( "data-imei" ) ;
        swal
          .fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", {
              ns: "translations",
              questionData: vehicleNo +" ( " + vehicleImei + " ) "+ " nolu Bisikleti Resetlemek ",
            }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", {
              ns: "translations",
            }),
            denyButtonText: t("swal.questionCancelButton", {
              ns: "translations",
            }),
          })
          .then((result) => {
            if (result.isConfirmed) {
              var config = {
                method: "POST",
                // url: getEndPoint() + '/api/Admin/Station/Control',
                //url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",
                url:   getEndPoint()+'/api/Admin/Station/Vehicle/GPS/Control',
                headers: {
                  "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: JSON.stringify({
                  VehicleNo: vehicleNo,
                  // "StationParkUnitId": data.StationParkUnitId ,
                  ProccessType: 3,
                  IMEI:vehicleImei,
                }),
              };
  
              axios(config).then(function (response) {
                jq.fn.dataTable.tables({ api: true }).ajax.reload();
  
                swal.fire({
                  title: t("swal.success.head", { ns: "translations" }),
                  text: t("swal.success.subject", { ns: "translations" }),
                  confirmButtonText: t("swal.confirmButton", {
                    ns: "translations",
                  }),
                  icon: t("swal.success.icon", { ns: "translations" }),
                });
              });
            }
          });
      });

      jq("#bicycle-gps-table tbody").on("click", "td #endSessionFerofenBicycle", function () {
        var tr = jq(this).closest("tr");
        //var row = _this.parkList.row(tr);
        //var data = row.data();
        // if (data == undefined) {
        //   row = _this.parkList.row(this);
        //   data = row.data();
        // }
        vehicleNo = jq(this).attr( "data-vehicle-no" ) ;
        vehicleImei = jq(this).attr( "data-imei" ) ;
        swal
          .fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", {
              ns: "translations",
              questionData: vehicleNo +" ( " + vehicleImei + " ) "+ " nolu Bisikleti Sonlandırma ",
            }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", {
              ns: "translations",
            }),
            denyButtonText: t("swal.questionCancelButton", {
              ns: "translations",
            }),
          })
          .then((result) => {
            if (result.isConfirmed) {
              var config = {
                method: "POST",
                // url: getEndPoint() + '/api/Admin/Station/Control',
                //url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",
                url:   getEndPoint()+'/api/Admin/Station/Vehicle/GPS/Control',
                headers: {
                  "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: JSON.stringify({
                  VehicleNo: vehicleNo,
                  // "StationParkUnitId": data.StationParkUnitId ,
                  ProccessType: 2,
                  IMEI:vehicleImei,
                }),
              };
  
              axios(config).then(function (response) {
                jq.fn.dataTable.tables({ api: true }).ajax.reload();
  
                swal.fire({
                  title: t("swal.success.head", { ns: "translations" }),
                  text: t("swal.success.subject", { ns: "translations" }),
                  confirmButtonText: t("swal.confirmButton", {
                    ns: "translations",
                  }),
                  icon: t("swal.success.icon", { ns: "translations" }),
                });
              });
            }
          });
      });

      

      jq("#bicycle-gps-table tbody").on(
        "click",
        "td #infoFerofenBicycle",
        function () {

          var tr = jq(this).closest("tr");
          //var row = _this.parkList.row(tr);
          //var data = row.data();
          // if (data == undefined) {
          //   row = _this.parkList.row(this);
          //   data = row.data();
          // }
          vehicleNo = jq(this).attr( "data-vehicle-no" ) ;
          vehicleImei = jq(this).attr( "data-imei" ) ;
          var config = {
            method: "POST",
            // url: getEndPoint() + '/api/Admin/Station/Control',
            //url: getEndPoint() + "/api/Admin/Station/ParkUnit/Control",
            url:   getEndPoint()+'/api/Admin/Station/Vehicle/GPS/Control',
  
            headers: {
              "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              VehicleNo: vehicleNo,
              IMEI:vehicleImei,
              // "StationParkUnitId": data.StationParkUnitId ,
              ProccessType: 5,
            }),
          };

   
  
          axios(config).then(function (response) {
            jq.fn.dataTable.tables({ api: true }).ajax.reload();
  
            swal.fire({
              title: t("swal.success.head", { ns: "translations" }),
              text: t("swal.success.subject", { ns: "translations" }),
              confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
              icon: t("swal.success.icon", { ns: "translations" }),
            });
          });
        }
      );
    
  }


  openFerofenLockClicK=()=>{
    const {t}=this.props;
    var _this=this;

    var data={
      
      VehicleNo: vehicleNo, 
      IMEI:vehicleImei, 
      ProccessType:1,
    }
    var config={
      method:"post",
      url:   getEndPoint()+'/api/Admin/Station/Vehicle/GPS/Control',
      headers:{
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data:data
    };
    axios(config).then((res)=>{
      swal.fire({
        title: t("swal.success.head", { ns: "translations" }),
        text: "Kilit açma işlemi başarıyla gerçekleşti",
        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
        icon: t("swal.success.icon", { ns: "translations" }),
      });
    })
    console.log(data);
  }
  
  componentDidUpdate = () => this.componentDidMount();
  resetSubmitHandler = (e) => {
    e.preventDefault();
    const _this = this;
    const { t } = this.props;

    // { ns: "translations" }
  };

  modalBicycleHandleSubmit(e) {
    e.preventDefault();

    const _this = this;
    const { t } = this.props;

    Swal.fire({
      title: t("swal.info.subjectNoData", { ns: "translations" }),
      showDenyButton: true,
      confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
      denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
    }).then((result) => {
      if (result.isConfirmed) {
        var userEditData = userData;
        userEditData.VehicleNo =
          $(".newBikeNumber").val() == ""
            ? userEditData.VehicleNo
            : $(".newBikeNumber").val();

        var data = {
          module_id: userEditData.VehicleId,
          channel: 1,
          desired_status: userEditData.StationParkUnitId,
        };
        
        var config = {
          method: "POST",
          url: getEndPoint() + "/api/Admin/OtherModules/EnergyTracking",
          headers: {
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: t("swal.success.successMessage", { ns: "translations" }),
              showConfirmButton: false,
              timer: 1500,
            }).then(function () {
              $("#bicycle-edit .close").trigger("click");
              _this.bicycleTable.ajax.reload();
            });
          })
          .catch(function (error) {
            Functions.requestAxiosErrorFunction(error);
          });
      } else if (result.isDenied) {
        Swal.fire({
          title: t("swal.cancelSwal.head", { ns: "translations" }),
          text: t("swal.cancelSwal.subject", { ns: "translations" }),
          confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
          icon: t("swal.cancelSwal.icon", { ns: "translations" }),
        });
      }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="retail-page p-2">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="tab-1">
            <div className="row">
              <Division className="card mb-1">
                <Division className="card-body  py-2">
                  <Division className="row">
                    <Division className="col-md-3">
                      <Division className="form-group">
                        <Text Tag="label">{t("region")}</Text>
                        <select
                          className="select-search form-control"
                          defaultValue={-1}
                        >
                          <option value={-1}>
                            {t("all", { ns: "translations" })}
                          </option>
                        </select>
                      </Division>
                    </Division>
                    <Division className="col-md-2">
                      <Division className="form-group">
                        <Text Tag="label">{t("chargeStatus")}</Text>
                        <select
                          className="select-search form-control"
                          defaultValue={-1}
                        >
                          <option value={-1}>
                            {t("all", { ns: "translations" })}
                          </option>
                          <option value={DeviceChargeType.DoesNotCharging}>
                            {t("deviceChargeType.doesNotCharging", {
                              ns: "translations",
                            })}
                          </option>
                          <option value={DeviceChargeType.ChargingWithDynamo}>
                            {t("deviceChargeType.chargingWithDynamo", {
                              ns: "translations",
                            })}
                          </option>
                          <option value={DeviceChargeType.ChargingWithPark}>
                            {t("deviceChargeType.chargingWithPark", {
                              ns: "translations",
                            })}
                          </option>
                        </select>
                      </Division>
                    </Division>
                    <Division className="col-md-2">
                      <Division className="form-group">
                        <Text Tag="label">{t("status")}</Text>
                        <select
                          className="select-search form-control"
                          defaultValue={-1}
                        >
                          <option value={-1}>
                            {t("all", { ns: "translations" })}
                          </option>
                          <option value={DeviceStateType.Stand}>
                            {t("deviceState.standing", { ns: "translations" })}
                          </option>
                          <option value={DeviceStateType.InUse}>
                            {t("deviceState.inUse", { ns: "translations" })}
                          </option>
                          <option value={DeviceStateType.InTransport}>
                            {t("deviceState.inTransport", {
                              ns: "translations",
                            })}
                          </option>
                        </select>
                      </Division>
                    </Division>
                    <Division className="col-md-2">
                      <Division className="form-group">
                        <Text Tag="label">{t("connection")}</Text>
                        <select
                          className="select-search form-control"
                          defaultValue={-1}
                        >
                          <option value={-1}>
                            {t("all", { ns: "translations" })}
                          </option>
                          <option value={DeviceConnectionType.NotConnected}>
                            {t("deviceConnection.notConnected", {
                              ns: "translations",
                            })}
                          </option>
                          <option value={DeviceConnectionType.Connected}>
                            {t("deviceConnection.connected", {
                              ns: "translations",
                            })}
                          </option>
                        </select>
                      </Division>
                    </Division>
                    <Division className="col-md-2">
                      <Division className="form-group">
                        <Text Tag="label">{t("queries")}</Text>
                        <select
                          className="select-search form-control"
                          defaultValue={-1}
                        >
                          <option value={-1}>
                            {t("all", { ns: "translations" })}
                          </option>
                          <option value={DeviceQueryType.OutOfBattery}>
                            {t("deviceQuery.outOfBattery", {
                              ns: "translations",
                            })}
                          </option>
                          <option value={DeviceQueryType.AlmostOutOfBattery}>
                            {t("deviceQuery.almostOutOfBattery", {
                              ns: "translations",
                            })}
                          </option>
                          <option
                            value={DeviceQueryType.DisconnectedInLast24Hours}
                          >
                            {t("deviceQuery.disconnectedInLast24Hours", {
                              ns: "translations",
                            })}
                          </option>
                          <option
                            value={
                              DeviceQueryType.BatteryModuleFaultInLast24Hours
                            }
                          >
                            {t("deviceQuery.batteryModuleFaultInLast24Hours", {
                              ns: "translations",
                            })}
                          </option>
                        </select>
                      </Division>
                    </Division>
                    <Division className="col-md-1 px-1 mt-4">
                      <Button className="btn btn-primary">
                        <i className="icon-search4 mr-2"></i> {t("search")}
                      </Button>
                    </Division>
                  </Division>
                </Division>
              </Division>

              <Division className="card mb-1">
                <Map
                  ajaxUrl="/api/Admin/OtherModules/GPS/Local"
                  filter={this.gpsListFilter}
                  columns={this.gpsListColumn}
                  initialCenter={[40.7032331, 29.8843142]}
                ></Map>
              </Division>

              <TableData
                cardTitle={t("GPS Listesi")}
                abilityid={7}
                auth={0}
                length="100"
                // ordering={true}
                customOrder={[6, "DESC"]}
                searching={false}
                customClass="col-12"
                ajaxUrl="/api/Admin/OtherModules/GPS/Local"
                tableName="bicycle-gps-table"
                filter={this.gpsListFilter}
                columns={this.gpsListColumn}
              />

              <div
                id="bicycle-edit"
                ref={this.modalBicycleRef}
                className="modal fade"
              >
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <form onSubmit={this.modalBicycleHandleSubmit}>
                      <div className="modal-header">
                        <h5 className="modal-title">Uyarı</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-body">
                        <div className="row">
                          <div className="col-lg-12">
                            <Text
                              Tag="label"
                              className="col-form-label col-lg-12 align-self-center"
                            >
                              {t("modal.bicycleRFID")}
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          id="deneme"
                          data-dismiss="modal"
                        >
                          {t("İptal")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab-2"></div>
        </div>

        <div
          className="modal fade"
          id="openModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                
                <p id="openModalParkNumber">
                   
                </p> Numaralı bisikletin kilidini
                açmak istediğinize emin misiniz ?
                
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.openFerofenLockClicK}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  {t("Onayla")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("Kapat")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["Homepage", "translations", "systemBicycles"])(
  GpsList
);
