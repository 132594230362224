import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Division from '../../components/elements/content-division';
import Functions from '../../class/functions';
import Text from '../../components/elements/text';
import CalendarIcon from "@mui/icons-material/CalendarToday";
import axios from 'axios';
import jq from 'jquery'
import { ReactSession } from 'react-client-session';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { ReportType } from "../../class/enums";
import { getEndPoint } from "../../class/api";

class RetrieveBicycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: true,
            parkList: []
        };
        this.maintenanceControlTableRef = React.createRef();
        this.ParkNumberRef = React.createRef();
        this.vehicleSelect = React.createRef();

        this.teslimFunc = this.teslimFunc.bind(this);
        this.userFunc = this.userFunc.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.refreshClick = this.refreshClick.bind(this);
    }
    userFunc() {
        const _this = this
        const { t } = this.props
        var selectBike = $('#userSelect');
        var option = new Option(
            t("loading", { ns: "translations" }),
            0,
            true,
            true);
        selectBike.append(option).trigger('change');
        var data = JSON.stringify({
            "Filter": {
                "IsUserId": true,
                "per_page": -1,
                "StatusId": [1, 2, 3, 4, 5, 6, 7, 8, 10]
            }

        });
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station/Vehicle',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                const responseData = response.data.data;
                $('#userSelect').empty().trigger("change");

                var userSelect = $('#userSelect');
                var option = new Option(
                    "Lütfen Seçim yapınız",
                    "0",
                    true,
                    true);
                userSelect.append(option).trigger('change');
                response.data.data.forEach(data => {
                    var option = new Option(
                        data.User.Name + ' ' + data.User.Surname + ' - ' + data.User.Phone + ' - ' + data.VehicleNo + '',
                        data.User.id + ' - ' + data.VehicleId,
                        false,
                        false);
                    userSelect.append(option).trigger('change');

                });


            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    teslimFunc() {
        const _this = this
        const { t } = this.props
        var selectBike = $('#teslim');
        var option = new Option(
            t("loading", { ns: "translations" }),
            0,
            true,
            true);
        selectBike.append(option).trigger('change');
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Station',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ per_page: 1000, "order": "Order", })
        };

        axios(config)

            .then(function (response) {
                const responseData = response.data.data;
                $('#teslim').empty().trigger("change");
                var selectBike = $('#teslim');
                var option = new Option(
                    'Lütfen Seçim yapınız',
                    0,
                    true,
                    true);
                selectBike.append(option).trigger('change');

                response.data.data.forEach(data => {

                    var opt = '<optgroup label="' + data.Name + '">';

                    data.StationParkUnits.forEach((elm, index) => {
                        if (elm.Status != 2) {
                            opt += '<option data-id="' + elm.ParkNumber + '" value="">' + elm.ParkId + ' - ' + elm.ParkName + ' - ' + elm.StatusTxt + ' </option>'

                        }

                    });
                    opt += '</optgroup>';

                    var option = new Option(
                        data.Name,
                        data.VehicleId,
                        false,
                        false);
                    selectBike.append(opt).trigger('change');

                });

            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }



    componentDidMount() {
        const { t } = this.props;
        const _this = this
        let dirAttr = 'ltr';
        if ($('html') !== undefined)
            dirAttr = $('html').attr('dir');

        const selectLanguage = {
            noResults: () => t("select2Localization.noResults", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };
        $('.select-multiple-tags').select2({
            tags: true,
            dir: dirAttr,
            language: selectLanguage
        });


        $('.select-search').select2({
            language: selectLanguage
        });

        $('#teslim').select2({
            language: selectLanguage,
            matcher: function (params, data) {
                var original_matcher = $.fn.select2.defaults.defaults.matcher;
                var result = original_matcher(params, data);
                if (result && data.children && result.children && data.children.length != result.children.length) {
                    result.children = data.children;
                }
                return result;
            },
        });

        this.teslimFunc()
        this.userFunc()

        if (this.bicycleTable != null)
            this.bicycleTable.destroy();


        this.bicycleTable = $('#bicycles-table').DataTable({
            dom: 'Blfrtip',
            lengthMenu: [[5, 10, 20, 50, 100, -1], [5, 10, 20, 50, 100, t("all", { ns: "translations" })]],
            "pageLength": 50,
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            language: {
                ...(t("tableLocalization", { ns: "translations" })),
                paginate: {
                    'next': $('html').attr('dir') == 'rtl' ? t("tableLocalization.paginate.next", { ns: "translations" }) + '&larr;' : t("tableLocalization.paginate.next", { ns: "translations" }) + ' &rarr;',
                    'previous': $('html').attr('dir') == 'rtl' ? '&rarr; ' + t("tableLocalization.paginate.previous", { ns: "translations" }) : '&larr; ' + t("tableLocalization.paginate.previous", { ns: "translations" })
                }
            },
            serverSide: true,
            order: [5, 'ASC'],
            processing: true,
            ajax: {
                url: getEndPoint() + '/api/Admin/Station/Vehicle',
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                error: function (xhr, error, thrown) {
                    Functions.requestAjaxErrorFunction(xhr, error, thrown);
                },
                crossDomain: true,
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                },
                dataFilter: function (data) {
                    var json = jQuery.parseJSON(data);
                    json.data = json.data;
                    json.recordsFiltered = json.pagingData.totalCount
                    json.recordsTotal = json.pagingData.totalCount
                    $(`#bicycles-table`).removeClass('table-loader').show()

                    return JSON.stringify(json);
                },
            },
            fnServerParams: (aoData) => {
                aoData['Filter'] = {
                    q: aoData.search.value,
                    order: aoData.columns[aoData.order[0].column].data,
                    sort: aoData.order[0].dir,
                    StatusId: [1, 2, 3, 4, 5, 6, 7, 8, 10],
                    IsUserId: true,

                };
                aoData["page"] = aoData.start / aoData.length + 1;
                aoData["per_page"] = aoData.length;
            },

            "columns": [
                { data: "VehicleId" },
                { data: "Name" },
                {
                    data: "VehicleNo", render: function (data, type, row) {
                        return `<a id="vehicle_no" href='#'>${data}</a>`
                    }
                },
                {
                    data: "User", render: function (data, type, row) {

                        var user;
                        var userID;
                        if (row.User != null) {
                            user = row.User.Name + ' ' + row.User.Surname
                            userID = '/users/edit/' + row.UserId
                            userID = Functions.RoleControl(11, 4) == false ? '#' : userID

                            return '<a class="getUserBtn" href="' + userID + '" type="button" data-id="' + row.UserId + '">' + user + '</a>';
                        }
                        else {
                            user = "Kullanıcı Bulunamadı";
                            return '<a class="btn getUserBtn">' + user + '</a>';

                        }

                    }
                },

                { data: "RFID" },
                {
                    data: "LastConnect", render: function (data) {
                        return Functions.getFormatLocaleDateHours(data)
                    }
                },
                { data: "VehicleType" },


            ]
        });
        new jq.fn.dataTable.Responsive(this.bicycleTable, { details: true, Responsive: true },);


        $('#bicycles-table tbody').on('click', 'td #vehicle_no', function () {
            var tr = $(this).closest('tr');
            var row = _this.bicycleTable.row(tr);
            var data = row.data();
            if (data == undefined) {

                row = _this.bicycleTable.row(this);
                data = row.data();
            }
            ReactSession.set('bikeSearchData', data.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";
        });
    }


    handleClick = () => {
        const _this = this;
        const { t } = this.props

        if ($('#userSelect').val() == "0") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("user") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })

            return;
        }

        if ($('#teslim').select2('data')[0].id == "0") {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("deliveryLocation") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return;
        }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subjectNoData", { ns: "translations" }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var vehicleSelect = $('#teslim').select2('data')[0].text;
                var userSelect = $('#userSelect').val();
                var userSplit = userSelect.split(" - ");
                var vehicleSplit = vehicleSelect.split(" - ");
                var ParkNumber = parseInt(vehicleSplit[0])
                var VehicleId = parseInt(userSplit[1])
                var UserId = parseInt(userSplit[0])


                var data = JSON.stringify({
                    "ParkNumber": ParkNumber > 0 ? ParkNumber : null,
                    "VehicleId": VehicleId > 0 ? VehicleId : null,
                    "ProccessType": 1,
                    "UserId": UserId > 0 ? UserId : null
                });
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member/RegisteredVehicleReceiving',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json'
                    },
                    data: data,
                    withCredentials: true
                };

                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            _this.bicycleTable.ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }
    refreshClick() {
        this.teslimFunc()

    }
    render() {
        const { t } = this.props;

        return (
            <Division className="system-maintenance-page p-2">


                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <Text Tag="h6" className="card-title">{t("head")}</Text>
                    </Division>

                    <Division className="card-body py-2">

                        <Division className="form-group row">
                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("user")}</Text>
                            <Division className="col-lg-4 align-self-center">
                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                    <select name="userSelect" id="userSelect" className="select-search form-control material-icons" defaultValue={-1}>
                                        <option value="0">   {t("loading", { ns: "translations" })} </option>
                                    </select>
                                </Division>
                            </Division>

                            <Text Tag="label" className="col-form-label col-lg-2 align-self-center">
                                <span onClick={this.refreshClick} type="text" className="col-form-label col-lg-2 align-self-center">
                                    <i className="icon-reload-alt"></i>
                                    {t("deliveryLocation")}
                                </span>
                            </Text>
                            <Division className="col-lg-4 align-self-center">
                                <Division className="form-group form-group-feedback form-group-feedback-left">
                                    <select defaultValue="0" name="teslim" id="teslim" ref={this.vehicleSelect} className="select-search form-control material-icons">
                                        <option value="0">   {t("loading", { ns: "translations" })} </option>
                                        {/* <optgroup label="İstasyon İsmi">
                                    <option value="volvo">155 istasyon ismi - park durumu</option>
                                    <option value="saab">Saab</option>
                                </optgroup> */}
                                    </select>
                                </Division>
                            </Division>
                        </Division>

                        <Division className="form-group row">
                        </Division>
                        <Division className="form-group row mt-2 d-flex justify-content-center">
                            <Division className="col-lg-3">
                                <button type="submit" onClick={this.handleClick} className="btn btn-secondary w-100 h-100">{t("save")}</button>
                            </Division>
                        </Division>

                    </Division>
                </Division>


                <div className="card border-gray border-left-2 border-top-2 border-right-2  border-bottom-2 ">
                    <div className="card-custom-color card-header header-elements-inline">
                        <h6 className="card-title">Kullanımda Olan Bisikletler</h6>
                        <div className="header-elements">
                            <div className="list-icons">
                                <a onClick={() => {

                                    jq(`#bicycles-table`).hide(),
                                        this.bicycleTable.ajax.reload(),
                                        jq(`#bicycles-table`).show()

                                }} className="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>

                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-lg-12">
                                <table id="bicycles-table" ref={this.usersTableRef} className="table-loader dataTable display  compact  w-100">
                                    <thead>
                                        <tr>

                                            <th>{t("table.VehicleId")}</th>
                                            <th>{t("table.Name")}</th>
                                            <th>{t("table.VehicleNo")}</th>
                                            <th>{t("table.userID")}</th>
                                            <th>{t("table.RFID")}</th>
                                            <th>{t("table.LastConnect")}</th>
                                            <th>{t("table.VehicleType")}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </Division>
                        </Division>
                    </Division>

                </div>


            </Division>
        );
    }
}

export default withTranslation(["systemBicycleStatusDefinitions", "translations"])(RetrieveBicycle);