import axios from "axios";
import { useEffect, useState } from "react";
import { getEndPoint } from "../../class/api";
const API_URL = getEndPoint();
const HeadCheck = () => {
  const [name] = useState([
    "API HeadCheck",
    "DB PG HeadCheck",
    "DB MsSQL HeadCheck",
    "GeneralUNILOCKService HeadCheck",
    "EnergyTrackService HeadCheck",
    "FerofenPCBMQTTService HeadCheck",
    "GeneralPCBMQTTService HeadCheck",
  ]);
  const [url] = useState([
    `${API_URL}/api/HeadCheck`,
    `${API_URL}/api/HeadCheck/dbPg`,
    `${API_URL}/api/HeadCheck/dbMssql`,
    `${API_URL}/api/HeadCheck/GeneralUNILOCKService`,
    `${API_URL}/api/HeadCheck/EnergyTrackService`,
    `${API_URL}/api/HeadCheck/FerofenPCBMQTTService`,
    `${API_URL}/api/HeadCheck/GeneralPCBMQTTService`,
  ]);
  const [statuses, setStatuses] = useState(Array(url.length).fill("#BFBFBF"));
  const [durum, setDurum] = useState(Array(url.length).fill("Loading..."));
  const [refreshDates, setRefreshDates] = useState(
    Array(url.length).fill(new Date())
  );
  const makeRequest = async (url, index) => {
    try {
      const res = await axios.get(url);
      setStatuses((prevStatuses) =>
        prevStatuses.map((status, i) => {
          return i === index
            ? res.data.status && res.data.status === "ok"
              ? "green"
              : "red"
            : status;
        })
      );
      setDurum((prevDurum) =>
        prevDurum.map((durum, i) => {
          return i === index
            ? res.data.status && res.data.status === "ok"
              ? "Runing"
              : `Error ${res.status}`
            : durum;
        })
      );
      console.log(res.data)
    }  catch (err) {
      setStatuses((prevStatuses) =>
      prevStatuses.map((status, i) => {
      return i === index ? err.response.status ? "red" : "res": status;
      })
      );
      setDurum((prevDurum) =>
      prevDurum.map((durum, i) => {
      return i === index ? err.response.status ? `Error (${err.response.status})` : ' Error ': durum;
      })
      );
      console.log(err);
      }
     };
  const reload = () => {
    window.location.reload();
  };
  const getRequest = () => {
    url.forEach((request, index) => makeRequest(request, index));
  };
  useEffect(() => {
    getRequest();
  }, []);
  const handleClickIcon = async (url, index) => {
    try {
      const res = await axios.get(`${url}`);
      setStatuses((prevStatuses) =>
        prevStatuses.map((status, i) => {
          return i === index
            ? res.data && res.data.status === "ok"
              ? "green"
              : "red"
            : status;
        })
      );
      const newRefreshDates = [...refreshDates];
      newRefreshDates[index] = new Date();
      setRefreshDates(newRefreshDates);
      setDurum((prevDurum) =>
        prevDurum.map((durum, i) => {
          return i === index
            ? res.data && res.data.status === "ok"
              ? "Runing"
              : "Error !!!"
            : durum;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };  
  return (
    <div className="container my-auto">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">HeadCheck Table</th>
            <th>Durum Yenilenme Tarihi</th>
            <th></th>
            <th>Durum</th>
            <th scope="col">
              <a href="#" style={{ color: "#BFBFBF" }}>
                <i onClick={() => reload()} className="icon-loop"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((status, index) => (
            <tr key={index}>
              <td>{name[index]}</td>
              <td>
                {refreshDates[index]
                  ? refreshDates[index].toLocaleString()
                  : ""}
              </td>

              <td>
                <button
                  style={{
                    backgroundColor: status,
                    height: "15px",
                    width: "15px",
                    border: "0.3px",
                    borderRadius: "10px",
                  }}
                ></button>
              </td>
              <td>{durum[index]}</td>
              <td>
                {" "}
                <a href="#" style={{ color: "#BFBFBF" }}>
                  <i
                    onClick={() => {
                      handleClickIcon(url[index], index);
                    }}
                    className="icon-loop"
                  ></i>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HeadCheck;
