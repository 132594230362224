import React, { Suspense, useEffect, useRef } from 'react'
import { useTranslation, withTranslation } from "react-i18next";
import { DateRangePickerHandler } from "../../js/plugins/pickers/daterangepicker";
import intlTelInput from 'intl-tel-input';
import "intl-tel-input/build/css/intlTelInput.min.css";
import InputMask from "../../js/plugins/forms/inputs/inputmask";
import Division from "../../components/elements/content-division";
import Text from "../../components/elements/text";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MapIcon from '@mui/icons-material/Map';
import FemaleIcon from '@mui/icons-material/Female';
import PublicIcon from '@mui/icons-material/Public';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session'
import jsonCity from '../../class/il.json'
import jsonCounty from '../../class/ilce.json'
import Functions from '../../class/functions';
import jq from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.all";
import moment from "moment";
import { getEndPoint } from "../../class/api";
import Cookies from 'universal-cookie';
import TableData from "../../components/table.js";
const cookies = new Cookies();
let processStatus = false

const UserEdit = (props) => {
    const [userData, setUserData] = React.useState({});
    const [pageLoad, setPageLoad] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const LastConnect = useRef(null)
    const mermberTypeRef = useRef(null) 
    var mermberType = useRef(null) 
    const birthDateRef = useRef(null)
    const { t } = useTranslation(['userEdit', 'translation'])
    const params = useParams(); 

    // tablo için gereken filtreler ve kolonlar
    const cyclistsFilter = [
        {
            ProcessStatusId: [1, 7],
            MemberId: userData.MemberId,
        }
    ]
    const cyclistsColumns = [

        {
            title: t("ID"),
            data: "MemberTripId"
        },

        {
            title: t("table.usage.vehicleNo"),
            data: "vehicle.VehicleNo", render: function (data) {
                return (`<a id="vehicle_no" class="btn badge badge-info" >${data} </a>`)
            }, orderable: false
        },
        {
            title: t("table.usage.exitStation"),
            data: "StationParkUnitEntryId", render: function (data, type, row) {
                if (data == null) {
                    return " "
                }
                if (row.station_park_unit_entry == null) {
                    return " "
                }
                return `<a href="${Functions.RoleControl(17, 0) == true ? `/stations/list/${row.station_park_unit_entry?.station?.StationId}` : `#`} ">${row.station_park_unit_entry?.station?.Name} (${row.station_park_unit_entry?.ParkOrder}) </a>`
            }, orderable: false
        },
        {
            title: t("table.usage.exitStationHours"),


            data: "EntryDate", render: function (data, type, row) {
                if (data == null) {
                    return " "
                }

                return Functions.getFormatLocaleDateHours(data)
            }
        },
        {
            title: t("table.usage.entryStation"),
            data: "StationParkUnitExitId", render: function (data, type, row) {
                if (data == null) {
                    return " "
                }
                if (row.station_park_unit_exit == null) {
                    return " "
                }
                return `<a href="/stations/list/${row.station_park_unit_exit?.station?.StationId}">${row.station_park_unit_exit?.station?.Name} (${row.station_park_unit_exit?.ParkOrder}) </a>`
            }, orderable: false
        },
        {
            title: t("table.usage.entryStationHours"),
            data: "ExitDate", render: function (data, type, row) {
                if (data == null) {
                    return " "
                }
                return Functions.getFormatLocaleDateHours(data)
            }
        },
        {
            title: t("table.usage.totalPrice"),

            data: "Fee", render: function (data, type, row) {
                var price = parseFloat(row.Fee).toFixed(2)
                return `<a class="btn badge badge-info priceEditBtn" id="priceEditBtn">${price}  ${row.Currency}</a>`
            }
        },
        {
            title: t("table.usage.processDate"),
            data: "EntryDate", render: function (data, type, row) {
                if (data == "") {
                    return " "
                }
                return Functions.getFormatLocaleDateHours(data)
            }
        }
    ]

    const creditFilters = [
        {
            MemberId: userData.MemberId,
        }
    ]
    const creditColumns = [

        {
            title: t("table.finance.id")
            , data: "MemberId"
        },
        {
            title: t("table.finance.userNameSurname"),
            data: 'member',
            render: function (data, type, row) {

                return Functions.MaskCharacter(`${data.user.Name} ${data.user.Surname}`, '*', 2)
            }, orderable: false
        },
        {
            title: t("table.finance.TransactingUser"),
            data: null, render: function (data, type, row) {
                return row.transacting_user.Name + ' ' + row.transacting_user.Surname
            }, orderable: false
        },
        {
            title: t("table.finance.BalanceAdd"),
            data: "Fee", render: function (data, type, row) {
                var price = parseFloat(data).toFixed(2)
                if (row.ProvisionTypeId === 4 && price !== "0.00") {
                    return `-${price}`
                }
                else {
                    return price
                }
            }
        },

        {
            title: t("table.finance.price"),
            data: "Balance", render: function (data, type, row) {
                var price = parseFloat(data).toFixed(2)

                return `${price}`
            }
        },
        { title: t("table.finance.Description"), data: "Description" },
        {
            title: t("table.finance.date"),
            data: "created_at", render: function (data, type, row) {
                return moment(data).format('DD/MM/YYYY HH:mm')
            }
        }
    ]


    const notFilters = [
        {
            MemberId: userData.MemberId,
            //filtreleme yaapcağımız alan yüksek ihtimalle member id ile filtreleme yapılır bu şekilde kalabilir kod
        }
    ]
    const notColumns = [

        {
            title: t("table.finance.id")//kolonun başlığı
            , data: "MemberNoteId"//gelen verinin içerisinde hangi veriyi ekrana basacağımızı belirttiğimiz alan
        },
        {
            title: t("table.finance.userNameSurname"),
            data: 'member',
            render: function (data, type, row) {
                //render fonksiyonu ile kolonun içerisindeki veriyi istediğimiz şekilde değiştirebiliriz
                return Functions.MaskCharacter(`${data.user.Name} ${data.user.Surname}`, '*', 2)
            }, orderable: false
        },
        {
            title: t("table.finance.TransactingUser"),
            data: null, render: function (data, type, row) {
                return row.transacting_user.Name + ' ' + row.transacting_user.Surname
            }, orderable: false
        },
        {
            title: t("Açıklama"),
            data: "Note" 
        }, 
        {
            title: t("table.finance.date"),
            data: "created_at", render: function (data, type, row) {
                return moment(data).format('DD/MM/YYYY HH:mm')
            }
        }
    ]


    const selectLanguage = {
        noResults: () => t("select2Localization.noResults", { ns: "translations" }),
        searching: () => t("select2Localization.searching", { ns: "translations" }),
        maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
        loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
        inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
        inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
        errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
    };
    // tablo için gereken filtreler ve kolonlar



    // Fonksiyonlar
    useEffect(() => {






        var phoneInputID = "#user-phone";
        var input = document.querySelector("#user-phone");
        var iti = intlTelInput(input,
            {
                autoPlaceholder: "polite",
                geoIpLookup: (success, failure) => {
                    //success(countryCode)
                },
                preferredCountries: ["tr", "us", "gb"],
                seperateDialCode: true,
                utilsScript: require("intl-tel-input/build/js/utils"),
                customContainer: "w-100",
                formatOnDisplay: true,
            }
        )
        $(phoneInputID).on("countrychange", function (event) {
            var selectedCountryData = iti.getSelectedCountryData();
            var newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL)
            var mask = newPlaceholder.replace(/[1-9]/g, "0");
            $(this).mask(mask);
        });

        iti.promise.then(function () {
            $(phoneInputID).trigger("countrychange");
        });


        $(mermberTypeRef.current).empty();

        var memberTypesData = ReactSession.get('memberTypes');
        memberTypesData.forEach(memberElm => {

            var newOption = new Option(
                memberElm.Name,
                memberElm.MemberTypeId,
            );
            $(mermberTypeRef.current).append(newOption).trigger('change');
        });
        $('.infoSelect').select2({
            language: selectLanguage
        });

        $('.dateHomeCity').select2({
            language: selectLanguage,
            data: jsonCity
        });

        var data = JSON.stringify({
            "Filter": {
                "id": params.userId,
            }
        });
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: data,
            withCredentials: true,
        };

        axios(config, { withCredentials: true })
            .then(function (response) {
                var resp = response.data.data[0]
                var maskName = Functions.MaskCharacter(`${resp.Name} ${resp.Surname}`, '*', 2)
                maskName = maskName.split(' ')


                setUserData(response.data.data[0])

                DateRangePickerHandler(LastConnect.current, {
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    singleDatePicker: true,
                    locale: t("dateRangePickerLocalization", { ns: "translations" }),
                    "startDate": moment(resp.LastConnect, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    "autoApply": true,
                    ranges: {
                        'Bugün': [moment(), moment()],
                    },
                });
                DateRangePickerHandler(birthDateRef.current, {
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    singleDatePicker: true,
                    locale: t("dateRangePickerLocalization", { ns: "translations" }),
                    "startDate": moment(resp.BirthDate).format("DD/MM/YYYY"),
                    "autoApply": true,
                    ranges: {
                        'Bugün': [moment(), moment()],
                    },
                });
                var memberTypeFilter = memberTypesData.filter(x => x.Name == response.data.data[0].MemberType)
                   

                $(mermberTypeRef.current).val(String(memberTypeFilter[0].MemberTypeId)).trigger('change');
                $('#RegistrationPlaceId').val(response.data.data[0].RegistrationPlaceId).trigger('change');
                $('#Status').val(resp.Status == true ? "true" : "false");
                $("#Gender").val(resp.Gender);
                $("#ad").val(ReactSession.get('user').id != 159 ? resp.Name : maskName[0]);
                var masks = maskName.splice(1, maskName.length);
                $("#Surname").val(ReactSession.get('user').id != 159 ? resp.Surname : String(masks).replace(',', ' '));
                $("#Email").val(Functions.MaskCharacter(`${resp.Email}`, '*', 1, 3));
                $("#TC").val(Functions.MaskCharacter(`${resp.TcNumber}`, '*', 1, 4));
                $("#AddressHome").val(Functions.MaskCharacter(`${resp.AddressHome}`, '*', 8, 6));
                $('#Tourist').val(resp.Tourist == true ? "true" : "false");
                $('#Contract').val(String(resp.Contract));
                $('#VehicleCountMax').val(String(resp.VehicleCountMax));
                $('#VehicleCountActive').val(String(resp.VehicleCountActive));
                iti.setNumber(Functions.MaskCharacter(`${resp.PhoneNoCountryCode}`, '*', 4));
                $('#EducationStatus').val(resp.EducationStatus == true ? "true" : "false");
                $('#MaritalStatus').val(resp.MaritalStatus == true ? "true" : "false");
                $('#RegisterDate').val(moment(resp.RegisterDate).format('DD/MM/YYYY HH:SS'));   


                setPageLoad(true);
            })
    }, []);
    // kullanıcı bilgisinin gelip Sayfa Yüklendikten sonra çalışan kodlar
    useEffect(() => {
        $("#AddressHomeCityId").val(userData.AddressHomeCityId).trigger('change');
        $('#AddressHomeCityId').on('change', function () {
            $('#AddressHomeCountyId').empty();
            $('#AddressHomeCountyId').select2({
                language: selectLanguage,
                data: jsonCounty.filter(x => x.il_id == $('#AddressHomeCityId').val())
            });

        });
        $("#AddressHomeCountyId").val(userData.AddressHomeCountyId).trigger('change');

        jq('#cyclists-table tbody').on('click', 'td #vehicle_no', function () {
            var tr = jq(this).closest('tr');
            var row = tables[0].row(tr);
            var data = row.data()
            ReactSession.set('bikeSearchData', data.vehicle.VehicleNo);
            window.location.href = "/bicycles/bicycle-list";

        });
        $('.userID').val(`${userData.Name} ${userData.Surname}`)
        jq('#cyclists-table tbody').on('click', 'td #priceEditBtn', function () {
            if (Functions.RoleControl(13, 2) == false)
                return
            var tr = jq(this).closest('tr');
            var row = tables[0].row(tr);
            var data = row.data()
            ReactSession.set('usagePriceEdit', data.MemberTripId)
            window.location.href = "/users/bicycle-usage-time-price-edit"
        });

    }, [pageLoad]);
    // kullanıcı bilgisinin gelip Sayfa Yüklendikten sonra çalışan kodlar

    const printClick = (param) => {
        var data = "";
        var RegistrationPlace = jsonCity.filter(x => x.id == parseInt($("#RegistrationPlaceId").val()))[0]


        if (param == "tr") {
            data = `<div id="aboneform"><table align="center" border="1" cellspacing="0" width="100%">
            <tbody>
                <tr>
                    <td align="center" colspan="2"><strong>AKILLI BİSİKLET ABONE BİLGİ FORMU</strong></td>
                    <td align="center"><span style="text-align: right;">KOBIS.18</span></td>
                </tr>
                <tr>
                    <td><strong>*TC Kimlik No</strong></td>
                    <td>${userData.TcNumber}</td>
                </tr>
                <tr>
                    <td><strong>*Adı - Soyadı</strong></td>
                    <td>${userData.Name} ${userData.Surname}</td>
                </tr>
                <tr>
                    <td><strong>*İkamet Adresi</strong></td>
                    <td>${userData.AddressHome} </td>
                </tr>
                <tr>
                    <td><strong>İş Adresi</strong></td>
                    <td>${userData.AddressWork}</td>
                </tr>
                <tr>
                    <td><strong>Ev Telefonu&nbsp;&nbsp;&nbsp; </strong></td>
                    <td></td>
                </tr>
                <tr>
                    <td><strong>İş Telefonu</strong></td>
                    <td></td>
                </tr>
                <tr>
                    <td><strong>*GSM&nbsp; </strong></td>
                    <td>${userData.PhoneNoCountryCode}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>E – Mail</strong></td>
                    <td>${userData.Email}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>Doğum &nbsp;Yeri ve Tarihi</strong></td>
                    <td> ${typeof RegistrationPlace == 'undefined' ? "" : RegistrationPlace.adi} / ${Functions.getFormatLocaleDate(userData.BirthDate)}</td >
            <td>&nbsp;</td>
                </tr >
            <tr>
                <td align="right" colspan="3">
                    <table border="0" width="100%">
                        <tbody>
                            <tr>
                                <td width="63%">
                                    <p>İş bu sözleşme ABONE tarafından doldurulmuş, iki nüsha düzenlenerek ve kayıtlar gizli tutulması şartıyla kayıt altına alınmıştır. Onay kaydı verilmeyen bilgi formları kabul edilmemiştir.<br>
                                        Aşağıdaki ekler ile teslim edilmeyen başvuru formları geçersizdir.<br>
                                            1 Adet Fotoğraf ,Nüfus Cüzdanı , Ehliyet-Pasaport-Evlilik Cüzdanı Vs. herhangi birinden 1 adet fotokopi,<br>
                                                ABONE Sözleşmesi.<br>
                                                    *Doldurulması zorunludur. &nbsp;**Her sayfa paraflanacaktır.</p>
                                            </td>
                                            <td width="37%">Yukarıda vermiş olduğum tüm bilgilerin doğru olduğunu beyan ederim.<br>
                                                <br>
                                                    <strong>Tarih :</strong> ....../...../..........<br>
                                                        <br>
                                                            <strong>İmza :........................</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" height="1148">
                                                <p align="center"><strong>Bireysel&nbsp; Taşıma Hizmeti Akıllı Bisiklet Abonelik&nbsp; Genel Koşulları</strong></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 1 -SÖZLEŞMENİN TARAFLARI</strong></span></p>

                                                <p><span style="font-size: 10px;">İş bu sözleşme; <strong>Kocaeli Büyükşehir Belediyesi Ulaşım ve Trafik Yönetimi Dairesi Başkanlığı Toplu Taşıma Şube Müdürlüğü; Oramiral Salim Dervişoğlu Caddesi Kocaeli Fuarı&nbsp; İzmit / Kocaeli</strong> adresinde mukim <strong>(</strong>Bundan böyle kısaca "<strong>Kurum</strong>" olarak anılacaktır) ile &nbsp; adresinde mukim (Bundan böyle kısaca "<strong>Abone</strong>" olarak anılacaktır) arasında yapılmıştır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 2 -Amaç</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'lerin burada öngörülen koşullar altında, <strong>Kurum</strong> kentsel alanı içerisinde bisikletlerin, <strong>Abone</strong>'lerin sorumluluğu altında istasyonlardan alınıp, tekrar istasyona geri getirilmesi durumlarının açıklanmasıdır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>Madde 3- Abone Kaydı</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, &nbsp;abone merkezlerine başvurarak abone kaydı işlemlerini başlatacaktır. Abone kaydı işlemleri tamamlanınca <strong>Abone</strong>'lere bisikletleri kullanmak için Abone kartı verilecektir. &nbsp;Abone kartları kişiye özel olup bir başkasına kullandırılamaz. Abone kartının başkasına kullandırılması halinde kullandıran, meydana gelebilecek her türlü sorumluluğu üstlenmiş olur. Ayrıca Kocaeli Büyükşehir Belediyesine her hangi bir hak iddia edemez.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 4- Sözleşmenin Feshi</strong></span></p>

                                                <p><span style="font-size: 10px;">Kayıt silme işlemi; <strong>Abone</strong>'nin kendi isteği doğrultusunda ya da sözleşmeye aykırı davranış neticesinde, gerekçe göstermeden <strong>Kurum</strong> tarafından silinir ve sözleşmesi tek taraflı olarak fesih edilir. Kayıt silinmesi durumunda abonelik bedeli iade edilmeyecektir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 5- Akıllı Bisiklet Hizmet Sözleşmesi</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, kartını kullanarak <strong>Kurum</strong> ile olan sözleşme haklarına muktedir olacaktır. Her yeni bisiklet kullanımı kuralların kabul edildiği anlamına gelir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 6- Abone Kart Geçerlilik Süresi ve Vize işlemi</strong></span></p>

                                                <p><span style="font-size: 10px;">Abone kart; <strong>Kurum</strong> ile <strong>Abone</strong> arasında yapılan sözleşme tarihinden itibaren geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>Kurum</strong>'da indirimli tarife yoktur.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 7- Ücret Tarifesi ve Abonelik Bedeli</strong></span></p>

                                                <p><span style="font-size: 10px;">Ücret tarifesi ve abonelik bedeli, Kocaeli Büyükşehir Belediyesi Meclisi tarafından belirlenir ve bu tarifeye göre ücret toplama işlemi gerçekleştirilir. <strong>Abone</strong> ücret tarifesi ve abonelik bedelini ödemeyi kabul ve taahhüt eder.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 8- Akıllı Bisiklet Abone Yükümlülüğü</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'nin yükümlülüğü bisikleti sağlam olarak iade etmek ve Madde-10 hükümlerine riayet etmektir.</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 9- Akıllı bisiklet Abonelik Şartı</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong> yasal yaşta, trafik kurallarına aşina, ayrıca bisiklet sürme vasfına sahip olmalıdır. 18 yaş altı olanlar; kati suretle abone kartına sahip olma ve kullanma hakkına sahip değildirler.&nbsp;</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 10- Akıllı Bisiklet Abone Hakları</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Kurum</strong> ile <strong>Abone</strong> arasında yapılan sözleşme, burada belirtilen limitler dâhilinde bisiklet kullanım hakkı sağlar. Bisikletler kullanıma hazır olarak <strong>Abone</strong>'lere sunulacaktır. <strong>Abone</strong> alacağı hizmetin hükümlerini, şartlarını öğrenmek ya da çeşitli isteklerini iletebilmek için www.kobis.com.tr adresinden ve/veya ilan edilmiş telefon hatları üzerinden müşteri hizmetleri ile irtibata geçebilirler. <strong>Kurum</strong> olabilecek en kısa sürede soruları ve talepleri işleme koyacak ve cevaplayacaktır. Bu süre <strong>Abone</strong>'nin iletişime geçmesinden itibaren en fazla 15 iş günü alacaktır. Eğer <strong>Abone</strong>'nin aldığı bisiklet kullanmak için uygun durumda değilse <strong>Abone</strong> başka bir bisiklet alma hakkına sahiptir. Kullanım için uygun olmayan bisikleti <strong>Abone</strong> 5 dakika içinde iade edecek, sistem <strong>Abone</strong>'ye başka bir bisiklet verecektir.&nbsp;</span></p>

                                                <p><span style="font-size: 10px;"><strong>MADDE 11- Akıllı Bisiklet Abone Yükümlülükleri</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>'ler Akıllı bisiklet hizmetini kullanırken;</span></p>

                                                <ol style="list-style-type: lower-alpha;">
                                                    <li><span style="font-size: 10px;">Hizmeti ve bisikletleri azami özenle kullanmak, sisteme veya bisikletlere, 3. kişilere veya 3. kişilere ait malvarlığına hasar vermemek,</span></li>
                                                    <li><span style="font-size: 10px;">Herhangi bir durumda kendisine tahsis edilen bisikleti 3. şahıslara kullandırmamak, kullandırılması durumunda abonelik iptal edilecektir. 3. Şahıslara kullandırılmasından doğan zararlar <strong>Abone</strong>'den tahsil edilecektir.</span></li>
                                                    <li><span style="font-size: 10px;">Şehir bisikletlerini yalnızca kişisel seyahat için kullanmak,</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletleri sadece, bisiklet yolu olarak belirtilen, yol çizgileri ile belirlenmiş alanlarda kullanmak. Bu alan dışından kullanımda doğabilecek olumsuzluklardan <strong>Kurum</strong> mesul tutulamaz.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletin etkilendiği herhangi bir arıza ya da kazada, yer ve durum bilgilerini gecikmeksizin <strong>Kurum</strong> Müşteri Hizmetlerine bildirmek. Eğer bisiklet arızadan dolayı istasyona dönemezse, <strong>Abone</strong>'nin arızayı rapor etmesinden sonra bisikletin 2 saat süresince güvende kalmasının sorumluluğu <strong>Abone</strong>'ye ait olacaktır. Bu süre zarfında <strong>Kurum</strong> belirtilen noktadan bisikleti teslim alacaktır.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong>, bisikletin istasyondan alıp geri bırakana kadar geçen sürede, bisikletlerin doğru şekilde korunması, bakılması ve güvende olmasından sorumludur. Kullanımdan sonra bisikletler istasyonlara geri getirilir ve kilitlendiğinden emin olarak ilgili yerlere bırakılır.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletler kullanılmak üzere alınırken, frenlerinin, oynar parçalarının kontrol edilmesi, koltuk yüksekliğinin ayarlanması ve son olarak da genel kullanıma uygunluğuna bakılması <strong>Abone</strong>'nin sorumluluğundadır.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong> bisikleti Türkiye Cumhuriyeti Kanunlarınca suç sayılabilecek şekilde ve diğer gayri kanuni işlerde ve yasak derecede alkol ve herhangi bir uyuşturucu alarak kullanamaz. Bisiklete zarar veremez. Bisiklete, bisiklet istasyonuna veya kamuya ait diğer eşyalara zarar verilmesi ve zararın tazmin edilmemesi durumunda Türkiye Cumhuriyeti Kanunlarının ilgili maddeleri uyarınca suç duyurusunda bulunulur.</span></li>
                                                    <li><span style="font-size: 10px;">Bisikletin <strong>Abone</strong>'de olduğu süre içerisinde (ileride çıkacak da olsa) gerek bisiklete, gerek <strong>Abone</strong>'ye ve gerekse 3. şahıslara verilen zararlardan dolayı tüm maddi ve manevi tazminat sorumlulukları ve cezai sorumluluklar <strong>Abone</strong>'ye aittir.&nbsp;Abone bisiklet kullanılması esnasında meydana gelebilecek zararları tazmin etmeyi kabul ve taahhüt eder. Aksi durumda Kocaeli Büyükşehir Belediyesine karşı talep edilecek tazminatları, açılacak dava veya icra takiplerinden dolayı Kocaeli Büyükşehir Belediyesinin rücu hakkı her zaman saklıdır.</span></li>
                                                    <li><span style="font-size: 10px;">Bisiklet kullanımından doğan trafik cezaları <strong>Abone</strong>'ye aittir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Kurum</strong> bisikleti tüm fonksiyonları çalışır vaziyette <strong>Abone</strong>'ye teslim etmiştir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Abone</strong> kullanıma başladıktan itibaren bisikletin en iyi şekilde korunması için gereken tüm önlemleri alacak; kötü ve kurallara uygun olmayan kullanımlardan dolayı bisiklette meydana gelebilecek her türlü arıza ve onarım giderleri <strong>Abone</strong>'ye ait olacaktır.</span></li>
                                                    <li><span style="font-size:10px;"><strong>Abone</strong>, tek üyelik ile en fazla 3 (üç) bisiklet kiralayabilir. Bisikleti istasyondan alabilmesi için abone kartında en az 5 (beş) TL bakiye bulundurması gerekmektedir. Her bisiklet için 5 (beş) TL, toplamda 3 bisiklet için 15 (onbeş) TL bakiyesi bulunması gerekmektedir.</span></li>
                                                    <li><span style="font-size: 10px;">Kullanım ücretleri; <strong>Kurum</strong> tarafından belirlenmiş abone kart dolum istasyonlarından nakit olarak yapılacaktır.</span></li>
                                                    <li><span style="font-size: 10px;">Bildirilen tarihe kadar tahakkuk ettirilen cezaları ödemeyen <strong>Abone</strong>'lerin abone kartları iptal edilecek ve yasal takibat başlatılacaktır. Yasal tahsilât sürecinde tüm masraflar <strong>Abone</strong>'ye rücu ettirilecektir.</span></li>
                                                    <li><span style="font-size: 10px;">Kartı iyi kullanma ve saklama sorumluluğu <strong>Abone</strong>'lere aittir. Kartın kaybolması, kötü kullanım neticesinde zarar görmesi nedeniyle başka bir kopyasının istenmesi durumunda kartın idarece belirlenen yeni basımının masrafı 10 (On) TL &nbsp;<strong>Abone</strong>'ye ait olacaktır.</span></li>
                                                    <li><span style="font-size: 10px;">Kask ve benzeri koruma malzemeleri temini <strong>Abone</strong>'nin sorumluluğundadır.</span></li>
                                                    <li><span style="font-size: 10px;">Kiralama zamanını takip eden 24 (yirmidört) saat süre zarfında bisikletin teslimi gerçekleşmez ise sistem çalıntı durumuna düşecektir.</span></li>
                                                    <li><span style="font-size: 10px;"><strong>Kurum</strong> Akıllı bisiklet Abone Yükümlülükleri şartlarında değişiklik yapma hakkını saklı tutar.</span></li>
                                                </ol>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 12- Gizlilik</strong></span></p>

                                                <p><span style="font-size: 10px;">Taraflar; sahip oldukları bilgileri (kişisel/kurumsal) gizli tutmakla sorumludur. Elde edilen bilgi ve belgeler kesinlikle 3. Şahıslarla paylaşılmayacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 13- Tebligat Adresi</strong></span></p>

                                                <p><span style="font-size: 10px;">Tarafların bu sözleşmede yazılı adreslerine yapılacak tebligatlar geçerlidir. Bu adreslerde bir değişiklik olması halinde adres değişikliği yapan taraf diğer tarafa yeni adresini yazılı olarak bildirmekle yükümlüdür. Bunun yapılmaması halinde eski adrese yapılacak tebligatlar kanunen geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 14- Mücbir Sebepler</strong></span></p>

                                                <p><span style="font-size: 10px;">Yasalarda belirtilmiş olan mücbir sebepler dâhilinde yine yasalarda belirtilmiş olan hükümler geçerlidir.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 15- Uygulanacak Hükümler</strong></span></p>

                                                <p><span style="font-size: 10px;">Bu sözleşme ile ilgili olarak çıkabilecek bütün ihtilaflarda öncelikle sözleşme hükümleri, bu sözleşmede bulunmayan hükümlerde ise TC kanunları uygulanacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 16- Yetkili Mahkeme ve İcra Daireleri</strong></span></p>

                                                <p><span style="font-size: 10px;">İş bu sözleşmeden dolayı doğacak bütün ihtilaflarda Kocaeli Mahkemeleri ve İcra daireleri yetkili olacaktır.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;<strong>MADDE 17- Yürürlük</strong></span></p>

                                                <p><span style="font-size: 10px;"><strong>Abone</strong>, Üyelik Sözleşmesi ve Başvuru Formu' nu eksiksiz doldurup imzaladıktan sonra müracaat etmiş olur. <strong>Kurum</strong> tarafından müracaat onaylandıktan sonra yürürlüğe girer.</span></p>

                                                <p><span style="font-size: 10px;">&nbsp;İş bu sözleşme 4 sayfa ve 17 Maddeden ibaret olup, iki nüsha olarak tanzim edilmiş, ekleri ile birlikte taraflarca okunarak&nbsp; &nbsp;…&nbsp; /&nbsp; …&nbsp; / 20… &nbsp;&nbsp;tarihinde karşılıklı imza altına alınmıştır.</span></p>

                                                <p><span style="line-height: 1.6; font-size: 10px;">.</span></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>

                                    <p>&nbsp;</p>

                                    <table border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" height="51" width="50%">
                                                    <p><strong>Kocaeli Büyükşehir Belediyesi</strong></p>

                                                    <p><strong>Ulaşım Dairesi Başkanlığı </strong></p>

                                                    <p><strong>Trafik Yönetimi Şube Müdürlüğü;&nbsp;</strong></p>

                                                    <p>ADSOYAD - İMZA</p>

                                                    <p>&nbsp;</p>
                                                </td>
                                                <td align="center" width="50%">
                                                    <p>${userData.Name} ${userData.Surname}<br>
                                                        ADSOYAD - İMZA</p>

                                                    <p>&nbsp;</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>&nbsp;</p>
                                </div>`;

        }
        else {
            data = `<div id="aboneform_en"><p>Individual Carriage Service Smart Bicycle Subscription General Conditions</p>

<p>ARTICLE 1 - THE PARTIES OF THE AGREEMENT</p>

<p>Business this contract; Kocaeli Metropolitan Municipality Department of Transportation and Traffic Management Department of Public Transportation; Admiral Salim Dervişoğlu Caddesi Kocaeli Fair was held between İzmit / Kocaeli address (hereinafter referred to as "Institution)) and  address (hereinafter referred to as Abone Subscriber)).</p>

<p>&nbsp;ARTICLE 2 - Purpose</p>

<p>It is the disclosure of cases where the subscribers are taken from the stations under the responsibility of the Subscribers and brought back to the station in the urban area of ??the Authority under the conditions stipulated herein.</p>

<p>&nbsp;Article 3- Subscriber Registration</p>

<p>The subscriber shall apply to subscriber centers and initiate subscriber registration. Subscriber card will be given to Subscribers to use the bicycles when the Subscriber registration process is completed. Subscriber cards are personal and cannot be used by anyone else. In case the subscriber card is used to another person, the user assumes all kinds of responsibilities that may occur. In addition, Kocaeli Metropolitan Municipality can not claim any rights.</p>

<p>&nbsp;ARTICLE 4- Termination of Contract</p>

<p>Registration deletion; At the request of the Subscriber, or as a result of the behavior contrary to the contract, it is deleted by the Authority without any justification and the contract is terminated unilaterally. If the registration is deleted, the subscription fee will not be refunded.</p>

<p>ARTICLE 5- Smart Bicycle Service Contract</p>

<p>Subscriber shall be able to use the card to contract rights with the Authority. The use of each new bicycle means that the rules are accepted.</p>

<p>ARTICLE 6- Subscriber Card Validity Period and Visa</p>

<p>Subscriber card; Valid from the date of the contract between the Authority and the Subscriber.</p>

<p>There is no discounted tariff at the Authority.</p>

<p>ARTICLE 7- Fee Schedule and Subscription Fee</p>

<p>The fee tariff and subscription fee are determined by Kocaeli Metropolitan Municipality Assembly and fee collection process is performed according to this tariff. The subscriber agrees and undertakes to pay the tariff and subscription fee.</p>

<p>ARTICLE 8- Smart Bicycle Subscriber Obligation</p>

<p>The Subscriber's obligation is to return the bicycle in good standing and to comply with the provisions of Article 10.</p>

<p>ARTICLE 9- Smart Bicycle Subscription Requirement</p>

<p>The subscriber must be of legal age, familiar with traffic rules, and also be qualified to ride a bicycle. Those under the age of 18; they do not have the right to own and use the subscriber card.</p>

<p>ARTICLE 10- Smart Bicycle Subscriber Rights</p>

<p>The contract between the Authority and the Subscriber provides the right to use the bicycle within the limits specified herein. Bicycles will be available to the Subscribers ready for use. The subscriber may contact the customer service at www.kobis.com.tr and / or on the announced phone lines in order to learn the terms and conditions of the service to be received or to convey various requests. The Institution will process and respond to questions and requests as soon as possible. This period shall take a maximum of 15 working days from the date the Subscriber communicates. If the Subscriber has not been eligible to use the bicycle, the Subscriber shall be entitled to purchase another bicycle. The unsuitable bike will be returned to the Subscriber within 5 minutes and the system will give the Subscriber another bike.</p>

<p>ARTICLE 11- Smart Bicycle Subscriber Obligations</p>

<p>Subscribers When using the smart bike service;</p>

<p>a.&nbsp;To use the service and bicycles with utmost care, not to damage the system or the property of bicycles, third parties or third parties,</p>

<p>b.&nbsp;In any case, it is not allowed to use the bicycle assigned to third parties and in case it is used, the subscription will be canceled. 3. The damages arising from the disbursement to the persons shall be collected from the Subscriber.</p>

<p>c.&nbsp;Use city bikes only for personal travel,</p>

<p>d.&nbsp;Use bikes only in areas designated by road lines, designated as bicycle paths. The Authority shall not be held liable for any inconveniences that may arise in use outside this area.</p>

<p>e.&nbsp;To report the location and status information to the Customer Service without any delay in case of any breakdown or accident affected by the bicycle. If the bicycle cannot return to the station due to the malfunction, the Subscriber shall be responsible for keeping the bicycle safe for 2 hours after the Subscriber reports the malfunction. During this period, the Authority will take the bicycle from the specified point.</p>

<p>f.&nbsp;The subscriber is responsible for the correct protection, care and safety of the bicycles until the time the bike is picked up and released. After use, the bikes are returned to the stations and left to the relevant places, making sure they are locked.</p>

<p>g.&nbsp;It is the responsibility of the Subscriber to check the brakes, moving parts, adjust the seat height and finally check their suitability for general use when the bicycles are taken for use.</p>

<p>h.&nbsp;The subscriber shall not use the bicycle in a manner that may be considered a crime by the laws of the Republic of Turkey and in other illegal activities and by taking alcohol and drugs to a prohibited degree. He can't hurt the bike. In case of damage to a bicycle, bicycle station or other public property and no compensation for the damage, a criminal complaint shall be filed in accordance with the relevant articles of the laws of the Republic of Turkey.</p>

<p>i.&nbsp;All material and non-pecuniary damages and penal liability for damages to the bicycle, to the Subscriber and to third parties during the time the bicycle is in the Subscriber (even in the future) shall belong to the Subscriber. The subscriber agrees and undertakes to compensate for the damages that may occur during the use of bicycles. Otherwise, the right of recourse of Kocaeli Metropolitan Municipality shall always be reserved due to the claims to be filed against the Kocaeli Metropolitan Municipality, due to lawsuits or enforcement proceedings.</p>

<p>j.&nbsp;Traffic fines arising from the use of bicycles belong to the Subscriber.</p>

<p>k.&nbsp;The organization has delivered all functions of the bicycle to the Subscriber.</p>

<p>l.&nbsp;The subscriber shall take all necessary measures for the best protection of the bicycle from the start of use; any fault and repair expenses that may occur on the bicycle due to bad and improper use shall be borne by the Subscriber.</p>

<p>m.&nbsp;The subscriber can rent up to 3 (three) bicycles with a single membership. In order to take the bicycle from the station, the subscriber card must have a balance of at least 5 (five) TL. Each bicycle must have a balance of 5 (five) TL and a total of 15 (fifteen) TL for 3 bikes.</p>

<p>n.&nbsp;Usage fees; Subscriber card filling stations determined by the Authority will be made in cash.</p>

<p>o.&nbsp;Subscriber cards of the Subscribers who have not paid the accrued fines until the notified date will be canceled and legal proceedings will be initiated. All expenses will be recourse to the Subscriber during the legal collection process.</p>

<p>p.&nbsp;Subscriber is responsible for the good use and storage of the card. If another copy is requested due to loss of the card and damage due to misuse, the cost of the new edition of the card determined by the administration shall be 10 (Ten) TL Subscriber.</p>

<p>q.&nbsp;It is the responsibility of the Subscriber to provide helmets and similar protection materials.</p>

<p>r.&nbsp;If the delivery of the bicycle does not take place within 24 (twenty-four) hours following the rental period, the system will be stolen.</p>

<p>s.&nbsp;The Authority reserves the right to change the terms of the Smart Bicycle Subscriber Liability.</p>

<p>ARTICLE 12- Confidentiality</p>

<p>Sides; is responsible for keeping the information they have (personal / corporate) confidential. The information and documents obtained will never be shared with third parties.</p>

<p>ARTICLE 13- Notification Address</p>

<p>The notices to be made to the addresses of the parties written in this agreement are valid. In case of a change in these addresses, the party making the change is obliged to inform the other party in writing of its new address. If this is not done, the notifications to the old address are valid by law.</p>

<p>ARTICLE 14- Inevitable Reasons</p>

<p>For the purposes of force majeure specified in the law, the provisions of the law shall also apply.</p>

<p>ARTICLE 15- Applicable Provisions</p>

<p>In all disputes that may arise in connection with this agreement, the provisions of the agreement shall be applied firstly and the provisions of the Republic of Turkey shall be applied in the provisions not included in this agreement.</p>

<p>ARTICLE 16- Competent Courts and Enforcement Offices</p>

<p>Kocaeli Courts and Enforcement Offices shall be authorized in all disputes arising out of this agreement.</p>

<p>ARTICLE 17- Effectiveness</p>

<p>The subscriber has applied after completing and signing the Membership Agreement and Application Form. It comes into force after the application is approved by the institution.</p>

<p>This agreement consists of 4 pages and 17 articles and it has been prepared in two copies and read by the parties together with the annexes and has been mutually signed on… /… / 20….</p>
</div>`;
        }
        var printWin = window.open("", "", "menubar=0,location=0,height=700,width=850")
        printWin.document.open()
        printWin.document.write(data)
        printWin.print()
    }

    const handleSubmitControl = (e) => {
        e.preventDefault();

        var Profilepicture = userData.Profilepicture

        if ((Profilepicture == null) || (Profilepicture == "") || (Profilepicture == undefined)) {
            Profilepicture = "/img/user.jpeg"
        }


        const formData = new FormData(e.target);
        const formDataEdit = stringifyFormData(formData);

        var input = document.querySelector("#user-phone");
        var iti = window.intlTelInputGlobals.getInstance(input);
        var phoneNumbers = iti.getNumber()
        if ((phoneNumbers.search("90500") == -1) && (!iti.isValidNumber())) {

            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userPhone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (processStatus == "0") {
            var phoneReturn = phoneControl()
            phoneReturn
                .then((response) => {
                    if (!response) {

                        Swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.existsDataSubject", { ns: "translations", requiredData: t("userPhone") }),
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })
                        return

                    }
                })
        }

        if (formDataEdit.Name == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userName") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.Surname == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userSurname") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (formDataEdit.Email == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userEmail") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Balance == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userBalance") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if ((formDataEdit.Tourist === "false") && (formDataEdit.TcNumber.length != 11)) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userId") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if ((formDataEdit.Tourist === "true") && (formDataEdit.PassportNumber === "")) {

            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: "Pasaport Numarası" }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Gender == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("gender") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Contract == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("userAgreementStatus") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return

        }
        if (formDataEdit.Status === "false") {
            Swal.fire({
                title: 'Lütfen Üyenin Pasife Geçme Nedenini Giriniz.',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Kaydet',
                cancelButtonText: 'Vazgeç',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
                preConfirm: (result) => {
                    console.log(result);
                    if (!result) {
                        Swal.showValidationMessage('Lütfen Bir Açıklama Giriniz')
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log(result.value);
                    formDataEdit.AddressWork = result.value;
                    handleSubmitProcess(formDataEdit)
                }
            })

        } else {
            handleSubmitProcess(formDataEdit)

        }





    }
    const handleSubmitProcess = (formDataEdit) => {
        var input = document.querySelector("#user-phone");
        var iti = window.intlTelInputGlobals.getInstance(input);

        var Profilepicture = userData.Profilepicture

        if ((Profilepicture == null) || (Profilepicture == "") || (Profilepicture == undefined)) {
            Profilepicture = "/img/user.jpeg"
        }
        Swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {

                var data = JSON.stringify({
                    "id": parseInt(params.userId),
                    "Name": formDataEdit.Name,
                    "Surname": formDataEdit.Surname,
                    "Email": formDataEdit.Email,
                    "CountryCode": '+' + iti.selectedCountryData.dialCode,
                    "Phone": `${iti.getNumber()}`,
                    "PhoneNoCountryCode": Functions.phoneControl(iti.getNumber(), iti.selectedCountryData),
                    "Sifre": formDataEdit.Sifre == "" ? userData.Sifre : formDataEdit.Sifre,
                    "MemberType": parseInt(formDataEdit.MemberType),
                    "TimeZone": "UTC+3",
                    "Status": (formDataEdit.Status == "true"),
                    "Member": {
                        "Tourist": (formDataEdit.Tourist == "true"),
                        "Balance": parseFloat(userData.Balance),
                        "TcNumber": formDataEdit.TcNumber,
                        "PassportNumber": formDataEdit.PassportNumber,
                        "Profilepicture": Profilepicture,
                        "Ccsec": userData.Ccsec == "" ? "required1" : userData.Ccsec,
                        "Gender": formDataEdit.Gender,
                        "VehicleCountMax": parseInt(formDataEdit.VehicleCountMax),
                        "VehicleCountActive": parseInt(formDataEdit.VehicleCountActive),
                        "Contract": (formDataEdit.Contract == "true"),
                        "MaritalStatus": userData.MaritalStatus,
                        "EducationStatus": userData.EducationStatus,
                        "RegistrationPlaceId": parseInt($("#RegistrationPlaceId").val()),//Doğum Yeri
                        "LastConnect": Functions.getFormatDBDate(formDataEdit.LastConnect),//zorunlu alan 
                        "BirthDate": Functions.getFormatDBDate(formDataEdit.birthDate), //this.birthDateRef.current.value,
                        "AddressWork": formDataEdit.AddressWork,
                        "AddressHome": formDataEdit.AddressHome,
                        "AddressHomeCityId": parseInt(formDataEdit.AddressHomeCityId),
                        "AddressHomeCountyId": parseInt(formDataEdit.AddressHomeCountyId),
                    }
                });
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member',
                    headers: {
                        'X-XSRF-TOKEN': 'TESTxcrf',
                        "Accept": "application/json",
                        'Content-Type': 'application/json'
                    },
                    data: data,
                    withCredentials: true
                };

                axios(config).then(function (response) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: t("swal.success.successMessage", { ns: "translations" }),
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                    .catch(function (error) {
                        Swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.subject", { ns: "translations" }) + error.message,
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })

                    });
            } else if (result.isDenied) {
                Swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }
    const phoneControl = (e) => {
        var input = document.querySelector("#user-phone");
        var iti = window.intlTelInputGlobals.getInstance(input);
        var data = JSON.stringify({
            "Phone": `${iti.getNumber()}`
        });

        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/Member/Ask',
            headers: {
                'X-XSRF-TOKEN': 'TESTxcrf',
                "Accept": "application/json",
                'Content-Type': 'application/json'
            },
            data: data
        };
        const axiosReturn = axios(config)
            .then(response => {

                if (response.data.data.length == 0) {
                    processStatus = true
                    if (e !== undefined) {
                        Swal.fire({
                            title: t("swal.success.head", { ns: "translations" }),
                            text: t("swal.success.existsDataSubject", { ns: "translations" }),
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.success.icon", { ns: "translations" })
                        })
                    }
                    return true
                }
                else if (response.data.data[0].Phone == userData.Phone) {
                    processStatus = true
                    if (e !== undefined) {
                        $("#userForm").trigger('submit');
                    }
                    return true

                }
                else {
                    Swal.fire({
                        title: t("swal.error.head", { ns: "translations" }),
                        text: t("swal.error.existsDataSubject", { ns: "translations" }),
                        confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                        icon: t("swal.error.icon", { ns: "translations" })
                    })
                    return false
                }

            })
        return axiosReturn

    }
    const creditAddClick = () => {
        if (($('.balanceNumber').val() == "") || (parseFloat($('.balanceNumber').val()) <= 0)) {
            swal.fire({
                title: "Hata",
                text: "Eksi miktar giremezsiniz!",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var balance = $('.balanceNumber').val().replace(',', '.')
                var data = JSON.stringify({
                    "AddBalance": parseFloat(balance).toFixed(2),
                    "Description": $("#addCreditDescription").val(),
                    "PaymentMethodId": 5,
                    "UserId": userData.id,
                });
                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/Member/AddBalance',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        "Accept": "application/json",
                        'Content-Type': 'application/json',
                    },
                    data: data,
                    withCredentials: true
                };


                axios(config)
                    .then(function (response) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: t("swal.success.successMessage", { ns: "translations" }),
                            showConfirmButton: false,
                            timer: 1500
                        }).then(function () {
                            jq.fn.dataTable.tables({ api: true }).ajax.reload()
                        })
                    })
                    .catch(function (error) {
                        Functions.requestAxiosErrorFunction(error);
                    });

            } else if (result.isDenied) {
                swal.fire({
                    title: t("swal.cancelSwal.head", { ns: "translations" }),
                    text: t("swal.cancelSwal.subject", { ns: "translations" }),
                    confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                    icon: t("swal.cancelSwal.icon", { ns: "translations" })
                })
            }
        })
    }
    const convertManager = () => {
        var input = document.querySelector("#user-phone");
        var iti = window.intlTelInputGlobals.getInstance(input);
        iti.setNumber(`${userData.PhoneNoCountryCode}`);
        var phoneNumbers = `${iti.getNumber()}`;

        if ((phoneNumbers.search("90500") == -1) && (!iti.isValidNumber())) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.phone") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (userData.Name == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.name") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (userData.Surname == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.surname") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if (userData.Email == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.email") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('#managerConvertPassword').val() == "") {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("modal.password") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        if ($('.convertManagerPassword').val() != $('.convertManagerPasswordRepeat').val()) {
            Swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: "Şifreler eşleşmiyor",
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return
        }
        console.log($('.convertManagerPassword').val());
        var data = JSON.stringify({
            Name: userData.Name,
            Surname: userData.Surname,
            Email: userData.Email,
            CountryCode: '+' + iti.selectedCountryData.dialCode,
            Phone: `${iti.getNumber()}`,
            PhoneNoCountryCode: Functions.phoneControl(iti.getNumber(), iti.selectedCountryData),
            Sifre: $('.convertManagerPassword').val(),
            TimeZone: "UTC+3",
            RoleId: 1,
            Status: true
        });
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/Manager',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t("swal.success.successMessage", { ns: "translations" }),
                    showConfirmButton: false,
                    timer: 1500
                }).then(function () {
                    window.location.href = "/management/managers"
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    const NotEkle = () => {
        var data = JSON.stringify({ 
            "Note": $('#not_content').val(),
            "MemberId": userData.MemberId
        });
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/Member/Notes',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t("swal.success.successMessage", { ns: "translations" }),
                    showConfirmButton: false,
                    timer: 1500
                }).then(function () {
                    jq.fn.dataTable.tables({ api: true }).ajax.reload()
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }

    const BildirimGonder = () => {  
        var data = JSON.stringify({ 
            "NotificationType" : $('#notification-type').val() ,
            "Message": $('#notification-content').val(),
            // "UserId": $('#notification-user-id').val(),
            "UserId": userData.id, 
            "MemberId": userData.MemberId,
            "UserPhone":  $('#notification-phone').val(),
        });
        var config = {
            method: 'POST',
            url: getEndPoint() + '/api/Admin/Member/Notification',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                "Accept": "application/json",
                'Content-Type': 'application/json',
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t("swal.success.successMessage", { ns: "translations" }),
                    showConfirmButton: false,
                    timer: 1500
                }).then(function () {
                    jq.fn.dataTable.tables({ api: true }).ajax.reload()
                })
            })
            .catch(function (error) {
                Functions.requestAxiosErrorFunction(error);
            });
    }
    // Fonksiyonlar

    return (

        <Division className="user-page p-2">
            <Division className="card">
                <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                    <h6 className="card-title">{t("menu.user.userEdit", { ns: "translations" })}</h6>
                    <div className="header-elements">
                        <div className="list-icons">
                            <a onClick={() => { jq.fn.dataTable.tables({ api: true }).ajax.reload() }} className="list-icons-item" data-action="reload"></a>
                        </div>
                    </div>
                </Division>
                <form id="userForm" onSubmit={handleSubmitControl}>
                    <Division className="card-body py-2">
                        <Division className="d-lg-flex">
                            <ul className="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
                                <li className="nav-item"><a href="#uye-bilgileri" className="nav-link active" data-toggle="tab">{t("userInformation")}</a></li>
                                <li className="nav-item"><a href="#kimlik" className="nav-link" data-toggle="tab">{t("identification")}</a></li>
                                <li className="nav-item"><a href="#iletisim" className="nav-link" data-toggle="tab">{t("contactInformation")}</a></li>
                                <li className="nav-item"><a href="#kullanim" className="nav-link" data-toggle="tab">{t("bikeHistory")}</a></li>
                                <li className="nav-item"><a href="#bakiye-gecmisi" className="nav-link" data-toggle="tab">{t("creditHistory")}</a></li>
                                <li className="nav-item"><a href="#not-gecmisi" className="nav-link" data-toggle="tab">{t("Kullanıcı Notları")}</a></li>
                                <li className="nav-item"><div className=' nav-link'> <a data-toggle="modal" data-target="#credit_add_modal" className="btn btn-warning">{t("Kredi Ekleme")}</a></div></li>
                            </ul>
                            <Division className="tab-content flex-lg-fill p-2">
                                <Division className="tab-pane fade show active" id="uye-bilgileri">

                                    <Division className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2">{t("registerId")}</Text>
                                        <Division className="col-lg-4">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input id="id" name="id" type="text" disabled className="form-control form-control-lg" defaultValue={userData.id} />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-seven-segment-9"></i>
                                                </Division>
                                            </Division>
                                        </Division>

                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userEmail")}</Text>
                                        <Division className="col-lg-4 align-self-center">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input defaultValue={userData.Email} id="Email" name="Email" type="text" className="form-control form-control-lg" placeholder="example@example.com" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-envelope"></i>
                                                </Division>
                                            </Division>
                                        </Division>
                                    </Division>

                                    <Division className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2">{t("userName")}</Text>
                                        <Division className="col-lg-4">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input id="ad" autoComplete='false' name="Name" type="text" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-user"></i>
                                                </Division>
                                            </Division>
                                        </Division>
                                        <Text Tag="label" className="col-form-label col-lg-2">{t("userSurname")}</Text>
                                        <Division className="col-lg-4">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input type="text" autoComplete='false' id="Surname" name="Surname" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-user"></i>
                                                </Division>
                                            </Division>
                                        </Division>
                                    </Division>



                                    <Division className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("webAccessPassword")}</Text>
                                        <Division className="col-lg-4 align-self-center">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input name="Sifre" autoComplete='false' type="password" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-eye"></i>
                                                </Division>
                                            </Division>
                                        </Division>
                                        <Text Tag="label" className="col-form-label col-lg-2">{t("userBalance")}</Text>
                                        <Division className="col-lg-4">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input disabled name="Balance" defaultValue={userData.Balance} type="text" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-credit-card"></i>
                                                </Division>
                                            </Division>
                                        </Division>
                                    </Division>


                                    <Division style={{ display: Functions.RoleControl(11, 1) == false ? "none" : "" }} className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("bicycleMaxCount")}</Text>
                                        <Division className="col-lg-4 align-self-center">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input name="VehicleCountMax" id="VehicleCountMax" type="text" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <PedalBikeIcon />
                                                </Division>
                                            </Division>
                                        </Division>

                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("Kayıt Tarihi")}</Text>

                                        <Division className="col-lg-4 align-self-center">
                                            {userData.RegisterDate !== null ? ""

                                                : <div className="alert alert-danger alert-styled-right alert-dismissible">
                                                    <button type="button" className="close" data-dismiss="alert">
                                                        <span>×</span>
                                                    </button>
                                                    <span className="font-weight-semibold">
                                                        <a href="#" className="alert-link">Kayıt Tarihi Bulunamadı!</a>
                                                    </span>
                                                </div>
                                            }
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input name="RegisterDate" id="RegisterDate" disabled readOnly type="text" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <PedalBikeIcon />
                                                </Division>
                                            </Division>
                                        </Division>
                                    </Division>


                                    <Division className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("Son Erişim Tarihi")}</Text>
                                        <Division className="col-lg-4 align-self-center">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input name="LastConnect" id="LastConnect" ref={LastConnect} type="text" defaultValue={userData.LastConnect} className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <i className="icon-calendar"></i>
                                                </Division>
                                            </Division>
                                        </Division>

                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("bicycleCount")}</Text>
                                        <Division className="col-lg-4 align-self-center">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input readOnly disabled name="VehicleCountActive" defaultValue={userData.VehicleCountActive} type="text" className="form-control form-control-lg" />
                                                <Division className="form-control-feedback form-control-feedback-lg">
                                                    <PedalBikeIcon />
                                                </Division>
                                            </Division>
                                        </Division>



                                    </Division>


                                    <Division className="form-group row">
                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userPhone")}</Text>
                                        <Division className="col-lg-7">
                                            <Division className="form-group form-group-feedback form-group-feedback-left">
                                                <input type="tel" autoComplete='false' name="Phone" id="user-phone" className="form-control form-control-lg"
                                                    defaultValue={userData.Phone} />

                                            </Division>
                                        </Division>
                                        <Division className="col-lg-3">
                                            <button type="submit" className="btn btn-primary w-100 h-100">{t("updateUserPhone")}</button>
                                        </Division>
                                    </Division>


                                    <Division className="form-group row">
                                        <Text style={{ display: Functions.RoleControl(11, 1) == false ? "none" : "" }} Tag="label" className="col-form-label col-lg-2 align-self-center">{t("accountStatus")}</Text>

                                        <Division style={{ display: Functions.RoleControl(11, 1) == false ? "none" : "" }} className="col-lg-4">
                                            <select name="Status" id="Status" className=" form-control w-100" >
                                                <option value="-1" disabled>{t("accountStatus")}</option>
                                                <option value="true">{t("accountStatusActive")}</option>
                                                <option value="false">{t("accountStatusDeactive")}</option>
                                            </select>
                                        </Division>
                                        <Text Tag="label" className="col-form-label col-lg-2">{t("userAccountType")}</Text>

                                        <Division className="col-lg-4">

                                            <select disabled name="MemberType" ref={mermberTypeRef} id="MemberType" className=" form-control w-100" >
                                                <option value="-1" disabled>{t("userAccountType")}</option>
                                            </select>
                                        </Division>

                                    </Division>

                                    <Division style={{ display: Functions.RoleControl(11, 1) == false ? "none" : "" }} className="form-group row">

                                        <Text Tag="label" className="col-form-label col-lg-2 align-self-center">{t("userAgreementStatus")}</Text>

                                        <Division className="col-lg-10">

                                            <select name="Contract" id="Contract" className=" form-control w-100" >
                                                <option value="-1" disabled>{t("userAgreementStatus")}</option>
                                                <option value="true">{t("userAgreementStatusActive")}</option>
                                                <option value="false">{t("userAgreementStatusDeactive")}</option>
                                            </select>
                                        </Division>
                                    </Division>



                                    <Division className="form-group row">


                                        <Division style={{ display: Functions.RoleControl(11, 1) == false ? "none" : "" }} className="col-12">
                                            <div className="form-group">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <button onClick={() => printClick("tr")} type="button" className="btn btn-primary">{t("printSubscriptionForm")}</button>
                                                    {
                                                        ReactSession.get('user').id == 228342 ?
                                                            <button type="button" data-target="#convertManagerModal" data-toggle="modal" className="btn btn-primary">Üyeyi Yönetici Yap</button>
                                                            : ""

                                                    }

                                                    <button type="button" onClick={() => printClick("en")} className="btn btn-primary">{t("printSubscriptionFormInEnglish")}</button>

                                                </div>
                                            </div>
                                            {console.log(userData.Status)}
                                            {
                                                userData.Status == false ?

                                                    <div className="alert alert-danger" role="alert">
                                                        <strong> Pasif Edilme Nedeni:</strong>  {userData.AddressWork}
                                                    </div>
                                                    : ""
                                            }
                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="tab-pane fade" id="kimlik">

                                    <Division className="row">
                                        <Division className="form-group row">
                                            <Text Tag="h4" className="mb-2">{t("identification")}</Text>

                                            <Division className="col-md-6 mt-2">
                                                <Division className="row">
                                                    <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">{t("gender")}</Text>
                                                    </Division>
                                                    <Division className="col-md-8">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">

                                                            <select name="Gender" id="Gender" defaultChecked={userData.Gender} className=" form-control material-icons"  >
                                                                <option value="">{t("selectGender.selectGender")}</option>
                                                                <option value="E">{t("selectGender.Select1")}</option>
                                                                <option value="F">{t("selectGender.Select2")}</option>
                                                            </select>
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <FemaleIcon />
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>

                                                <Division className="row mt-2">
                                                    <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">Kayıt Yeri</Text>
                                                    </Division>
                                                    <Division className="col-md-8">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <select name="RegistrationPlaceId" id="RegistrationPlaceId" className="infoSelect select-search form-control material-icons"  >
                                                                <option value={"1"}>İstasyon</option>
                                                                <option value={"2"}>Abone Noktası</option>
                                                                <option value={"3"}>Web</option>
                                                                <option value={"4"}>Admin Panel</option>
                                                                <option value={"5"}>Mobil</option>
                                                                <option value={"0"}>Diğer</option>

                                                            </select>

                                                        </Division>
                                                    </Division>
                                                </Division>
                                                <Division className="row mt-2">
                                                    <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">{t("selectTourist.label")}</Text>
                                                    </Division>
                                                    <Division className="col-md-8">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <select name="Tourist" id="Tourist" className=" form-control material-icons"   >
                                                                <option value="false">{t("selectTourist.Select2")}</option>
                                                                <option value="true">{t("selectTourist.Select1")}</option>
                                                            </select>
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <WorkOutlineIcon />
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>
                                            </Division>
                                            <Division className="col-md-6 mt-2">
                                                <Division className="form-group row">
                                                    <Division className="col-md-4 d-flex justify-content-start align-items-center align-content-center">
                                                        <Text Tag="h6" className="m-0">{t("birthDate")}</Text>
                                                    </Division>
                                                    <Division className="col-md-8">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                            <input ref={birthDateRef} defaultValue={userData.BirthDate} name="birthDate" className="form-control material-icons" type="text" />
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <CalendarTodayIcon />
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>

                                                <Division className="form-group row">
                                                    <Text Tag="label" className="col-form-label col-lg-4">{t("userId")}</Text>
                                                    <Division className="col-lg-8">
                                                        <Division className="form-group form-group-feedback form-group-feedback-left">
                                                            <input type="text" id="TC" autoComplete='false' name="TcNumber" className="form-control form-control-lg" />
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <i className="icon-user"></i>
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>
                                                <Division className="form-group row">
                                                    <Text Tag="label" className="col-form-label col-lg-4">{t("passportNumber")}</Text>
                                                    <Division className="col-lg-8">
                                                        <Division className="form-group form-group-feedback  mb-0">
                                                            <input name="PassportNumber" defaultValue={userData.PassportNumber} type="text" className="form-control material-icons" placeholder={t("passportNumber")} />
                                                            <Division className="form-control-feedback form-control-feedback-lg">
                                                                <PublicIcon />
                                                            </Division>
                                                        </Division>
                                                    </Division>
                                                </Division>
                                            </Division>
                                        </Division>
                                    </Division>
                                </Division>

                                <Division className="tab-pane fade" id="iletisim">

                                    <Division className="row">

                                        <Division className="col-md-12">
                                            <Text Tag="h4" className="mt-4 mb-2">{t("homeAddress")}</Text>
                                            <Division className="row">
                                                <Division className="col-md-2 ">
                                                    <Text Tag="h6" className="m-0">{t("address")}</Text>
                                                </Division>
                                                <Division className="col-md-10">
                                                    <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                        <textarea name="AddressHome" id="AddressHome" className="form-control  material-icons" defaultValue={userData.AddressHome} rows="5"></textarea>
                                                        <Division className="form-control-feedback form-control-feedback-lg">
                                                            <MapIcon />
                                                        </Division>
                                                    </Division>
                                                </Division>
                                            </Division>
                                            <Division className="row mt-2">
                                                <Division className="col-md-2 ">
                                                    <Text Tag="h6" className="m-0">{t("province")}</Text>
                                                </Division>
                                                <Division className="col-md-10">
                                                    <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                        <select name="AddressHomeCityId" id="AddressHomeCityId" className="dateHomeCity select-search form-control material-icons" >
                                                            <option value="0">{t("selectProvince")}</option>
                                                        </select>

                                                    </Division>
                                                </Division>
                                            </Division>
                                            <Division className="row mt-2">
                                                <Division className="col-md-2 d-flex justify-content-start align-items-center align-content-center">
                                                    <Text Tag="h6" className="m-0">{t("district")}</Text>
                                                </Division>
                                                <Division className="col-md-10">
                                                    <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                                        <select name="AddressHomeCountyId" id="AddressHomeCountyId" className="dateHomeCounty select-search form-control material-icons" >
                                                            <option value="0">{t("selectDistrict")}</option>

                                                        </select>

                                                    </Division>
                                                </Division>
                                            </Division>

                                        </Division>
                                    </Division>
                                </Division>
                                <Division className="tab-pane fade" id="kullanim">


                                    {pageLoad == true ?
                                        <TableData
                                            setTable={(e) => {
                                                var table = tables
                                                table.push(e);
                                                setTables({ tables: table })
                                            }}
                                            customOrder={[3, 'desc']}
                                            customClass="col-lg-12"
                                            searching={false}
                                            auth={1}
                                            abilityid={1}
                                            length="5"
                                            cardTitle={"Kullanım Geçmişi"}

                                            ajaxUrl="/api/Admin/Member/Trips"
                                            tableName="cyclists-table"
                                            filter={cyclistsFilter}
                                            columns={cyclistsColumns} />
                                        : ""
                                    }

                                </Division>
                                <Division className="tab-pane fade" id="bakiye-gecmisi">


                                    {pageLoad == true ?
                                        <TableData
                                            customOrder={[6, 'asc']}
                                            setTable={(e) => {
                                                var table = tables
                                                table.push(e);
                                                setTables({ tables: table })
                                            }}
                                            customClass="col-lg-12"
                                            cardTitle={"Bakiye Geçmişi"}
                                            searching={false}
                                            auth={0}
                                            abilityid={1}
                                            length="5"
                                            ajaxUrl="/api/Admin/Member/PaymentHistory"
                                            tableName="user-credit"
                                            filter={creditFilters}
                                            columns={creditColumns} />
                                        : ""
                                    }
                                </Division>

                                <Division className="tab-pane fade" id="bakiye-gecmisi">


                                    {pageLoad == true ?
                                        <TableData
                                            customOrder={[6, 'asc']}
                                            setTable={(e) => {
                                                var table = tables
                                                table.push(e);
                                                setTables({ tables: table })
                                            }}
                                            customClass="col-lg-12"
                                            cardTitle={"Bakiye Geçmişi"}
                                            searching={false}
                                            auth={0}
                                            abilityid={1}
                                            length="5"
                                            ajaxUrl="/api/Admin/Member/PaymentHistory"
                                            tableName="user-credit"
                                            filter={creditFilters}
                                            columns={creditColumns}

                                        />
                                        : ""
                                    }
                                </Division>
                                <Division className="tab-pane fade" id="not-gecmisi">


                                    {pageLoad == true ?
                                        <TableData
                                            customOrder={[4, 'desc']}//default olarak ilk kolonu sıralıyor biz burdan custom olarak belirtebiliriz
                                            setTable={(e) => {
                                                var table = tables
                                                table.push(e);
                                                setTables({ tables: table })//tablo örneğini aldığımız yer
                                            }}
                                            customClass="col-lg-12"
                                            cardTitle={"Kullanıcı Notları"}
                                            searching={false}
                                            auth={0}
                                            abilityid={1}
                                            length="5"//lenght 5 kalsın ne kadar az veri olursa daha hızlı açılır
                                            ajaxUrl="/api/Admin/Member/Notes"//ajax url
                                            tableName="not_table"//tablonun id si
                                            filter={notFilters}//uygulanacak filtreleri verdiğimiz props
                                            columns={notColumns}//hangi kolonları istiyorsak değişken olarak belirtiyoruz

                                        />
                                        : ""
                                    }
                                </Division>
                            </Division>
                        </Division>
                        <Division className="form-group row mt-2 d-flex justify-content-center">
                            <Division className="col-lg-3">
                            
                                <button id="submitFormButton" type="submit" className="btn btn-secondary w-100 h-100"  
                                 style={{ display: (  String(userData.MemberType) == "SüperVisor" ||  userData.MemberType == null ) ? "none" : "" }}  
  >{t("save")}</button>
                            </Division>
                            <Division className="col-lg-3">
                                <button type='button' data-toggle="modal" data-target="#not_ekle_modal" className="btn btn-warning w-100 h-100">{t("Not Ekle")}</button>

                                {/* Not Alanı */}
                            </Division>
                            <Division className="col-lg-3">
                                <button type='button' data-toggle="modal" data-target="#mdl_bildirimGonder" className="btn btn-primary w-100 h-100">{t("Bildirim Gönder")}</button>
                            </Division>


                            
                            <Division className="col-lg-3">
                                <button type="button" onClick={() => { window.location.href = "/users/list" }} className="btn btn-danger w-100 h-100">{t("cancel")}</button>
                            </Division>
                        </Division>
                    </Division>
                </form>
            </Division >
            <div className="modal fade" id="credit_add_modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Kredi Ekle</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Ad Soyad")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <input type="text" className="form-control form-control-lg userID" readOnly defaultValue={`${userData ?? userData.Name} ${userData ?? userData.Surname}`} placeholder={t("ID")} />
                                    </Division>
                                </Division>

                            </div>

                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Eklenecek Bakiye")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <input type="text" className="form-control form-control-lg balanceNumber" placeholder={t("Eklenecek Bakiye")} />
                                    </Division>
                                </Division>

                            </div>

                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Açıklama")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <input type="text" className="form-control form-control-lg addCreditDescription" placeholder={t("Açıklama")} />

                                    </Division>
                                </Division>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                            <button type="button" className="btn btn-primary" onClick={creditAddClick}>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="convertManagerModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Üyeyi Yönetici Yap</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Şifre")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <input type="password" className="form-control form-control-lg convertManagerPassword" placeholder={t("Şifre")} />
                                    </Division>
                                </Division>

                            </div>

                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Yeni Şifre")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <input type="password" className="form-control form-control-lg convertManagerPasswordRepeat" placeholder={t("Yeni Şifre")} />
                                    </Division>
                                </Division>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={convertManager}>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="not_ekle_modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Not Ekle</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Not Giriniz")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <textarea id='not_content' className="form-control form-control-lg "
                                        />
                                    </Division>
                                </Division>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={NotEkle}>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="mdl_bildirimGonder" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">BİLDİRİM GONDER</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                            <input type="hidden"  id="notification-user-id"  value={userData.id} />


                            <div className="row">

                            <div className="col-6">
                                    <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Telefon Numarası")} ( {userData.DeviceType == 1 ? "ANDROID" : "IOS"} )</Text> 

                                    <Division className="col-lg-12 align-self-center">
                                        <Division className="form-group form-group-feedback ">
                                        <input type="text" autoComplete='false'   id="notification-phone" className="form-control form-control-lg"
                                                        defaultValue={userData.Phone} disabled/>
                                        </Division>
                                    </Division>
                                </div>
                                <div className="col-6">

                                    <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Bildirim Tipi")}</Text>
                                    <Division   className="col-lg-12">
                                        <select name="notification-type" id="notification-type" className=" form-control w-100" > 
                                            <option value="5">{t("SMS")}</option>
                                            <option value="6">{t("Push Notification")}</option>
                                            <option value="4">{t("E posta")}</option>
                                        </select>
                                    </Division>
                                </div>

                            </div>


                                <Text Tag="label" className="col-form-label col-lg-12 align-self-center">{t("Bildirm Metnini Giriniz")}</Text>

                                <Division className="col-lg-12 align-self-center">
                                    <Division className="form-group form-group-feedback ">
                                        <textarea id='notification-content' className="form-control form-control-lg " value={"Sn. "+userData.Name + " "+userData.Surname+", Lütfen destek birimi ile iletişime geçiniz."}
                                        />
                                    </Division>
                                </Division>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Kapat</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={BildirimGonder}>Gönder</button>
                        </div>
                    </div>
                </div>
            </div>







        </Division >
    )

}
export default withTranslation()(UserEdit);
function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return (data);
}