import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import { getEndPoint } from "../../class/api";

import Text from '../../components/elements/text';
import Division from '../../components/elements/content-division';
import UnorderedList, { ListItem } from '../../components/elements/unordered-list';
import Button from '../../components/elements/button';
import Anchor from '../../components/elements/anchor';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
const cookies = new Cookies();

class EventNotificationDefinitions extends Component {
    constructor(props) {
        super(props);
        this.eventData = "";
        this.focusData = "";
        var config = {
            method: 'put',
            url: getEndPoint() + '/api/Admin/NotificationEventDefinition',
            headers: {
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "Filter": {
                    "NotificationEventDefinitionId": this.props.router.params.eventID,
                    "order": "NotificationEventDefinitionId",
                    "sort": "ASC"
                }
            })
        };

        axios(config)
            .then((response) => {
                this.eventData = response.data.data[0];
                $('#ModulName').val(response.data.data[0].ModuleName);
                $('#ModuleCode').val(response.data.data[0].ModuleCode);
                $('#Description').val(response.data.data[0].Description);
                $("#NotifyMember").prop('checked', response.data.data[0].NotifyMember);
                $("#NotifyAdmin").prop('checked', response.data.data[0].NotifyAdmin);
                $("#NotifyEmergency").prop('checked', response.data.data[0].NotifyEmergency);
                $("#MessageSmsMember").val(response.data.data[0].MessageSmsMember);
                $("#MessageEmailMember").val(response.data.data[0].MessageEmailMember);
                $("#MessageTxtMember").val(response.data.data[0].MessageTxtMember);
                $("#MessagePushMember").val(response.data.data[0].MessagePushMember);
                $("#MessageSmsAdmin").val(response.data.data[0].MessageSmsAdmin);
                $("#MessageEmailAdmin").val(response.data.data[0].MessageEmailAdmin);
                $("#MessageTxtAdmin").val(response.data.data[0].MessageTxtAdmin);
                $("#MessagePushAdmin").val(response.data.data[0].MessagePushAdmin);
            })

    }
    componentDidMount() {
        const { t } = this.props;
        const selectLanguage = {
            noResUnorderedListts: () => t("select2Localization.noResUnorderedListts", { ns: "translations" }),
            searching: () => t("select2Localization.searching", { ns: "translations" }),
            maximumSelected: () => t("select2Localization.maximumSelected", { ns: "translations" }),
            loadingMore: () => t("select2Localization.loadingMore", { ns: "translations" }),
            inputTooShort: () => t("select2Localization.inputTooShort", { ns: "translations" }),
            inputTooLong: () => t("select2Localization.inputTooLong", { ns: "translations" }),
            errorLoading: () => t("select2Localization.errorLoading", { ns: "translations" }),
        };

        $.extend($.summernote.lang, {
            "customLang": { ...(t("summerNoteEditorLocalization", { ns: "translations" })) }
        });

        $('.summernote-height').summernote({
            height: 200,
            lang: i18n.language == "en" ? "en-US" : "customLang"
        });
    }

    componentDidUpdate = () => this.componentDidMount();

    // onRowTextChanged(event, index) {
    //     $(`#row-${index}`).html(event.target.value);
    // }
    focusFunc = (e) => {
        this.focusData = $(e.target)
    }
    focusFillData = (e, data) => {
        $(this.focusData).val($(this.focusData).val() + data)
    }
    handleClickSave = () => {
        const _this = this;
        const { t } = this.props

        swal.fire({
            title: t("swal.info.head", { ns: "translations" }),
            text: t("swal.info.subject", { ns: "translations", questionData: t("swal.operationType.save", { ns: "translations" }) }),
            showDenyButton: true,
            confirmButtonText: t("swal.questionSaveButton", { ns: "translations" }),
            denyButtonText: t("swal.questionCancelButton", { ns: "translations" }),
        }).then((result) => {
            if (result.isConfirmed) {
                var data = JSON.stringify({
                    "NotificationEventDefinitionId": this.props.router.params.eventID,
                    "ModuleName": $('#ModulName').val(),
                    "ModuleCode": $('#ModuleCode').val(),
                    "Description": $('#Description').val(),
                    "NotifyMember": $("#NotifyMember").prop('checked'),
                    "NotifyAdmin": $("#NotifyAdmin").prop('checked'),
                    "NotifyEmergency": $("#NotifyEmergency").prop('checked'),
                    "MessageSmsMember": $("#MessageSmsMember").val(),
                    "MessageEmailMember": $("#MessageEmailMember").val(),
                    "MessageTxtMember": $("#MessageTxtMember").val(),
                    "MessagePushMember": $("#MessagePushMember").val(),
                    "MessageSmsAdmin": $("#MessageSmsAdmin").val(),
                    "MessageEmailAdmin": $("#MessageEmailAdmin").val(),
                    "MessageTxtAdmin": $("#MessageTxtAdmin").val(),
                    "MessagePushAdmin": $("#MessagePushAdmin").val(),
                    "Status": true
                });

                var config = {
                    method: 'post',
                    url: getEndPoint() + '/api/Admin/NotificationEventDefinition',
                    headers: {
                        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data: data
                };
                axios(config)

                    .then(function (response) {
                        swal.fire({
                            title: t("swal.success.head", { ns: "translations" }),
                            text: t("swal.success.subject", { ns: "translations" }),
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.success.icon", { ns: "translations" })
                        })
                    })
                    .catch(function (error) {
                        swal.fire({
                            title: t("swal.error.head", { ns: "translations" }),
                            text: t("swal.error.subject", { ns: "translations" }) + error.message,
                            confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                            icon: t("swal.error.icon", { ns: "translations" })
                        })

                    });
            }
        })

    }
    render() {
        const { t } = this.props;
        const arrayRowCount = 8;

        const parameters = t("parameters.generalParameters").reduce((resUnorderedListtArray, item, index) => {
            const chunkIndex = Math.floor(index / 9)

            if (!resUnorderedListtArray[chunkIndex]) {
                resUnorderedListtArray[chunkIndex] = [] // start a new chunk
            }
            resUnorderedListtArray[chunkIndex].push(item + " ")
            return resUnorderedListtArray
        }, [])

        return (
            <Division className="p-2">
                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header">
                        <Text Tag="h6" className="card-title">{t("menu.management.eventNotificationDefinitions", { ns: "translations" })}</Text>
                    </Division>

                    <Division className="card-body mt-2">
                        <UnorderedList className="nav nav-tabs nav-tabs-highlight">
                            <ListItem className="nav-item"><Anchor href="#module-details" className="nav-link active" data-toggle="tab">{t("tabs.moduleDetails")}</Anchor></ListItem>
                            <ListItem className="nav-item"><Anchor href="#user-messages" className="nav-link" data-toggle="tab">{t("tabs.userMessages")}</Anchor></ListItem>
                            <ListItem className="nav-item"><Anchor href="#admin-messages" className="nav-link" data-toggle="tab">{t("tabs.adminMessages")}</Anchor></ListItem>
                        </UnorderedList>

                        <Division className="row">
                            <Division className='col-md-12'>
                                <Division className="alert alert-info m-0">
                                    <h4><strong>{t("parameters.parameters")}</strong></h4>
                                    <UnorderedList className="media-list">
                                        {
                                            parameters.map((element, index) => {
                                                return (
                                                    <ListItem key={`${index}`} className='media  m-0 mb-2'>
                                                        {
                                                            element.map((elmData, index) => {

                                                                return (<a key={index} onClick={(e) => this.focusFillData(e, elmData)}>{elmData}</a>)

                                                            })
                                                        }
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </UnorderedList>
                                </Division>
                            </Division>
                        </Division>
                        <Division className="tab-content">
                            <Division className="tab-pane fade active show" id="module-details">
                                <Division className='row'>
                                    <Division className='col-md-6 mt-2'>
                                        <label>{t("ModulName")}</label>
                                        <input readOnly id='ModulName' className="form-control" rows="5" style={{ resize: "none" }}></input>
                                    </Division>
                                    <Division className='col-md-6 mt-2'>
                                        <label>{t("ModuleCode")}</label>
                                        <input readOnly id='ModuleCode' className="form-control" rows="5" style={{ resize: "none" }}></input>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>

                                        <div class="custom-control custom-switch mb-2">
                                            <input type="checkbox" class="custom-control-input" id="NotifyMember" />
                                            <label class="custom-control-label " for="NotifyMember">{t("NotifyMember")}</label>
                                        </div>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>

                                        <div class="custom-control custom-switch  mb-2">
                                            <input type="checkbox" class="custom-control-input" id="NotifyAdmin" />
                                            <label class="custom-control-label " for="NotifyAdmin">{t("NotifyAdmin")}</label>
                                        </div>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>

                                        <div class="custom-control custom-switch  mb-2">
                                            <input type="checkbox" class="custom-control-input" id="NotifyEmergency" />
                                            <label class="custom-control-label " for="NotifyEmergency">{t("NotifyEmergency")}</label>
                                        </div>
                                    </Division>

                                    <Division className='col-md-12 mt-2'>
                                        <label>{t("Description")}</label>
                                        <input readOnly id='Description' className="form-control" rows="5" style={{ resize: "none" }}></input>
                                    </Division>
                                </Division>
                            </Division>
                            <Division className="tab-pane fade" id="user-messages">
                                <Division className='row'>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("smsMessage")}</label>
                                        <textarea id='MessageSmsMember' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("textMail")}</label>
                                        <textarea id='MessageEmailMember' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("textMessage")}</label>
                                        <textarea id='MessageTxtMember' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-12 mt-2'>
                                        <label className="form-control">{t("MessagePushMember")}</label>

                                        <textarea id='MessagePushMember' onFocus={(e) => this.focusFunc(e)} rows="5" className="form-control"></textarea>
                                    </Division>
                                </Division>
                            </Division>
                            <Division className="tab-pane fade" id="admin-messages">
                                <Division className='row'>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("smsMessage")}</label>
                                        <textarea id='MessageSmsAdmin' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("textMail")}</label>
                                        <textarea id='MessageEmailAdmin' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-4 mt-2'>
                                        <label className="form-control">{t("textMessage")}</label>
                                        <textarea id='MessageTxtAdmin' onFocus={(e) => this.focusFunc(e)} className="form-control" rows="5" style={{ resize: "none" }}></textarea>
                                    </Division>
                                    <Division className='col-md-12 mt-2'>
                                        <label>{t("MessagePushAdmin")}</label>
                                        <textarea id='MessagePushAdmin' onFocus={(e) => this.focusFunc(e)} data-type="textss" rows="5" className="form-control"></textarea>
                                    </Division>
                                </Division>
                            </Division>
                            <button onClick={this.handleClickSave} className="btn btn-primary mt-2">{t("save")}</button>
                        </Division>
                    </Division>
                </Division>

            </Division >
        );
    }
}
const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
export default withTranslation(["managementEventNotificationDefinitions", "translations"])(withRouter(EventNotificationDefinitions));