import React from "react";

// <li ...></li>
export function ListItem(props) {
    return (
        <li {...props}>{props.children}</li>
    );
}

// <ul ...></ul>
export default function UnorderedList(props) {
    return (
        <ul {...props}>
            {props.children}
        </ul>
    );
}