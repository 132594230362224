import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { getEndPoint } from "../../class/api";

import { DateRangePickerHandler } from '../../js/plugins/pickers/daterangepicker';
import swalInit from '../../js/plugins/notifications/sweet_alert.min.js'
import LoopIcon from '@mui/icons-material/Loop';
import Division from '../../components/elements/content-division';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Select } from '../../components/Select';
import moment from 'moment';
const cookies = new Cookies();
let bikeList;
class TagChange extends Component {
    constructor(props) {
        super(props);
        this.tagHandleClick = this.tagHandleClick.bind(this);
        this.bikeGpsSelect = React.createRef();
        this.tagNameRef = React.createRef();

        this.dateRangeRef = React.createRef();
    }

    componentDidMount() {
        const _this = this;
        const { t } = this.props
        DateRangePickerHandler(this.dateRangeRef.current, {
            parentEl: '.content-inner',
            showDropdowns: true,
            locale: t("dateRangePickerLocalization", { ns: "translations" }),
            "autoApply": true,
            ranges: {
                'Bugün': [moment(), moment()],
            },
        });
    }
    tagHandleClick() {
        const _this = this;
        const { t } = this.props
        if (this.tagNameRef.current.value == '') {
            swal.fire({
                title: t("swal.error.head", { ns: "translations" }),
                text: t("swal.error.missingParamerter", { ns: "translations", requiredData: t("tag") }),
                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                icon: t("swal.error.icon", { ns: "translations" })
            })
            return;
        }
        else {

            var bikeSelect = $('#bikeSelect').val();
            var bikeData = ""
            var config = {
                method: 'put',
                url: getEndPoint() + '/api/Admin/Station/Vehicle',
                headers: {
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                    "Accept": "application/json",
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({ "Filter": { "VehicleId": parseInt(bikeSelect), } })
            };

            axios(config)
                .then((response) => {
                    bikeData = response.data.data[0];
                    bikeData.RFID = this.tagNameRef.current.value;
                    var data = JSON.stringify(bikeData);
                    var config = {
                        method: 'post',
                        url: getEndPoint() + '/api/Admin/Station/Vehicle',
                        headers: {
                            'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        data: data
                    };

                    axios(config)
                        .then((response) => {

                            swal.fire({
                                title: t("swal.success.head", { ns: "translations" }),
                                text: t("swal.success.subject", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                icon: t("swal.success.icon", { ns: "translations" })
                            })
                            this.componentDidMount()

                        })
                        .catch((error) => {
                            swalInit.fire({
                                title: t("swal.error.head", { ns: "translations" }),
                                text: t("swal.error.subject", { ns: "translations" }) + error.message,
                                icon: t("swal.error.icon", { ns: "translations" }),
                                confirmButtonText: t("swal.confirmButton", { ns: "translations" }),
                                padding: 40
                            });

                        });
                });



        }

    }

    render() {
        const { t } = this.props;

        return (
            <Division className="p-2">
                <Division className="card">
                    <Division style={{ backgroundColor: "#c9cacb" }} className="card-header header-elements-inline">
                        <h6 className="card-title">{t("menu.bicycles.tagChange", { ns: "translations" })}</h6>
                        <Division className="header-elements"></Division>
                    </Division>
                    <Division className="card-body py-2">
                        <Division className="row">
                            <Division className="col-md-7 mt-2">
                                <Division className="form-group form-group-feedback form-group-feedback-left mb-0">
                                    <Select
                                        defaultValues="1"
                                        selectID="bikeSelect"
                                        customCss={"bikeSelect"}
                                        resultID={["VehicleId"]}
                                        result={["VehicleNo", "Name", "RFID", "VehicleType", "StatusIdTxt"]}
                                        ajaxURL="/api/Admin/Station/Vehicle"
                                    />
                                </Division>
                            </Division>

                            <Division className="col-md-5 mt-2">
                                <input ref={this.tagNameRef} placeholder='Tag Giriniz' name="tagName" type="number" className="form-control" />
                            </Division>
                            <Division className="col-md-12 text-right pt-4">
                                <button onClick={this.tagHandleClick} className="btn btn-primary col-12">
                                    <LoopIcon className="mr-2" />
                                    {t("changes")}
                                </button>
                            </Division>
                        </Division>
                    </Division>
                </Division>

            </Division>
        );
    }
}

export default withTranslation(['gpsThermalMap', 'translations'])(TagChange);